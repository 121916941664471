<template>
    <v-form ref="form" v-model="valid" validate-on="submit" :class="{'new-item': isAddNewItem}">
        <table style="width: 100%; border-spacing: 1px; margin-bottom: -1px" class="tblForm swatchG9BG">
            <tr v-if="index == 0 || workTaskProductList.length == 0">
                <td class="swatchG7BG">{{ $t('NumberShort') }}</td>
                <td v-if="showMixColumn" class="swatchG7BG">{{ $t('Mix') }}</td>
                <td class="swatchG7BG">{{ $t('Product') }}</td>
                <td class="swatchG7BG">{{ $t('Type') }}</td>
                <td class="swatchG7BG">{{ $t('ApplicationRate') }}</td>
                <td class="swatchG7BG"></td>
            </tr>
            <tr>
                <td style="width: 7%; text-align: center" class="swatchWHTBG">
                    <span v-if="isAddNewItem">NEW</span>
                    <span v-if="!isAddNewItem">{{ index + 1 }}</span>
                </td>
                <td v-if="showMixColumn" style="width: 7%; text-align: center" class="swatchWHTBG">
                    <CircleIcon
                        :class="workTaskTypeCode == WorkTaskTypeCode.Fertilize ? 'swatchB2BG' : 'swatchB1BG'"
                        color="swatchWHT"
                        v-if="isAssociatedWithMixture"
                        :title="$t('ThisProductIsAssociatedWithAMixture')">
                        <FontAwesomeIcon :icon="['fas', 'flask-round-potion']" />
                    </CircleIcon>
                </td>
                <td style="width: 33%" class="swatchWHTBG">
                    <div style="display: flex; align-items: center">
                        <v-autocomplete
                            v-model="localWorkTaskProduct.productId"
                            :rules="[requiredRule, uniqueRule]"
                            required
                            :readonly="!isAddNewItem"
                            :placeholder="$t('SelectAProduct')"
                            :items="products"
                            item-title="productName"
                            item-value="productId"
                            max-width="250px"
                            menu-icon=""
                            class="swatchA1 font-weight-bold"
                            :class="{noSelection: !isAddNewItem}">
                            <template v-slot:append-inner>
                                <CircleIcon
                                    class="marginLeft marginRightSmall swatchG2BG"
                                    color="swatchWHT"
                                    v-if="!isAddNewItem">
                                    <FontAwesomeIcon :icon="['fas', 'lock']" />
                                </CircleIcon>
                                <CircleIcon
                                    class="marginLeft marginRightSmall swatchGRNBG"
                                    color="swatchWHT"
                                    v-else-if="localWorkTaskProduct.productId != null">
                                    <FontAwesomeIcon :icon="['fas', 'check']" />
                                </CircleIcon>
                            </template>
                        </v-autocomplete>

                        <!-- View Product button -->
                        <v-btn
                            v-if="localWorkTaskProduct.productId != null"
                            @click="viewProductModalStore.open(localWorkTaskProduct.productId)"
                            class="v-btn--custom ml-4">
                            <template v-slot:prepend>
                                <FontAwesomeIcon :icon="['fal', 'arrow-circle-right']" size="xl" />
                            </template>
                            {{ $t('View') }}
                        </v-btn>
                    </div>
                </td>
                <td style="width: 17%; text-align: center" class="swatchWHTBG">
                    <ProductSubtypeIcon
                        v-if="productDetails"
                        :product-type-code="productDetails.productTypeCode"
                        :product-subtype-code="productDetails.productSubtypeCode"
                        icon-height="38"
                        icon-width="38" />
                </td>
                <td style="width: 24%" class="swatchWHTBG">
                    <div style="display: flex; align-items: center">
                        <v-text-field
                            v-model="applicationRate"
                            :rules="isActionMode ? [requiredRule] : [requiredRule, numericGreaterThanZeroRule]"
                            required
                            max-width="100px"
                            class="font-weight-bold"
                            :class="isActualApplicationRateDifferentToPlanned ? 'swatchRED-text' : 'swatchA1-text'" />

                        <v-select
                            v-model="applicationRateUnitCode"
                            :rules="[requiredRule]"
                            required
                            :items="applicationRateUnits"
                            item-title="applicationRateUnitName"
                            item-value="applicationRateUnitCode"
                            max-width="150px"
                            class="ml-2"
                            :class="
                                isActualApplicationRateUnitCodeDifferentToPlanned ? 'swatchRED-text' : 'swatchA1-text'
                            " />
                    </div>
                </td>
                <td style="width: 12%; text-align: center" class="swatchWHTBG">
                    <!-- Add button. Shown when the component is in "Add" mode. -->
                    <v-btn
                        v-if="isAddNewItem"
                        @click="addWorkTaskProduct"
                        class="v-btn--custom"
                        style="align-self: flex-end; max-width: 100%">
                        <template v-slot:prepend>
                            <FontAwesomeIcon :icon="['fal', 'plus']" size="xl" />
                        </template>
                        {{ $t('Add') }}
                    </v-btn>

                    <!-- Delete button. Shown when displaying an existing work task product. It will be replaced with 
                        the Reset button in action mode if this is a product that has been saved against the task. -->
                    <v-btn
                        v-if="!isAddNewItem && (!isActionMode || localWorkTaskProduct.workTaskProductId == null)"
                        @click="deleteWorkTaskProduct"
                        class="v-btn--custom"
                        style="align-self: flex-end; max-width: 100%">
                        <template v-slot:prepend>
                            <FontAwesomeIcon :icon="['fal', 'trash']" size="xl" />
                        </template>
                        {{ $t('Delete') }}
                    </v-btn>

                    <!-- Reset button. Shown in action mode if this is a product that has been saved against this task -->
                    <v-btn
                        v-if="isActionMode && localWorkTaskProduct.workTaskProductId != null"
                        @click="resetWorkTaskProduct"
                        class="v-btn--custom"
                        style="align-self: flex-end; max-width: 100%">
                        <template v-slot:prepend>
                            <FontAwesomeIcon :icon="['fal', 'arrows-rotate']" size="xl" />
                        </template>
                        {{ $t('Reset') }}
                    </v-btn>
                </td>
            </tr>
        </table>
    </v-form>
</template>

<script setup lang="ts">
import Validation from '@/helpers/ValidationHelper';
import i18n from '@/i18n';
import {GetProductsResponse} from '@/models/api/responses/data-source/GetProductsResponse';
import {WorkTaskProductForm} from '@/models/work-tasks/WorkTaskProductForm';
import {computed, nextTick, onMounted, ref, watch} from 'vue';
import ProductSubtypeIcon from '@/components/ProductSubtypeIcon.vue';
import {applicationRateUnits} from '@/services/application-rate-units-service';
import {WorkTaskMixtureForm} from '@/models/work-tasks/WorkTaskMixtureForm';
import {ProductTypeCode} from '@/enums/product-type-code';
import {WorkTaskTypeCode} from '@/enums/work-task-type-code';
import ManageWorkTaskHelper from '@/helpers/ManageWorkTaskHelper';
import {ApplicationRateUnitCode} from '@/enums/application-rate-unit-code';
import {WorkTaskActionTypeCode} from '@/enums/work-task-action-type-code';
import {WorkTaskSearchResultDto} from '@/models/data-transfer-objects/search/work-task-search/WorkTaskSearchResultDto';
import {useViewProductModalStore} from '@/stores/modals/view-product-modal-store';
const viewProductModalStore = useViewProductModalStore();

const props = withDefaults(
    defineProps<{
        workTaskProduct?: WorkTaskProductForm | null;
        workTaskProductList: WorkTaskProductForm[];
        workTaskMixtureList?: WorkTaskMixtureForm[] | null;
        products: GetProductsResponse[];
        index: number;
        workTaskTypeCode: WorkTaskTypeCode;
        isActionMode?: boolean;
        workTaskActionTypeCode?: WorkTaskActionTypeCode | null;
        workTask?: WorkTaskSearchResultDto | null;
    }>(),
    {
        workTaskProduct: null,
        workTaskMixtureList: null,
        isActionMode: false,
        workTaskActionTypeCode: null,
        workTask: null,
    },
);
const emit = defineEmits(['add', 'update', 'delete']);

// Form
const form = ref();
const valid = ref<boolean>(true);
const isLoading = ref<boolean>(true);
const isAutomaticallySettingApplicationRate = ref<boolean>(false);
const localWorkTaskProduct = ref<WorkTaskProductForm>(new WorkTaskProductForm());
let newItemCount = 0;
let hasApplicationRateBeenChanged = false;

// Rules
const requiredRule = Validation.createRule_Required();
const numericGreaterThanZeroRule = Validation.createRule_Numeric(
    i18n.global.t('Validation_MustBeGreaterThanZero'),
    0.000000000001,
);
const uniqueRule = ref((v: number | null) => {
    if (!v) return true;
    if (!isAddNewItem.value) return true;
    return (
        props.workTaskProductList.filter((wtm) => wtm.productId == v).length == 0 ||
        i18n.global.t('Validation_ProductUnique')
    );
});

/**
 * Adds the product.
 */
const addWorkTaskProduct = async () => {
    // Perform final client side validation of form
    await validate();

    // If form is valid
    if (valid.value) {
        // Increment new item count so that new items have a unique ID
        newItemCount += 1;

        // Set new item ID
        localWorkTaskProduct.value.newItemId = 'NewItem' + newItemCount;

        // Call emit
        emit('add', localWorkTaskProduct.value);

        // Reset data so that another item can be added if needed
        localWorkTaskProduct.value = new WorkTaskProductForm();
    }
};

/**
 * Deletes the product.
 */
const deleteWorkTaskProduct = () => {
    emit('delete', props.index, localWorkTaskProduct.value);
};

/**
 * Resets the actual application details to their planned values.
 */
const resetWorkTaskProduct = () => {
    if (hasApplicationRateBeenChanged || props.workTaskActionTypeCode == WorkTaskActionTypeCode.Cancelled) {
        // If the application rate has been changed OR if the action is "Cancel", set the application rate back to its planned value
        localWorkTaskProduct.value.actualApplicationRate = localWorkTaskProduct.value.plannedApplicationRate;
    } else {
        // Set isAutomaticallySettingApplicationRate to true so that the "hasApplicationRateBeenChanged" flag does not get updated
        isAutomaticallySettingApplicationRate.value = true;

        // If the application rate has not been changed and the action is "Complete", set the application rate to 0
        localWorkTaskProduct.value.actualApplicationRate = 0;

        // End loading (nextTick is used to ensure that the watch events on localWorkTaskProduct are not triggered during the load)
        nextTick(() => {
            isAutomaticallySettingApplicationRate.value = false;
        });
    }

    // Always reset the unit code back to its default value (if it doesn't have one then leave it unchanged)
    localWorkTaskProduct.value.actualApplicationRateUnitCode =
        localWorkTaskProduct.value.plannedApplicationRateUnitCode ??
        localWorkTaskProduct.value.actualApplicationRateUnitCode;
};

/**
 * Validate the form.
 */
const validate = async (): Promise<boolean> => {
    if (form.value) {
        const {valid} = await form.value.validate();
        return valid;
    }
    return false;
};

/**
 * If the action mode is set to Complete, automatically set the application rate details to their defaults.
 * Otherwise, set the unit code to the default and the rate to 0.
 */
const setDefaultApplicationDetails = () => {
    /**
     * Defaults should only be set if:
     * Component is in Action mode.
     * Component is not in "New Item" mode
     * This is not a product that has just been manually added as part of the "action".
     */
    if (props.isActionMode && !isAddNewItem.value && localWorkTaskProduct.value.workTaskProductId != null) {
        // Set isLoading to true so that the "hasApplicationRateBeenChanged" flag does not get updated
        isAutomaticallySettingApplicationRate.value = true;

        if (props.workTaskActionTypeCode == WorkTaskActionTypeCode.Completed) {
            // Complete: Set the actual application rate details to their planned values.
            localWorkTaskProduct.value.actualApplicationRate = localWorkTaskProduct.value.plannedApplicationRate;
            localWorkTaskProduct.value.actualApplicationRateUnitCode =
                localWorkTaskProduct.value.plannedApplicationRateUnitCode;
        } else if (props.workTaskActionTypeCode == WorkTaskActionTypeCode.Cancelled) {
            // Cancel: Set the actual application rate details to 0
            localWorkTaskProduct.value.actualApplicationRate = 0;
            localWorkTaskProduct.value.actualApplicationRateUnitCode =
                localWorkTaskProduct.value.plannedApplicationRateUnitCode;
        }

        // End loading (nextTick is used to ensure that the watch events on localWorkTaskProduct are not triggered during the load)
        nextTick(() => {
            isAutomaticallySettingApplicationRate.value = false;
        });
    }
};

/**
 * Flag to indicate if this is in "Add" mode or if it's displaying an existing work task product.
 */
const isAddNewItem = computed(() => props.index == -1);

/**
 * Details of the selected product.
 */
const productDetails = computed(() => {
    return ManageWorkTaskHelper.getProductDetails(localWorkTaskProduct.value.productId, props.products);
});

/**
 * Flag to indicate if the product is associated with one of the mixtures that have been added to the work task.
 */
const isAssociatedWithMixture = computed(() => {
    // If in Action Task mode and if there is a mixture ID stored against the product, return true.
    // When in Manage Task mode there could still be a mixture ID stored against the product,
    // however this is ignored and the workTaskMixtureList is checked instead.
    if (props.isActionMode && localWorkTaskProduct.value.mixtureId != null) return true;

    return (
        props.workTaskMixtureList != null &&
        props.workTaskMixtureList.some(
            (wtm) =>
                wtm.mixtureProducts != null &&
                wtm.mixtureProducts.some((mp) => mp.productId == localWorkTaskProduct.value.productId),
        )
    );
});

/**
 * Flag to indicate if the Mix column should be shown.
 */
const showMixColumn = computed(() => {
    return props.workTaskTypeCode == WorkTaskTypeCode.Fertilize || props.workTaskTypeCode == WorkTaskTypeCode.Spray;
});

/**
 * Flag to indicate if the actual application rate is different to the planned value.
 */
const isActualApplicationRateDifferentToPlanned = computed(() => {
    return (
        props.isActionMode &&
        localWorkTaskProduct.value.actualApplicationRate != localWorkTaskProduct.value.plannedApplicationRate
    );
});

/**
 * Flag to indicate if the actual application rate unit code is different to the planned value.
 */
const isActualApplicationRateUnitCodeDifferentToPlanned = computed(() => {
    return (
        props.isActionMode &&
        localWorkTaskProduct.value.actualApplicationRateUnitCode !=
            localWorkTaskProduct.value.plannedApplicationRateUnitCode
    );
});

/**
 * Application rate used in the conditional v-model. If in action mode the "actual" value will be used, otherwise
 * the "planned" value will be used.
 */
const applicationRate = computed<number | null>({
    get() {
        return props.isActionMode
            ? localWorkTaskProduct.value.actualApplicationRate
            : localWorkTaskProduct.value.plannedApplicationRate;
    },
    set(value: number | null) {
        if (props.isActionMode) {
            localWorkTaskProduct.value.actualApplicationRate = value;
        } else {
            localWorkTaskProduct.value.plannedApplicationRate = value;
        }
    },
});

/**
 * Application rate used in the conditional v-model. If in action mode the "actual" value will be used, otherwise
 * the "planned" value will be used.
 */
const applicationRateUnitCode = computed<ApplicationRateUnitCode | null>({
    get() {
        return props.isActionMode
            ? localWorkTaskProduct.value.actualApplicationRateUnitCode
            : localWorkTaskProduct.value.plannedApplicationRateUnitCode;
    },
    set(value: ApplicationRateUnitCode | null) {
        if (props.isActionMode) {
            localWorkTaskProduct.value.actualApplicationRateUnitCode = value;
        } else {
            localWorkTaskProduct.value.plannedApplicationRateUnitCode = value;
        }
    },
});

// Update parent when the data is changed.
watch(
    () => localWorkTaskProduct,
    () => {
        if (!isLoading.value) {
            emit('update', localWorkTaskProduct.value);
        }
    },
    {deep: true},
);

// Set default application rate details when a product is selected.
watch(
    () => localWorkTaskProduct.value.productId,
    () => {
        // If this is the new item component, and a valid product has been selected
        if (isAddNewItem.value && localWorkTaskProduct.value.productId != null) {
            // Determine which application rate unit to use by default
            let defaultApplicationRateUnit;
            if (productDetails.value?.productTypeCode == ProductTypeCode.Chemical) {
                // If this is a Chemical, choose the default chemical unit
                defaultApplicationRateUnit = applicationRateUnits.find((aru) => aru.isDefaultChemicalUnit) || null;
            } else if (productDetails.value?.productTypeCode == ProductTypeCode.Fertilizer) {
                // If this is a Fertilizer, choose the default fertilizer unit
                defaultApplicationRateUnit = applicationRateUnits.find((aru) => aru.isDefaultFertilizerUnit) || null;
            } else {
                defaultApplicationRateUnit = applicationRateUnits.length > 0 ? applicationRateUnits[0] : null;
            }

            // Set default application rate unit
            applicationRateUnitCode.value = defaultApplicationRateUnit?.applicationRateUnitCode || null;

            // Clear application rate
            applicationRate.value = 0;
        }
    },
);

/**
 * Update application details when the action mode is changed.
 */
watch(
    () => props.workTaskActionTypeCode,
    () => {
        if (!isLoading.value) {
            setDefaultApplicationDetails();
        }
    },
);

/**
 * Record if the application rate has been changed.
 */
watch(
    () => localWorkTaskProduct.value.actualApplicationRate,
    (newVal, oldVal) => {
        if (!isLoading.value && !isAutomaticallySettingApplicationRate.value) {
            if (newVal != oldVal) {
                hasApplicationRateBeenChanged = true;
            }
        }
    },
);

onMounted(async () => {
    if (props.workTaskProduct) {
        localWorkTaskProduct.value = Object.assign(new WorkTaskProductForm(), props.workTaskProduct);
    }

    // End loading (nextTick is used to ensure that the watch events on localWorkTaskProduct are not triggered during the load)
    nextTick(() => {
        isLoading.value = false;

        // If the task has not been completed or cancelled
        if (!props.workTask?.isCompleted && !props.workTask?.isCancelled) {
            setDefaultApplicationDetails();
        }
    });
});

defineExpose({validate});
</script>
