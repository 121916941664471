import {WorkTaskTypeCode} from '@/enums/work-task-type-code';

export class SearchWorkTasksRequest {
    isQuickSearch: boolean = false;
    quickSearchText: string | null = null;
    workTaskId: number | null = null;
    workTaskIdList: number[] | null = null;
    farmSiteId: number | null = null;
    farmSiteIdList: number[] | null = null;
    farmFieldId: number | null = null;
    farmFieldIdList: number[] | null = null;
    isCurrentSeasonOnly: boolean = false;
    farmFieldName: string | null = null;
    cropTypeIdList: number[] | null = null;
    cropIdList: number[] | null = null;
    cropYear: number | null = null;
    workTaskTypeCodeList: WorkTaskTypeCode[] | null = null;
    workTaskDetails: string | null = null;
    workTaskStatus: string | null = null;
    dateRaisedFrom: string | null = null;
    dateRaisedTo: string | null = null;
    dateDueFrom: string | null = null;
    dateDueTo: string | null = null;
    dateConcludedFrom: string | null = null;
    dateConcludedTo: string | null = null;
    userIdList: number[] | null = null;
    mixtureIdList: number[] | null = null;
    sortOrder: string | null = null;
    treatAllHistoricWorkTasksAsRecent = false;
    includeArtifacts = false;
    includeWorkSubTasks = false;
    includeWorkTaskUsers = false;
    includeWorkTaskMixtures = false;
    includeWorkTaskProducts = false;
    includeWorkTaskCropVarietyPreferences = false;
    includeWorkTaskFarmBlocks = false;
    includeWorkTaskActions = false;
    includeDependentWorkTasks = false;
    isExportCSV: boolean = false;

    constructor(data?: Partial<SearchWorkTasksRequest>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
