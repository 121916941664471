<template>
    <v-dialog
        v-model="actionWorkTaskModalStore.isVisible"
        class="pnlUserManager"
        style="min-width: 90vw; max-width: 90vw">
        <v-card>
            <v-toolbar color="secondary">
                <v-toolbar-title>
                    <FontAwesomeIcon :icon="['fal', 'clipboard-list-check']" size="xl" class="mr-2" />
                    {{ $t('ActionTask') }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <CloseModalButton @click="close" />
            </v-toolbar>

            <LoadingSymbol v-if="isLoading || workTask == null" />

            <v-form ref="form" v-else class="pa-4 divUserManager" v-model="valid" style="overflow-y: scroll">
                <!-- Work Task Details Header -->
                <WorkTaskDetailsHeader
                    :work-task="workTask"
                    :farm-field="farmField!"
                    :show-operations-menu="false"
                    :show-created-and-modified-details="false" />

                <!-- Subheader -->
                <div class="subHeader mt-2 mb-4">
                    {{ isModifyCompletedMode ? $t('ActionDetails') : $t('SelectActionForThisTask') }}
                </div>

                <!-- Select Cancel or Delete -->
                <div style="display: flex" class="mb-2">
                    <div style="margin-left: auto; margin-right: auto">
                        <div v-if="!workTask.isStarted" class="tableCell">
                            <div
                                style="display: flex; align-items: center"
                                class="radioButtonBox"
                                :class="{
                                    selected:
                                        localActionWorkTaskModel.workTaskActionTypeCode ==
                                        WorkTaskActionTypeCode.Started,
                                    isReadOnly: isModifyCompletedMode,
                                }"
                                @click="actionChanged(WorkTaskActionTypeCode.Started)">
                                <v-radio
                                    v-model="localActionWorkTaskModel.workTaskActionTypeCode"
                                    :true-value="WorkTaskActionTypeCode.Started"
                                    :label="$t('StartThisTask')"
                                    color="swatchB2"
                                    :disabled="isModifyCompletedMode" />
                                <FontAwesomeIcon :icon="['far', 'circle-play']" class="swatchB6" size="xl" />
                            </div>
                        </div>
                        <div v-else-if="workTask.isPaused" class="tableCell">
                            <div
                                style="display: flex; align-items: center"
                                class="radioButtonBox"
                                :class="{
                                    selected:
                                        localActionWorkTaskModel.workTaskActionTypeCode ==
                                        WorkTaskActionTypeCode.Restarted,
                                    isReadOnly: isModifyCompletedMode,
                                }"
                                @click="actionChanged(WorkTaskActionTypeCode.Restarted)">
                                <v-radio
                                    v-model="localActionWorkTaskModel.workTaskActionTypeCode"
                                    :true-value="WorkTaskActionTypeCode.Restarted"
                                    :label="$t('RestartThisTask')"
                                    color="swatchB2"
                                    :disabled="isModifyCompletedMode" />
                                <FontAwesomeIcon :icon="['far', 'circle-play']" class="swatchB6" size="xl" />
                            </div>
                        </div>
                        <div v-else class="tableCell">
                            <div
                                style="display: flex; align-items: center"
                                class="radioButtonBox"
                                :class="{
                                    selected:
                                        localActionWorkTaskModel.workTaskActionTypeCode ==
                                        WorkTaskActionTypeCode.Paused,
                                    isReadOnly: isModifyCompletedMode,
                                }"
                                @click="actionChanged(WorkTaskActionTypeCode.Paused)">
                                <v-radio
                                    v-model="localActionWorkTaskModel.workTaskActionTypeCode"
                                    :true-value="WorkTaskActionTypeCode.Paused"
                                    :label="$t('PauseThisTask')"
                                    color="swatchB2"
                                    :disabled="isModifyCompletedMode" />
                                <FontAwesomeIcon :icon="['fal', 'circle-pause']" class="swatchG1" size="xl" />
                            </div>
                        </div>
                        <div class="tableCell pl-6">
                            <div
                                style="display: flex; align-items: center"
                                class="radioButtonBox"
                                :class="{
                                    selected:
                                        localActionWorkTaskModel.workTaskActionTypeCode ==
                                        WorkTaskActionTypeCode.Cancelled,
                                    isReadOnly: isModifyCompletedMode,
                                }"
                                @click="actionChanged(WorkTaskActionTypeCode.Cancelled)">
                                <v-radio
                                    v-model="localActionWorkTaskModel.workTaskActionTypeCode"
                                    :true-value="WorkTaskActionTypeCode.Cancelled"
                                    :label="$t('CancelThisTaskAbandon')"
                                    color="swatchB2"
                                    :disabled="isModifyCompletedMode"
                                    style="max-width: 11rem" />
                                <FontAwesomeIcon
                                    :icon="['fal', 'ban']"
                                    size="xl"
                                    class="swatchG1"
                                    style="margin-left: auto" />
                            </div>
                        </div>
                        <div class="tableCell pl-6">
                            <div
                                style="display: flex; align-items: center"
                                class="radioButtonBox"
                                :class="{
                                    selected:
                                        localActionWorkTaskModel.workTaskActionTypeCode ==
                                        WorkTaskActionTypeCode.Completed,
                                    isReadOnly: isModifyCompletedMode,
                                }"
                                @click="actionChanged(WorkTaskActionTypeCode.Completed)">
                                <v-radio
                                    v-model="localActionWorkTaskModel.workTaskActionTypeCode"
                                    :true-value="WorkTaskActionTypeCode.Completed"
                                    :label="$t('CompleteThisTask')"
                                    color="swatchB2"
                                    :disabled="isModifyCompletedMode"
                                    style="max-width: 11rem" />
                                <FontAwesomeIcon
                                    :icon="['fas', 'circle-check']"
                                    size="xl"
                                    class="swatchGRN"
                                    style="margin-left: auto" />
                            </div>
                        </div>

                        <!-- "Action Selected" validator" -->
                        <v-validation
                            :validation-value="localActionWorkTaskModel.workTaskActionTypeCode"
                            :rules="[actionSelectedRule]">
                            <template v-slot="{errorMessages, isValid}">
                                <div v-if="isValid.value != null" style="text-align: center">
                                    <span
                                        v-for="(error, index) in errorMessages.value"
                                        :key="index"
                                        class="error-message">
                                        {{ error }}
                                    </span>
                                </div>
                            </template>
                        </v-validation>
                    </div>
                </div>

                <!-- Completion Details -->
                <div v-if="!isModifyCompletedMode">
                    <!-- On Behalf Of -->
                    <div
                        v-if="userStore.hasRoleLevel(RoleCode.Tasks, 2)"
                        class="flex-layout fill flex-layout-space-05 align-items-center">
                        <div class="formHead">{{ $t('OnBehalfOf') }}</div>
                        <div class="formIcon">
                            <HelpIcon :help-text="$t('ActionWorkTask_ActionedUser_HelpText')" />
                        </div>
                        <div>
                            <v-select
                                v-model="localActionWorkTaskModel.actionedUserId"
                                :items="users"
                                hide-details
                                item-title="userFullName"
                                item-value="userId"
                                width="400px"
                                class="swatchA1 font-weight-bold">
                                <template #selection="{item}">
                                    <UserChip
                                        :user="
                                            UserHelper.userChipType(
                                                item.value,
                                                userStore.user && item.value && item.value === userStore.user.userId
                                                    ? $t('Myself')
                                                    : item.title,
                                            )
                                        " />
                                </template>
                            </v-select>
                        </div>
                    </div>

                    <!-- Comments -->
                    <div
                        v-if="
                            localActionWorkTaskModel.workTaskActionTypeCode == WorkTaskActionTypeCode.Cancelled ||
                            localActionWorkTaskModel.workTaskActionTypeCode == WorkTaskActionTypeCode.Completed
                        "
                        class="flex-layout fill flex-layout-space-05 align-items-center">
                        <div class="formHead">{{ $t('Comments') }}</div>
                        <div class="formIcon">
                            <HelpIcon :help-text="$t('ActionWorkTask_Comments_HelpText')" />
                        </div>
                        <div style="flex-grow: 1">
                            <v-textarea
                                v-model="localActionWorkTaskModel.comments"
                                :rules="[requiredRule]"
                                required
                                variant="outlined"
                                density="compact"
                                rows="1"
                                auto-grow
                                hide-details="auto"
                                class="swatchA1 font-weight-bold">
                            </v-textarea>
                        </div>
                    </div>

                    <!-- Actioned Date/Time -->
                    <div class="flex-layout fill flex-layout-space-05 align-items-center">
                        <div class="formHead">{{ $t('ActionDateSlashTime') }}</div>
                        <div class="formIcon">
                            <HelpIcon :help-text="$t('ActionWorkTask_ActionedDate_HelpText')" />
                        </div>
                        <div>
                            <VueDatePicker
                                v-model="localActionWorkTaskModel.actionedDate"
                                format="dd/MM/yyyy HH:mm"
                                :teleport="true"
                                text-input
                                style="max-width: 220px"
                                class="swatchA1 font-weight-medium"
                                :locale="i18n.global.locale" />

                            <!-- Actioned Date/Time validator -->
                            <v-validation
                                :validation-value="localActionWorkTaskModel.actionedDate"
                                :rules="[requiredRule, notGreaterThanTodaysDateRule, notBeforeLatestActionRule]">
                                <template v-slot="{errorMessages, isValid}">
                                    <div v-if="isValid.value != null">
                                        <span
                                            v-for="(error, index) in errorMessages.value"
                                            :key="index"
                                            class="error-message">
                                            {{ error }}
                                        </span>
                                    </div>
                                </template>
                            </v-validation>
                        </div>
                    </div>

                    <!-- Estimated Completion Time -->
                    <div
                        v-if="localActionWorkTaskModel.workTaskActionTypeCode == WorkTaskActionTypeCode.Started"
                        class="flex-layout fill flex-layout-space-05 align-items-center">
                        <div class="formHead">{{ $t('EstimatedCompletionTime') }}</div>
                        <div class="formIcon">
                            <HelpIcon :help-text="$t('ActionWorkTask_EstimatedCompletionTime_HelpText')" />
                        </div>
                        <div style="flex-grow: 1">
                            <v-select
                                v-model="localActionWorkTaskModel.estimatedCompletionTimeCode"
                                :items="workTaskCompletionTimesWithNull"
                                :item-title="(item: WorkTaskCompletionTime) => item.getWorkTaskCompletionTimeName()"
                                item-value="workTaskCompletionTimeCode"
                                max-width="180px" />
                        </div>
                    </div>
                </div>

                <!-- Action Details -->
                <v-expansion-panels class="marginTop" v-model="expansionPanelsActionDetails">
                    <v-expansion-panel>
                        <v-expansion-panel-title class="expansion-header">
                            {{ $t('ActionDetails') }}
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <WorkTaskActionDetails
                                :work-task-actions="workTask.workTaskActions"
                                :started-user="
                                    UserHelper.userChipType(
                                        workTask.startedUserId,
                                        UserHelper.userFullName(
                                            workTask.startedUserGivenName,
                                            workTask.startedUserSurname,
                                        ),
                                    )
                                " />
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>

                <!-- Task Details -->
                <div
                    v-if="
                        localActionWorkTaskModel.workTaskActionTypeCode == WorkTaskActionTypeCode.Cancelled ||
                        localActionWorkTaskModel.workTaskActionTypeCode == WorkTaskActionTypeCode.Completed
                    ">
                    <!-- Cultivate: Sub-Tasks -->
                    <div v-if="workTask.workTaskTypeCode == WorkTaskTypeCode.Cultivate" class="mt-2">
                        <v-expansion-panels class="marginTop" v-model="expansionPanelsSubTasks">
                            <v-expansion-panel>
                                <v-expansion-panel-title class="expansion-header">
                                    {{ $t('CultivateSubTasks') }}
                                </v-expansion-panel-title>
                                <v-expansion-panel-text>
                                    <ActionWorkSubTaskItem
                                        v-for="(workSubTask, index) in workTask.workSubTasks"
                                        :key="workSubTask.workSubTaskId"
                                        :work-sub-task="workSubTask"
                                        :work-task-action-type-code="localActionWorkTaskModel.workTaskActionTypeCode"
                                        :work-task="workTask"
                                        :index="index"
                                        @update="updateWorkSubTask" />
                                </v-expansion-panel-text>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </div>

                    <!-- Fertilize/Spray: Mixtures and Products -->
                    <div
                        v-if="
                            workTask.workTaskTypeCode == WorkTaskTypeCode.Fertilize ||
                            workTask.workTaskTypeCode == WorkTaskTypeCode.Spray
                        "
                        class="mt-6">
                        <!-- Fertilizer/Spray Products -->
                        <v-expansion-panels class="marginTop" v-model="expansionPanelsProducts">
                            <v-expansion-panel>
                                <v-expansion-panel-title class="expansion-header">
                                    {{
                                        workTask.workTaskTypeCode == WorkTaskTypeCode.Fertilize
                                            ? $t('FertiliserProducts')
                                            : $t('SprayProducts')
                                    }}
                                </v-expansion-panel-title>
                                <v-expansion-panel-text>
                                    <!-- Products saved against this task -->
                                    <ManageWorkTaskProductItem
                                        v-for="(workTaskProduct, index) in localActionWorkTaskModel.workTaskProducts"
                                        :ref="
                                        (el) => {
                                            if (el) (workTaskProductRefs[index] as any) = el;
                                        }
                                    "
                                        :key="`WorkTaskProduct-${
                                            workTaskProduct.workTaskProductId || workTaskProduct.newItemId
                                        }`"
                                        :work-task-product="workTaskProduct"
                                        :work-task-product-list="localActionWorkTaskModel.workTaskProducts"
                                        :index="index"
                                        :products="products"
                                        :work-task-type-code="workTask.workTaskTypeCode"
                                        :is-action-mode="true"
                                        :work-task-action-type-code="localActionWorkTaskModel.workTaskActionTypeCode"
                                        :work-task="workTask"
                                        @update="updateWorkTaskProduct"
                                        @delete="deleteWorkTaskProduct" />

                                    <!-- Add product -->
                                    <ManageWorkTaskProductItem
                                        :work-task-product-list="localActionWorkTaskModel.workTaskProducts"
                                        :index="-1"
                                        :products="products"
                                        :work-task-type-code="workTask.workTaskTypeCode"
                                        :is-action-mode="true"
                                        :work-task-action-type-code="localActionWorkTaskModel.workTaskActionTypeCode"
                                        :work-task="workTask"
                                        @add="addWorkTaskProduct" />
                                </v-expansion-panel-text>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </div>

                    <!-- Irrigate: Irrigate Rate -->
                    <div v-if="workTask.workTaskTypeCode == WorkTaskTypeCode.Irrigate" class="mt-6">
                        <v-expansion-panels class="marginTop" v-model="expansionPanelsProducts">
                            <v-expansion-panel>
                                <v-expansion-panel-title class="expansion-header">
                                    {{ $t('TaskDetails') }}
                                </v-expansion-panel-title>
                                <v-expansion-panel-text>
                                    <div class="flex-layout fill flex-layout-space-05 align-items-center formRow">
                                        <div class="formHead">{{ $t('IrrigationRate') }}</div>
                                        <div class="formIcon">
                                            <HelpIcon :help-text="$t('ManageWorkTask_IrrigationRate_HelpText')" />
                                        </div>
                                        <div class="formModifyGroup">
                                            <v-text-field
                                                v-model="localActionWorkTaskModel.irrigationRateActual"
                                                :rules="[requiredRule, numericRule]"
                                                required
                                                max-width="180px"
                                                class="swatchA1 font-weight-bold"
                                                suffix="ML per Ha" />
                                        </div>
                                    </div>
                                </v-expansion-panel-text>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </div>

                    <!-- Plant: Varieties, Fertilizer Products, Spray Products -->
                    <div
                        v-if="
                            workTask.workTaskTypeCode == WorkTaskTypeCode.Plant &&
                            localActionWorkTaskModel.workTaskActionTypeCode == WorkTaskActionTypeCode.Completed
                        "
                        class="mt-6">
                        <!-- Varieties -->
                        <v-expansion-panels class="marginTop" v-model="expansionPanelsCropVarieties">
                            <v-expansion-panel>
                                <v-expansion-panel-title class="expansion-header">
                                    {{ $t('BlockPlanning') }}
                                </v-expansion-panel-title>
                                <v-expansion-panel-text>
                                    <!-- Varieties saved against this task -->
                                    <ActionWorkTaskFarmBlockItem
                                        v-for="(
                                            workTaskFarmBlock, index
                                        ) in localActionWorkTaskModel.workTaskFarmBlocks"
                                        :ref="
                                            (el) => {
                                                if (el) (workTaskFarmBlockRefs[index] as any) = el;
                                            }
                                        "
                                        :key="`workTaskFarmBlock-${
                                            workTaskFarmBlock.workTaskFarmBlockId || workTaskFarmBlock.newItemId
                                        }-${index}`"
                                        :work-task-farm-block="workTaskFarmBlock"
                                        :work-task-farm-block-list="localActionWorkTaskModel.workTaskFarmBlocks"
                                        :work-task="workTask"
                                        :farm-field="farmField!"
                                        :index="index"
                                        :crop-varieties="cropVarieties"
                                        :farm-blocks="farmBlocks"
                                        @add="addWorkTaskFarmBlock"
                                        @update="updateWorkTaskFarmBlock"
                                        @delete="deleteWorkTaskFarmBlock" />
                                </v-expansion-panel-text>
                            </v-expansion-panel>
                        </v-expansion-panels>

                        <!-- Fertilizer Products -->
                        <v-expansion-panels class="marginTop" v-model="expansionPanelsFertilizerProducts">
                            <v-expansion-panel>
                                <v-expansion-panel-title class="expansion-header">
                                    {{ $t('FertiliserProductsToApplyAtPlanting') }}
                                </v-expansion-panel-title>
                                <v-expansion-panel-text>
                                    <!-- Products saved against this task -->
                                    <ManageWorkTaskProductItem
                                        v-for="(
                                            workTaskProduct, index
                                        ) in localActionWorkTaskModel.workTaskProducts.filter(
                                            (wtp) =>
                                                ManageWorkTaskHelper.getProductDetails(wtp.productId, products)
                                                    ?.productTypeCode == ProductTypeCode.Fertilizer,
                                        )"
                                        :ref="
                                            (el) => {
                                                if (el) (workTaskFertilizeProductRefs[index] as any) = el;
                                            }
                                        "
                                        :key="`WorkTaskFertiliserProduct-${
                                            workTaskProduct.workTaskProductId || workTaskProduct.newItemId
                                        }`"
                                        :work-task-product="workTaskProduct"
                                        :work-task-product-list="localActionWorkTaskModel.workTaskProducts"
                                        :index="index"
                                        :products="
                                            products.filter((p) => p.productTypeCode == ProductTypeCode.Fertilizer)
                                        "
                                        :work-task-type-code="workTask.workTaskTypeCode"
                                        :is-action-mode="true"
                                        :work-task-action-type-code="localActionWorkTaskModel.workTaskActionTypeCode"
                                        :work-task="workTask"
                                        @update="updateWorkTaskProduct"
                                        @delete="deleteWorkTaskProduct" />

                                    <!-- Add product -->
                                    <ManageWorkTaskProductItem
                                        :work-task-product-list="localActionWorkTaskModel.workTaskProducts"
                                        :index="-1"
                                        :products="
                                            products.filter((p) => p.productTypeCode == ProductTypeCode.Fertilizer)
                                        "
                                        :work-task-type-code="workTask.workTaskTypeCode"
                                        :is-action-mode="true"
                                        :work-task-action-type-code="localActionWorkTaskModel.workTaskActionTypeCode"
                                        :work-task="workTask"
                                        @add="addWorkTaskProduct" />
                                </v-expansion-panel-text>
                            </v-expansion-panel>
                        </v-expansion-panels>

                        <!-- Spray Products -->
                        <v-expansion-panels class="marginTop" v-model="expansionPanelsSprayProducts">
                            <v-expansion-panel>
                                <v-expansion-panel-title class="expansion-header">
                                    {{ $t('SprayProductsToApplyAtPlanting') }}
                                </v-expansion-panel-title>
                                <v-expansion-panel-text>
                                    <!-- Products saved against this task -->
                                    <ManageWorkTaskProductItem
                                        v-for="(
                                            workTaskProduct, index
                                        ) in localActionWorkTaskModel.workTaskProducts.filter(
                                            (wtp) =>
                                                ManageWorkTaskHelper.getProductDetails(wtp.productId, products)
                                                    ?.productTypeCode == ProductTypeCode.Chemical,
                                        )"
                                        :ref="
                                            (el) => {
                                                if (el) (workTaskSprayProductRefs[index] as any) = el;
                                            }
                                        "
                                        :key="`WorkTaskSprayProduct-${
                                            workTaskProduct.workTaskProductId || workTaskProduct.newItemId
                                        }`"
                                        :work-task-product="workTaskProduct"
                                        :work-task-product-list="localActionWorkTaskModel.workTaskProducts"
                                        :index="index"
                                        :products="
                                            products.filter((p) => p.productTypeCode == ProductTypeCode.Chemical)
                                        "
                                        :work-task-type-code="workTask.workTaskTypeCode"
                                        :is-action-mode="true"
                                        :work-task-action-type-code="localActionWorkTaskModel.workTaskActionTypeCode"
                                        :work-task="workTask"
                                        @update="updateWorkTaskProduct"
                                        @delete="deleteWorkTaskProduct" />

                                    <!-- Add product -->
                                    <ManageWorkTaskProductItem
                                        :work-task-product-list="localActionWorkTaskModel.workTaskProducts"
                                        :index="-1"
                                        :products="
                                            products.filter((p) => p.productTypeCode == ProductTypeCode.Chemical)
                                        "
                                        :work-task-type-code="workTask.workTaskTypeCode"
                                        :is-action-mode="true"
                                        :work-task-action-type-code="localActionWorkTaskModel.workTaskActionTypeCode"
                                        :work-task="workTask"
                                        @add="addWorkTaskProduct" />
                                </v-expansion-panel-text>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </div>
                </div>

                <!-- Confirm Complete -->
                <v-card
                    v-if="
                        localActionWorkTaskModel.workTaskActionTypeCode == WorkTaskActionTypeCode.Completed ||
                        localActionWorkTaskModel.workTaskActionTypeCode == WorkTaskActionTypeCode.Cancelled
                    "
                    class="marginTop">
                    <v-card-text class="completeConfirmation pa-0">
                        <v-row class="confirm-header pa-3 ma-0">
                            <FontAwesomeIcon :icon="['fal', 'check']" size="xl" class="mr-5" />
                            <span>
                                {{ confirmBoxTitle }}
                            </span>
                        </v-row>

                        <div class="pa-2">
                            <v-row class="ma-2" style="font-size: medium">
                                {{ $t('ActionTrackedGeneric') }}
                            </v-row>

                            <v-row class="ma-1">
                                <v-col cols="1" align-self="center">
                                    <v-checkbox
                                        v-model="localActionWorkTaskModel.isConfirmed"
                                        class="confirm-checkbox"
                                        hide-details />
                                </v-col>
                                <v-col cols="11" align-self="center" style="font-size: medium">
                                    {{ confirmBoxConfirmationMessage }}
                                </v-col>
                            </v-row>

                            <v-row v-if="isModifyCompletedMode" class="ma-1">
                                <v-col cols="1" align-self="center">
                                    {{ $t('Comments') }}
                                </v-col>
                                <v-col cols="11" align-self="center" style="font-size: medium">
                                    <v-textarea
                                        v-model="localActionWorkTaskModel.comments"
                                        :rules="[requiredRule]"
                                        required
                                        variant="outlined"
                                        density="compact"
                                        rows="1"
                                        auto-grow
                                        hide-details="auto"
                                        class="swatchA1 swatchWHTBG-field font-weight-bold">
                                    </v-textarea>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card-text>
                </v-card>
            </v-form>

            <v-card-actions class="justify-space-between swatchG9BG">
                <v-btn @click="close">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'arrow-rotate-left']" size="xl" />
                    </template>
                    {{ $t('Cancel') }}
                </v-btn>

                <v-btn
                    v-if="
                        localActionWorkTaskModel.workTaskActionTypeCode == WorkTaskActionTypeCode.Completed ||
                        localActionWorkTaskModel.workTaskActionTypeCode == WorkTaskActionTypeCode.Cancelled
                    "
                    @click="save"
                    :disabled="isLoading || !localActionWorkTaskModel.isConfirmed">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'save']" size="xl" />
                    </template>
                    {{ $t('ConfirmAndSubmit') }}
                </v-btn>
                <v-btn v-else @click="save" :disabled="isLoading">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'save']" size="xl" />
                    </template>
                    {{ $t('Save') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" timeout="4000">
        {{ snackbar.text }}
    </v-snackbar>
</template>

<script setup lang="ts">
import '@/assets/scss/user/user-manager.scss';
import {watch, inject, ref, defineAsyncComponent, computed, nextTick} from 'vue';
import ApiService from '@/services/api-service.js';
import CloseModalButton from '@/components/CloseModalButton.vue';
import WorkTaskDetailsHeader from '@/components/WorkTaskDetailsHeader.vue';
import WorkTaskActionDetails from '@/components/work-tasks/WorkTaskActionDetails.vue';
import UserChip from '@/components/UserChip.vue';
import ManageWorkTaskProductItem from '@/views/work-tasks/ManageWorkTaskProductItem.vue';
import ActionWorkTaskFarmBlockItem from '@/views/work-tasks/ActionWorkTaskFarmBlockItem.vue';
import {SearchWorkTasksRequest} from '@/models/api/requests/search/SearchWorkTasksRequest';
import {SearchWorkTasksResponse} from '@/models/api/responses/search/SearchWorkTasksResponse';
import {WorkTaskSearchResultDto} from '@/models/data-transfer-objects/search/work-task-search/WorkTaskSearchResultDto';
import {SearchFarmFieldsRequest} from '@/models/api/requests/search/SearchFarmFieldsRequest';
import {SearchFarmFieldsResponse} from '@/models/api/responses/search/SearchFarmFieldsResponse';
import {FarmFieldSearchResultDto} from '@/models/data-transfer-objects/search/farm-field-search/FarmFieldSearchResultDto';
import {useActionWorkTaskModalStore} from '@/stores/modals/action-work-task-modal-store';
import ActionWorkSubTaskItem from './ActionWorkSubTaskItem.vue';
import i18n from '@/i18n';
import {ActionWorkTaskForm} from '@/models/work-tasks/ActionWorkTaskForm';
import Validation from '@/helpers/ValidationHelper';
import {RoleCode} from '@/enums/role-code';
const VueDatePicker = defineAsyncComponent(() => import('@vuepic/vue-datepicker'));
import {useUsers} from '@/composables/data-source/users';
const {getUsers, users} = useUsers();
import {useUserStore} from '@/stores/user-store';
import {format, isAfter, isBefore, isSameDay} from 'date-fns';
import {WorkTaskActionTypeCode} from '@/enums/work-task-action-type-code';
import {WorkTaskTypeCode} from '@/enums/work-task-type-code';
import {isEqual} from 'lodash';
import {WorkSubTaskDto} from '@/models/data-transfer-objects/search/work-task-search/WorkSubTaskDto';
import {ValidatableComponent} from '@/models/generic/ValidatableComponent';
import {WorkTaskProductForm} from '@/models/work-tasks/WorkTaskProductForm';
import ManageWorkTaskHelper from '@/helpers/ManageWorkTaskHelper';
import UserHelper from '@/helpers/UserHelper';
import {ProductTypeCode} from '@/enums/product-type-code';
const userStore = useUserStore();
import {useProducts} from '@/composables/data-source/products';
const {getProducts, products} = useProducts();
import {useFarmBlocks} from '@/composables/data-source/farm-blocks';
const {getFarmBlocks, farmBlocks} = useFarmBlocks();
import {useCropVarieties} from '@/composables/data-source/crop-varieties';
import {ActionWorkTaskFarmBlockForm} from '@/models/work-tasks/ActionWorkTaskFarmBlockForm';
import {WorkTaskCropVarietyPreferenceDto} from '@/models/data-transfer-objects/search/work-task-search/WorkTaskCropVarietyPreferenceDto';
import {ActionWorkSubTaskDto} from '@/models/data-transfer-objects/work-tasks/ActionWorkSubTaskDto';
import {ActionWorkTaskRequest} from '@/models/api/requests/work-tasks/ActionWorkTaskRequest';
import {ActionWorkTaskProductDto} from '@/models/data-transfer-objects/work-tasks/ActionWorkTaskProductDto';
import {ActionWorkTaskFarmBlockDto} from '@/models/data-transfer-objects/work-tasks/ActionWorkTaskFarmBlockDto';
import {WorkTaskCompletionTime, workTaskCompletionTimesWithNull} from '@/services/work-task-completion-time-service';
const {getCropVarieties, cropVarieties} = useCropVarieties();

// Form
const form = ref();
const valid = ref<boolean>(true);
let isLoading = ref<boolean>(false);
const snackbar = ref({
    show: false,
    text: '',
});
const expansionPanelsActionDetails = ref<number[]>([]);
const expansionPanelsSubTasks = ref([0]);
const expansionPanelsProducts = ref([0]);
const expansionPanelsCropVarieties = ref([0]);
const expansionPanelsFertilizerProducts = ref([0]);
const expansionPanelsSprayProducts = ref([0]);
const workTaskProductRefs = ref<ValidatableComponent[]>([]);
const workTaskFertilizeProductRefs = ref<ValidatableComponent[]>([]);
const workTaskSprayProductRefs = ref<ValidatableComponent[]>([]);
const workTaskFarmBlockRefs = ref<ValidatableComponent[]>([]);

// Rules
const requiredRule = Validation.createRule_Required();
const numericRule = Validation.createRule_Numeric(i18n.global.t('Validation_MustBeNonNegative'), 0);
const actionSelectedRule = (v: string) => {
    return v != null || i18n.global.t('Validation_AnActionMustBeSelected');
};
const notGreaterThanTodaysDateRule = (v: Date | null) => {
    if (!v) return true;

    // Check if the date is before today's date or the same day but later time
    const today = new Date();
    if (isBefore(v, today) || (isSameDay(v, today) && isAfter(v, today))) {
        return true;
    }

    return i18n.global.t('Validation_MustNotBeGreaterThanTodaysDate');
};
const notBeforeLatestActionRule = (v: Date | null) => {
    if (!v) return true;
    if (!workTask.value) return true;

    // Valid if there are no work task actions (technically there should always be at least one - the Created action)
    if (!workTaskActionsToUseInValidation.value.length) {
        return true;
    }

    // Check if the date is on or after the latest task action
    const latestAction = workTaskActionsToUseInValidation.value[workTaskActionsToUseInValidation.value.length - 1];
    if (isEqual(v, latestAction.workTaskActionDate) || isAfter(v, latestAction.workTaskActionDate)) {
        return true;
    }

    // Return error
    return i18n.global.t('Validation_TaskCompletedDateMustBeAfterLatestAction', {
        workTaskActionDate: format(latestAction.workTaskActionDate, 'dd/MM/yyyy HH:mm'),
    });
};

// Services
const apiService = inject('apiService') as ApiService;

// Modal
const actionWorkTaskModalStore = useActionWorkTaskModalStore();
const workTask = ref<WorkTaskSearchResultDto | null>(null);
const farmField = ref<FarmFieldSearchResultDto | null>(null);
const localActionWorkTaskModel = ref<ActionWorkTaskForm>(new ActionWorkTaskForm());

/**
 * Load work task data for the modal.
 */
const loadWorkTaskData = async () => {
    isLoading.value = true;

    // Reset form
    resetForm();

    // Get dropdown/form data
    await getUsers();
    await getProducts();
    await getFarmBlocks();
    await getCropVarieties();

    // Update name of current user
    const currentUser = users.value.find((u) => u.userId == userStore.user?.userId);
    if (currentUser) currentUser.userFullName = i18n.global.t('Myself');

    // If a work task ID was specified
    if (actionWorkTaskModalStore.workTaskId !== null) {
        // Build search request using the work task ID
        const searchRequest: SearchWorkTasksRequest = new SearchWorkTasksRequest({
            isQuickSearch: false,
            workTaskId: actionWorkTaskModalStore.workTaskId,
            includeWorkSubTasks: true,
            includeWorkTaskMixtures: true,
            includeWorkTaskProducts: true,
            includeWorkTaskCropVarietyPreferences: true,
            includeWorkTaskFarmBlocks: true,
            includeWorkTaskActions: true,
        });

        // Get work task details
        const searchResults = (await apiService.post('search/work-tasks', searchRequest)) as SearchWorkTasksResponse;

        if (searchResults.workTasks.length == 1) {
            // Store search result
            workTask.value = searchResults.workTasks[0];

            // Set selected action if the task has already been completed or cancelled
            if (workTask.value.isCompleted) {
                localActionWorkTaskModel.value.workTaskActionTypeCode = WorkTaskActionTypeCode.Completed;
            } else if (workTask.value.isCancelled) {
                localActionWorkTaskModel.value.workTaskActionTypeCode = WorkTaskActionTypeCode.Cancelled;
            }

            // Set work task details in form model
            localActionWorkTaskModel.value.workTaskId = workTask.value.workTaskId;
            localActionWorkTaskModel.value.workTaskProducts = workTask.value.workTaskProducts.map((sourceObject) => {
                return Object.assign(new WorkTaskProductForm(), sourceObject);
            });
            localActionWorkTaskModel.value.workTaskFarmBlocks = workTask.value.workTaskFarmBlocks.map(
                (sourceObject) => {
                    return Object.assign(new ActionWorkTaskFarmBlockForm(), sourceObject);
                },
            );

            // If modifying a completed/cancelled task
            if (isModifyCompletedMode.value) {
                // Set the irrigation rate value to what was stored in the database when the task was last modified/completed/cancelled
                localActionWorkTaskModel.value.irrigationRateActual = workTask.value.irrigationRateActual;
            }

            // Get farm field details
            await getFarmFieldDetails();

            // If this is a Plant task and there are no crop varieties saved against it (should only happen for tasks that have not yet been completed)
            if (
                workTask.value.workTaskTypeCode == WorkTaskTypeCode.Plant &&
                localActionWorkTaskModel.value.workTaskFarmBlocks.length == 0
            ) {
                // Get first crop variety preference if there is one. This is used to set default values for
                // the first block.
                let cropVarietyPreference: WorkTaskCropVarietyPreferenceDto | null = null;
                if (workTask.value.workTaskCropVarietyPreferences.length > 0) {
                    cropVarietyPreference = workTask.value.workTaskCropVarietyPreferences[0];
                }
                // Add default empty variety row
                localActionWorkTaskModel.value.workTaskFarmBlocks.push(
                    new ActionWorkTaskFarmBlockForm({
                        newItemId: 'DefaultNewItem',
                        startRow: 1,
                        endRow: farmField.value?.farmFieldNumRows,
                        cropVarietyId: cropVarietyPreference?.cropVarietyId,
                        seedOriginBlockId: cropVarietyPreference?.seedOriginBlockId,
                        seedRate: cropVarietyPreference?.seedRate,
                        seedRateUnitCode: cropVarietyPreference?.seedRateUnitCode,
                    }),
                );
            }
        }
    }

    // End loading (nextTick is used to ensure that the watch events are not triggered during the load)
    nextTick(() => {
        isLoading.value = false;
    });
};

/**
 * Get farm field details of an existing work task.
 */
const getFarmFieldDetails = async () => {
    if (workTask.value == null) return;

    // Build search request using the farm field ID
    const searchRequest: SearchFarmFieldsRequest = new SearchFarmFieldsRequest({
        isQuickSearch: false,
        farmFieldId: workTask.value.farmFieldId,
    });

    // Get farm field details
    const searchResults = (await apiService.post('search/farm-fields', searchRequest)) as SearchFarmFieldsResponse;

    if (searchResults.farmFields.length == 1) {
        // Store search result
        farmField.value = searchResults.farmFields[0];
    }
};

/**
 * Validate all products before saving.
 */
const validateAllProducts = async (): Promise<boolean> => {
    // Do not validate products if this is a Plant task and the task is cancelled (because they will not be saved)
    if (
        localActionWorkTaskModel.value.workTaskActionTypeCode == WorkTaskActionTypeCode.Cancelled &&
        workTask.value?.workTaskTypeCode == WorkTaskTypeCode.Plant
    )
        return true;

    // Multiple refs are used for work task products (because they are sometimes split into Fertilizer and Spray products),
    // so we will concatenate each array to ensure all are validated.
    const allRefs = workTaskProductRefs.value
        .concat(workTaskFertilizeProductRefs.value)
        .concat(workTaskSprayProductRefs.value);

    const results = await Promise.all(allRefs.map((child) => child.validate()));
    return results.every((result) => result === true);
};

/**
 * Validate all farm blocks before saving.
 */
const validateAllFarmBlocks = async (): Promise<boolean> => {
    // Do not validate farm blocks if the task is cancelled (because they will not be saved)
    if (localActionWorkTaskModel.value.workTaskActionTypeCode == WorkTaskActionTypeCode.Cancelled) return true;

    const results = await Promise.all(workTaskFarmBlockRefs.value.map((child) => child.validate()));
    return results.every((result) => result === true);
};

/**
 * Save the modal.
 */
const save = async () => {
    // Perform final client side validation of form
    await form.value.validate();
    const isProductsValid = await validateAllProducts();
    const isFarmBlocksValid = await validateAllFarmBlocks();

    // If form is valid
    if (valid.value && isProductsValid && isFarmBlocksValid) {
        let snackbarMessage = '';
        isLoading.value = true;

        try {
            // Build API request
            const actionWorkTaskRequest = buildActionWorkTaskApiRequest();

            // Determine API endpoint
            let actionWorkTaskApiEndpoint = '';
            if (localActionWorkTaskModel.value.workTaskActionTypeCode == WorkTaskActionTypeCode.Paused)
                actionWorkTaskApiEndpoint = 'work-tasks/pause-work-task';
            else if (localActionWorkTaskModel.value.workTaskActionTypeCode == WorkTaskActionTypeCode.Restarted)
                actionWorkTaskApiEndpoint = 'work-tasks/restart-work-task';
            else if (localActionWorkTaskModel.value.workTaskActionTypeCode == WorkTaskActionTypeCode.Started)
                actionWorkTaskApiEndpoint = 'work-tasks/start-work-task';
            else actionWorkTaskApiEndpoint = 'work-tasks/action-work-task';

            // Call API to action work task
            await apiService.post(actionWorkTaskApiEndpoint, actionWorkTaskRequest);

            // Set snackbar message
            if (localActionWorkTaskModel.value.workTaskActionTypeCode == WorkTaskActionTypeCode.Completed) {
                snackbarMessage = i18n.global.t('CompleteWorkTask_Success');
            } else if (localActionWorkTaskModel.value.workTaskActionTypeCode == WorkTaskActionTypeCode.Cancelled) {
                snackbarMessage = i18n.global.t('CancelWorkTask_Success');
            } else if (localActionWorkTaskModel.value.workTaskActionTypeCode == WorkTaskActionTypeCode.Paused) {
                snackbarMessage = i18n.global.t('PauseWorkTask_Success');
            } else if (localActionWorkTaskModel.value.workTaskActionTypeCode == WorkTaskActionTypeCode.Restarted) {
                snackbarMessage = i18n.global.t('RestartWorkTask_Success');
            } else if (localActionWorkTaskModel.value.workTaskActionTypeCode == WorkTaskActionTypeCode.Started) {
                snackbarMessage = i18n.global.t('StartWorkTask_Success');
            }

            // Show success feedback to user
            snackbar.value.show = true;
            snackbar.value.text = snackbarMessage;
            actionWorkTaskModalStore.savedCounter++;

            // Close modal
            actionWorkTaskModalStore.close();
        } catch (ex: unknown) {
            // Show fail feedback to user
            snackbar.value.show = true;
            snackbar.value.text = i18n.global.t('ErrorGeneric');
            isLoading.value = false;
        }
    } else {
        // Show validation error feedback to user
        snackbar.value.show = true;
        snackbar.value.text = i18n.global.t('ErrorValidation');
    }
};

/**
 * Builds the request object for the Action Task APIs.
 */
const buildActionWorkTaskApiRequest = () => {
    let workTaskActionComments: string | null = null;
    let workSubTasks: ActionWorkSubTaskDto[] = [];
    let workTaskProducts: ActionWorkTaskProductDto[] = [];
    let workTaskFarmBlocks: ActionWorkTaskFarmBlockDto[] = [];
    let irrigationRateActual: number | null = null;

    // If the task is being completed or cancelled, add task details to the API request
    if (
        localActionWorkTaskModel.value.workTaskActionTypeCode == WorkTaskActionTypeCode.Completed ||
        localActionWorkTaskModel.value.workTaskActionTypeCode == WorkTaskActionTypeCode.Cancelled
    ) {
        workTaskActionComments = localActionWorkTaskModel.value.comments;

        // For each sub-task
        workTask.value!.workSubTasks.forEach((workSubTask) => {
            // Build sub-task object
            const uploadedWorkSubTask = new ActionWorkSubTaskDto({
                workSubTaskId: workSubTask.workSubTaskId,
                isCompleted: workSubTask.isCompleted,
            });

            // Add sub-task to array
            workSubTasks.push(uploadedWorkSubTask);
        });

        // Get products to send to API (only if the task is Fertilize/Spray, or if it's Plant and is being completed and not cancelled)
        if (
            workTask.value?.workTaskTypeCode == WorkTaskTypeCode.Fertilize ||
            workTask.value?.workTaskTypeCode == WorkTaskTypeCode.Spray ||
            (workTask.value?.workTaskTypeCode == WorkTaskTypeCode.Plant &&
                localActionWorkTaskModel.value.workTaskActionTypeCode == WorkTaskActionTypeCode.Completed)
        ) {
            // For each work task product
            localActionWorkTaskModel.value.workTaskProducts.forEach((workTaskProduct) => {
                // Build task product object
                const uploadedWorkTaskProduct = new ActionWorkTaskProductDto({
                    workTaskProductId: workTaskProduct.workTaskProductId,
                    productId: workTaskProduct.productId!,
                    actualApplicationRate: workTaskProduct.actualApplicationRate!,
                    actualApplicationRateUnitCode: workTaskProduct.actualApplicationRateUnitCode!,
                });

                // Add task product to array
                workTaskProducts.push(uploadedWorkTaskProduct);
            });
        }

        // Get farm blocks to send to API (only if the task is being completed and not cancelled)
        if (localActionWorkTaskModel.value.workTaskActionTypeCode == WorkTaskActionTypeCode.Completed) {
            // For each work task farm block
            localActionWorkTaskModel.value.workTaskFarmBlocks.forEach((workTaskFarmBlock) => {
                // Build task farm block object
                const uploadedWorkTaskFarmBlock = new ActionWorkTaskFarmBlockDto({
                    workTaskFarmBlockId: workTaskFarmBlock.workTaskFarmBlockId,
                    farmBlockCode: workTaskFarmBlock.farmBlockCode!,
                    cropVarietyId: workTaskFarmBlock.cropVarietyId!,
                    seedOriginBlockId: workTaskFarmBlock.seedOriginBlockId,
                    seedRate: workTaskFarmBlock.seedRate!,
                    seedRateUnitCode: workTaskFarmBlock.seedRateUnitCode!,
                    startRow: workTaskFarmBlock.startRow!,
                    endRow: workTaskFarmBlock.endRow!,
                });

                // Add task farm block to array
                workTaskFarmBlocks.push(uploadedWorkTaskFarmBlock);
            });
        }

        // Set irrigation rate value if this is an Irrigate task
        if (workTask.value?.workTaskTypeCode == WorkTaskTypeCode.Irrigate) {
            irrigationRateActual = localActionWorkTaskModel.value.irrigationRateActual;
        }
    }

    // Build API request
    const actionWorkTaskRequest: ActionWorkTaskRequest = {
        workTask: {
            workTaskId: localActionWorkTaskModel.value.workTaskId!,
            workTaskActionTypeCode: localActionWorkTaskModel.value.workTaskActionTypeCode!,
            workTaskActionUserId: isModifyCompletedMode.value ? null : localActionWorkTaskModel.value.actionedUserId,
            workTaskActionComments: workTaskActionComments,
            workTaskActionDate: isModifyCompletedMode.value ? null : localActionWorkTaskModel.value.actionedDate,
            irrigationRateActual: irrigationRateActual,
            estimatedCompletionTimeCode: localActionWorkTaskModel.value.estimatedCompletionTimeCode,
            workSubTasks: workSubTasks,
            workTaskProducts: workTaskProducts,
            workTaskFarmBlocks: workTaskFarmBlocks,
        },
    };

    return actionWorkTaskRequest;
};

/**
 * Updates action flags.
 */
const actionChanged = (workTaskActionTypeCode: WorkTaskActionTypeCode) => {
    // If in Modify Completed Task mode, do not change the action (effectively disable the click event)
    if (isModifyCompletedMode.value) return false;

    localActionWorkTaskModel.value.workTaskActionTypeCode = workTaskActionTypeCode;
};

/**
 * Updates a sub-task.
 */
const updateWorkSubTask = (workSubTask: WorkSubTaskDto) => {
    if (!workTask.value) return;

    const index = workTask.value.workSubTasks.findIndex((wst) => wst.workSubTaskId === workSubTask.workSubTaskId);

    if (index !== -1) {
        // Update the found object with the new object's properties
        workTask.value.workSubTasks[index] = {...workTask.value.workSubTasks[index], ...workSubTask};
    }
};

/**
 * Adds a new product.
 */
const addWorkTaskProduct = (workTaskProduct: WorkTaskProductForm) => {
    localActionWorkTaskModel.value.workTaskProducts.push(workTaskProduct);
};

/**
 * Updates a product.
 */
const updateWorkTaskProduct = (workTaskProduct: WorkTaskProductForm) => {
    const index = getIndexOfWorkTaskProduct(workTaskProduct);

    if (index !== -1) {
        // Update the found object with the new object's properties
        localActionWorkTaskModel.value.workTaskProducts[index] = {
            ...localActionWorkTaskModel.value.workTaskProducts[index],
            ...workTaskProduct,
        };
    }
};

/**
 * Deletes the specified product. This deletion is not saved until the form is saved.
 */
const deleteWorkTaskProduct = (refObjectIndex: number, workTaskProduct: WorkTaskProductForm) => {
    const index = getIndexOfWorkTaskProduct(workTaskProduct);

    if (index !== -1) {
        // Delete the object
        localActionWorkTaskModel.value.workTaskProducts.splice(index, 1);

        // Delete the ref object so that it's no longer validated
        workTaskProductRefs.value.splice(refObjectIndex, 1);
    }
};

/**
 * Get the index of the specified work task product, based on its unique ID.
 */
const getIndexOfWorkTaskProduct = (workTaskProduct: WorkTaskProductForm) => {
    return localActionWorkTaskModel.value.workTaskProducts.findIndex(
        (wst) =>
            (workTaskProduct.workTaskProductId != null &&
                wst.workTaskProductId === workTaskProduct.workTaskProductId) ||
            (workTaskProduct.newItemId != null && wst.newItemId === workTaskProduct.newItemId),
    );
};

/**
 * Adds a new block.
 */
const addWorkTaskFarmBlock = (workTaskFarmBlock: ActionWorkTaskFarmBlockForm) => {
    localActionWorkTaskModel.value.workTaskFarmBlocks.push(workTaskFarmBlock);
};

/**
 * Updates a block.
 */
const updateWorkTaskFarmBlock = (workTaskFarmBlock: ActionWorkTaskFarmBlockForm) => {
    const index = localActionWorkTaskModel.value.workTaskFarmBlocks.findIndex(
        (wst) =>
            (workTaskFarmBlock.workTaskFarmBlockId != null &&
                wst.workTaskFarmBlockId === workTaskFarmBlock.workTaskFarmBlockId) ||
            (workTaskFarmBlock.newItemId != null && wst.newItemId === workTaskFarmBlock.newItemId),
    );

    if (index !== -1) {
        // Update the found object with the new object's properties
        localActionWorkTaskModel.value.workTaskFarmBlocks[index] = {
            ...localActionWorkTaskModel.value.workTaskFarmBlocks[index],
            ...workTaskFarmBlock,
        };
    }
};

/**
 * Deletes the specified block. This deletion is not saved until the form is saved.
 */
const deleteWorkTaskFarmBlock = (workTaskFarmBlockIndex: number) => {
    const isLastItem = workTaskFarmBlockIndex == localActionWorkTaskModel.value.workTaskFarmBlocks.length - 1;
    const startRow = localActionWorkTaskModel.value.workTaskFarmBlocks[workTaskFarmBlockIndex].startRow;

    // Delete variety
    localActionWorkTaskModel.value.workTaskFarmBlocks.splice(workTaskFarmBlockIndex, 1);

    // Delete the ref object for this deleted variety so that it's no longer validated
    workTaskFarmBlockRefs.value.splice(workTaskFarmBlockIndex, 1);

    // Update details of the block that came before the one that was deleted
    if (isLastItem && farmField.value) {
        // If the last block was deleted, set the end row of the "new" last block to the number of farm field rows (so that it encompasses the remaining rows)
        localActionWorkTaskModel.value.workTaskFarmBlocks[
            localActionWorkTaskModel.value.workTaskFarmBlocks.length - 1
        ].endRow = farmField.value.farmFieldNumRows;
    } else {
        // Set the previous block's start row to the start row of the block that was deleted
        localActionWorkTaskModel.value.workTaskFarmBlocks[workTaskFarmBlockIndex].startRow = startRow;
    }
};

/**
 * Close the modal.
 */
const close = () => {
    actionWorkTaskModalStore.close();
};

/**
 * Reset the form to its default state.
 */
const resetForm = () => {
    workTask.value = null;
    farmField.value = null;
    localActionWorkTaskModel.value = new ActionWorkTaskForm();
    localActionWorkTaskModel.value.actionedUserId = userStore.user?.userId || null;
    localActionWorkTaskModel.value.actionedDate = new Date();
    workTaskProductRefs.value = [];
    workTaskFertilizeProductRefs.value = [];
    workTaskSprayProductRefs.value = [];
    workTaskFarmBlockRefs.value = [];
    expansionPanelsActionDetails.value = [];
    expansionPanelsSubTasks.value = [0];
    expansionPanelsProducts.value = [0];
    expansionPanelsCropVarieties.value = [0];
    expansionPanelsFertilizerProducts.value = [0];
    expansionPanelsSprayProducts.value = [0];
};

/**
 * Flag to indicate if the user is modifying the completion details of a task that has already been completed/cancelled.
 */
const isModifyCompletedMode = computed(
    () => workTask.value && (workTask.value.isCompleted || workTask.value.isCancelled),
);

/**
 * The title of the Confirm box.
 */
const confirmBoxTitle = computed(() => {
    if (isModifyCompletedMode.value) {
        return i18n.global.t('ConfirmModificationOfThisConcludedTask');
    } else if (localActionWorkTaskModel.value.workTaskActionTypeCode == WorkTaskActionTypeCode.Cancelled) {
        return i18n.global.t('ConfirmCancellationOfTask');
    } else if (localActionWorkTaskModel.value.workTaskActionTypeCode == WorkTaskActionTypeCode.Completed) {
        return i18n.global.t('ConfirmCompletionOfTask');
    }

    return null;
});

/**
 * The confirmation message of the Confirm box.
 */
const confirmBoxConfirmationMessage = computed(() => {
    if (isModifyCompletedMode.value) {
        return i18n.global.t('ModifyCompletedWorkTaskConfirmationMessage');
    } else if (localActionWorkTaskModel.value.workTaskActionTypeCode == WorkTaskActionTypeCode.Cancelled) {
        return i18n.global.t('CancelWorkTaskConfirmationMessage');
    } else if (localActionWorkTaskModel.value.workTaskActionTypeCode == WorkTaskActionTypeCode.Completed) {
        return i18n.global.t('CompleteWorkTaskConfirmationMessage');
    }

    return null;
});

/**
 * Filtered list of work task actions to be used when validating that the new action is after the latest previous action. Only
 * certain types of actions should be included in this validation.
 */
const workTaskActionsToUseInValidation = computed(() => {
    if (!workTask.value) return [];

    return workTask.value.workTaskActions.filter(
        (ta) =>
            ta.workTaskActionTypeCode == WorkTaskActionTypeCode.Started ||
            ta.workTaskActionTypeCode == WorkTaskActionTypeCode.Paused ||
            ta.workTaskActionTypeCode == WorkTaskActionTypeCode.Completed ||
            ta.workTaskActionTypeCode == WorkTaskActionTypeCode.Cancelled ||
            ta.workTaskActionTypeCode == WorkTaskActionTypeCode.Restarted,
    );
});

// Watch for changes to modal state
watch(
    () => actionWorkTaskModalStore.isVisible,
    async (isVisible) => {
        if (isVisible) {
            await loadWorkTaskData();
        }
    },
);

/**
 * Update irrigation rate when the action mode is changed.
 */
watch(
    () => localActionWorkTaskModel.value.workTaskActionTypeCode,
    () => {
        if (!isLoading.value) {
            if (localActionWorkTaskModel.value.workTaskActionTypeCode == WorkTaskActionTypeCode.Cancelled) {
                // Cancel: Set the irrigation rate to 0
                localActionWorkTaskModel.value.irrigationRateActual = 0;
            } else if (localActionWorkTaskModel.value.workTaskActionTypeCode == WorkTaskActionTypeCode.Completed) {
                // Complete: Set the irrigation rate to the planned value
                if (workTask.value) {
                    localActionWorkTaskModel.value.irrigationRateActual = workTask.value.irrigationRatePlanned;
                }
            }
        }
    },
);
</script>

<style lang="scss" scoped>
@import '@/assets/scss/swatches.scss';

.formHead {
    width: 9rem;
}

.completeConfirmation {
    background-color: rgba($swatchGRN, 0.2) !important;

    .confirm-header {
        background-color: rgba($swatchA5, 0.5);
        font-size: large;
    }

    .confirm-checkbox {
        font-size: 18pt;
    }
}
</style>
