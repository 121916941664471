<template>
    <v-menu v-model="isOperationsActive" open-on-click>
        <template v-slot:activator="{props}">
            <v-btn v-bind="props" class="ml-2 swatchWHTBG" rounded="1" v-if="isInListView">
                <template v-slot:append>
                    <FontAwesomeIcon :icon="['fal', 'bars']" size="xl" />
                </template>
                {{ $t('Operations') }}
            </v-btn>
            <v-btn
                v-else
                class="operation-button icon-only"
                :class="isOperationsActive ? 'active-button' : ''"
                elevation="2"
                rounded="x-large"
                v-bind="props">
                <FontAwesomeIcon :icon="['fal', 'bars']" size="xl" />
            </v-btn>
        </template>

        <v-list>
            <v-list-item v-if="farmFields.length == 0">
                <v-list-item-title v-if="farmStore.selectedSiteId && userStore.hasRoleLevel(RoleCode.System, 1)">
                    <v-btn @click="viewFarmSiteModalStore.open(farmStore.selectedSiteId)">{{
                        $t('ViewFarmSite')
                    }}</v-btn>
                </v-list-item-title>
                <v-list-item-title v-if="userStore.hasRoleLevel(RoleCode.Fields, 3)">
                    <v-btn @click="manageFarmFieldModalStore.open()">
                        {{ $t('CreateANewField') }}
                    </v-btn>
                </v-list-item-title>
                <v-list-item-title @click="advancedSearchModalStore.close()">
                    <v-btn @click="router.push({name: 'FarmFieldList'})"> {{ $t('ManageFields') }} </v-btn>
                </v-list-item-title>
                <v-list-item-title v-if="userStore.hasRoleLevel(RoleCode.Tasks, 1)">
                    <v-list-item-title @click="advancedSearchModalStore.close()">
                        <v-btn @click="router.push({name: 'WorkTaskList'})">{{ $t('SiteTasksList') }}</v-btn>
                    </v-list-item-title>
                </v-list-item-title>
            </v-list-item>

            <v-list-item v-else-if="farmFields.length == 1">
                <v-list-item-title>
                    <v-btn @click="viewFarmFieldModalStore.open(farmFields[0].farmFieldId)">{{
                        $t('ViewField')
                    }}</v-btn>
                </v-list-item-title>
                <v-list-item-title v-if="farmFields[0].farmFieldId && userStore.hasRoleLevel(RoleCode.Fields, 2)">
                    <v-btn @click="manageFarmFieldModalStore.open(farmFields[0].farmFieldId)">
                        {{ $t('ModifyField') }}
                    </v-btn>
                </v-list-item-title>
                <v-list-item-title v-if="userStore.hasRoleLevel(RoleCode.Tasks, 2)">
                    <v-btn
                        @click="
                            manageWorkTaskModalStore.open(
                                ManageWorkTaskModalMode.Create,
                                null,
                                farmFields[0].farmFieldId,
                            )
                        ">
                        {{ $t('CreateNewTaskInField') }}
                    </v-btn>
                </v-list-item-title>
                <v-list-item-title v-if="userStore.hasRoleLevel(RoleCode.Fields, 2)">
                    <v-btn @click="manageFarmFieldSeasonModalStore.open(farmFields[0])">
                        {{ $t('ManageSeasons') }}
                    </v-btn>
                </v-list-item-title>
                <v-list-item-title v-if="userStore.hasRoleLevel(RoleCode.Tasks, 1)">
                    <v-btn @click="passSelectedIds('WorkTaskList')">{{ $t('FieldTaskList') }}</v-btn>
                </v-list-item-title>

                <v-list-item-title v-if="farmFields[0].farmFieldId && userStore.hasRoleLevel(RoleCode.Fields, 3)">
                    <v-btn
                        @click="
                            viewFarmFieldModalStore.close();
                            deleteFarmFieldModalStore.open(farmFields[0].farmFieldId);
                        ">
                        {{ $t('DeleteField') }}
                    </v-btn>
                </v-list-item-title>

                <v-list-item-title v-if="userStore.hasRoleLevel(RoleCode.Tasks, 3)">
                    <v-btn @click="clearTasksModalStore.open(farmFields[0].farmFieldId)">
                        {{ $t('ClearTasks') }}
                    </v-btn>
                </v-list-item-title>
            </v-list-item>

            <v-list-item v-else>
                <v-list-item-title v-if="userStore.hasRoleLevel(RoleCode.Tasks, 1)">
                    <v-btn @click="passSelectedIds('WorkTaskList')">{{ $t('ViewTasksForFields') }}</v-btn>
                </v-list-item-title>
                <v-list-item-title>
                    <v-btn @click="passSelectedIds('FarmFieldList')">{{ $t('ManageFields') }}</v-btn>
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script setup lang="ts">
import {FarmFieldSearchResultDto} from '@/models/data-transfer-objects/search/farm-field-search/FarmFieldSearchResultDto';
import {defineProps, ref} from 'vue';
import {useViewFarmSiteModalStore} from '@/stores/modals/view-farm-site-modal-store';
import {useViewFarmFieldModalStore} from '@/stores/modals/view-farm-field-modal-store';
import {useManageFarmFieldModalStore} from '@/stores/modals/manage-farm-field-modal-store';
import {useManageWorkTaskModalStore} from '@/stores/modals/manage-work-task-modal-store';
import {useManageFarmFieldSeasonModalStore} from '@/stores/modals/manage-farm-field-season-modal-store';
import {ManageWorkTaskModalMode} from '@/enums/modal-modes/manage-work-task-modal-mode';
import {useFarmStore} from '@/stores/farm-store';
import {RoleCode} from '@/enums/role-code';
import {useUserStore} from '@/stores/user-store';
const userStore = useUserStore();
import {useDeleteFarmFieldModalStore} from '@/stores/modals/delete-farm-field-modal-store';
const deleteFarmFieldModalStore = useDeleteFarmFieldModalStore();
import {useAdvancedSearchModalStore} from '@/stores/modals/advanced-search-modal-store';
const advancedSearchModalStore = useAdvancedSearchModalStore();
import router from '@/router';
import {useClearTasksModalStore} from '@/stores/modals/clear-tasks-modal-store';
import {useHomeDashboardStore} from '@/stores/home-dashboard-store';
const homeDashboardStore = useHomeDashboardStore();

defineProps<{
    farmFields: FarmFieldSearchResultDto[];
    isInListView?: boolean;
}>();

// Stores
const viewFarmSiteModalStore = useViewFarmSiteModalStore();
const viewFarmFieldModalStore = useViewFarmFieldModalStore();
const manageFarmFieldModalStore = useManageFarmFieldModalStore();
const manageWorkTaskModalStore = useManageWorkTaskModalStore();
const manageFarmFieldSeasonModalStore = useManageFarmFieldSeasonModalStore();
const farmStore = useFarmStore();
const clearTasksModalStore = useClearTasksModalStore();

const isOperationsActive = ref<boolean>(false);

// Pass selected farm field IDs to a report
const passSelectedIds = (route: string) => {
    homeDashboardStore.parameterFarmFields = homeDashboardStore.selectedFarmFieldsLevel1.map((ff) => ff.farmFieldId);
    router.push({name: route});
};
</script>
