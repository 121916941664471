<template>
    <v-dialog v-model="viewWorkTaskTemplateModalStore.isVisible" style="min-width: 90vw; max-width: 90vw">
        <v-card ref="viewWorkTaskTemplateModalContentRef">
            <v-toolbar color="secondary">
                <v-toolbar-title>
                    <FontAwesomeIcon :icon="['fak', 'task-template']" size="xl" class="mr-2" />
                    {{ $t('ViewTaskTemplate') }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <CloseModalButton @click="close" />
            </v-toolbar>

            <LoadingSymbol v-if="isLoading || workTaskTemplate == null" />

            <v-form v-else class="pa-4" style="overflow-y: scroll">
                <!-- Work Task Template Details Header -->
                <WorkTaskTemplateDetailsHeader :work-task-template="workTaskTemplate" />

                <!-- Work Task Template Details Subheader -->
                <div class="subHeader mt-2">
                    {{ $t('TaskDetails') }}
                </div>

                <!-- Work Task Template Details -->
                <WorkTaskTemplateDetails :work-task-template="workTaskTemplate" />
            </v-form>

            <v-card-actions class="justify-space-between swatchG9BG">
                <v-btn @click="close">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'arrow-rotate-left']" size="xl" />
                    </template>
                    {{ $t('Cancel') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" timeout="4000">
        {{ snackbar.text }}
    </v-snackbar>
</template>

<script setup lang="ts">
import '@/assets/scss/user/user-manager.scss';
import {watch, inject, ref, ComponentPublicInstance, nextTick} from 'vue';
import ApiService from '@/services/api-service.js';
import CloseModalButton from '@/components/CloseModalButton.vue';
import WorkTaskTemplateDetailsHeader from '@/components/WorkTaskTemplateDetailsHeader.vue';
import WorkTaskTemplateDetails from '@/components/WorkTaskTemplateDetails.vue';
import {SearchWorkTaskTemplatesRequest} from '@/models/api/requests/search/SearchWorkTaskTemplatesRequest';
import {SearchWorkTaskTemplatesResponse} from '@/models/api/responses/search/SearchWorkTaskTemplatesResponse';
import {WorkTaskTemplateSearchResultDto} from '@/models/data-transfer-objects/search/work-task-template-search/WorkTaskTemplateSearchResultDto';
import {useViewWorkTaskTemplateModalStore} from '@/stores/modals/view-work-task-template-modal-store';
import PrintHelper from '@/helpers/PrintHelper';
import i18n from '@/i18n';
import {WorkTaskTemplateSearchParametersDto} from '@/models/data-transfer-objects/search/work-task-template-search/WorkTaskTemplateSearchParametersDto';

// Form
let isLoading = ref<boolean>(false);
const viewWorkTaskTemplateModalContentRef = ref<ComponentPublicInstance | null>(null);
const snackbar = ref({
    show: false,
    text: '',
});

// Services
const apiService = inject('apiService') as ApiService;

// Modal
const viewWorkTaskTemplateModalStore = useViewWorkTaskTemplateModalStore();
const workTaskTemplate = ref<WorkTaskTemplateSearchResultDto | null>(null);

/**
 * Load work task template data for the modal.
 */
const loadWorkTaskTemplateData = async () => {
    isLoading.value = true;

    // Reset form
    resetForm();

    // If a work task ID was specified
    if (viewWorkTaskTemplateModalStore.workTaskTemplateId !== null) {
        // Build search request using the work task template ID
        const searchRequest: SearchWorkTaskTemplatesRequest = {
            searchParameters: new WorkTaskTemplateSearchParametersDto({
                workTaskTemplateId: viewWorkTaskTemplateModalStore.workTaskTemplateId,
                includeArtifacts: true,
                includeWorkTaskTemplateSubTasks: true,
                includeWorkTaskTemplateMixtures: true,
                includeWorkTaskTemplateProducts: true,
                includeWorkTaskTemplateCropVarietyPreferences: true,
            }),
        };

        // Get work task template details
        const searchResults = (await apiService.post(
            'search/work-task-templates',
            searchRequest,
        )) as SearchWorkTaskTemplatesResponse;

        if (searchResults.workTaskTemplates.length == 1) {
            // Store search result
            workTaskTemplate.value = searchResults.workTaskTemplates[0];

            // Sort work task products
            sortWorkTaskProducts();
        }
    }

    // End loading
    isLoading.value = false;

    if (viewWorkTaskTemplateModalStore.isPrinting) {
        printContent();
    }
};

/**
 * Close the modal.
 */
const close = () => {
    viewWorkTaskTemplateModalStore.close();
};

/**
 * Reset the form to its default state.
 */
const resetForm = () => {
    workTaskTemplate.value = null;
};

/**
 * Sort the list of products to ensure that they can be grouped by mixture.
 */
const sortWorkTaskProducts = () => {
    if (!workTaskTemplate.value) return;

    workTaskTemplate.value.workTaskTemplateProducts.sort((a, b) => {
        // Prioritize items with non-null mixtureName
        if (a.mixtureName === null && b.mixtureName !== null) return 1; // 'a' comes after 'b'
        if (a.mixtureName !== null && b.mixtureName === null) return -1; // 'a' comes before 'b'

        // If both mixtureNames are non-null, or both are null, compare by mixtureName
        if (a.mixtureName !== null && b.mixtureName !== null) {
            if (a.mixtureName < b.mixtureName) return -1;
            if (a.mixtureName > b.mixtureName) return 1;
        }

        // If mixtureName is the same or both are null, prioritize non-null mixtureId
        if (a.mixtureId === null && b.mixtureId !== null) return 1; // 'a' comes after 'b'
        if (a.mixtureId !== null && b.mixtureId === null) return -1; // 'a' comes before 'b'

        // If both mixtureIds are non-null, or both are null, compare by mixtureId
        if (a.mixtureId !== null && b.mixtureId !== null) {
            if (a.mixtureId < b.mixtureId) return -1;
            if (a.mixtureId > b.mixtureId) return 1;
        }

        // If both mixtureName and mixtureId are the same or both null, compare by workTaskProductId
        if (a.workTaskTemplateProductId < b.workTaskTemplateProductId) return -1;
        if (a.workTaskTemplateProductId > b.workTaskTemplateProductId) return 1;

        // If all fields are equal, return 0 (no change in order)
        return 0;
    });
};

/**
 * Get the current date in YYYYMMDD format.
 */
const getCurrentDateFormatted = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
    const day = String(today.getDate()).padStart(2, '0');

    return `${year}${month}${day}`;
};

/**
 * Obtains modal content for printing.
 */
const printContent = async () => {
    await nextTick(); // Ensure DOM is updated
    if (viewWorkTaskTemplateModalContentRef.value && viewWorkTaskTemplateModalContentRef.value.$el) {
        const content = viewWorkTaskTemplateModalContentRef.value.$el.innerHTML; // Access DOM element
        if (content) {
            const currentDate = getCurrentDateFormatted();
            const taskTemplateName = workTaskTemplate.value?.workTaskTemplateName
                ? workTaskTemplate.value?.workTaskTemplateName
                : i18n.global.t('PrintTaskTemplate');
            const taskTemplateId = workTaskTemplate.value?.workTaskTemplateId;
            const printTitle = `${currentDate}_${taskTemplateName.replace(/\s+/g, '_')}_${taskTemplateId}`;
            PrintHelper.printDialogContent(content, printTitle);
        } else {
            snackbar.value.show = true;
            snackbar.value.text = i18n.global.t('ErrorGeneric');
        }
    }

    viewWorkTaskTemplateModalStore.isPrinting = false;
};

// Watch for changes to modal state
watch(
    () => viewWorkTaskTemplateModalStore.isVisible,
    async (isVisible) => {
        if (isVisible) {
            await loadWorkTaskTemplateData();
        }
    },
);

watch(
    () => viewWorkTaskTemplateModalStore.isPrinting,
    async (isPrinting) => {
        if (isPrinting && !isLoading.value) {
            printContent();
        }
    },
);
</script>
