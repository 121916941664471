<template>
    <v-container class="navigator-panel pa-0 ma-0">
        <v-row no-gutters>
            <!-- Level 1 -->
            <v-col cols="auto">
                <v-card class="rounded-0" style="width: 400px">
                    <LoadingSymbol v-if="homeDashboardStore.isLoading" />
                    <div v-else>
                        <v-tabs
                            height="80"
                            class="navigator-panel-tabs"
                            bg-color="swatchA1"
                            v-model="homeDashboardStore.navigatorPanelActiveTab"
                            align-tabs="center">
                            <!-- Custom previous arrow -->
                            <div class="navigator-arrow">
                                <v-btn class="arrow-button" icon @click="changeTab(false)">
                                    <FontAwesomeIcon :icon="['fa', 'chevron-left']" size="xl" />
                                </v-btn>
                            </div>
                            <v-tab
                                class="navigator-tab"
                                :class="{
                                    'active-tab':
                                        homeDashboardStore.navigatorPanelActiveTab === NavigatorPanelTab.FarmFields,
                                }"
                                :value="NavigatorPanelTab.FarmFields">
                                <div>
                                    <div class="icon-container">
                                        <FontAwesomeIcon :icon="['fal', 'vector-polygon']" />
                                    </div>
                                    <div class="tab-text-container">
                                        {{ $t('NavigatorPanelTab_Fields') }}
                                    </div>
                                </div>
                            </v-tab>
                            <v-tab
                                class="navigator-tab"
                                :class="{
                                    'active-tab':
                                        homeDashboardStore.navigatorPanelActiveTab === NavigatorPanelTab.WorkTasks,
                                }"
                                :value="NavigatorPanelTab.WorkTasks">
                                <div>
                                    <div class="icon-container">
                                        <FontAwesomeIcon :icon="['fal', 'clipboard-list-check']" />
                                    </div>
                                    <div class="tab-text-container">
                                        {{ $t('NavigatorPanelTab_Tasks') }}

                                        <span
                                            v-if="level2AlertNumber > 0"
                                            class="alert-marker tasks"
                                            :style="{
                                                fontSize: level2AlertNumber < 100 ? '12px' : '8px',
                                            }">
                                            {{ level2AlertNumber }}
                                        </span>
                                    </div>
                                </div>
                            </v-tab>
                            <v-tab
                                class="navigator-tab"
                                :class="{
                                    'active-tab':
                                        homeDashboardStore.navigatorPanelActiveTab === NavigatorPanelTab.Observations,
                                }"
                                :value="NavigatorPanelTab.Observations">
                                <div>
                                    <div class="icon-container">
                                        <FontAwesomeIcon :icon="['fal', 'binoculars']" />
                                    </div>
                                    <div class="tab-text-container">
                                        {{ $t('NavigatorPanelTab_Obs') }}

                                        <span
                                            v-if="level3AlertNumber > 0"
                                            class="alert-marker obs"
                                            :style="{
                                                fontSize: level3AlertNumber < 100 ? '12px' : '8px',
                                            }">
                                            {{ level3AlertNumber }}
                                        </span>
                                    </div>
                                </div>
                            </v-tab>
                            <!-- Custom next arrow -->
                            <div class="navigator-arrow">
                                <v-btn class="arrow-button" icon @click="changeTab(true)">
                                    <FontAwesomeIcon :icon="['fa', 'chevron-right']" size="xl" />
                                </v-btn>
                            </div>
                        </v-tabs>
                        <NavigatorOperations
                            :navigator-level="1"
                            :expanded-width="true"
                            :is-compressed-task-mode="isCompressedTaskMode"
                            @update-compressed-mode="handleUpdateCompressedMode" />
                        <v-tabs-window
                            v-model="homeDashboardStore.navigatorPanelActiveTab"
                            class="navigator-panel-content swatchBGBG"
                            :style="{height: contentHeight}"
                            @scroll="homeDashboardStore.onFarmFieldMouseOut">
                            <!-- Level 1: Farm Fields -->
                            <v-tabs-window-item :value="NavigatorPanelTab.FarmFields">
                                <NavigatorTabFarmFields
                                    class="fill-height"
                                    :key="homeDashboardStore.fieldPanelVersion" />
                            </v-tabs-window-item>
                            <!-- Level 1: Work Tasks -->
                            <v-tabs-window-item :value="NavigatorPanelTab.WorkTasks">
                                <NavigatorTabWorkTasks
                                    v-model:modelValue="homeDashboardStore.selectedWorkTaskLevel1"
                                    :is-tab1="true"
                                    :isCompressedMode="isCompressedTaskMode"
                                    :key="homeDashboardStore.workTaskPanelVersion"
                                    class="fill-height" />
                            </v-tabs-window-item>
                            <!-- Level 1: Observations -->
                            <v-tabs-window-item :value="NavigatorPanelTab.Observations">
                                <NavigatorTabObservations class="fill-height" />
                            </v-tabs-window-item>
                        </v-tabs-window>
                    </div>
                </v-card>
            </v-col>
            <v-divider v-if="isLevel2TabVisible" class="swatchG9" vertical />
            <!-- Level 2 -->
            <v-col v-if="isLevel2TabVisible" :style="{width: level2TabWidth + 'px'}">
                <NavigatorTabFarmDetailsHeader
                    v-if="homeDashboardStore.navigatorPanelActiveTab !== NavigatorPanelTab.Observations"
                    :farm-field="homeDashboardStore.selectedFarmFieldsLevel1[0]"
                    :farm-field-id="homeDashboardStore.selectedWorkTaskLevel1?.farmFieldId" />
                <NavigatorTabObservationDetailsHeader
                    v-else-if="
                        homeDashboardStore.navigatorPanelActiveTab === NavigatorPanelTab.Observations &&
                        homeDashboardStore.selectedObservationLevel1
                    "
                    :observation="homeDashboardStore.selectedObservationLevel1" />
                <NavigatorOperations
                    :navigator-level="2"
                    :expanded-width="level2TabWidth == '400'"
                    :is-compressed-task-mode="isCompressedTaskMode"
                    @update-compressed-mode="handleUpdateCompressedMode" />
                <!-- Level 2: Farm Fields > Work Tasks -->
                <div v-if="isFarmFieldsLevel2TabVisible" class="navigator-panel-content swatchBLK">
                    <NavigatorTabWorkTasks
                        v-model:modelValue="homeDashboardStore.selectedWorkTaskLevel2"
                        :farm-field="homeDashboardStore.selectedFarmFieldsLevel1[0]"
                        :isCompressedMode="isCompressedTaskMode"
                        :is-tab1="false"
                        :style="{height: contentHeight}" />
                </div>
                <!-- Level 2: Work Tasks > Work Task Details -->
                <div v-else-if="isWorkTasksLevel2TabVisible" class="navigator-panel-content swatchBLK">
                    <NavigatorTabWorkTaskDetails
                        :work-task-id="homeDashboardStore.selectedWorkTaskLevel1?.workTaskId"
                        :style="{height: contentHeight}" />
                </div>
                <!-- Level 2: Observations > Observation Details -->
                <div v-else-if="isObservationsLevel2TabVisible" class="navigator-panel-content swatchBLK">
                    <NavigatorTabObservationDetails
                        :observation-id="homeDashboardStore.selectedObservationLevel1?.observationId"
                        :style="{height: contentHeight}" />
                </div>
            </v-col>
            <v-divider v-if="isLevel3TabVisible" class="swatchG9" vertical />
            <!-- Level 3 -->
            <v-col v-if="isLevel3TabVisible" style="width: 340px">
                <NavigatorTabFarmDetailsHeader
                    v-if="homeDashboardStore.navigatorPanelActiveTab === NavigatorPanelTab.FarmFields"
                    :farm-field="homeDashboardStore.selectedFarmFieldsLevel1[0]" />
                <NavigatorOperations :navigator-level="3" :expanded-width="false" />
                <!-- Level 2: Farm Fields > Work Tasks > Work Task Details -->
                <div v-if="isWorkTasksLevel3TabVisible" class="navigator-panel-content swatchBLK">
                    <NavigatorTabWorkTaskDetails
                        :work-task-id="homeDashboardStore.selectedWorkTaskLevel2?.workTaskId" />
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
import {computed, inject, onMounted, ref, watch} from 'vue';
import {NavigatorPanelTab} from '@/enums/home-dashboard/navigator-panel-tab';
import NavigatorTabFarmFields from '@/views/home-dashboard/NavigatorTabFarmFields.vue';
import NavigatorTabWorkTasks from '@/views/home-dashboard/NavigatorTabWorkTasks.vue';
import NavigatorTabWorkTaskDetails from '@/views/home-dashboard/NavigatorTabWorkTaskDetails.vue';
import NavigatorTabObservations from '@/views/home-dashboard/NavigatorTabObservations.vue';
import NavigatorTabObservationDetails from '@/views/home-dashboard/NavigatorTabObservationDetails.vue';
import NavigatorTabFarmDetailsHeader from '@/views/home-dashboard/NavigatorTabFarmDetailsHeader.vue';
import NavigatorTabObservationDetailsHeader from '@/views/home-dashboard/NavigatorTabObservationDetailsHeader.vue';
import NavigatorOperations from '@/views/home-dashboard/NavigatorOperations.vue';
import {SearchFarmFieldsRequest} from '@/models/api/requests/search/SearchFarmFieldsRequest';
import ApiService from '@/services/api-service';
import {SearchWorkTasksRequest} from '@/models/api/requests/search/SearchWorkTasksRequest';
import {SearchObservationsRequest} from '@/models/api/requests/search/SearchObservationsRequest';
import {SearchFarmFieldsResponse} from '@/models/api/responses/search/SearchFarmFieldsResponse';
import {SearchWorkTasksResponse} from '@/models/api/responses/search/SearchWorkTasksResponse';
import {SearchObservationsResponse} from '@/models/api/responses/search/SearchObservationsResponse';
import {useUserStore} from '@/stores/user-store';
import {useHomeDashboardStore} from '@/stores/home-dashboard-store';
import {useManageWorkTaskModalStore} from '@/stores/modals/manage-work-task-modal-store';
import {useDeleteOrCancelWorkTaskModalStore} from '@/stores/modals/delete-or-cancel-work-task-modal-store';
import {useManageObservationModalStore} from '@/stores/modals/manage-observation-modal-store';
import {useViewObservationModalStore} from '@/stores/modals/view-observation-modal-store';
import {useActionWorkTaskModalStore} from '@/stores/modals/action-work-task-modal-store';
import {useClearTasksModalStore} from '@/stores/modals/clear-tasks-modal-store';
import {useRescheduleWorkTaskModalStore} from '@/stores/modals/reschedule-work-task-modal-store';
import {RoleCode} from '@/enums/role-code';
import {useManageFarmFieldModalStore} from '@/stores/modals/manage-farm-field-modal-store';
import {useDeleteFarmFieldModalStore} from '@/stores/modals/delete-farm-field-modal-store';
const deleteFarmFieldModalStore = useDeleteFarmFieldModalStore();

const contentHeight = ref<string>('');
const userStore = useUserStore();
const manageWorkTaskModalStore = useManageWorkTaskModalStore();
const actionWorkTaskModalStore = useActionWorkTaskModalStore();
const rescheduleWorkTaskModalStore = useRescheduleWorkTaskModalStore();
const manageObservationModalStore = useManageObservationModalStore();
const viewObservationModalStore = useViewObservationModalStore();
const deleteOrCancelWorkTaskModalStore = useDeleteOrCancelWorkTaskModalStore();
const manageFarmFieldModalStore = useManageFarmFieldModalStore();
const homeDashboardStore = useHomeDashboardStore();
const clearTasksModalStore = useClearTasksModalStore();
const isCompressedTaskMode = ref(false);

// Initial load of data
const apiService = inject('apiService') as ApiService;
const fetchDataForEachTab = async () => {
    homeDashboardStore.isLoading = true;

    // Farm Fields
    if (homeDashboardStore.farmFieldPreventInitialLoading) homeDashboardStore.farmFieldPreventInitialLoading = false;
    else {
        if (userStore.hasRoleLevel(RoleCode.Fields, 1)) {
            const farmFieldsSearchRequest: SearchFarmFieldsRequest = new SearchFarmFieldsRequest({
                isQuickSearch: false,
                farmSiteId: userStore.user?.farmSiteId,
                includeCropVarieties: true,
                includeFarmBlocks: true,
            });
            const farmFieldsSearchResponse: SearchFarmFieldsResponse = await apiService.post(
                'search/farm-fields',
                farmFieldsSearchRequest,
            );
            homeDashboardStore.farmFields = farmFieldsSearchResponse.farmFields;
        } else {
            homeDashboardStore.farmFields = [];
        }
    }

    // Work Tasks
    if (homeDashboardStore.workTaskPreventLoadingList) {
        homeDashboardStore.workTaskPreventLoadingList = false;
    } else {
        if (userStore.hasRoleLevel(RoleCode.Tasks, 1)) {
            const workTasksSearchRequest: SearchWorkTasksRequest = new SearchWorkTasksRequest({
                isQuickSearch: false,
                farmSiteId: userStore.user?.farmSiteId,
                includeWorkTaskMixtures: true,
                includeWorkTaskProducts: true,
                includeWorkTaskUsers: true,
                includeWorkSubTasks: true,
            });
            const workTasksSearchResponse: SearchWorkTasksResponse = await apiService.post(
                'search/work-tasks',
                workTasksSearchRequest,
            );
            homeDashboardStore.workTasks = workTasksSearchResponse.workTasks;
        } else {
            homeDashboardStore.workTasks = [];
        }
    }

    // Observations
    if (homeDashboardStore.observationPreventInitialLoading)
        homeDashboardStore.observationPreventInitialLoading = false;
    else {
        if (userStore.hasRoleLevel(RoleCode.Observations, 1)) {
            const observationsSearchRequest: SearchObservationsRequest = new SearchObservationsRequest({
                isQuickSearch: false,
                farmSiteId: userStore.user?.farmSiteId,
            });
            const observationsSearchResponse: SearchObservationsResponse = await apiService.post(
                'search/observations',
                observationsSearchRequest,
            );
            homeDashboardStore.observations = observationsSearchResponse.observations;
        } else {
            homeDashboardStore.observations = [];
        }
    }

    // End loading
    homeDashboardStore.isLoading = false;

    // If a farm field or observation should be focused on, do that now
    homeDashboardStore.focusOnFarmField();
    homeDashboardStore.focusOnObservation();
};

// Functions to determine whether the various level 2/3 tabs are visible
const isFarmFieldsLevel2TabVisible = computed(() => {
    return (
        !homeDashboardStore.isMultiSelectFarmFieldsMode &&
        homeDashboardStore.selectedFarmFieldsLevel1 &&
        homeDashboardStore.selectedFarmFieldsLevel1.length > 0 &&
        homeDashboardStore.navigatorPanelActiveTab == NavigatorPanelTab.FarmFields &&
        userStore.hasRoleLevel(RoleCode.Tasks, 1)
    );
});
const isWorkTasksLevel2TabVisible = computed(() => {
    return (
        homeDashboardStore.selectedWorkTaskLevel1 &&
        homeDashboardStore.navigatorPanelActiveTab == NavigatorPanelTab.WorkTasks
    );
});
const isObservationsLevel2TabVisible = computed(() => {
    return (
        homeDashboardStore.selectedObservationLevel1 &&
        homeDashboardStore.navigatorPanelActiveTab == NavigatorPanelTab.Observations
    );
});
const isLevel2TabVisible = computed(() => {
    return (
        isFarmFieldsLevel2TabVisible.value || isWorkTasksLevel2TabVisible.value || isObservationsLevel2TabVisible.value
    );
});
const isWorkTasksLevel3TabVisible = computed(() => {
    return (
        homeDashboardStore.selectedFarmFieldsLevel1 &&
        homeDashboardStore.selectedWorkTaskLevel2 &&
        homeDashboardStore.navigatorPanelActiveTab == NavigatorPanelTab.FarmFields
    );
});
const isLevel3TabVisible = computed(() => {
    return isWorkTasksLevel3TabVisible.value;
});
const level2TabWidth = computed(() => {
    return isObservationsLevel2TabVisible.value ? '400' : '340';
});

const level2AlertNumber = computed(() => {
    return homeDashboardStore.workTasks.filter((wt) => wt.isFlaggedAsAlert).length;
});

const level3AlertNumber = computed(() => {
    return homeDashboardStore.observations.filter((o) => o.isFlaggedAsAlert).length;
});

// Changes the active tab when pagination arrows are clicked
const changeTab = (isRight: boolean) => {
    const enumKeys = Object.keys(NavigatorPanelTab) as Array<keyof typeof NavigatorPanelTab>;
    const currentTabIndex = enumKeys.indexOf(homeDashboardStore.navigatorPanelActiveTab);

    let newIndex;
    if (isRight) {
        newIndex = (currentTabIndex + 1) % enumKeys.length;
    } else {
        newIndex = (currentTabIndex - 1 + enumKeys.length) % enumKeys.length;
    }

    homeDashboardStore.navigatorPanelActiveTab = NavigatorPanelTab[enumKeys[newIndex]];
};

const handleUpdateCompressedMode = (newCompressedMode: boolean) => {
    isCompressedTaskMode.value = newCompressedMode;
};

const calculateContentHeight = () => {
    const viewportHeight = window.innerHeight;
    const header = document.querySelector('.divMasterHeaderRow');
    const footer = document.querySelector('.divMasterFooterCell');
    const panel = document.querySelector('.navigator-panel-tabs');
    const operations = document.querySelector('.navigator-panel-operations');
    const headerHeight = header ? header.clientHeight : 0;
    const footerHeight = footer ? footer.clientHeight : 0;
    const panelHeight = panel ? panel.clientHeight : 0;
    const operationsHeight = operations ? operations.clientHeight : 0;
    const availableHeight = viewportHeight - (headerHeight + footerHeight + panelHeight + operationsHeight);

    contentHeight.value = `${availableHeight}px`;
};

// Reload home dashboard data
const reloadData = async () => {
    // Deselect all items
    homeDashboardStore.deselectFarmFieldsLevel1();
    homeDashboardStore.deselectWorkTaskLevel1();
    homeDashboardStore.deselectObservationLevel1();
    homeDashboardStore.deselectWorkTaskLevel2();

    // Reload data
    await fetchDataForEachTab();
};

// When the user changes their selected farm site, reload data.
watch(
    () => userStore.user?.farmSiteId,
    async () => {
        if (userStore.user) {
            await reloadData();
        }
    },
);

// When fields/tasks/observations are changed, reload data
watch(
    [
        () => manageWorkTaskModalStore.savedCounter,
        () => manageObservationModalStore.savedCounter,
        () => deleteOrCancelWorkTaskModalStore.savedCounter,
        () => viewObservationModalStore.savedCounter,
        () => actionWorkTaskModalStore.savedCounter,
        () => rescheduleWorkTaskModalStore.savedCounter,
        () => manageFarmFieldModalStore.savedCounter,
        () => deleteFarmFieldModalStore.deletedCounter,
        () => clearTasksModalStore.deletedCounter,
    ],
    async () => {
        await reloadData();
    },
    {deep: true},
);

// Get initial list of items for each tab. This is done in the parent component so that it knows about the results for each tab
// before the tab has been mounted. This allows us to display the number of alerts in the parent component immediately after the
// page has been accessed.
onMounted(async () => {
    calculateContentHeight();
    window.addEventListener('resize', calculateContentHeight);
    await fetchDataForEachTab();
});
</script>

<style lang="scss">
@import '@/assets/scss/home-dashboard/navigator-panel.scss';
</style>
