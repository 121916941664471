<template>
    <v-dialog v-model="deleteWorkTaskPlanModalStore.isVisible" style="width: 70vw">
        <v-card>
            <v-toolbar color="swatchA2">
                <v-toolbar-title>
                    <FontAwesomeIcon :icon="['fal', 'trash-xmark']" size="xl" class="mr-2" />
                    {{ $t('ConfirmWorkTaskPlanDelete') }}
                </v-toolbar-title>
                <v-spacer />
                <CloseModalButton @click="close" />
            </v-toolbar>
            <LoadingSymbol v-if="isLoading" />
            <v-form v-else ref="form" class="pa-4">
                <div v-if="workTaskPlan">
                    <WorkTaskPlanDetailsHeader :work-task-plan="workTaskPlan" :show-delete-plan-link="false" />
                </div>

                <!-- Confirm Delete -->
                <v-card class="marginTop">
                    <v-toolbar class="permission-denied pa-3" v-if="!userHasAccess">
                        <v-toolbar-title style="font-size: medium">
                            {{ $t('DeleteWorkTaskPlan_PermissionDenied') }}
                        </v-toolbar-title>
                    </v-toolbar>

                    <v-card-text class="complete-confirmation pa-0" v-else>
                        <v-row class="confirm-header pa-3 ma-0">
                            <FontAwesomeIcon :icon="['fal', 'check']" size="xl" class="mr-5" />
                            <span> {{ $t('ConfirmDeletion') }} </span>
                        </v-row>

                        <div class="pa-2">
                            <v-row class="ma-2" style="font-size: medium">
                                {{ $t('DeleteWorkTaskPlan_ActionTracked') }}
                            </v-row>

                            <v-row class="ma-1">
                                <v-col cols="1" align-self="center">
                                    <v-checkbox v-model="isConfirmed" class="confirm-checkbox" hide-details />
                                </v-col>
                                <v-col cols="11" align-self="center" style="font-size: medium">
                                    {{ $t('DeleteWorkTaskPlan_Confirmation') }}
                                </v-col>
                            </v-row>
                        </div>
                    </v-card-text>
                </v-card>
            </v-form>

            <v-card-actions class="justify-space-between swatchG9BG" v-if="!isLoading">
                <v-btn @click="close">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'arrow-rotate-left']" size="xl" />
                    </template>
                    {{ $t('Cancel') }}
                </v-btn>
                <v-btn @click="confirmDelete" :disabled="!isConfirmed" v-if="userHasAccess">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'check']" size="xl" />
                    </template>
                    {{ $t('ConfirmAndSubmit') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" timeout="4000">
        {{ snackbar.text }}
    </v-snackbar>
</template>

<script setup lang="ts">
import '@/assets/scss/farm/delete-farm-site-modal.scss';
import '@/assets/scss/user/user-manager.scss';

import {useUserStore} from '@/stores/user-store';
import {RoleCode} from '@/enums/role-code';
import {useDeleteWorkTaskPlanModalStore} from '@/stores/modals/delete-work-task-plan-modal-store';
import {watch, inject, ref, onUnmounted} from 'vue';
import ApiService from '@/services/api-service.js';
import CloseModalButton from '@/components/CloseModalButton.vue';
import i18n from '@/i18n';
import {WorkTaskPlanSearchResultDto} from '@/models/data-transfer-objects/search/work-task-plan-search/WorkTaskPlanSearchResultDto';
import {SearchWorkTaskPlansRequest} from '@/models/api/requests/search/SearchWorkTaskPlansRequest';
import {WorkTaskPlanSearchParametersDto} from '@/models/data-transfer-objects/search/work-task-plan-search/WorkTaskPlanSearchParametersDto';
import {SearchWorkTaskPlansResponse} from '@/models/api/responses/search/SearchWorkTaskPlansResponse';
import WorkTaskPlanDetailsHeader from '@/components/WorkTaskPlanDetailsHeader.vue';
const userStore = useUserStore();

// Form
let isLoading = ref<boolean>(false);
const snackbar = ref({
    show: false,
    text: '',
});
const deleteWorkTaskPlanModalStore = useDeleteWorkTaskPlanModalStore();
const isConfirmed = ref<boolean>(false);
const workTaskPlan = ref<WorkTaskPlanSearchResultDto | null>(null);

// Services
const apiService = inject('apiService') as ApiService;

const userHasAccess = ref<boolean>(userStore.hasRoleLevel(RoleCode.PlansAndTemplates, 3));

/**
 * Load data for the modal.
 */
const loadWorkTaskPlanData = async () => {
    isLoading.value = true;

    // Reset form
    resetForm();

    // If a work task plan ID was specified
    if (deleteWorkTaskPlanModalStore.workTaskPlanId !== null) {
        // Build search request using the work task plan ID
        const searchRequest: SearchWorkTaskPlansRequest = {
            searchParameters: new WorkTaskPlanSearchParametersDto({
                workTaskPlanId: deleteWorkTaskPlanModalStore.workTaskPlanId,
            }),
        };

        // Get work task plan details
        const searchResults = (await apiService.post(
            'search/work-task-plans',
            searchRequest,
        )) as SearchWorkTaskPlansResponse;

        if (searchResults.workTaskPlans.length == 1) {
            // Store search result
            workTaskPlan.value = searchResults.workTaskPlans[0];
        }
    }

    isLoading.value = false;
};

/**
 * Close the modal.
 */
const close = () => {
    deleteWorkTaskPlanModalStore.close();
};

/**
 * Save the modal.
 */
const confirmDelete = async () => {
    if (workTaskPlan.value == null) return;

    if (!userHasAccess.value) return;
    isLoading.value = true;

    try {
        // Call API to delete plan
        const response = await apiService.post(
            'work-task-plans/delete-work-task-plan',
            workTaskPlan.value.workTaskPlanId,
        );

        if (response) {
            // Show success feedback to user
            snackbar.value.show = true;
            snackbar.value.text = i18n.global.t('DeleteWorkTaskPlan_Success');
            deleteWorkTaskPlanModalStore.deletedCounter++;

            // Close modal
            deleteWorkTaskPlanModalStore.close();
        } else {
            // Show fail feedback to user
            snackbar.value.show = true;
            snackbar.value.text = i18n.global.t('ErrorGeneric');
            isLoading.value = false;
        }
    } catch (ex: unknown) {
        // Show fail feedback to user
        snackbar.value.show = true;
        snackbar.value.text = i18n.global.t('ErrorGeneric');
        isLoading.value = false;
    }
};

onUnmounted(() => {
    deleteWorkTaskPlanModalStore.close();
});

/**
 * Reset the form to its default state.
 */
const resetForm = () => {
    workTaskPlan.value = null;
    isConfirmed.value = false;
};

/**
 * Watch for changes to workTaskPlanId.
 */
watch(
    () => deleteWorkTaskPlanModalStore.isVisible,
    async (isVisible) => {
        if (isVisible) {
            await loadWorkTaskPlanData();
        }
    },
);
</script>

<style lang="scss" scoped>
@import '@/assets/scss/swatches.scss';

.permission-denied {
    background-color: rgba($swatchRED, 0.2) !important;
}

.complete-confirmation {
    background-color: rgba($swatchGRN, 0.2) !important;

    .confirm-header {
        background-color: rgba($swatchA5, 0.5);
        font-size: large;
    }

    .confirm-checkbox {
        font-size: 18pt;
    }
}
</style>
