<template>
    <div>
        <v-dialog v-model="showLogoutWarning" persistent max-width="500" @click:outside="handleClosePopup">
            <v-card>
                <v-toolbar color="primary">
                    <v-toolbar-title>
                        <FontAwesomeIcon :icon="['fas', 'warning']" size="xl" class="mr-2" />
                        {{ $t('Warning') }}
                    </v-toolbar-title>
                    <v-spacer />
                    <CloseModalButton @click="handleClosePopup" />
                </v-toolbar>
                <v-card-text>{{ $t('LogoutWarning_Text') }}</v-card-text>
            </v-card>
        </v-dialog>

        <v-container fluid fill-height class="pa-0 ma-0">
            <v-row no-gutters>
                <!-- Navigator Panel -->
                <v-col cols="auto">
                    <NavigatorPanel />
                </v-col>
                <!-- Home Dashboard Map -->
                <v-col>
                    <HomeDashboardMap />
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script setup lang="ts">
import NavigatorPanel from '@/views/home-dashboard/NavigatorPanel.vue';
import HomeDashboardMap from '@/views/home-dashboard/HomeDashboardMap.vue';
import {computed, onMounted, onUnmounted, watch} from 'vue';
import {useUserStore} from '@/stores/user-store';
import {useHomeDashboardStore} from '@/stores/home-dashboard-store';
const userStore = useUserStore();
const homeDashboardStore = useHomeDashboardStore();

const showLogoutWarning = computed(() => userStore.showLogoutWarning);

const handleClosePopup = () => {
    userStore.setShowLogoutWarning(false);
};

onMounted(() => {
    // Update flag to indicate that user is on the Home Dashboard
    homeDashboardStore.isHomeDashboardOpen = true;
});

onUnmounted(() => {
    // Deselect and clear all items when the Home Dashboard is navigated away from
    homeDashboardStore.deselectFarmFieldsLevel1();
    homeDashboardStore.deselectWorkTaskLevel1();
    homeDashboardStore.deselectObservationLevel1();
    homeDashboardStore.deselectWorkTaskLevel2();
    homeDashboardStore.farmFields.length = 0;
    homeDashboardStore.workTasks = [];
    homeDashboardStore.observations = [];
    homeDashboardStore.resetFarmFieldSearchParameters();
    homeDashboardStore.resetWorkTaskSearchParameters(true);
    homeDashboardStore.resetObservationSearchParameters(true);

    // Update flag to indicate that user is not on the Home Dashboard
    homeDashboardStore.isHomeDashboardOpen = false;
});

/**
 * Watch if the user's selected farm site has changed
 */
watch(
    () => userStore.user?.farmSiteId,
    () => {
        homeDashboardStore.resetFarmFieldSearchParameters();
        homeDashboardStore.resetWorkTaskSearchParameters(true);
        homeDashboardStore.resetObservationSearchParameters(true);
    },
);
</script>
