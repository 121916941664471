<template>
    <v-dialog v-model="viewWorkTaskPlanModalStore.isVisible" style="min-width: 90vw; max-width: 90vw">
        <v-card ref="viewWorkTaskPlanModalContentRef">
            <v-toolbar color="secondary">
                <v-toolbar-title>
                    <FontAwesomeIcon :icon="['fal', 'list-timeline']" size="xl" class="mr-2" />
                    {{ $t('ViewTaskPlan') }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <CloseModalButton @click="close" />
            </v-toolbar>

            <LoadingSymbol v-if="isLoading || workTaskPlanService.workTaskPlan == null" />

            <v-form v-else class="pa-4" style="overflow-y: scroll">
                <!-- Work Task Plan Details Header -->
                <WorkTaskPlanDetailsHeader
                    v-if="workTaskPlanService.workTaskPlan.value"
                    :work-task-plan="workTaskPlanService.workTaskPlan.value" />

                <!-- Work Task Plan Summary -->
                <v-expansion-panels class="marginTop" v-model="expansionPanelsPlanSummary">
                    <v-expansion-panel>
                        <v-expansion-panel-title class="expansion-header">
                            {{ $t('PlanSummary') }}
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <WorkTaskPlanSummary :work-task-plan-service="workTaskPlanService" />
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>

                <!-- Work Task Plan Details -->
                <v-expansion-panels class="marginTop" v-model="expansionPanelsPlanDetails">
                    <v-expansion-panel>
                        <v-expansion-panel-title class="expansion-header-dark">
                            {{ $t('PlanDetails') }}
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <div
                                v-for="(workTaskPlanItem, index) in workTaskPlanService.workTaskPlanItemsSorted.value"
                                :key="workTaskPlanItem.workTaskPlanItemId">
                                <div v-if="index > 0" class="div-separator"></div>

                                <!-- Work Task Template Details Header -->
                                <div class="ma-2">
                                    <WorkTaskTemplateDetailsHeader
                                        :work-task-template="workTaskPlanService.getTaskTemplate(workTaskPlanItem.workTaskTemplateId)!"
                                        :show-operations-menu="false"
                                        :show-created-and-modified-details="false"
                                        :sequence-number="workTaskPlanItem.sequenceNumber"
                                        :number-of-days-due-after-previous-task="
                                            workTaskPlanItem.numberOfDaysDueAfterPreviousTask
                                        " />
                                </div>

                                <!-- Work Task Template Details -->
                                <WorkTaskTemplateDetails
                                    :work-task-template="
                                        workTaskPlanService.getTaskTemplate(workTaskPlanItem.workTaskTemplateId)
                                    "
                                    :show-default-task-name="false" />
                            </div>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-form>

            <v-card-actions class="justify-space-between swatchG9BG">
                <v-btn @click="close">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'arrow-rotate-left']" size="xl" />
                    </template>
                    {{ $t('Cancel') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" timeout="4000">
        {{ snackbar.text }}
    </v-snackbar>
</template>

<script setup lang="ts">
import '@/assets/scss/user/user-manager.scss';
import {watch, inject, ref, ComponentPublicInstance, nextTick} from 'vue';
import ApiService from '@/services/api-service.js';
import CloseModalButton from '@/components/CloseModalButton.vue';
import WorkTaskPlanDetailsHeader from '@/components/WorkTaskPlanDetailsHeader.vue';
import WorkTaskTemplateDetailsHeader from '@/components/WorkTaskTemplateDetailsHeader.vue';
import WorkTaskTemplateDetails from '@/components/WorkTaskTemplateDetails.vue';
import WorkTaskPlanSummary from '@/components/WorkTaskPlanSummary.vue';
import {useViewWorkTaskPlanModalStore} from '@/stores/modals/view-work-task-plan-modal-store';
import PrintHelper from '@/helpers/PrintHelper';
import i18n from '@/i18n';
import WorkTaskPlanService from '@/services/work-task-plan-service';
import {useDeleteWorkTaskPlanModalStore} from '@/stores/modals/delete-work-task-plan-modal-store';
const deleteWorkTaskPlanModalStore = useDeleteWorkTaskPlanModalStore();

// Form
let isLoading = ref<boolean>(false);
const expansionPanelsPlanSummary = ref([0]);
const expansionPanelsPlanDetails = ref([0]);
const viewWorkTaskPlanModalContentRef = ref<ComponentPublicInstance | null>(null);
const snackbar = ref({
    show: false,
    text: '',
});

// Services
const apiService = inject('apiService') as ApiService;
const workTaskPlanService = new WorkTaskPlanService(apiService);

// Modal
const viewWorkTaskPlanModalStore = useViewWorkTaskPlanModalStore();

/**
 * Load work task plan data for the modal.
 */
const loadWorkTaskPlanData = async () => {
    isLoading.value = true;

    // Reset form
    resetForm();

    // If a work task ID was specified
    if (viewWorkTaskPlanModalStore.workTaskPlanId !== null) {
        // Load plan details
        await workTaskPlanService.loadWorkTaskPlan(viewWorkTaskPlanModalStore.workTaskPlanId);
    }

    // End loading
    isLoading.value = false;

    if (viewWorkTaskPlanModalStore.isPrinting) {
        printContent();
    }
};

/**
 * Close the modal.
 */
const close = () => {
    viewWorkTaskPlanModalStore.close();
};

/**
 * Reset the form to its default state.
 */
const resetForm = () => {
    workTaskPlanService.workTaskPlan.value = null;
    expansionPanelsPlanSummary.value = [0];

    if (viewWorkTaskPlanModalStore.expandPlanDetailsByDefault) {
        expansionPanelsPlanDetails.value = [0];
    } else {
        expansionPanelsPlanDetails.value = [];
    }
};

/**
 * Get the current date in YYYYMMDD format.
 */
const getCurrentDateFormatted = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
    const day = String(today.getDate()).padStart(2, '0');

    return `${year}${month}${day}`;
};

/**
 * Obtains modal content for printing.
 */
const printContent = async () => {
    await nextTick(); // Ensure DOM is updated
    if (viewWorkTaskPlanModalContentRef.value && viewWorkTaskPlanModalContentRef.value.$el) {
        const content = viewWorkTaskPlanModalContentRef.value.$el.innerHTML; // Access DOM element
        if (content) {
            const currentDate = getCurrentDateFormatted();
            const taskPlanName = workTaskPlanService.workTaskPlan.value?.workTaskPlanName
                ? workTaskPlanService.workTaskPlan.value?.workTaskPlanName
                : i18n.global.t('PrintTaskPlan');
            const taskPlanId = workTaskPlanService.workTaskPlan.value?.workTaskPlanId;
            const printTitle = `${currentDate}_${taskPlanName.replace(/\s+/g, '_')}_${taskPlanId}`;
            PrintHelper.printDialogContent(content, printTitle);
        } else {
            snackbar.value.show = true;
            snackbar.value.text = i18n.global.t('ErrorGeneric');
        }
    }

    viewWorkTaskPlanModalStore.isPrinting = false;
};

// Watch for changes to modal state
watch(
    () => viewWorkTaskPlanModalStore.isVisible,
    async (isVisible) => {
        if (isVisible) {
            await loadWorkTaskPlanData();
        }
    },
);

watch(
    () => viewWorkTaskPlanModalStore.isPrinting,
    async (isPrinting) => {
        if (isPrinting && !isLoading.value) {
            printContent();
        }
    },
);

watch(
    () => deleteWorkTaskPlanModalStore.deletedCounter,
    (newVal, oldVal) => {
        // If plan has been deleted, close this modal
        if (newVal > oldVal) {
            close();
        }
    },
);
</script>
