<template>
    <v-dialog v-model="deleteFarmFieldModalStore.isVisible" class="deleteFarmSiteModal">
        <v-card>
            <v-toolbar color="swatchA2">
                <v-toolbar-title>
                    <FontAwesomeIcon :icon="['fal', 'vector-polygon']" size="xl" class="mr-2" />
                    {{ $t('ConfirmFarmFieldDelete') }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <CloseModalButton @click="close" />
            </v-toolbar>
            <LoadingSymbol v-if="isLoading || localFarmFieldId == null" />
            <v-form v-else ref="form" class="pa-4">
                <FarmFieldProfile v-if="farmField" :farmField="farmField" />

                <!-- Confirm Delete -->
                <v-card class="marginTop">
                    <v-toolbar class="pa-3 permissionDenied" v-if="isFarmFieldAssociated || !isUserHasAccess">
                        <v-toolbar-title style="font-size: medium">
                            {{ $t('DeleteFarmField_PermissionDenied') }}
                        </v-toolbar-title>
                    </v-toolbar>

                    <v-card-text class="farmSiteDeleteConfirmation pa-0" v-else>
                        <v-row class="confirm-header pa-3 ma-0">
                            <FontAwesomeIcon :icon="['fal', 'check']" size="xl" class="mr-5" />
                            <span> {{ $t('ConfirmDeletion') }} </span>
                        </v-row>

                        <div class="pa-2">
                            <v-row class="ma-2" style="font-size: medium">
                                {{ $t('DeleteFarmField_ActionTracked') }}
                            </v-row>

                            <v-row class="ma-1">
                                <v-col cols="1" align-self="center">
                                    <v-checkbox v-model="isConfirmed" class="confirm-checkbox" hide-details />
                                </v-col>
                                <v-col cols="11" align-self="center" style="font-size: medium">
                                    {{ $t('DeleteFarmField_Confirmation') }}
                                </v-col>
                            </v-row>
                        </div>
                    </v-card-text>
                </v-card>
            </v-form>

            <v-card-actions class="justify-space-between swatchG9BG" v-if="!isLoading && localFarmFieldId != null">
                <v-btn @click="close">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'arrow-rotate-left']" size="xl" />
                    </template>
                    {{ $t('Cancel') }}
                </v-btn>
                <v-btn @click="confirmDelete" :disabled="!isConfirmed" v-if="isUserHasAccess && !isFarmFieldAssociated">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'check']" size="xl" />
                    </template>
                    {{ $t('ConfirmAndSubmit') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" timeout="4000">
        {{ snackbar.text }}
    </v-snackbar>
</template>

<script setup lang="ts">
import '@/assets/scss/farm/delete-farm-site-modal.scss';
import '@/assets/scss/user/user-manager.scss';

import FarmFieldProfile from '@/components/FarmFieldProfile.vue';
import {useUserStore} from '@/stores/user-store';
import {RoleCode} from '@/enums/role-code';
import {useDeleteFarmFieldModalStore} from '@/stores/modals/delete-farm-field-modal-store';
import {computed, watch, inject, ref, onUnmounted} from 'vue';
import ApiService from '@/services/api-service.js';
import CloseModalButton from '@/components/CloseModalButton.vue';
import i18n from '@/i18n';
import {SearchFarmFieldsResponse} from '@/models/api/responses/search/SearchFarmFieldsResponse';
import {ManageFarmFieldForm} from '@/models/farm/ManageFarmFieldForm';
import {useFarmFields} from '@/composables/data-source/farm-fields';
const {getFarmFields} = useFarmFields();
const userStore = useUserStore();

// Form
let isLoading = ref<boolean>(false);
const snackbar = ref({
    show: false,
    text: '',
});
const deleteFarmFieldModalStore = useDeleteFarmFieldModalStore();
const isConfirmed = ref<boolean>(false);
const localFarmFieldId = ref<number | null>(null);
const farmField = ref<ManageFarmFieldForm | null>(null);

// Services
const apiService = inject('apiService') as ApiService;

const isUserHasAccess = ref<boolean>(userStore.hasRoleLevel(RoleCode.System, 3));

const isFarmFieldAssociated = computed(() => {
    if (!farmField.value) return false;
    if (
        (farmField.value.workTaskCount && farmField.value.workTaskCount > 0) ||
        (farmField.value.observationCount && farmField.value.observationCount > 0)
    )
        return true;
    return false;
});

/**
 * Load data for the modal.
 */
const loadData = async (farmFieldId: number) => {
    isLoading.value = true;

    // Reset form
    resetForm();

    // Set data
    localFarmFieldId.value = farmFieldId;

    // Get farm field details
    const searchResults = (await apiService.post('search/farm-fields', {
        farmFieldId: farmFieldId,
    })) as SearchFarmFieldsResponse;

    if (searchResults.farmFields.length === 1) {
        farmField.value = Object.assign(new ManageFarmFieldForm(), searchResults.farmFields[0]);
    }

    isLoading.value = false;
};

/**
 * Close the modal.
 */
const close = () => {
    deleteFarmFieldModalStore.close();
};

/**
 * Save the modal.
 */
const confirmDelete = async () => {
    if (farmField.value == null) return;

    if (!isUserHasAccess.value) return;
    isLoading.value = true;

    try {
        // Call API to delete user
        const response = await apiService.post('farmFields/delete-farm-field', {
            farmFieldId: farmField.value.farmFieldId,
        });

        if (response) {
            // Show success feedback to user
            snackbar.value.show = true;
            snackbar.value.text = i18n.global.t('DeleteFarmField_Success');
            deleteFarmFieldModalStore.deletedCounter++;
            // update farm store
            await getFarmFields();

            // Close modal
            deleteFarmFieldModalStore.deletedCounter++;
            deleteFarmFieldModalStore.close();
        } else {
            // Show fail feedback to user
            snackbar.value.show = true;
            snackbar.value.text = i18n.global.t('ErrorGeneric');
            isLoading.value = false;
        }
    } catch (ex: unknown) {
        // Show fail feedback to user
        snackbar.value.show = true;
        snackbar.value.text = i18n.global.t('ErrorGeneric');
        isLoading.value = false;
    }
};

onUnmounted(() => {
    deleteFarmFieldModalStore.close();
});

/**
 * Reset the form to its default state.
 */
const resetForm = () => {
    farmField.value = null;
    isConfirmed.value = false;
};

// Watch for changes to deleteUserModalStore.userId
watch(
    () => deleteFarmFieldModalStore.isVisible,
    async (isVisible) => {
        if (isVisible && deleteFarmFieldModalStore.farmFieldId !== null) {
            await loadData(deleteFarmFieldModalStore.farmFieldId);
        }
    },
);
</script>
