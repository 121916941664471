<template>
    <div>
        <v-dialog v-model="isVisible">
            <v-card>
                <v-form ref="form" class="pa-4" style="overflow-y: scroll; height: 100%" @keyup.enter="search">
                    <mixture-search-parameters v-model:searchParameters="localSearchParametersMixture" />
                </v-form>
                <v-card-actions class="swatchG9BG">
                    <v-btn @click="isVisible = false">
                        <template v-slot:prepend>
                            <FontAwesomeIcon :icon="['fal', 'arrow-rotate-left']" size="xl" />
                        </template>
                        {{ $t('Cancel') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="search" :disabled="isLoading">
                        <template v-slot:prepend>
                            <FontAwesomeIcon :icon="['fal', 'search']" size="xl" />
                        </template>
                        {{ $t('Search') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <LoadingSymbol v-if="isLoading" />
        <mixture-search-results
            v-else
            @switch-to-search="openSearchModal"
            :is-filter-applied="isFilterApplied"
            :results-list="searchResultsMixture" />
    </div>
</template>

<script setup lang="ts">
import {computed, ref, inject, onMounted, watch} from 'vue';
import ApiService from '@/services/api-service.js';
import {SearchMixtureRequest} from '@/models/api/requests/search/SearchMixtureRequest';
import {MixtureSearchResultDto} from '@/models/data-transfer-objects/search/mixture-search/MixtureSearchResultDto';
import {SearchMixturesResponse} from '@/models/api/responses/search/SearchMixturesResponse';
import MixtureSearchParameters from '@/views/search/MixtureSearchParameters.vue';
import MixtureSearchResults from '@/views/search/MixtureSearchResults.vue';
import {useRoute} from 'vue-router';
const route = useRoute();

// Services
const apiService = inject('apiService') as ApiService;

//Local variables
const isVisible = ref<boolean>(false);
const isLoading = ref<boolean>(false);
const searchParametersMixture = ref(new SearchMixtureRequest());
const localSearchParametersMixture = ref(new SearchMixtureRequest());
const searchResultsMixture = ref<MixtureSearchResultDto[]>([]);

const isFilterApplied = computed(
    () =>
        (searchParametersMixture.value.isQuickSearch &&
            (searchParametersMixture.value.quickSearchText === null ||
                searchParametersMixture.value.quickSearchText === '')) ||
        (!searchParametersMixture.value.isQuickSearch &&
            (searchParametersMixture.value.mixtureName === null || searchParametersMixture.value.mixtureName === '') &&
            (searchParametersMixture.value.productName === null || searchParametersMixture.value.productName === '') &&
            (searchParametersMixture.value.farmFieldIdList === null ||
                searchParametersMixture.value.farmFieldIdList.length === 0) &&
            (searchParametersMixture.value.productTypeCodeList === null ||
                searchParametersMixture.value.productTypeCodeList.length === 0) &&
            (searchParametersMixture.value.productSubtypeCodeList === null ||
                searchParametersMixture.value.productSubtypeCodeList.length === 0) &&
            (searchParametersMixture.value.farmSiteIdList === null ||
                searchParametersMixture.value.farmSiteIdList.length === 0) &&
            (searchParametersMixture.value.dateRangeFrom === null ||
                searchParametersMixture.value.dateRangeFrom === '') &&
            (searchParametersMixture.value.dateRangeTo === null || searchParametersMixture.value.dateRangeTo === '')),
);

/**
 * Open search modal
 */
async function openSearchModal() {
    isVisible.value = true;
}

/**
 * Save the modal.
 */
const search = async () => {
    isLoading.value = true;

    searchParametersMixture.value = Object.assign(new SearchMixtureRequest(), localSearchParametersMixture.value);
    const response: SearchMixturesResponse = await apiService.post('search/mixtures', searchParametersMixture.value);
    searchResultsMixture.value = response.mixtures;

    //reset product ID filter after the first search
    if (searchParametersMixture.value.productIdList && searchParametersMixture.value.productIdList.length > 0) {
        searchParametersMixture.value.productIdList = [];
        localSearchParametersMixture.value.productIdList = [];
    }

    isLoading.value = false;
    isVisible.value = false;
};

onMounted(async () => {
    if (route.params.productId && Number.isInteger(Number(route.params.productId))) {
        localSearchParametersMixture.value.productIdList = [Number(route.params.productId)];
    }
    await search();
    localSearchParametersMixture.value = Object.assign(new SearchMixtureRequest(), searchParametersMixture.value);
});

watch(
    () => route.params.productId,
    async (val) => {
        if (val && Number.isInteger(Number(val))) {
            localSearchParametersMixture.value = new SearchMixtureRequest();
            localSearchParametersMixture.value.productIdList = [Number(route.params.productId)];
            await search();
            localSearchParametersMixture.value = Object.assign(
                new SearchMixtureRequest(),
                searchParametersMixture.value,
            );
        }
    },
);
</script>
