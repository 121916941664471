<template>
    <v-dialog
        v-model="selectWorkTaskTemplateModalStore.isVisible"
        class="pnlUserManager"
        style="min-width: 90vw; max-width: 90vw">
        <v-card>
            <v-toolbar color="secondary">
                <v-toolbar-title>
                    <FontAwesomeIcon :icon="['fal', 'clipboard-list-check']" size="xl" class="mr-2" />
                    {{ $t('CreateTaskFromATemplate') }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <CloseModalButton @click="close" />
            </v-toolbar>

            <LoadingSymbol v-if="isLoading" />

            <v-form ref="form" v-else class="pa-4 divUserManager" v-model="valid" style="overflow-y: scroll">
                <!-- Task Type -->
                <div class="flex-layout fill flex-layout-space-05 align-items-center">
                    <div class="formHead">{{ $t('TaskType') }}</div>
                    <div class="formIcon">
                        <HelpIcon :help-text="$t('SelectWorkTaskTemplate_TaskType_HelpText')" />
                    </div>
                    <div class="formModifyGroup">
                        <v-select
                            v-model="selectWorkTaskTemplateModel.workTaskTypeCode"
                            :items="workTaskTypesWithNull"
                            item-title="title"
                            item-value="value"
                            max-width="250px"
                            class="swatchA1 font-weight-bold" />
                    </div>
                </div>

                <!-- Task Template -->
                <div class="flex-layout fill flex-layout-space-05 align-items-center">
                    <div class="formHead">{{ $t('TaskTemplate') }}</div>
                    <div class="formIcon">
                        <HelpIcon :help-text="$t('SelectWorkTaskTemplate_TaskTemplate_HelpText')" />
                    </div>
                    <div class="formModifyGroup">
                        <v-autocomplete
                            v-model="selectWorkTaskTemplateModel.workTaskTemplate"
                            :rules="[requiredRule]"
                            required
                            :placeholder="$t('SelectTemplate')"
                            :items="filteredWorkTaskTemplateList"
                            item-title="workTaskTemplateName"
                            item-value="workTaskTemplateId"
                            return-object
                            max-width="400px"
                            menu-icon=""
                            class="swatchA1 font-weight-bold">
                            <template v-slot:append-inner>
                                <CircleIcon
                                    class="marginLeft marginRightSmall swatchGRNBG"
                                    color="swatchWHT"
                                    v-if="selectWorkTaskTemplateModel.workTaskTemplate != null">
                                    <FontAwesomeIcon :icon="['fas', 'check']" />
                                </CircleIcon>
                            </template>
                        </v-autocomplete>

                        <!-- Selected Task Template Details -->
                        <div v-if="selectWorkTaskTemplateModel.workTaskTemplate != null" class="mt-4">
                            <!-- Task Chips -->
                            <div style="display: flex; align-items: center">
                                <WorkTaskTemplateChip
                                    :work-task-type-code="selectWorkTaskTemplateModel.workTaskTemplate.workTaskTypeCode"
                                    :work-task-template-name="
                                        selectWorkTaskTemplateModel.workTaskTemplate.workTaskTemplateName
                                    " />

                                <div class="ml-4">
                                    <WorkTaskTypeChip
                                        :workTaskTypeCode="
                                            selectWorkTaskTemplateModel.workTaskTemplate.workTaskTypeCode
                                        " />
                                </div>
                            </div>

                            <!-- Times Used -->
                            <div style="display: flex; align-items: center" class="mt-4">
                                {{ $t('TimesUsed') }}

                                <div class="ml-8">
                                    <WorkTaskTemplateNumberOfTasksChip
                                        :number-of-work-tasks="
                                            selectWorkTaskTemplateModel.workTaskTemplate.numberOfWorkTasks
                                        " />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </v-form>

            <v-card-actions class="justify-space-between swatchG9BG">
                <v-btn @click="close">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'arrow-rotate-left']" size="xl" />
                    </template>
                    {{ $t('Cancel') }}
                </v-btn>

                <v-btn @click="openCreateWorkTaskModal" :disabled="isLoading">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'save']" size="xl" />
                    </template>
                    {{ $t('Next') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
import '@/assets/scss/user/user-manager.scss';
import {watch, inject, ref, computed} from 'vue';
import ApiService from '@/services/api-service.js';
import CloseModalButton from '@/components/CloseModalButton.vue';
import WorkTaskTemplateChip from '@/components/WorkTaskTemplateChip.vue';
import WorkTaskTemplateNumberOfTasksChip from '@/components/other-chips/WorkTaskTemplateNumberOfTasksChip.vue';
import {useSelectWorkTaskTemplateModalStore} from '@/stores/modals/select-work-task-template-modal-store';
import {SelectWorkTaskTemplateForm} from '@/models/work-tasks/SelectWorkTaskTemplateForm';
import Validation from '@/helpers/ValidationHelper';
import {useWorkTaskTypes} from '@/composables/data-source/work-task-types';
import {SearchWorkTaskTemplatesResponse} from '@/models/api/responses/search/SearchWorkTaskTemplatesResponse';
import {SearchWorkTaskTemplatesRequest} from '@/models/api/requests/search/SearchWorkTaskTemplatesRequest';
import {WorkTaskTemplateSearchParametersDto} from '@/models/data-transfer-objects/search/work-task-template-search/WorkTaskTemplateSearchParametersDto';
import {WorkTaskTemplateSearchResultDto} from '@/models/data-transfer-objects/search/work-task-template-search/WorkTaskTemplateSearchResultDto';
import {ManageWorkTaskModalMode} from '@/enums/modal-modes/manage-work-task-modal-mode';
const {getWorkTaskTypes, workTaskTypesWithNull} = useWorkTaskTypes();
import {useManageWorkTaskModalStore} from '@/stores/modals/manage-work-task-modal-store';
const manageWorkTaskModalStore = useManageWorkTaskModalStore();

// Form
const form = ref();
const valid = ref<boolean>(true);
let isLoading = ref<boolean>(false);
const workTaskTemplates = ref<WorkTaskTemplateSearchResultDto[]>([]);

// Rules
const requiredRule = Validation.createRule_Required();

// Services
const apiService = inject('apiService') as ApiService;

// Modal
const selectWorkTaskTemplateModalStore = useSelectWorkTaskTemplateModalStore();
const selectWorkTaskTemplateModel = ref<SelectWorkTaskTemplateForm>(new SelectWorkTaskTemplateForm());

/**
 * Load data for the modal.
 */
const loadInitialData = async () => {
    isLoading.value = true;

    // Reset form
    resetForm();

    // Get dropdown/form data
    await getWorkTaskTypes();

    // Build search request
    const workTaskTemplatesSearchRequest: SearchWorkTaskTemplatesRequest = {
        searchParameters: new WorkTaskTemplateSearchParametersDto(),
    };

    // Get work task template details
    const workTaskTemplatesSearchResults = (await apiService.post(
        'search/work-task-templates',
        workTaskTemplatesSearchRequest,
    )) as SearchWorkTaskTemplatesResponse;
    workTaskTemplates.value = workTaskTemplatesSearchResults.workTaskTemplates;

    // End loading
    isLoading.value = false;
};

/**
 * Close this modal and open the Create Task modal with the template details pre-populated.
 */
const openCreateWorkTaskModal = async () => {
    // Perform final client side validation of form
    await form.value.validate();
    let isValid = valid.value;

    // If form is valid
    if (isValid) {
        // Open Create Task modal with details of this template pre-populated
        manageWorkTaskModalStore.open(
            ManageWorkTaskModalMode.Create,
            null,
            selectWorkTaskTemplateModalStore.farmFieldId,
            selectWorkTaskTemplateModel.value.workTaskTemplate?.workTaskTemplateId,
        );

        // Close this modal (must be done after opening the next modal so that farmFieldId is not cleared early)
        selectWorkTaskTemplateModalStore.close();
    }
};

/**
 * Close the modal.
 */
const close = () => {
    selectWorkTaskTemplateModalStore.close();
};

/**
 * Reset the form to its default state.
 */
const resetForm = () => {
    selectWorkTaskTemplateModel.value = new SelectWorkTaskTemplateForm();
};

/**
 * List of work task templates filtered by the selected task type.
 */
const filteredWorkTaskTemplateList = computed(() => {
    const workTaskTypeCode = selectWorkTaskTemplateModel.value.workTaskTypeCode;

    return workTaskTemplates.value.filter(
        (wtt) => workTaskTypeCode == null || wtt.workTaskTypeCode == workTaskTypeCode,
    );
});

// Watch for changes to modal state
watch(
    () => selectWorkTaskTemplateModalStore.isVisible,
    async (isVisible) => {
        if (isVisible) {
            await loadInitialData();
        }
    },
);

/**
 * When the task type code changes, set the selected template back to null to ensure a template with a different
 * type is not selected.
 */
watch(
    () => selectWorkTaskTemplateModel.value.workTaskTypeCode,
    async () => {
        // If the task type of the selected template is different, set the selected template to null
        if (
            selectWorkTaskTemplateModel.value.workTaskTemplate?.workTaskTypeCode !=
            selectWorkTaskTemplateModel.value.workTaskTypeCode
        ) {
            selectWorkTaskTemplateModel.value.workTaskTemplate = null;
        }
    },
);

/**
 * When the task template changes, set the selected task type to the task type of the selected template.
 */
watch(
    () => selectWorkTaskTemplateModel.value.workTaskTemplate,
    async () => {
        // If the task type of the selected template is different, set the selected template to null
        if (
            selectWorkTaskTemplateModel.value.workTaskTemplate != null &&
            selectWorkTaskTemplateModel.value.workTaskTemplate.workTaskTypeCode !=
                selectWorkTaskTemplateModel.value.workTaskTypeCode
        ) {
            selectWorkTaskTemplateModel.value.workTaskTypeCode =
                selectWorkTaskTemplateModel.value.workTaskTemplate.workTaskTypeCode;
        }
    },
);
</script>
