<template>
    <v-dialog v-model="viewProductModalStore.isVisible" class="viewFarmSiteModal">
        <v-card style="overflow-y: hidden">
            <v-toolbar color="swatchA2">
                <v-toolbar-title>
                    <font-awesome-icon :icon="['fad', 'box-taped']" size="xl" class="mr-2" />
                    {{ modalTitle }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <CloseModalButton @click="close" />
            </v-toolbar>
            <LoadingSymbol v-if="isLoading || localProductId == null || !product" />
            <v-form v-else ref="form" class="pa-4" style="overflow-y: scroll">
                <v-row align="center">
                    <v-col cols="2">
                        <ProductSubtypeIcon
                            :productTypeCode="product.productTypeCode"
                            :productSubtypeCode="product.productSubtypeCode"
                            iconHeight="50"
                            iconWidth="50"
                            iconSize="27" />
                    </v-col>
                    <v-col cols="auto" class="swatchA1 slightlyLarger bold"
                        >"{{ product.productName }}" <IdIcon>{{ product.productId }}</IdIcon>
                    </v-col>
                </v-row>

                <v-row align="center">
                    <v-col cols="2" class="pl-5">
                        {{ $t('Provider') }}
                    </v-col>
                    <v-col cols="8" class="swatchA1">{{ product.productBrandName }} </v-col>
                    <v-col cols="2" class="d-flex justify-end"
                        ><FieldSizeChip
                            v-if="product.farmFieldApplied !== null"
                            :farmFieldSize="parseFloat(product.farmFieldApplied.toFixed(2))"
                    /></v-col>
                </v-row>
                <v-row align="center">
                    <v-col cols="2" class="pl-5">
                        {{ $t('Composition') }}
                    </v-col>
                    <v-col cols="10" class="swatchA1">{{ product.productComposition ?? '' }} </v-col>
                </v-row>
                <v-row align="center">
                    <v-col cols="2" class="pl-5">
                        {{ $t('Formulation') }}
                    </v-col>
                    <v-col cols="10" class="swatchA1">{{ product.productFormulation ?? '' }} </v-col>
                </v-row>
                <v-row align="center">
                    <v-col cols="2" class="pl-5">
                        {{ $t('Summary') }}
                    </v-col>
                    <v-col cols="10" class="swatchA1">{{ product.productSummary ?? '' }} </v-col>
                </v-row>
                <v-row align="center">
                    <v-col cols="2" class="pl-5">
                        {{ $t('Mixture') }}
                    </v-col>
                    <v-col cols="10" class="swatchA1"
                        >{{
                            product.mixtureCount > 1
                                ? $t('ViewProductModal_MixtureAssociatedPlural', {
                                      numberOfMixtureAssociated: product.mixtureCount,
                                  })
                                : $t('ViewProductModal_MixtureAssociatedSingle', {
                                      numberOfMixtureAssociated: product.mixtureCount,
                                  })
                        }}
                        <span class="pl-3">
                            <v-btn
                                class="v-btn--custom"
                                rounded="1"
                                @click.prevent.stop="closeAllModal"
                                :to="`/mixture-list/${product.productId}`"
                                ><FontAwesomeIcon :icon="['fa', 'flask-round-potion']" class="pr-2" size="xl" />{{
                                    $t('ViewMixtures')
                                }}</v-btn
                            ></span
                        >
                    </v-col>
                </v-row>

                <div class="pa-2"></div>
                <v-expansion-panels multiple v-model="openedExpansionPanels">
                    <v-expansion-panel>
                        <v-expansion-panel-title class="expansion-header">
                            <FontAwesomeIcon :icon="['fal', 'clipboard-list-check']" size="xl" class="mr-2" />
                            {{
                                $t('HistoricApplicationsHyphen12Months') + ' (' + workTaskProducts.length + ')'
                            }}</v-expansion-panel-title
                        >
                        <v-expansion-panel-text>
                            <v-data-table
                                class="pa-0 ma-0 pt-1 historic-application-table"
                                :headers="historicApplicationHeader"
                                :items="workTaskProducts"
                                :items-per-page="-1"
                                hide-default-footer>
                                <template v-slot:[`item.farmSiteAbbreviation`]="{item}">
                                    <v-col align="center">
                                        <FarmSiteChip
                                            v-if="item.farmSiteAbbreviation && item.farmSiteName"
                                            :farm-site-abbreviation="item.farmSiteAbbreviation"
                                            :farm-site-name="item.farmSiteName" />
                                    </v-col>
                                </template>
                                <template v-slot:[`item.farmFieldCode`]="{item}">
                                    <div class="swatchA1 slightlyLarger bold" v-if="item.farmFieldCode">
                                        {{ item.farmFieldCode }}
                                    </div>
                                </template>
                                <template v-slot:[`item.lastAppliedTime`]="{item}">
                                    <v-col
                                        align="center"
                                        v-if="item.lastAppliedTime"
                                        class="swatchA1 ma-0 pa-0"
                                        style="max-width: 150px">
                                        {{ formatDate(item.lastAppliedTime) }}
                                    </v-col>
                                </template>

                                <template v-slot:[`item.workTaskUsers`]="{item}">
                                    <div v-for="workTaskUser in item.workTaskUsers" :key="workTaskUser.userId">
                                        <v-col
                                            align="center"
                                            v-if="workTaskUser.userId"
                                            class="swatchA1 ma-0 pa-0 pb-1">
                                            <UserChip
                                                :user="
                                                    UserHelper.userChipType(
                                                        workTaskUser.userId,
                                                        UserHelper.userFullName(
                                                            workTaskUser.userGivenName,
                                                            workTaskUser.userSurname,
                                                        ),
                                                    )
                                                " />
                                        </v-col>
                                    </div>
                                </template>

                                <template v-slot:[`item.actualApplicationRate`]="{item}">
                                    <v-col align="center" class="swatchA1" v-if="item.actualApplicationRate != null">
                                        {{
                                            item.actualApplicationRate +
                                            ' ' +
                                            getUnitName(item.actualApplicationRateUnitCode)
                                        }}
                                    </v-col>
                                    <v-col
                                        align="center"
                                        class="swatchA1"
                                        v-else-if="item.plannedApplicationRate != null">
                                        {{
                                            item.plannedApplicationRate +
                                            ' ' +
                                            getUnitName(item.plannedApplicationRateUnitCode)
                                        }}
                                    </v-col>
                                </template>

                                <template v-slot:[`item.farmFieldSize`]="{item}">
                                    <v-col align="center" class="swatchA1" v-if="item.farmFieldSize">
                                        {{ parseFloat(item.farmFieldSize.toFixed(2)) + ' ha' }}
                                    </v-col>
                                </template>

                                <template v-slot:[`item.action`]="{item}">
                                    <v-btn
                                        class="v-btn--custom"
                                        rounded="1"
                                        elevation="2"
                                        v-if="item.workTaskId"
                                        @click.prevent.stop="viewWorkTaskModalStore.open(item.workTaskId)">
                                        <template v-slot:prepend>
                                            <FontAwesomeIcon :icon="['fal', 'circle-arrow-right']" size="xl" />
                                        </template>
                                        {{ $t('ViewTask') }}
                                    </v-btn>
                                </template>
                            </v-data-table>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                    <div class="pa-2"></div>
                    <v-expansion-panel>
                        <v-expansion-panel-title class="expansion-header">
                            <FontAwesomeIcon :icon="['fal', 'copy']" size="xl" class="mr-2" />
                            {{ $t('DataSheetsAndFiles') + ' (' + artifacts.length + ')' }}</v-expansion-panel-title
                        >
                        <v-expansion-panel-text>
                            <v-data-table
                                class="pa-0 ma-0 pt-1 historic-application-table"
                                :headers="artifactHeader"
                                :items="artifacts"
                                :items-per-page="-1"
                                hide-default-footer>
                                <template v-slot:[`item.artifactFileTypeCode`]="{item}">
                                    <v-col align="center">
                                        <FileTypeButton
                                            :url="item.artifactUrl"
                                            :display-name="undefined"
                                            :file-type-code="item.artifactFileTypeCode" />
                                    </v-col>
                                </template>
                                <template v-slot:[`item.artifactDisplayName`]="{item}">
                                    <div class="swatchA1 slightlyLarger bold pa-0 ma-0" style="max-width: 200px">
                                        {{ item.artifactDisplayName }}
                                    </div>
                                </template>
                                <template v-slot:[`item.artifactDescription`]="{item}">
                                    <div
                                        class="swatchA1 pa-0 ma-0"
                                        v-if="item.artifactDescription"
                                        style="min-width: 250px">
                                        {{ item.artifactDescription }}
                                    </div>
                                </template>

                                <template v-slot:[`item.artifactFileSize`]="{item}">
                                    <v-col
                                        cols="12"
                                        align="center"
                                        class="swatchA1 pa-0 ma-0"
                                        v-if="item.artifactFileSize !== null"
                                        style="min-width: 100px">
                                        {{ parseFloat((item.artifactFileSize / 1024).toFixed(4)) + ' MB' }}
                                    </v-col>
                                </template>

                                <template v-slot:[`item.artifactCreatedDate`]="{item}">
                                    <v-col
                                        cols="12"
                                        align="center"
                                        class="swatchA1 pa-0 ma-0"
                                        v-if="item.artifactCreatedDate"
                                        style="min-width: 180px">
                                        {{ formatDateTime(item.artifactCreatedDate) }}
                                    </v-col>
                                </template>
                            </v-data-table>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                    <div class="pa-2"></div>
                    <v-expansion-panel>
                        <v-expansion-panel-title class="expansion-header">
                            <FontAwesomeIcon :icon="['fal', 'arrow-up-right-from-square']" size="xl" class="mr-2" />
                            {{ $t('Links') + ' (' + links.length + ')' }}</v-expansion-panel-title
                        >
                        <v-expansion-panel-text>
                            <v-data-table
                                class="pa-0 ma-0 pt-1 historic-application-table"
                                :headers="linkHeader"
                                :items="links"
                                :items-per-page="-1"
                                hide-default-footer>
                                <template v-slot:[`item.linkId`]="{item}">
                                    <v-col align="center">
                                        <FileTypeButton
                                            :url="item.linkUrl"
                                            :display-name="undefined"
                                            :file-type-code="ArtifactFileTypeCode.Other" />
                                    </v-col>
                                </template>
                                <template v-slot:[`item.linkName`]="{item}">
                                    <div class="swatchA1 slightlyLarger bold pa-0 ma-0" style="max-width: 200px">
                                        {{ item.linkName }}
                                    </div>
                                </template>
                                <template v-slot:[`item.linkUrl`]="{item}">
                                    <div
                                        class="swatchA1 slightlySmaller pa-0 ma-0"
                                        v-if="item.linkUrl"
                                        style="min-width: 250px">
                                        {{ item.linkUrl }}
                                    </div>
                                </template>

                                <template v-slot:[`item.linkCreatedDate`]="{item}">
                                    <v-col
                                        cols="12"
                                        align="center"
                                        class="swatchA1 pa-0 ma-0"
                                        v-if="item.linkCreatedDate"
                                        style="min-width: 180px">
                                        {{ formatDateTime(item.linkCreatedDate) }}
                                    </v-col>
                                </template>
                            </v-data-table>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-form>

            <v-card-actions class="justify-space-between swatchG9BG" v-if="!isLoading && localProductId != null">
                <v-btn @click="close">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'arrow-rotate-left']" size="xl" />
                    </template>
                    {{ $t('Back') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
import '@/assets/scss/farm/view-farm-site-modal.scss';
import 'leaflet/dist/leaflet.css';
import '@/assets/scss/home-dashboard/home-dashboard-map.scss';

import {useViewProductModalStore} from '@/stores/modals/view-product-modal-store';
import {watch, inject, ref, onUnmounted, computed} from 'vue';
import ApiService from '@/services/api-service.js';
import CloseModalButton from '@/components/CloseModalButton.vue';
import {ProductDto} from '@/models/data-transfer-objects/search/product-search/ProductSearchResultDto';
import {SearchProductsResponse} from '@/models/api/responses/search/SearchProductsResponse';
import {SearchWorkTaskProductsResponse} from '@/models/api/responses/search/SearchWorkTaskProductsResponse';
import ProductSubtypeIcon from '@/components/ProductSubtypeIcon.vue';
import {useAdvancedSearchModalStore} from '@/stores/modals/advanced-search-modal-store';
import FieldSizeChip from '@/components/other-chips/FieldSizeChip.vue';
import {WorkTaskProductDto} from '@/models/data-transfer-objects/search/product-search/WorkTaskProductDto';
import i18n from '@/i18n';
import FarmSiteChip from '@/components/other-chips/FarmSiteChip.vue';
import {format} from 'date-fns';
import {getApplicationRateUnitByCode} from '@/services/application-rate-units-service';
import {ApplicationRateUnitCode} from '@/enums/application-rate-unit-code';
import {useViewWorkTaskModalStore} from '@/stores/modals/view-work-task-modal-store';
import UserChip from '@/components/UserChip.vue';
import UserHelper from '@/helpers/UserHelper';
import {SearchProductRequest} from '@/models/api/requests/search/SearchProductRequest';
import {DetailedArtifactDto} from '@/models/data-transfer-objects/system/DetailedArtifactDto';
import FileTypeButton from '@/components/FileTypeButton.vue';
import {LinkDto} from '@/models/data-transfer-objects/system/LinkDto';
import {ArtifactFileTypeCode} from '@/enums/artifact-file-type-code';
import {ProductTypeCode} from '@/enums/product-type-code';

const viewWorkTaskModalStore = useViewWorkTaskModalStore();
// Form
let isLoading = ref<boolean>(false);

const viewProductModalStore = useViewProductModalStore();
const isConfirmed = ref<boolean>(false);
const localProductId = ref<number | null>(null);
const product = ref<ProductDto | null>(null);
const workTaskProducts = ref<WorkTaskProductDto[]>([]);
const artifacts = ref<DetailedArtifactDto[]>([]);
const links = ref<LinkDto[]>([]);
const advancedSearchModalStore = useAdvancedSearchModalStore();
const historicApplicationHeader = ref([
    {title: i18n.global.t('FarmSite'), key: 'farmSiteAbbreviation', sortable: false},
    {title: i18n.global.t('FieldCode'), key: 'farmFieldCode', sortable: true},
    {title: i18n.global.t('Date'), key: 'lastAppliedTime', sortable: true},
    {title: i18n.global.t('Operator(s)'), key: 'workTaskUsers', sortable: false},
    {title: i18n.global.t('ApplicationRate'), key: 'actualApplicationRate', sortable: true},
    {title: i18n.global.t('Area'), key: 'farmFieldSize', sortable: true},
    {title: '', key: 'action', sortable: false},
]);

const artifactHeader = ref([
    {title: i18n.global.t('Icon'), key: 'artifactFileTypeCode', sortable: false},
    {title: i18n.global.t('Name'), key: 'artifactDisplayName', sortable: true},
    {title: i18n.global.t('Description'), key: 'artifactDescription', sortable: false},
    {title: i18n.global.t('Size'), key: 'artifactFileSize', sortable: false},
    {title: i18n.global.t('Date'), key: 'artifactCreatedDate', sortable: true},
]);

const linkHeader = ref([
    {title: i18n.global.t('Icon'), key: 'linkId', sortable: false},
    {title: i18n.global.t('Name'), key: 'linkName', sortable: true},
    {title: i18n.global.t('Description'), key: 'linkUrl', sortable: false},
    {title: i18n.global.t('Date'), key: 'linkCreatedDate', sortable: true},
]);

const openedExpansionPanels = ref<number[]>([0, 1, 2]);
// Services
const apiService = inject('apiService') as ApiService;

/**
 * Load data for the modal.
 */
const loadData = async (productId: number) => {
    isLoading.value = true;

    // Reset form
    resetForm();

    // Set data
    localProductId.value = productId;

    // Get product details
    const searchResults = (await apiService.post('search/products', {
        isQuickSearch: false,
        productIdList: [productId],
        includeDetailedArtifacts: true,
        includeLinks: true,
    } as SearchProductRequest)) as SearchProductsResponse;

    if (searchResults.products.length === 1) {
        product.value = searchResults.products[0];
        artifacts.value = searchResults.products[0].detailedArtifacts;
        links.value = searchResults.products[0].links;
    }

    const workProductSearchResults = (await apiService.post('search/work-task-products', {
        productId: productId,
    })) as SearchWorkTaskProductsResponse;

    if (workProductSearchResults.workTaskProducts.length > 0) {
        workTaskProducts.value = workProductSearchResults.workTaskProducts;
    }

    //close no data panels
    const expandPanel = [];
    if (workTaskProducts.value.length > 0) expandPanel.push(0);
    if (artifacts.value.length > 0) expandPanel.push(1);
    if (links.value.length > 0) expandPanel.push(2);
    openedExpansionPanels.value = expandPanel;

    isLoading.value = false;
};

/**
 * Close the modal.
 */
const close = () => {
    viewProductModalStore.close();
};

const closeAllModal = () => {
    close();
    advancedSearchModalStore.close();
};
onUnmounted(() => {
    viewProductModalStore.close();
});

/**
 * Reset the form to its default state.
 */
const resetForm = () => {
    product.value = null;
    isConfirmed.value = false;
    workTaskProducts.value = [];
    artifacts.value = [];
    links.value = [];
};

// Watch for changes to viewUserModalStore.userId
watch(
    () => viewProductModalStore.isVisible,
    async (val) => {
        if (val && viewProductModalStore.productId !== null) {
            await loadData(viewProductModalStore.productId);
        }
    },
);

const formatDate = (date: Date | null): string | null => {
    if (!date) return null;
    return format(date, 'd MMMM, yyyy');
};

const formatDateTime = (date: Date | null): string | null => {
    if (!date) return null;

    return format(date, 'dd/MM/yyyy h:mm a');
};

const getUnitName = (unitCode: ApplicationRateUnitCode | null) => {
    if (!unitCode) return '';
    const applicationRateUnit = getApplicationRateUnitByCode(unitCode);
    if (!applicationRateUnit) return '';
    return applicationRateUnit.applicationRateUnitName;
};

/**
 * The title of the modal.
 */
const modalTitle = computed(() => {
    if (product.value?.productTypeCode == ProductTypeCode.Chemical) {
        return i18n.global.t('ViewSprayChemical');
    } else if (product.value?.productTypeCode == ProductTypeCode.Fertilizer) {
        return i18n.global.t('ViewFertilizer');
    } else {
        return i18n.global.t('ViewProduct');
    }
});
</script>
<style lang="scss">
@import '@/assets/scss/swatches.scss';
.historic-application-table .v-table__wrapper thead {
    background-color: $swatchG9;
}
</style>
