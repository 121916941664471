<template>
    <div class="resetForm">
        <!-- Login Form -->
        <v-card outlined class="transparent-card">
            <v-toolbar flat color="primary">
                <v-toolbar-title>
                    <FontAwesomeIcon :icon="['fal', 'unlock']" size="xl" class="mr-2" />
                    {{ $t('ResetPassword') }}
                </v-toolbar-title>
            </v-toolbar>

            <div v-if="resetResponse !== 1">
                <v-form class="pa-4" v-model="valid">
                    <table style="width: 80%" class="tblForm">
                        <tr>
                            <td class="tableCell padding" style="width: 40%">
                                <div
                                    class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                                    <span> {{ $t('EmailAddress') }}</span>
                                    <HelpIcon :help-text="$t('ForgottenPassword_EmailAddress_HelpText')" />
                                </div>
                            </td>
                            <td style="width: 60%">
                                <v-text-field variant="outlined" v-model="email" required :rules="emailRules" />
                            </td>
                        </tr>

                        <tr>
                            <td class="tableCell padding">
                                <div
                                    class="flex-layout align-items-center justify-content-space-between flex-wrap-nowrap">
                                    <span>{{ $t('Surname') }}</span>
                                    <HelpIcon :help-text="$t('ForgottenPassword_Surname_HelpText')" />
                                </div>
                            </td>
                            <td>
                                <v-text-field variant="outlined" v-model="surname" required :rules="surnameRules" />
                            </td>
                        </tr>
                    </table>

                    <v-alert v-if="errorMessage" type="error" :value="true">
                        {{ errorMessage }}
                    </v-alert>
                </v-form>
            </div>
            <div class="pa-4" v-else>
                {{ $t('ForgottenPassword_ResetSuccess_Message1') }} <br /><br />
                <span class="swatchA2" style="font-weight: bold; margin-left: 20px">{{ email }}</span> <br /><br />
                {{ $t('ForgottenPassword_ResetSuccess_Message2', {systemName: systemName}) }} <br /><br />
                {{ $t('ForgottenPassword_ResetSuccess_Message3', {passwordResetHours: passwordResetHours}) }}
                <br /><br />
                {{
                    $t('ForgottenPassword_ResetSuccess_Message4', {
                        utilityName: utilityName,
                        utilityPhoneNumber: utilityPhoneNumber,
                    })
                }}
            </div>
            <v-card-actions class="justify-space-between swatchG9BG">
                <BackButton />

                <v-btn color="primary" @click="resetPassword" :disabled="!valid" v-if="resetResponse !== 1">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'floppy-disk']" size="xl" :value="2134" />
                    </template>
                    {{ $t('ResetPassword') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script lang="ts">
import {defineComponent, inject, ref, watch} from 'vue';
import {useConfigStore} from '@/stores/config-store';
import {useUserStore} from '@/stores/user-store';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {byPrefixAndName} from '@awesome.me/kit-b64aff0d40/icons';
import ApiService from '@/services/api-service';
import router from '@/router';
import axios from 'axios';
import i18n from '@/i18n';

export default defineComponent({
    components: {
        FontAwesomeIcon,
    },
    setup() {
        // Stores
        const configStore = useConfigStore();
        const userStore = useUserStore();

        // Services
        const apiService = inject('apiService') as ApiService;

        // Form fields

        const email = ref<string>('');
        const surname = ref<string>('');
        const rememberMe = ref<boolean>(false);
        const valid = ref<boolean>(true);
        const errorMessage = ref('');
        const regexEmail = new RegExp(configStore.regexEmail);
        const utilityName = ref<string>(configStore.utilityName);
        const utilityPhoneNumber = ref<string>(configStore.utilityPhoneNumber);
        const passwordResetHours = ref<number>(configStore.passwordResetHoursValid);
        const systemName = ref<string>(configStore.systemName);
        const resetResponse = ref<number | null>(null);

        // Rules
        const emailRules = ref<Array<(v: string) => true | string>>([
            (v) => !!v || i18n.global.t('EmailIsRequired'),
            (v) => regexEmail.test(v) || i18n.global.t('WrongEmailFormat'),
        ]);

        const surnameRules = ref<Array<(v: string) => true | string>>([
            (v) => !!v || i18n.global.t('SurnameIsRequired'),
        ]);

        watch([email, surname], ([newEmail, newSurname], [oldEmail, oldSurname]) => {
            if (newEmail != oldEmail || newSurname != oldSurname) {
                errorMessage.value = '';
            }
        });

        /**
         * Call API to attempt to reset user's password
         */
        async function resetPassword() {
            if (valid.value) {
                try {
                    resetResponse.value = await apiService.post('users/reset-user-password', {
                        UserEmailAddress: email.value,
                        UserSurname: surname.value,
                    });
                    switch (resetResponse.value) {
                        //Valid Reset Password trigger (If reset email was successfully issued, display the "Success" panel)
                        case 1:
                            break;
                        //Reason: User Account has not confirm the sign up verification.
                        case 2:
                            errorMessage.value = i18n.global.t('ForgottenPassword_NotConfirmSignUp_ErrorMessage');
                            break;
                        //Reason: No user exists with this email address and surname.
                        default:
                            errorMessage.value = i18n.global.t('ForgottenPassword_UserNotExist_ErrorMessage');
                            break;
                    }
                } catch (error) {
                    if (axios.isAxiosError(error) && error.response) {
                        // Generic error message if the error structure is not as expected
                        errorMessage.value = i18n.global.t('ForgottenPassword_ResetFail_ErrorMessage');
                    }
                }
            }
        }

        return {
            // FontAwesome
            byPrefixAndName,

            // Stores
            userStore,
            configStore,

            // Services
            apiService,
            router,

            // Form fields
            email,
            surname,
            rememberMe,
            valid,
            errorMessage,
            resetResponse,
            utilityName,
            utilityPhoneNumber,
            passwordResetHours,
            systemName,

            // Rules
            emailRules,
            surnameRules,

            // Functions
            resetPassword,
        };
    },
});
</script>
<style scoped>
body.forgotten-password {
    background-image: none;
}

.resetForm {
    width: 50rem;
    margin: 0px auto;
}
</style>
