<template>
    <v-container>
        <v-text-field
            v-model="localLatLngString"
            @input="handleInput"
            :error-messages="latLngError"
            :rules="coordinatesRules"
            style="width: 400px">
            <template v-slot:prepend-inner
                ><font-awesome-icon
                    :icon="['fas', 'location-dot']"
                    @click="openMap"
                    class="hand pr-2"
                    :title="$t('OpenLocationPicker')" />
            </template>
        </v-text-field>

        <v-dialog v-model="showMap" max-width="600px">
            <v-card>
                <v-toolbar color="secondary">
                    <v-toolbar-title>
                        <FontAwesomeIcon :icon="['fas', 'location-dot']" size="xl" class="mr-2" />
                        {{ $t('ChooseLocation') }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <CloseModalButton @click="closeMap" />
                </v-toolbar>

                <v-card-text>
                    <div id="map" style="height: 400px; width: 100%">
                        <l-map :zoom="zoom" :center="center" style="height: 100%; width: 100%" :options="mapOptions">
                            <l-tile-layer :url="url"></l-tile-layer>
                            <l-marker
                                :lat-lng="marker"
                                @update:lat-lng="updateMarkerPositionFromMap"
                                :draggable="true"></l-marker>
                        </l-map>
                    </div>

                    <div class="mt-2">
                        <span class="bold">{{ $t('Latitude_Colon') }}</span> {{ marker[0] }}
                    </div>
                    <div>
                        <span class="bold">{{ $t('Longitude_Colon') }}</span> {{ marker[1] }}
                    </div>
                </v-card-text>
                <v-card-actions class="swatchG9BG">
                    <v-btn @click="closeMap">
                        <template v-slot:prepend>
                            <FontAwesomeIcon :icon="['fal', 'arrow-rotate-left']" size="xl" />
                        </template>
                        {{ $t('Cancel') }}
                    </v-btn>
                    <v-spacer />
                    <v-btn @click="save">
                        <template v-slot:prepend>
                            <FontAwesomeIcon :icon="['fal', 'save']" size="xl" />
                        </template>
                        {{ $t('Save') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script setup lang="ts">
/**
 * LocationPicker is used to allow the user to specify a latitude/longitude by either
 * entering it into the textbox or dragging a marker on the map to the desired location.
 *
 * Props:
 * @prop {String} latLng - An array that should contain a max of two numbers. It can be null or empty -
 * in this case a default value will be used (taken from the config settings).
 *
 * Events:
 * @event update - Emitted when the dialog is saved. This updates the latlong on the parent.
 */

import {ref, watch, defineProps, defineEmits, onMounted, nextTick, PropType} from 'vue';
import {LMap, LTileLayer, LMarker} from '@vue-leaflet/vue-leaflet';
import 'leaflet/dist/leaflet.css';
import i18n from '@/i18n';

const props = defineProps({
    latLng: {
        type: Array as PropType<number[]>,
        required: true,
    },
    inputRequired: {
        type: Boolean as PropType<boolean>,
        default: false,
    },
});

const coordinatesRules = ref([
    (v: string | null) => !!v || !props.inputRequired || i18n.global.t('Validation_Required'),
    (v: string | null) => {
        if (!v || v === '') return i18n.global.t('Validation_InvalidFormat');
        const numberRegex = /^-?\d+(\.\d+)?$/;
        const textToken = v.split(',');
        if (textToken.length !== 2) return i18n.global.t('Validation_InvalidFormat');
        else
            return (
                (numberRegex.test(textToken[0].trim()) && numberRegex.test(textToken[1].trim())) ||
                i18n.global.t('Validation_InvalidFormat')
            );
    },
]);

const emits = defineEmits(['update:latLng']);

//Config store
import {useConfigStore} from '@/stores/config-store';
import {LatLngTuple, PointExpression} from 'leaflet';
const configStore = useConfigStore();

// Reactive data
const showMap = ref(false);
const mapOptions = ref({
    attributionControl: false,
});
const zoom = ref(13);
const center = ref<PointExpression>([0, 0]);
const url = process.env.VUE_APP_AERIAL_IMAGERY_TILE_LAYER_URL!;
const marker = ref<LatLngTuple>([0, 0]);
const localLatLngString = ref('');
const latLngError = ref('');

// Update marker coordinates from the map
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const updateMarkerPositionFromMap = (e: any) => {
    marker.value = [e.lat, e.lng];
};

// Update marker coordinates from the latlng string
const updateMarkerPositionFromLatLngString = () => {
    if (localLatLngString.value) {
        // Get the marker latlng from the latlng string
        const [lat, lng] = localLatLngString.value.split(',').map(Number);
        if (!isNaN(lat) && !isNaN(lng)) {
            marker.value = [lat, lng];
        }
    }
};

// Convert latLng into marker coordinates on change
watch(
    () => props.latLng,
    (newVal) => {
        if (isLatLngValid(newVal)) {
            localLatLngString.value = `${newVal[0]}, ${newVal[1]}`;
        }
        updateMarkerPositionFromLatLngString();
    },
);

// Emit updates to latLng
const emitUpdate = () => {
    // Validate latlng and do not emit if it's invalid
    validateLatLng();
    if (latLngError.value) return;

    localLatLngString.value = `${marker.value[0]}, ${marker.value[1]}`;
    emits('update:latLng', marker.value);
};

// Handle input and emit changes
const handleInput = (event: Event) => {
    const target = event.target as HTMLInputElement;
    localLatLngString.value = target.value;
    emits('update:latLng', localLatLngString.value.split(',').map(Number));
};

// Open the map
const openMap = () => {
    // Validate latlng and do not open the map if it's invalid
    validateLatLng();
    if (latLngError.value) return;

    showMap.value = true;
};

// Close the map
const closeMap = () => {
    showMap.value = false;
};

// Saves the coordinates
const save = () => {
    emitUpdate();
    closeMap();
};

// Validate latitude and longitude input
const validateLatLng = () => {
    const regex = /^-?\d+(\.\d+)?\s*,\s*-?\d+(\.\d+)?$/;

    // If the regex is valid, or if no latlng has been specified
    if (!localLatLngString.value || regex.test(localLatLngString.value)) {
        // Clear error message
        latLngError.value = '';

        // Update marker
        nextTick(() => {
            updateMarkerPositionFromLatLngString();
        });
    } else {
        // Set error message
        latLngError.value = i18n.global.t('InvalidLatitudeAndLongitude');
    }
};

// Check to see if the specified array of numbers is a valid latitude and longitude
const isLatLngValid = (latLng: number[] | null) => {
    return latLng != null && latLng.length == 2 && !isNaN(latLng[0]) && !isNaN(latLng[1]);
};

// Watch for when the map is opened and center the map to the marker
watch(showMap, () => {
    if (showMap.value) {
        center.value = [marker.value[0], marker.value[1]];
    }
});

// Set values when mounted
onMounted(() => {
    if (isLatLngValid(props.latLng)) {
        // Set local string value based on the latlng prop so that it can be displayed in the text field
        localLatLngString.value = `${props.latLng[0]}, ${props.latLng[1]}`;
    } else {
        // If no latlng has been specified (or if it's invalid for some reason), set the marker to the default position and zoom in
        marker.value = [configStore.defaultMapLatitude, configStore.defaultMapLongitude];
        zoom.value = 16;
    }

    // Update marker position
    updateMarkerPositionFromLatLngString();
});
</script>

<style>
#map {
    height: 100%;
    width: 100%;
}
</style>
