<template>
    <v-dialog v-model="deleteOrCancelWorkTaskModalStore.isVisible" class="pnlUserManager" style="max-width: 50vw">
        <v-card>
            <v-toolbar color="secondary">
                <v-toolbar-title>
                    <FontAwesomeIcon :icon="['fal', 'trash']" size="xl" class="mr-2" />
                    {{ $t('DeleteSlashCancelTask') }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <CloseModalButton @click="close" />
            </v-toolbar>

            <LoadingSymbol v-if="isLoading || workTask == null" />

            <v-form ref="form" v-else class="pa-4 divUserManager" v-model="valid" style="overflow-y: scroll">
                <!-- Work Task Details Header -->
                <WorkTaskDetailsHeader :work-task="workTask" :farm-field="farmField!" :show-operations-menu="false" />

                <!-- Instructions -->
                <v-card class="pnlInstructions mt-2">
                    <v-toolbar color="swatchA6">
                        <v-toolbar-title>
                            <FontAwesomeIcon :icon="['fas', 'question-circle']" size="xl" class="mr-2" />
                            {{ $t('Instructions') }}
                        </v-toolbar-title>
                    </v-toolbar>

                    <v-card-text class="instructionContentBG">
                        {{ $t('DeleteOrCancelWorkTaskInstructions') }}
                    </v-card-text>
                </v-card>

                <!-- Dependent Tasks Warning -->
                <v-card v-if="workTask.dependentWorkTasks.length > 0" class="pnlInstructions mt-2">
                    <v-toolbar color="swatchERR">
                        <v-toolbar-title>
                            <FontAwesomeIcon :icon="['fas', 'triangle-exclamation']" size="xl" class="mr-2" />
                            {{ $t('DependentTaskWarning_Title') }}
                        </v-toolbar-title>
                    </v-toolbar>

                    <v-card-text class="warning-content">
                        {{
                            $t('DependentTaskWarning_Message', {
                                numberOfDependentTasks: workTask.dependentWorkTasks.length,
                            })
                        }}
                    </v-card-text>
                </v-card>

                <!-- Select Cancel or Delete -->
                <div style="display: flex">
                    <div style="margin-left: auto; margin-right: auto">
                        <div class="tableCell">
                            <div
                                style="display: flex; align-items: center"
                                class="radioButtonBox"
                                :class="{selected: deleteOrCancelWorkTaskModel.isCancel}"
                                @click="actionChanged(false)">
                                <v-radio
                                    v-model="actionValue"
                                    true-value="CANCEL"
                                    false-value="DELETE"
                                    :label="$t('CancelThisTask')"
                                    color="swatchB2" />
                                <FontAwesomeIcon :icon="['fal', 'box-archive']" size="xl" />
                            </div>
                        </div>
                        <div class="tableCell">
                            <div
                                style="display: flex; align-items: center; margin-left: 5rem"
                                class="radioButtonBox"
                                :class="{selected: deleteOrCancelWorkTaskModel.isDelete}"
                                @click="actionChanged(true)">
                                <v-radio
                                    v-model="actionValue"
                                    true-value="DELETE"
                                    false-value="CANCEL"
                                    :label="$t('DeleteThisTask')"
                                    color="swatchB2" />
                                <FontAwesomeIcon :icon="['fal', 'trash-xmark']" size="xl" />
                            </div>
                        </div>

                        <!-- "Action Selected" validator" -->
                        <v-validation
                            :validation-value="actionValue"
                            :rules="[actionSelectedRule, taskAlreadyCancelled]">
                            <template v-slot="{errorMessages, isValid}">
                                <div v-if="isValid.value != null" style="text-align: center">
                                    <span
                                        v-for="(error, index) in errorMessages.value"
                                        :key="index"
                                        class="error-message">
                                        {{ error }}
                                    </span>
                                </div>
                            </template>
                        </v-validation>
                    </div>
                </div>

                <!-- Explanation -->
                <div class="flex-layout fill flex-layout-space-05 align-items-center mt-6">
                    <div class="formHead">{{ $t('Explanation') }}</div>
                    <div class="formIcon">
                        <HelpIcon :help-text="$t('DeleteOrCancelWorkTask_Explanation_HelpText')" />
                    </div>
                    <div style="flex-grow: 1">
                        <v-textarea
                            v-model="deleteOrCancelWorkTaskModel.explanation"
                            :rules="[requiredRule]"
                            required
                            variant="outlined"
                            density="compact"
                            rows="1"
                            auto-grow
                            hide-details="auto"
                            class="swatchA1 font-weight-bold">
                        </v-textarea>
                    </div>
                </div>
            </v-form>

            <v-card-actions class="justify-space-between swatchG9BG">
                <v-btn @click="close">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'arrow-rotate-left']" size="xl" />
                    </template>
                    {{ $t('Cancel') }}
                </v-btn>
                <v-btn @click="save" :disabled="isLoading">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'save']" size="xl" />
                    </template>
                    {{ $t('Save') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" timeout="4000">
        {{ snackbar.text }}
    </v-snackbar>
</template>

<script setup lang="ts">
import '@/assets/scss/user/user-manager.scss';
import {computed, watch, inject, ref} from 'vue';
import ApiService from '@/services/api-service.js';
import CloseModalButton from '@/components/CloseModalButton.vue';
import WorkTaskDetailsHeader from '@/components/WorkTaskDetailsHeader.vue';
import {SearchWorkTasksRequest} from '@/models/api/requests/search/SearchWorkTasksRequest';
import {SearchWorkTasksResponse} from '@/models/api/responses/search/SearchWorkTasksResponse';
import {WorkTaskSearchResultDto} from '@/models/data-transfer-objects/search/work-task-search/WorkTaskSearchResultDto';
import {SearchFarmFieldsRequest} from '@/models/api/requests/search/SearchFarmFieldsRequest';
import {SearchFarmFieldsResponse} from '@/models/api/responses/search/SearchFarmFieldsResponse';
import {FarmFieldSearchResultDto} from '@/models/data-transfer-objects/search/farm-field-search/FarmFieldSearchResultDto';
import {useDeleteOrCancelWorkTaskModalStore} from '@/stores/modals/delete-or-cancel-work-task-modal-store';
import i18n from '@/i18n';
import {CancelWorkTaskRequest} from '@/models/api/requests/work-tasks/CancelWorkTaskRequest';
import {DeleteOrCancelWorkTaskForm} from '@/models/work-tasks/DeleteOrCancelWorkTaskForm';
import {DeleteWorkTaskRequest} from '@/models/api/requests/work-tasks/DeleteWorkTaskRequest';
import Validation from '@/helpers/ValidationHelper';

// Form
const form = ref();
const valid = ref<boolean>(true);
let isLoading = ref<boolean>(false);
const snackbar = ref({
    show: false,
    text: '',
});

// Rules
const requiredRule = Validation.createRule_Required();
const actionSelectedRule = (v: string) => {
    return v != null || i18n.global.t('Validation_CancelOrDeleteWorkTaskSelection');
};
const taskAlreadyCancelled = (v: string) => {
    if (!v) return true;
    if (deleteOrCancelWorkTaskModel.value.isCancel && workTask.value?.isCancelled)
        return i18n.global.t('Validation_CancelOrDeleteWorkTaskAlreadyCancelled');
    return true;
};

// Services
const apiService = inject('apiService') as ApiService;

// Modal
const deleteOrCancelWorkTaskModalStore = useDeleteOrCancelWorkTaskModalStore();
const workTask = ref<WorkTaskSearchResultDto | null>(null);
const farmField = ref<FarmFieldSearchResultDto | null>(null);
const deleteOrCancelWorkTaskModel = ref<DeleteOrCancelWorkTaskForm>(new DeleteOrCancelWorkTaskForm());

/**
 * Load work task data for the modal.
 */
const loadWorkTaskData = async () => {
    isLoading.value = true;

    // Reset form
    resetForm();

    // If a work task ID was specified
    if (deleteOrCancelWorkTaskModalStore.workTaskId !== null) {
        // Build search request using the work task ID
        const searchRequest: SearchWorkTasksRequest = new SearchWorkTasksRequest({
            isQuickSearch: false,
            workTaskId: deleteOrCancelWorkTaskModalStore.workTaskId,
            includeDependentWorkTasks: true,
        });

        // Get work task details
        const searchResults = (await apiService.post('search/work-tasks', searchRequest)) as SearchWorkTasksResponse;

        if (searchResults.workTasks.length == 1) {
            // Store search result
            workTask.value = searchResults.workTasks[0];

            // Set work task ID in form model
            deleteOrCancelWorkTaskModel.value.workTaskId = workTask.value.workTaskId;

            // Get farm field details
            await getFarmFieldDetails();
        }
    }

    // End loading
    isLoading.value = false;
};

/**
 * Get farm field details of an existing work task.
 */
const getFarmFieldDetails = async () => {
    if (workTask.value == null) return;

    // Build search request using the farm field ID
    const searchRequest: SearchFarmFieldsRequest = new SearchFarmFieldsRequest({
        isQuickSearch: false,
        farmFieldId: workTask.value.farmFieldId,
    });

    // Get farm field details
    const searchResults = (await apiService.post('search/farm-fields', searchRequest)) as SearchFarmFieldsResponse;

    if (searchResults.farmFields.length == 1) {
        // Store search result
        farmField.value = searchResults.farmFields[0];
    }
};

/**
 * Save the modal.
 */
const save = async () => {
    // Perform final client side validation of form
    await form.value.validate();

    // If form is valid
    if (valid.value) {
        let snackbarMessage = '';
        let result = false;
        isLoading.value = true;

        try {
            if (deleteOrCancelWorkTaskModel.value.isDelete) {
                // Build API request
                const deleteWorkTaskRequest: DeleteWorkTaskRequest = {
                    workTaskId: deleteOrCancelWorkTaskModel.value.workTaskId!,
                    deletedExplanation: deleteOrCancelWorkTaskModel.value.explanation!,
                };

                // Call API to save work task
                result = await apiService.post('work-tasks/delete-work-task', deleteWorkTaskRequest);

                // Set snackbar message
                snackbarMessage = i18n.global.t('DeleteWorkTask_Success');
            } else if (deleteOrCancelWorkTaskModel.value.isCancel) {
                // Build API request
                const cancelWorkTaskRequest: CancelWorkTaskRequest = {
                    workTaskId: deleteOrCancelWorkTaskModel.value.workTaskId!,
                    cancelledExplanation: deleteOrCancelWorkTaskModel.value.explanation!,
                };

                // Call API to save work task
                result = await apiService.post('work-tasks/cancel-work-task', cancelWorkTaskRequest);

                // Set snackbar message
                snackbarMessage = i18n.global.t('CancelWorkTask_Success');
            }

            // If the API call failed, throw an error and show the user the generic error message
            if (!result) {
                throw new Error('API call unsuccessful.');
            }

            // Show success feedback to user
            snackbar.value.show = true;
            snackbar.value.text = snackbarMessage;
            deleteOrCancelWorkTaskModalStore.savedCounter++;

            // Close modal
            deleteOrCancelWorkTaskModalStore.close();
        } catch (ex: unknown) {
            // Show fail feedback to user
            snackbar.value.show = true;
            snackbar.value.text = i18n.global.t('ErrorGeneric');
            isLoading.value = false;
        }
    } else {
        // Show validation error feedback to user
        snackbar.value.show = true;
        snackbar.value.text = i18n.global.t('ErrorValidation');
    }
};

/**
 * Updates cancel/delete flags.
 */
const actionChanged = (isDelete: boolean) => {
    if (isDelete) {
        if (deleteOrCancelWorkTaskModel.value.isDelete) {
            // If the Delete option was clicked on but it was already selected, set the selected action to NULL
            deleteOrCancelWorkTaskModel.value.isDelete = false;
        } else {
            // If the Delete option was clicked on and it wasn't already selected, set the selected action to DELETE
            deleteOrCancelWorkTaskModel.value.isDelete = true;
            deleteOrCancelWorkTaskModel.value.isCancel = false;
        }
    } else {
        if (deleteOrCancelWorkTaskModel.value.isCancel) {
            // If the Cancel option was clicked on but it was already selected, set the selected action to NULL
            deleteOrCancelWorkTaskModel.value.isCancel = false;
        } else {
            // If the Cancel option was clicked on and it wasn't already selected, set the selected action to CANCEL
            deleteOrCancelWorkTaskModel.value.isCancel = true;
            deleteOrCancelWorkTaskModel.value.isDelete = false;
        }
    }
};

/**
 * Close the modal.
 */
const close = () => {
    deleteOrCancelWorkTaskModalStore.close();
};

/**
 * Reset the form to its default state.
 */
const resetForm = () => {
    workTask.value = null;
    farmField.value = null;
    deleteOrCancelWorkTaskModel.value = new DeleteOrCancelWorkTaskForm();
};

/**
 * The value of the selected action (cancel or delete).
 */
const actionValue = computed(() => {
    if (deleteOrCancelWorkTaskModel.value.isDelete) return 'DELETE';
    else if (deleteOrCancelWorkTaskModel.value.isCancel) return 'CANCEL';
    else return null;
});

// Watch for changes to modal state
watch(
    () => deleteOrCancelWorkTaskModalStore.isVisible,
    async (isVisible) => {
        if (isVisible) {
            await loadWorkTaskData();
        }
    },
);
</script>
