<template>
    <v-dialog v-model="deleteFarmSiteModalStore.isVisible" class="deleteFarmSiteModal">
        <v-card>
            <v-toolbar color="swatchA2">
                <v-toolbar-title>
                    <FontAwesomeIcon :icon="['fal', 'farm']" size="xl" class="mr-2" />
                    {{ $t('ConfirmFarmSiteDelete') }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <CloseModalButton @click="close" />
            </v-toolbar>
            <LoadingSymbol v-if="isLoading || localFarmSiteId == null" />
            <v-form v-else ref="form" class="pa-4">
                <div class="profile-detail" v-if="farmSite">
                    <div v-if="selectedCropCode">
                        <CropIcon
                            :cropCode="selectedCropCode"
                            :primary="true"
                            icon-height="40"
                            icon-width="40"
                            icon-size="23"
                            class="swatchA5" />
                    </div>
                    <div class="space-between-div paddingLeft">
                        <div>
                            <span class="bold swatchA2 slightlyLarger" v-if="farmSite.farmSiteName">
                                {{ farmSite.farmSiteName }}
                            </span>
                            <IdIcon v-if="farmSite.farmSiteId">{{ farmSite.farmSiteId }}</IdIcon>
                        </div>
                        <div v-if="farmSite.farmSiteDescription">
                            <span>{{ farmSite.farmSiteDescription }}</span>
                        </div>
                    </div>
                </div>

                <!-- Confirm Delete -->
                <v-card class="marginTop">
                    <v-toolbar class="pa-3 permissionDenied" v-if="isFarmFieldAssociated || !isUserHasAccess">
                        <v-toolbar-title style="font-size: medium">
                            {{ $t('DeleteFarmSite_PermissionDenied') }}
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-toolbar class="pa-3 permissionDenied" v-else-if="currentSiteId === localFarmSiteId">
                        <v-toolbar-title style="font-size: medium">
                            {{ $t('DeleteFarmSite_DeleteCurrentSiteDenied') }}
                        </v-toolbar-title>
                    </v-toolbar>

                    <v-card-text class="farmSiteDeleteConfirmation pa-0" v-else>
                        <v-row class="confirm-header pa-3 ma-0">
                            <FontAwesomeIcon :icon="['fal', 'check']" size="xl" class="mr-5" />
                            <span> {{ $t('ConfirmDeletion') }} </span>
                        </v-row>

                        <div class="pa-2">
                            <v-row class="ma-2" style="font-size: medium">
                                {{ $t('DeleteFarmSite_ActionTracked') }}
                            </v-row>

                            <v-row class="ma-1">
                                <v-col cols="1" align-self="center">
                                    <v-checkbox v-model="isConfirmed" class="confirm-checkbox" hide-details />
                                </v-col>
                                <v-col cols="11" align-self="center" style="font-size: medium">
                                    {{ $t('DeleteFarmSite_Confirmation') }}
                                </v-col>
                            </v-row>
                        </div>
                    </v-card-text>
                </v-card>
            </v-form>

            <v-card-actions class="justify-space-between swatchG9BG" v-if="!isLoading && localFarmSiteId != null">
                <v-btn @click="close">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'arrow-rotate-left']" size="xl" />
                    </template>
                    {{ $t('Cancel') }}
                </v-btn>
                <v-btn
                    @click="confirmDelete"
                    :disabled="!isConfirmed"
                    v-if="isUserHasAccess && !isFarmFieldAssociated && currentSiteId !== localFarmSiteId">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'check']" size="xl" />
                    </template>
                    {{ $t('ConfirmAndSubmit') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" timeout="4000">
        {{ snackbar.text }}
    </v-snackbar>
</template>

<script setup lang="ts">
import '@/assets/scss/farm/delete-farm-site-modal.scss';
import '@/assets/scss/user/user-manager.scss';

import {useUserStore} from '@/stores/user-store';
import {RoleCode} from '@/enums/role-code';
import {useDeleteFarmSiteModalStore} from '@/stores/modals/delete-farm-site-modal-store';
import {computed, watch, inject, ref, onUnmounted} from 'vue';
import ApiService from '@/services/api-service.js';
import CloseModalButton from '@/components/CloseModalButton.vue';
import i18n from '@/i18n';
import CropIcon from '@/components/CropIcon.vue';
import {useCrops} from '@/composables/data-source/crops';
const {getCrops, crops} = useCrops();
import {SearchFarmSitesResponse} from '@/models/api/responses/search/SearchFarmSitesResponse';
import {ManageFarmSiteForm} from '@/models/farm/ManageFarmSiteForm';
import {CropCode} from '@/enums/crop-code';
import {useFarmStore} from '@/stores/farm-store';
const farmStore = useFarmStore();
import {useFarmSites} from '@/composables/data-source/farm-sites';
const {getFarmSites} = useFarmSites();
const userStore = useUserStore();

// Form
let isLoading = ref<boolean>(false);
const snackbar = ref({
    show: false,
    text: '',
});
const deleteFarmSiteModalStore = useDeleteFarmSiteModalStore();
const isConfirmed = ref<boolean>(false);
const localFarmSiteId = ref<number | null>(null);
const farmSite = ref<ManageFarmSiteForm | null>(null);

// get the current site
const currentSiteId = computed(() => farmStore.selectedSiteId);

// Services
const apiService = inject('apiService') as ApiService;

const isUserHasAccess = ref<boolean>(userStore.hasRoleLevel(RoleCode.System, 3));

const selectedCropCode = computed(() => {
    if (!crops.value || crops.value.length === 0) return undefined;
    if (!farmSite.value || !farmSite.value.defaultPrimaryCropId) return undefined;

    const selectedCrop = crops.value.filter((crop) => crop.cropId === farmSite.value?.defaultPrimaryCropId);
    if (selectedCrop.length > 0) {
        const cropCode = selectedCrop[0].cropCode;
        if (Object.values(CropCode).includes(cropCode as CropCode)) {
            return cropCode as CropCode;
        }
    }

    return undefined;
});

const isFarmFieldAssociated = computed(() => {
    if (!farmSite.value) return false;
    if (farmSite.value.associatedFarmFieldNumber && farmSite.value.associatedFarmFieldNumber > 0) return true;
    return false;
});
/**
 * Load data for the modal.
 */
const loadData = async (farmSiteId: number) => {
    isLoading.value = true;

    // Reset form
    resetForm();

    // Set data
    localFarmSiteId.value = farmSiteId;
    await getCrops();

    // Get farm site details
    const searchResults = (await apiService.post('search/farm-sites-by-id', {
        farmSiteId: farmSiteId,
    })) as SearchFarmSitesResponse;

    if (searchResults.farmSites.length === 1) {
        farmSite.value = Object.assign(new ManageFarmSiteForm(), searchResults.farmSites[0]);
    }

    isLoading.value = false;
};

/**
 * Close the modal.
 */
const close = () => {
    deleteFarmSiteModalStore.close();
};

/**
 * Save the modal.
 */
const confirmDelete = async () => {
    if (farmSite.value == null) return;

    if (!isUserHasAccess.value) return;
    isLoading.value = true;

    try {
        // Call API to delete user
        const response = await apiService.post('farmSites/delete-farm-site', {farmSiteId: farmSite.value.farmSiteId});

        if (response) {
            // Show success feedback to user
            snackbar.value.show = true;
            snackbar.value.text = i18n.global.t('DeleteFarmSite_Success');
            deleteFarmSiteModalStore.deletedCounter++;
            // update farm store
            await farmStore.fetchFarmSites(apiService);
            await getFarmSites();

            // Close modal
            deleteFarmSiteModalStore.close();
        } else {
            // Show fail feedback to user
            snackbar.value.show = true;
            snackbar.value.text = i18n.global.t('ErrorGeneric');
            isLoading.value = false;
        }
    } catch (ex: unknown) {
        // Show fail feedback to user
        snackbar.value.show = true;
        snackbar.value.text = i18n.global.t('ErrorGeneric');
        isLoading.value = false;
    }
};

onUnmounted(() => {
    deleteFarmSiteModalStore.close();
});

/**
 * Reset the form to its default state.
 */
const resetForm = () => {
    farmSite.value = null;
    isConfirmed.value = false;
};

// Watch for changes to deleteUserModalStore.userId
watch(
    () => deleteFarmSiteModalStore.isVisible,
    async (isVisible) => {
        if (isVisible && deleteFarmSiteModalStore.farmSiteId !== null) {
            await loadData(deleteFarmSiteModalStore.farmSiteId);
        }
    },
);
</script>
