<template>
    <v-toolbar class="swatchA2BG">
        <FontAwesomeIcon :icon="['fal', 'table-list']" size="xl" class="swatchWHT ml-3" />
        <v-toolbar-title class="swatchWHT crop-data-table-title"
            >{{ $t('FieldList') + ` (${resultsList.length})` }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn class="v-btn--custom mr-2" rounded="1" @click="manageFarmFieldModalStore.open()">
            <template v-slot:prepend>
                <FontAwesomeIcon :icon="['fal', 'plus']" size="xl" />
            </template>
            {{ $t('Add') }}
        </v-btn>
        <v-btn class="v-btn--custom mr-2" rounded="1" @click="viewFieldListOnHome">
            <template v-slot:prepend>
                <FontAwesomeIcon :icon="['fal', 'map']" size="xl" />
            </template>
            {{ $t('MapView') }}
        </v-btn>
        <v-btn :class="selectButtonClass" rounded="1" @click="selectMode = !selectMode">
            <template v-slot:prepend>
                <FontAwesomeIcon :icon="['fal', 'square-dashed-circle-plus']" size="xl" />
            </template>
            {{ $t('SelectFields') }}
        </v-btn>

        <v-btn :class="filterButtonClass" rounded="1" @click="switchBackToSearch">
            <template v-slot:prepend>
                <FontAwesomeIcon :icon="['fal', 'filter']" size="xl" v-if="isFilterApplied" />
                <FontAwesomeIcon :icon="['fac', 'filter-check']" size="xl" v-else />
            </template>
            {{ $t('Filters') }}
        </v-btn>

        <navigator-field-operations-menu :farm-fields="selected" :is-in-list-view="true" />
    </v-toolbar>
    <v-data-table
        :headers="headers"
        :items="resultsList"
        :items-per-page="configStore.defaultListItemNumberPerPage"
        v-model:selected="selected"
        class="farm-field-table">
        <template #item="{item}">
            <tr :class="getItemClass(item)" @click.stop.prevent="handleResultClick(item)">
                <td>
                    <v-row align="center" style="min-width: 180px" no-gutters class="pa-1">
                        <v-col cols="2" align="center">
                            <div style="height: 65px">
                                <SvgLoader
                                    v-if="item.farmFieldPolygonImageUrl"
                                    :url="item.farmFieldPolygonImageUrl"
                                    :color="item.farmFieldColorHexCode ?? '#B8E531'" />

                                <SvgLoader
                                    v-else
                                    url="Field_UnknownShape.svg"
                                    :color="item.farmFieldColorHexCode ?? '#B8E531'" />
                            </div>
                        </v-col>
                        <v-col cols="1"></v-col>
                        <v-col cols="9">
                            <span class="swatchA1 slightlyLarger text-ellipsis limited-width">
                                {{ `${item.farmFieldCode} - ${item.farmFieldName}` }}
                            </span>
                        </v-col>
                    </v-row>
                </td>
                <td v-if="showFarmSite">
                    <v-col col="12" align="center" v-if="item.farmSiteId"
                        ><FarmSiteChip :farm-site-abbreviation="item.farmSiteAbbreviation" />
                    </v-col>
                </td>
                <td>
                    <div v-if="item.farmBlocks && item.farmBlocks.length > 0" style="min-width: 150px">
                        <span class="pr-2" v-for="[cropCode, cropVarieties] in cropGroup(item)" :key="cropCode">
                            <span
                                @click.prevent.stop="
                                    viewCropVarietyModalStore.open(parseInt(Object.keys(cropVarieties)[0]))
                                ">
                                <CropIcon
                                    :cropCode="cropCode"
                                    :primary="checkCropPrimary(item.farmBlocks, cropCode)"
                                    :varieties="[...Object.values(cropVarieties).map((cv) => cv.cropVarietyName)]" />
                            </span>
                        </span>
                    </div>
                </td>
                <td>
                    <v-row>
                        <v-col cols="12" align="center">
                            <FarmFieldAgeIcon
                                v-if="item.cropYear != null"
                                iconHeight="45"
                                iconWidth="45"
                                :fieldAge="item.cropYear.toString()" />
                        </v-col>
                    </v-row>
                </td>
                <td>
                    <div class="tableCell" v-if="item.farmFieldSize != null" style="min-width: 80px">
                        <span class="swatchA1 slightlyLarger">
                            {{ `${parseFloat(item.farmFieldSize.toFixed(2))} ha` }}
                        </span>
                    </div>
                </td>
                <td>
                    <v-row v-if="item.farmFieldSeasonStartDate">
                        <v-col cols="12" align="center">
                            <SeasonChip :seasonName="item.currentSeasonName" />
                        </v-col>
                    </v-row>
                </td>
                <td>
                    <div style="min-width: 150px" v-if="item.lastWorkTask">
                        <v-row
                            ><span @click.prevent.stop="viewWorkTaskModalStore.open(item.lastWorkTask.workTaskId)"
                                ><WorkTaskTypeIcon :workTaskTypeCode="item.lastWorkTask.workTaskTypeCode"
                            /></span>
                            <span class="ml-2 pt-2 swatchA1">{{
                                format(item.lastWorkTask.completedDate, 'd MMM, yyyy')
                            }}</span></v-row
                        >
                    </div>
                </td>
                <td>
                    <div style="min-width: 150px" v-if="item.nextWorkTask">
                        <v-row>
                            <span @click.prevent.stop="viewWorkTaskModalStore.open(item.nextWorkTask.workTaskId)"
                                ><WorkTaskTypeIcon :workTaskTypeCode="item.nextWorkTask.workTaskTypeCode"
                            /></span>
                            <span :class="nextTaskClass(item.nextWorkTask.dueDate)">{{
                                formatDate(item.nextWorkTask.dueDate)
                            }}</span>
                        </v-row>
                    </div>
                </td>
                <td style="max-width: 50px">
                    <div
                        v-if="item.openObservations && item.openObservations.length > 0"
                        class="observations-container">
                        <span
                            v-for="observationType in [...observationTypeSet(item)]"
                            :key="observationType"
                            class="observation-chip">
                            <ObservationTypeChips
                                :observationType="observationType"
                                iconHeight="30"
                                iconWidth="38"
                                iconSize="23" />
                        </span>
                    </div>
                    <div v-else>{{ $t('None') }}</div>
                </td>
                <td>
                    <div class="tableCell" style="min-width: 80px">
                        <span
                            class="swatchA1 slightlyLarger"
                            v-if="item.farmFieldSize && item.farmFieldSeasons && item.farmFieldSeasons.length > 1">
                            {{ getLastSeasonAverageYield(item.farmFieldSize, item.farmFieldSeasons) }}
                        </span>
                    </div>
                </td>
                <td>
                    <div class="tableCell" v-if="item.farmFieldSize != null" style="min-width: 80px">
                        <span
                            class="swatchA1 slightlyLarger"
                            v-if="item.farmFieldSeasons && item.farmFieldSeasons.length > 1">
                            {{ getLastSeasonCCS(item.farmFieldSeasons) }}
                        </span>
                    </div>
                </td>
                <td>
                    <div style="text-align: center">
                        <v-menu open-on-hover>
                            <template v-slot:activator="{props}">
                                <v-btn class="v-btn--custom" v-bind="props" @click.stop.prevent>
                                    {{ $t('Action') }}
                                </v-btn>
                            </template>

                            <v-list>
                                <v-list-item v-if="item.farmFieldId && userStore.hasRoleLevel(RoleCode.Fields, 1)">
                                    <v-list-item-title>
                                        <v-btn @click="viewFarmFieldModalStore.open(item.farmFieldId)">
                                            {{ $t('ViewField') }}
                                        </v-btn>
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item v-if="item.farmFieldId && userStore.hasRoleLevel(RoleCode.Fields, 2)">
                                    <v-list-item-title>
                                        <v-btn @click="manageFarmFieldModalStore.open(item.farmFieldId)">
                                            {{ $t('ModifyField') }}
                                        </v-btn>
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item v-if="item.farmFieldId && userStore.hasRoleLevel(RoleCode.Tasks, 2)">
                                    <v-list-item-title>
                                        <v-btn
                                            @click="
                                                manageWorkTaskModalStore.open(
                                                    ManageWorkTaskModalMode.Create,
                                                    null,
                                                    item.farmFieldId,
                                                )
                                            ">
                                            {{ $t('CreateNewTaskInField') }}
                                        </v-btn>
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item v-if="item.farmFieldId && userStore.hasRoleLevel(RoleCode.Tasks, 2)">
                                    <v-list-item-title>
                                        <v-btn @click="selectWorkTaskTemplateModalStore.open(item.farmFieldId)">
                                            {{ $t('CreateNewTaskFromTemplate') }}
                                        </v-btn>
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item v-if="item && userStore.hasRoleLevel(RoleCode.Fields, 1)">
                                    <v-list-item-title>
                                        <v-btn @click="manageFarmFieldSeasonModalStore.open(item)">
                                            {{ $t('ManageSeasons') }}
                                        </v-btn>
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item v-if="userStore.hasRoleLevel(RoleCode.Tasks, 2) && item.plantedWorkTaskId">
                                    <v-list-item-title>
                                        <v-btn
                                            @click="
                                                manageWorkTaskModalStore.open(
                                                    ManageWorkTaskModalMode.Modify,
                                                    item.plantedWorkTaskId,
                                                    item.farmFieldId,
                                                )
                                            ">
                                            {{ $t('ModifyLatestPlantTask') }}
                                        </v-btn>
                                    </v-list-item-title>
                                </v-list-item>

                                <v-list-item>
                                    <v-list-item-title
                                        v-if="item.farmFieldId && userStore.hasRoleLevel(RoleCode.Fields, 3)">
                                        <v-btn @click="deleteFarmFieldModalStore.open(item.farmFieldId)">
                                            {{ $t('DeleteField') }}
                                        </v-btn>
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </td>
            </tr>
        </template>
    </v-data-table>
</template>

<script setup lang="ts">
import {ref, computed, watch} from 'vue';
import {FarmFieldSearchResultDto} from '@/models/data-transfer-objects/search/farm-field-search/FarmFieldSearchResultDto';
import i18n from '@/i18n';
import {useUserStore} from '@/stores/user-store';
const userStore = useUserStore();
import {RoleCode} from '@/enums/role-code';
import FarmFieldAgeIcon from '@/components/FarmFieldAgeIcon.vue';
import SeasonChip from '@/components/other-chips/SeasonChip.vue';
import ObservationTypeChips from '@/components/ObservationTypeChips.vue';
import {format} from 'date-fns';
import {ObservationDto} from '@/models/data-transfer-objects/search/farm-field-search/ObservationDto';
import {useManageFarmFieldModalStore} from '@/stores/modals/manage-farm-field-modal-store';
const manageFarmFieldModalStore = useManageFarmFieldModalStore();
import {useViewFarmFieldModalStore} from '@/stores/modals/view-farm-field-modal-store';
const viewFarmFieldModalStore = useViewFarmFieldModalStore();
import SvgLoader from '@/components/SvgLoader.vue';
import {useViewWorkTaskModalStore} from '@/stores/modals/view-work-task-modal-store';
const viewWorkTaskModalStore = useViewWorkTaskModalStore();
import FarmSiteChip from '@/components/other-chips/FarmSiteChip.vue';
import {useSelectWorkTaskTemplateModalStore} from '@/stores/modals/select-work-task-template-modal-store';
const selectWorkTaskTemplateModalStore = useSelectWorkTaskTemplateModalStore();
import {useManageWorkTaskModalStore} from '@/stores/modals/manage-work-task-modal-store';
import {ManageWorkTaskModalMode} from '@/enums/modal-modes/manage-work-task-modal-mode';
const manageWorkTaskModalStore = useManageWorkTaskModalStore();
import {useConfigStore} from '@/stores/config-store';
const configStore = useConfigStore();
import {useDeleteFarmFieldModalStore} from '@/stores/modals/delete-farm-field-modal-store';
const deleteFarmFieldModalStore = useDeleteFarmFieldModalStore();
import {useManageFarmFieldSeasonModalStore} from '@/stores/modals/manage-farm-field-season-modal-store';
const manageFarmFieldSeasonModalStore = useManageFarmFieldSeasonModalStore();
import {FarmFieldSeasonDto} from '@/models/data-transfer-objects/search/farm-field-search/FarmFieldSeasonDto';
import {FarmBlockDto} from '@/models/data-transfer-objects/search/farm-field-search/FarmBlockDto';
import {CropCode} from '@/enums/crop-code';
import {useViewCropVarietyModalStore} from '@/stores/modals/view-crop-variety-modal-store';
const viewCropVarietyModalStore = useViewCropVarietyModalStore();
import NavigatorFieldOperationsMenu from '@/components/operations-menus/NavigatorFieldOperationsMenu.vue';

const props = withDefaults(
    defineProps<{
        resultsList: FarmFieldSearchResultDto[];
        isFilterApplied: boolean;
        showFarmSite?: boolean;
    }>(),
    {
        resultsList: () => [] as FarmFieldSearchResultDto[],
        isFilterApplied: false,
        showFarmSite: false,
    },
);

const emit = defineEmits<{
    (event: 'switch-to-search'): void;
    (event: 'view-fields-on-home'): void;
}>();

const viewFieldListOnHome = () => {
    emit('view-fields-on-home');
};

const selectMode = ref<boolean>(false);
const selected = ref<FarmFieldSearchResultDto[]>([]);

const cropGroup = (item: FarmFieldSearchResultDto) =>
    item.farmBlocks.reduce((acc, e) => {
        if (e.cropVariety && e.cropVariety.cropVarietyId) {
            if (!acc.has(e.cropCode)) acc.set(e.cropCode, {});
            acc.get(e.cropCode)[e.cropVariety.cropVarietyId] = e.cropVariety;
        }

        return acc;
    }, new Map());

const headers = ref([
    {title: i18n.global.t('Field'), key: 'farmFieldName', sortable: true},
    {title: i18n.global.t('CropSlashVarieties'), key: 'farmBlocks', sortable: true},
    {title: i18n.global.t('CropYear'), key: 'cropYear', sortable: true},
    {title: i18n.global.t('Size'), key: 'farmFieldSize', sortable: true},
    {title: i18n.global.t('CurrentSeason'), key: 'currentSeason', sortable: true},
    {title: i18n.global.t('LastTask'), key: 'lastWorkTask', sortable: true},
    {title: i18n.global.t('NextTask'), key: 'nextWorkTask', sortable: true},
    {title: i18n.global.t('Observations'), key: 'observations', sortable: true},
    {title: i18n.global.t('LastSeasonYield'), key: 'previousSeasonYield', sortable: false},
    {title: i18n.global.t('LastSeasonCCS'), key: 'previousSeasonCCS', sortable: false},
]);

props.showFarmSite && headers.value.splice(1, 0, {title: i18n.global.t('FarmSite'), key: 'farmSiteId', sortable: true});

const hasStaffAccess = userStore.hasRoleLevel(RoleCode.System, 3);
if (hasStaffAccess) {
    headers.value.push({title: i18n.global.t('Action'), key: 'action', sortable: false});
}

/**
 * Handle click event on a result.
 */
const handleResultClick = (item: FarmFieldSearchResultDto) => {
    if (!selectMode.value) viewFarmFieldModalStore.open(item.farmFieldId);
    else {
        const index = selected.value.indexOf(item);
        if (index === -1) {
            selected.value.push(item);
        } else {
            selected.value.splice(index, 1);
        }
    }
};

const filterButtonClass = computed(() => (props.isFilterApplied ? ['v-btn--custom'] : ['swatchB6BG', 'swatchWHT']));
const selectButtonClass = computed(() =>
    !selectMode.value ? ['v-btn--custom', 'mr-2'] : ['swatchB6BG', 'swatchWHT', 'mr-2'],
);

const switchBackToSearch = () => {
    emit('switch-to-search');
};

const observationTypeSet = (item: FarmFieldSearchResultDto): Set<string> =>
    item.openObservations.reduce((acc: Set<string>, e: ObservationDto) => {
        if (e.observationTypeCode) acc.add(e.observationTypeCode as string);
        return acc;
    }, new Set());

const formatDate = (date: Date | null): string | null => {
    if (!date) return null;
    return format(date, 'd MMM, yyyy');
};

const nextTaskClass = (dueDate: Date) =>
    new Date(dueDate) >= new Date() ? ['ml-2', 'swatchA1', ' pt-2'] : ['ml-2', ' pt-2', 'swatchRED'];

const getLastSeasonAverageYield = (farmFieldSize: number, farmFieldSeasons: FarmFieldSeasonDto[]) => {
    const lastSeason = farmFieldSeasons.find((e) => e.isActive === false);
    if (lastSeason && lastSeason.yieldTotalWeight)
        return parseFloat((lastSeason.yieldTotalWeight / farmFieldSize).toFixed(2)) + ' t/ha';
    else return '';
};

const getLastSeasonCCS = (farmFieldSeasons: FarmFieldSeasonDto[]) => {
    const lastSeason = farmFieldSeasons.find((e) => e.isActive === false);
    if (lastSeason && lastSeason.yieldAverageCcs) return parseFloat(lastSeason.yieldAverageCcs.toFixed(2)) + ' %';
    else return '';
};

const checkCropPrimary = (farmBlocks: FarmBlockDto[], cropCode: CropCode) => {
    const farmBlock = farmBlocks.find((fb) => fb.cropCode === cropCode);
    if (farmBlock) return farmBlock.isCropPrimary;
    else return false;
};

watch(selectMode, () => {
    selected.value = [];
});

const getItemClass = (item: FarmFieldSearchResultDto) => {
    return selected.value.find((e: FarmFieldSearchResultDto) => e.farmFieldId === item.farmFieldId)
        ? 'highlighted-row'
        : 'farm-field-table-row';
};
</script>
<style lang="scss">
@import '@/assets/scss/swatches.scss';
.farm-field-table .v-table__wrapper {
    overflow-y: hidden;
}

.farm-field-table .v-table__wrapper thead {
    background-color: $swatchG7;
}

.observations-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

#farm-field-operations-activator {
    border: 1px solid $swatchG5;
    background-color: $swatchGA;
    color: $swatchG1;

    &:hover {
        color: $swatchWHT;
        background-color: $swatchB6;
    }
}

.farm-field-list {
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.farm-field-table .highlighted-row {
    background-color: $swatchB7;
    cursor: pointer;
}

.farm-field-table .farm-field-table-row:hover {
    background-color: $swatchA7;
    cursor: pointer;
}

.farm-field-table .text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.farm-field-table .limited-width {
    display: block;
    max-width: 300px;
}
</style>
