import {NavigatorPanelTab} from '@/enums/home-dashboard/navigator-panel-tab';
import {FarmBlockDto} from '@/models/data-transfer-objects/search/farm-field-search/FarmBlockDto';
import {SearchFarmFieldsRequest} from '@/models/api/requests/search/SearchFarmFieldsRequest';
import {SearchObservationsRequest} from '@/models/api/requests/search/SearchObservationsRequest';
import {SearchWorkTasksRequest} from '@/models/api/requests/search/SearchWorkTasksRequest';
import {FarmFieldSearchResultDto} from '@/models/data-transfer-objects/search/farm-field-search/FarmFieldSearchResultDto';
import {ObservationSearchResultDto} from '@/models/data-transfer-objects/search/observation-search/ObservationSearchResultDto';
import {WorkTaskSearchResultDto} from '@/models/data-transfer-objects/search/work-task-search/WorkTaskSearchResultDto';
import {defineStore} from 'pinia';
import {computed, nextTick, reactive, ref, watch} from 'vue';
import {HomeDashboardMapSetting} from '@/models/home-dashboard/HomeDashboardMapSetting';
import i18n from '@/i18n';
import {HomeDashboardMapSettingCode} from '@/enums/home-dashboard/home-dashboard-map-setting-code';
import { NavigatorFutureTaskList } from '@/enums/home-dashboard/navigator-future-task-list';

export const useHomeDashboardStore = defineStore('homeDashboard', () => {
    const isHomeDashboardOpen = ref(false);
    const isLoading = ref(false);
    const isMapLoading = ref(false);
    const navigatorPanelActiveTab = ref(NavigatorPanelTab.FarmFields);
    const farmFields = ref<FarmFieldSearchResultDto[]>([]);
    const workTasks = ref<WorkTaskSearchResultDto[]>([]);
    const observations = ref<ObservationSearchResultDto[]>([]);
    const isMultiSelectFarmFieldsMode = ref(false);
    const isTaskFutureMode = ref(true);
    const isObservationOpenMode = ref(true);
    const selectedFarmFieldsLevel1 = reactive<FarmFieldSearchResultDto[]>([]);
    const selectedWorkTaskLevel1 = ref<WorkTaskSearchResultDto | null>(null);
    const selectedObservationLevel1 = ref<ObservationSearchResultDto | null>(null);
    const selectedWorkTaskLevel2 = ref<WorkTaskSearchResultDto | null>(null);
    const farmFieldIdToFocusOn = ref<number | null>(null);
    const observationIdToFocusOn = ref<number | null>(null);
    const mousedOverFarmFieldId = ref<number | null>(null);
    const mousedOverFarmBlock = ref<FarmBlockDto | null>(null);
    const searchParametersFarmField = ref<SearchFarmFieldsRequest>({
        ...new SearchFarmFieldsRequest(),
        includeCropVarieties: true,
        includeFarmBlocks: true,
        sortOrder: 'FarmCodeName',
    });
    const farmFieldFiltersVisible = ref<boolean>(false);
    const farmFieldFilterApplied = ref<boolean>(false);

    const searchParametersRecentTask = ref<SearchWorkTasksRequest>({
        ...new SearchWorkTasksRequest(),
        sortOrder: 'DateCompleted',
        includeWorkTaskMixtures: true,
        includeWorkTaskProducts: true,
        includeWorkTaskUsers: true,
        includeWorkSubTasks: true,
    });
    const searchParametersFutureTask = ref<SearchWorkTasksRequest>({
        ...new SearchWorkTasksRequest(),
        sortOrder: 'ScheduledDate',
        includeWorkTaskMixtures: true,
        includeWorkTaskProducts: true,
        includeWorkTaskUsers: true,
        includeWorkSubTasks: true,
    });
    const recentWorkTasksFiltered = ref<WorkTaskSearchResultDto[]>([]);
    const futureWorkTasksFiltered = ref<WorkTaskSearchResultDto[]>([]);
    const workTaskFiltersVisible = ref<boolean>(false);
    const workTaskRecentFilterApplied = ref<boolean>(false);
    const workTaskFutureFilterApplied = ref<boolean>(false);
    const workTaskPreventLoadingList = ref<boolean>(false);
    const farmFieldPreventInitialLoading = ref<boolean>(false);
    const workTaskPanelVersion = ref<number>(0);
    const fieldPanelVersion = ref<number>(0);
    const parameterFarmFields = ref<number[]>([]);

    const searchParametersOpenObservation = ref<SearchObservationsRequest>({
        ...new SearchObservationsRequest(),
        sortOrder: 'FarmCodeName',
    });
    const searchParametersClosedObservation = ref<SearchObservationsRequest>({
        ...new SearchObservationsRequest(),
        sortOrder: 'FarmCodeName',
    });
    const openObservationsFiltered = ref<ObservationSearchResultDto[]>([]);
    const closedObservationsFiltered = ref<ObservationSearchResultDto[]>([]);
    const observationFiltersVisible = ref<boolean>(false);
    const observationOpenFilterApplied = ref<boolean>(false);
    const observationClosedFilterApplied = ref<boolean>(false);
    const observationPreventInitialLoading = ref<boolean>(false);
    const observationPanelVersion = ref<number>(0);
    const isTaskDragMode = ref(false);
    const draggedWorkTaskId = ref<number | null>(null);
    const draggedSourceList = ref<NavigatorFutureTaskList | null>(null);

    // Set up map settings
    const mapSettings = ref([
        new HomeDashboardMapSetting({
            settingCode: HomeDashboardMapSettingCode.FieldNames,
            settingName: i18n.global.t('FieldNames'),
        }),
        new HomeDashboardMapSetting({
            settingCode: HomeDashboardMapSettingCode.CropVariety,
            settingName: i18n.global.t('CropSlashVariety'),
        }),
        new HomeDashboardMapSetting({
            settingCode: HomeDashboardMapSettingCode.CropYear,
            settingName: i18n.global.t('RatoonSlashYear'),
        }),
        new HomeDashboardMapSetting({
            settingCode: HomeDashboardMapSettingCode.NextTaskIcon,
            settingName: i18n.global.t('NextTaskIcon'),
        }),
        new HomeDashboardMapSetting({
            settingCode: HomeDashboardMapSettingCode.Metrics,
            settingName: i18n.global.t('Metrics'),
        }),
        new HomeDashboardMapSetting({
            settingCode: HomeDashboardMapSettingCode.Observations,
            settingName: i18n.global.t('Observations'),
        }),
        new HomeDashboardMapSetting({
            settingCode: HomeDashboardMapSettingCode.BackgroundMap,
            settingName: i18n.global.t('BackgroundMap'),
            hasOpacity: true,
            opacity: 100,
        }),
        new HomeDashboardMapSetting({
            settingCode: HomeDashboardMapSettingCode.AerialImagery,
            settingName: i18n.global.t('AerialImagery'),
            hasOpacity: true,
            opacity: 100,
        }),
    ]);

    /**
     * Deselect all Level 1 farm fields.
     */
    function deselectFarmFieldsLevel1() {
        selectedFarmFieldsLevel1.length = 0;
    }

    /**
     * Deselect Level 1 work task.
     */
    function deselectWorkTaskLevel1() {
        selectedWorkTaskLevel1.value = null;
    }

    /**
     * Deselect Level 1 observation.
     */
    function deselectObservationLevel1() {
        selectedObservationLevel1.value = null;
    }

    /**
     * Deselect Level 2 work task.
     */
    function deselectWorkTaskLevel2() {
        selectedWorkTaskLevel2.value = null;
    }

    /**
     * Record the farm field that is moused over.
     */
    function onFarmFieldMouseOver(farmFieldId: number) {
        mousedOverFarmFieldId.value = farmFieldId;
    }

    /**
     * Remove record of moused over farm field.
     */
    function onFarmFieldMouseOut() {
        mousedOverFarmFieldId.value = null;
    }

    /**
     * Select or deselect a farm field.
     */
    function onFarmFieldClicked(clickedFarmField: FarmFieldSearchResultDto, isClickedOnMap: boolean) {
        // If the farm was clicked on the map, and another farm field has been selected (in single select mode), then disable the click event
        if (isClickedOnMap && isOtherFarmFieldSelected(clickedFarmField)) return;

        // Ensure we are in Farm Fields mode
        navigatorPanelActiveTab.value = NavigatorPanelTab.FarmFields;

        // nextTick is used because changing the active tab will trigger its watch (which deselects everything) so we want it to run first
        nextTick(() => {
            // Handle click event depending on whether we are in single or multi select mode
            if (isMultiSelectFarmFieldsMode.value) {
                const index = selectedFarmFieldsLevel1.findIndex(
                    (farmField) => farmField.farmFieldId === clickedFarmField.farmFieldId,
                );
                if (index >= 0) {
                    // If the item is already selected, deselect it
                    selectedFarmFieldsLevel1.splice(index, 1);
                } else {
                    // If the item is not selected, add it to the selection
                    selectedFarmFieldsLevel1.push(clickedFarmField);
                }
            } else {
                if (
                    selectedFarmFieldsLevel1.length === 1 &&
                    selectedFarmFieldsLevel1[0].farmFieldId === clickedFarmField.farmFieldId
                ) {
                    // If selecting the same field, deselect it
                    deselectFarmFieldsLevel1();
                } else {
                    // Select the single field
                    selectedFarmFieldsLevel1.splice(0, selectedFarmFieldsLevel1.length, clickedFarmField);
                }
            }
        });
    }

    /**
     * Checks to see if the specified farm field has been moused over.
     */
    function isFarmFieldMousedOver(farmField: FarmFieldSearchResultDto) {
        return mousedOverFarmFieldId.value == farmField.farmFieldId;
    }

    /**
     * Checks to see if the specified farm field has been selected.
     */
    function isFarmFieldSelected(farmField: FarmFieldSearchResultDto) {
        return selectedFarmFieldsLevel1.some((ff) => ff.farmFieldId == farmField.farmFieldId);
    }

    /**
     * Checks to see if another farm field has been selected.
     */
    function isOtherFarmFieldSelected(farmField: FarmFieldSearchResultDto) {
        // If in single select mode
        return (
            !isMultiSelectFarmFieldsMode.value &&
            // And a farm field has been selected
            selectedFarmFieldsLevel1.length > 0 &&
            // And this farm field is not the one that's been selected
            !isFarmFieldSelected(farmField)
        );
    }

    /**
     * Reset farm field search parameters.
     */
    function resetFarmFieldSearchParameters() {
        searchParametersFarmField.value = {
            ...new SearchFarmFieldsRequest(),
            includeCropVarieties: true,
            includeFarmBlocks: true,
            sortOrder: 'FarmCodeName',
        };

        farmFieldFilterApplied.value = false;
    }

    /**
     * Reset work task search parameters.
     */
    function resetWorkTaskSearchParameters(fullReset: boolean) {
        const newSearchRequest = {
            ...new SearchWorkTasksRequest(),
        };

        const resetParameters = (isFutureMode: boolean) => {
            if (isFutureMode) {
                newSearchRequest.sortOrder = 'ScheduledDate';
                searchParametersFutureTask.value = newSearchRequest;
                workTaskFutureFilterApplied.value = false;
            } else {
                newSearchRequest.sortOrder = 'DateCompleted';
                searchParametersRecentTask.value = newSearchRequest;
                workTaskRecentFilterApplied.value = false;
            }
        };

        if (fullReset) {
            resetParameters(true);
            resetParameters(false);
        } else {
            resetParameters(isTaskFutureMode.value);
        }
    }

    /**
     * Select or deselect an observation.
     */
    function onObservationClicked(clickedObservation: ObservationSearchResultDto) {
        // Ensure we are in Observations mode
        navigatorPanelActiveTab.value = NavigatorPanelTab.Observations;

        // Ensure observations are visible on the map
        showOrHideObservationsOnMap(true);

        // nextTick is used because changing the active tab will trigger its watch (which deselects everything) so we want it to run first
        nextTick(() => {
            if (isObservationSelected(clickedObservation)) {
                // If selecting the same observation, deselect it
                selectedObservationLevel1.value = null;
            } else {
                // Select the observation
                selectedObservationLevel1.value = clickedObservation;
            }
        });
    }

    /**
     * Checks to see if the specified observation has been selected.
     */
    function isObservationSelected(observation: ObservationSearchResultDto) {
        return selectedObservationLevel1.value?.observationId == observation.observationId;
    }

    /**
     * Record the farm block that is moused over.
     */
    function onFarmBlockMouseOver(farmBlock: FarmBlockDto) {
        mousedOverFarmBlock.value = farmBlock;
    }

    /**
     * Remove record of moused over farm block.
     */
    function onFarmBlockMouseOut() {
        mousedOverFarmBlock.value = null;
    }

    /**
     * Checks to see if the specified farm block has been moused over.
     */
    function isFarmBlockMousedOver(farmBlock: FarmBlockDto) {
        return mousedOverFarmBlock.value?.farmBlockId == farmBlock.farmBlockId;
    }

    /**
     * If a farm field should be focused on, triggers its click event to focus on it.
     */
    function focusOnFarmField() {
        // If a specific field should be focused on
        if (farmFieldIdToFocusOn.value != null) {
            // Get the field from the results
            const farmFieldsToFocusOn = farmFields.value.filter((ff) => ff.farmFieldId == farmFieldIdToFocusOn.value);

            // If the farm field exists
            if (farmFieldsToFocusOn.length > 0) {
                // Ensure single select mode
                isMultiSelectFarmFieldsMode.value = false;

                // Deselect all farm fields
                deselectFarmFieldsLevel1();

                // Trigger the farm field's click event to focus on it
                onFarmFieldClicked(farmFieldsToFocusOn[0], false);
            }

            // Clear the focus ID now that the farm field has been focused on
            farmFieldIdToFocusOn.value = null;
        }
    }

    /**
     * If an observation should be focused on, triggers its click event to focus on it.
     */
    function focusOnObservation() {
        // If a specific observation should be focused on
        if (observationIdToFocusOn.value != null) {
            // Get the observation from the results
            const observationsToFocusOn = observations.value.filter(
                (o) => o.observationId == observationIdToFocusOn.value,
            );

            // If the observation exists
            if (observationsToFocusOn.length > 0) {
                // Deselect currently selected observation
                deselectObservationLevel1();

                // Trigger the observation's click event to focus on it
                onObservationClicked(observationsToFocusOn[0]);
            }

            // Clear the focus ID now that the observation has been focused on
            observationIdToFocusOn.value = null;
        }
    }

    /**
     * Reset observation search parameters.
     */
    function resetObservationSearchParameters(fullReset: boolean) {
        const newSearchRequest = {
            ...new SearchObservationsRequest(),
            sortOrder: 'FarmCodeName',
        };

        const resetParameters = (isOpenMode: boolean) => {
            if (isOpenMode) {
                searchParametersOpenObservation.value = newSearchRequest;
                observationOpenFilterApplied.value = false;
            } else {
                searchParametersClosedObservation.value = newSearchRequest;
                observationClosedFilterApplied.value = false;
            }
        };

        if (fullReset) {
            resetParameters(true);
            resetParameters(false);
        } else {
            resetParameters(isObservationOpenMode.value);
        }
    }

    /**
     * Hide the Task Metrics panel.
     */
    function hideTaskMetrics() {
        const setting = mapSettings.value.find((s) => s.settingCode == HomeDashboardMapSettingCode.Metrics);

        if (setting) {
            setting.isVisible = false;
        }
    }

    /**
     * Update flag to show or hide observations on the map.
     */
    function showOrHideObservationsOnMap(isVisible: boolean) {
        const setting = mapSettings.value.find((s) => s.settingCode == HomeDashboardMapSettingCode.Observations);

        if (setting) {
            setting.isVisible = isVisible;
        }
    }

    /**
     * Checks to see if the specified content is visible on the map.
     */
    function isContentVisibleOnMap(settingCode: HomeDashboardMapSettingCode) {
        return mapSettings.value.find((s) => s.settingCode == settingCode)?.isVisible ?? false;
    }

    /**
     * The opacity of the Background Map layer.
     */
    const backgroundMapOpacity = computed(() => {
        return (
            (mapSettings.value.find((s) => s.settingCode == HomeDashboardMapSettingCode.BackgroundMap)?.opacity ?? 0) /
            100
        );
    });

    /**
     * The opacity of the Aerial Imagery layer.
     */
    const aerialImageryOpacity = computed(() => {
        return (
            (mapSettings.value.find((s) => s.settingCode == HomeDashboardMapSettingCode.AerialImagery)?.opacity ?? 0) /
            100
        );
    });

    /**
     * Deselect all items when switching between tabs.
     */
    watch(navigatorPanelActiveTab, () => {
        deselectFarmFieldsLevel1();
        deselectWorkTaskLevel1();
        deselectObservationLevel1();
        deselectWorkTaskLevel2();
    });

    /**
     * When farm field selections are changed, deselect the Level 2 Work Task (in case there is one already selected)
     */
    watch(selectedFarmFieldsLevel1, () => {
        deselectWorkTaskLevel2();
    });

    /**
     * Watch for when a farm field is set to be focused on.
     */
    watch(farmFieldIdToFocusOn, () => {
        // If the home dashboard is not open, do nothing
        if (!isHomeDashboardOpen.value) return;

        // Focus on the farm field
        focusOnFarmField();
    });

    /**
     * Watch for when an observation is set to be focused on.
     */
    watch(observationIdToFocusOn, () => {
        // If the home dashboard is not open, do nothing
        if (!isHomeDashboardOpen.value) return;

        // Focus on the observation
        focusOnObservation();
    });

    /**
     * When farm field multi select mode changes from multi to single, deselect all Level 1 farm fields
     */
    watch(isMultiSelectFarmFieldsMode, () => {
        if (!isMultiSelectFarmFieldsMode.value) {
            deselectFarmFieldsLevel1();
        }
    });

    return {
        isHomeDashboardOpen,
        isLoading,
        isMapLoading,
        navigatorPanelActiveTab,
        farmFields,
        workTasks,
        observations,
        isMultiSelectFarmFieldsMode,
        isTaskFutureMode,
        isObservationOpenMode,
        selectedFarmFieldsLevel1,
        selectedWorkTaskLevel1,
        selectedObservationLevel1,
        selectedWorkTaskLevel2,
        farmFieldIdToFocusOn,
        observationIdToFocusOn,
        searchParametersFarmField,
        farmFieldFiltersVisible,
        farmFieldFilterApplied,
        searchParametersRecentTask,
        searchParametersFutureTask,
        recentWorkTasksFiltered,
        futureWorkTasksFiltered,
        workTaskFiltersVisible,
        workTaskRecentFilterApplied,
        workTaskFutureFilterApplied,
        searchParametersOpenObservation,
        searchParametersClosedObservation,
        openObservationsFiltered,
        closedObservationsFiltered,
        observationFiltersVisible,
        observationOpenFilterApplied,
        observationClosedFilterApplied,
        isTaskDragMode,
        draggedWorkTaskId,
        draggedSourceList,
        mapSettings,
        deselectFarmFieldsLevel1,
        deselectWorkTaskLevel1,
        deselectObservationLevel1,
        deselectWorkTaskLevel2,
        onFarmFieldMouseOver,
        onFarmFieldMouseOut,
        onFarmFieldClicked,
        isFarmFieldMousedOver,
        isFarmFieldSelected,
        isOtherFarmFieldSelected,
        resetFarmFieldSearchParameters,
        resetWorkTaskSearchParameters,
        onObservationClicked,
        isObservationSelected,
        onFarmBlockMouseOver,
        onFarmBlockMouseOut,
        isFarmBlockMousedOver,
        mousedOverFarmBlock,
        focusOnFarmField,
        focusOnObservation,
        resetObservationSearchParameters,
        workTaskPreventLoadingList,
        observationPreventInitialLoading,
        farmFieldPreventInitialLoading,
        hideTaskMetrics,
        showOrHideObservationsOnMap,
        isContentVisibleOnMap,
        backgroundMapOpacity,
        aerialImageryOpacity,
        workTaskPanelVersion,
        observationPanelVersion,
        fieldPanelVersion,
        parameterFarmFields
    };
});
