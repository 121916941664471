import {CropCode} from '@/enums/crop-code';
import i18n from '@/i18n';

export interface Crop {
    cropCode: CropCode;
    getCropName(): string;
    iconFamily: string;
    iconCode: string;
}

export const crops: Crop[] = [
    {
        cropCode: CropCode.SugarCane,
        getCropName() {
            return i18n.global.t('Crop_CropName_SugarCane');
        },
        iconFamily: 'fac',
        iconCode: 'sugarcane',
    },
    {
        cropCode: CropCode.Sunflowers,
        getCropName() {
            return i18n.global.t('Crop_CropName_Sunflowers');
        },
        iconFamily: 'fac',
        iconCode: 'sunflowers',
    },
    {
        cropCode: CropCode.SoyBeans,
        getCropName() {
            return i18n.global.t('Crop_CropName_SoyBeans');
        },
        iconFamily: 'fac',
        iconCode: 'soybeans',
    },
    {
        cropCode: CropCode.Cowpeas,
        getCropName() {
            return i18n.global.t('Crop_CropName_Cowpeas');
        },
        iconFamily: 'fac',
        iconCode: 'cowpeas',
    },
    {
        cropCode: CropCode.Lablab,
        getCropName() {
            return i18n.global.t('Crop_CropName_Lablab');
        },
        iconFamily: 'fac',
        iconCode: 'lablab',
    },
    {
        cropCode: CropCode.OtherLegume,
        getCropName() {
            return i18n.global.t('Crop_CropName_OtherLegume');
        },
        iconFamily: 'fac',
        iconCode: 'other-legume',
    },
];

export function getCropByCode(cropCode: CropCode): Crop | undefined {
    return crops.find((c) => c.cropCode === cropCode);
}
