<template>
    <v-dialog v-model="deleteWorkTaskTemplateModalStore.isVisible" style="width: 70vw">
        <v-card>
            <v-toolbar color="swatchA2">
                <v-toolbar-title>
                    <FontAwesomeIcon :icon="['fal', 'trash-xmark']" size="xl" class="mr-2" />
                    {{ $t('ConfirmWorkTaskTemplateDelete') }}
                </v-toolbar-title>
                <v-spacer />
                <CloseModalButton @click="close" />
            </v-toolbar>
            <LoadingSymbol v-if="isLoading" />
            <v-form v-else ref="form" class="pa-4">
                <div v-if="workTaskTemplate">
                    <WorkTaskTemplateDetailsHeader
                        :work-task-template="workTaskTemplate"
                        :show-delete-task-link="false" />
                </div>

                <!-- Confirm Delete -->
                <v-card class="marginTop">
                    <v-toolbar
                        class="permission-denied pa-3"
                        v-if="(numberOfWorkTaskPlans && numberOfWorkTaskPlans > 0) || !userHasAccess">
                        <v-toolbar-title style="font-size: medium">
                            {{ $t('DeleteWorkTaskTemplate_PermissionDenied', {numberOfPlans: numberOfWorkTaskPlans}) }}
                        </v-toolbar-title>
                    </v-toolbar>

                    <v-card-text class="complete-confirmation pa-0" v-else>
                        <v-row class="confirm-header pa-3 ma-0">
                            <FontAwesomeIcon :icon="['fal', 'check']" size="xl" class="mr-5" />
                            <span> {{ $t('ConfirmDeletion') }} </span>
                        </v-row>

                        <div class="pa-2">
                            <v-row class="ma-2" style="font-size: medium">
                                {{ $t('DeleteWorkTaskTemplate_ActionTracked') }}
                            </v-row>

                            <v-row class="ma-1">
                                <v-col cols="1" align-self="center">
                                    <v-checkbox v-model="isConfirmed" class="confirm-checkbox" hide-details />
                                </v-col>
                                <v-col cols="11" align-self="center" style="font-size: medium">
                                    {{ $t('DeleteWorkTaskTemplate_Confirmation') }}
                                </v-col>
                            </v-row>
                        </div>
                    </v-card-text>
                </v-card>
            </v-form>

            <v-card-actions class="justify-space-between swatchG9BG" v-if="!isLoading">
                <v-btn @click="close">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'arrow-rotate-left']" size="xl" />
                    </template>
                    {{ $t('Cancel') }}
                </v-btn>
                <v-btn
                    @click="confirmDelete"
                    :disabled="!isConfirmed"
                    v-if="userHasAccess && !(numberOfWorkTaskPlans && numberOfWorkTaskPlans > 0)">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'check']" size="xl" />
                    </template>
                    {{ $t('ConfirmAndSubmit') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" timeout="4000">
        {{ snackbar.text }}
    </v-snackbar>
</template>

<script setup lang="ts">
import '@/assets/scss/farm/delete-farm-site-modal.scss';
import '@/assets/scss/user/user-manager.scss';

import {useUserStore} from '@/stores/user-store';
import {RoleCode} from '@/enums/role-code';
import {useDeleteWorkTaskTemplateModalStore} from '@/stores/modals/delete-work-task-template-modal-store';
import {watch, inject, ref, onUnmounted, computed} from 'vue';
import ApiService from '@/services/api-service.js';
import CloseModalButton from '@/components/CloseModalButton.vue';
import i18n from '@/i18n';
import {SearchWorkTaskTemplatesRequest} from '@/models/api/requests/search/SearchWorkTaskTemplatesRequest';
import {WorkTaskTemplateSearchParametersDto} from '@/models/data-transfer-objects/search/work-task-template-search/WorkTaskTemplateSearchParametersDto';
import {SearchWorkTaskTemplatesResponse} from '@/models/api/responses/search/SearchWorkTaskTemplatesResponse';
import {WorkTaskTemplateSearchResultDto} from '@/models/data-transfer-objects/search/work-task-template-search/WorkTaskTemplateSearchResultDto';
import WorkTaskTemplateDetailsHeader from '@/components/WorkTaskTemplateDetailsHeader.vue';
const userStore = useUserStore();

// Form
let isLoading = ref<boolean>(false);
const snackbar = ref({
    show: false,
    text: '',
});
const deleteWorkTaskTemplateModalStore = useDeleteWorkTaskTemplateModalStore();
const isConfirmed = ref<boolean>(false);
const workTaskTemplate = ref<WorkTaskTemplateSearchResultDto | null>(null);

// Services
const apiService = inject('apiService') as ApiService;

const userHasAccess = ref<boolean>(userStore.hasRoleLevel(RoleCode.PlansAndTemplates, 3));

const numberOfWorkTaskPlans = computed(() => workTaskTemplate.value?.numberOfWorkTaskPlans);

/**
 * Load data for the modal.
 */
const loadWorkTaskTemplateData = async () => {
    isLoading.value = true;

    // Reset form
    resetForm();

    // If a work task ID was specified
    if (deleteWorkTaskTemplateModalStore.workTaskTemplateId !== null) {
        // Build search request using the work task template ID
        const searchRequest: SearchWorkTaskTemplatesRequest = {
            searchParameters: new WorkTaskTemplateSearchParametersDto({
                workTaskTemplateId: deleteWorkTaskTemplateModalStore.workTaskTemplateId,
            }),
        };

        // Get work task template details
        const searchResults = (await apiService.post(
            'search/work-task-templates',
            searchRequest,
        )) as SearchWorkTaskTemplatesResponse;

        if (searchResults.workTaskTemplates.length == 1) {
            // Store search result
            workTaskTemplate.value = searchResults.workTaskTemplates[0];
        }
    }

    isLoading.value = false;
};

/**
 * Close the modal.
 */
const close = () => {
    deleteWorkTaskTemplateModalStore.close();
};

/**
 * Save the modal.
 */
const confirmDelete = async () => {
    if (workTaskTemplate.value == null) return;

    if (!userHasAccess.value) return;
    isLoading.value = true;

    try {
        // Call API to delete template
        const response = await apiService.post(
            'work-task-templates/delete-work-task-template',
            workTaskTemplate.value.workTaskTemplateId,
        );

        if (response) {
            // Show success feedback to user
            snackbar.value.show = true;
            snackbar.value.text = i18n.global.t('DeleteWorkTaskTemplate_Success');
            deleteWorkTaskTemplateModalStore.deletedCounter++;

            // Close modal
            deleteWorkTaskTemplateModalStore.close();
        } else {
            // Show fail feedback to user
            snackbar.value.show = true;
            snackbar.value.text = i18n.global.t('ErrorGeneric');
            isLoading.value = false;
        }
    } catch (ex: unknown) {
        // Show fail feedback to user
        snackbar.value.show = true;
        snackbar.value.text = i18n.global.t('ErrorGeneric');
        isLoading.value = false;
    }
};

onUnmounted(() => {
    deleteWorkTaskTemplateModalStore.close();
});

/**
 * Reset the form to its default state.
 */
const resetForm = () => {
    workTaskTemplate.value = null;
    isConfirmed.value = false;
};

/**
 * Watch for changes to workTaskTemplateId.
 */
watch(
    () => deleteWorkTaskTemplateModalStore.isVisible,
    async (isVisible) => {
        if (isVisible) {
            await loadWorkTaskTemplateData();
        }
    },
);
</script>

<style lang="scss" scoped>
@import '@/assets/scss/swatches.scss';

.permission-denied {
    background-color: rgba($swatchRED, 0.2) !important;
}

.complete-confirmation {
    background-color: rgba($swatchGRN, 0.2) !important;

    .confirm-header {
        background-color: rgba($swatchA5, 0.5);
        font-size: large;
    }

    .confirm-checkbox {
        font-size: 18pt;
    }
}
</style>
