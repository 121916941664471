<template>
    <v-dialog v-model="viewObservationModalStore.isVisible" style="width: 90vw">
        <v-card style="overflow-y: hidden">
            <v-toolbar color="swatchA2">
                <v-toolbar-title>
                    <FontAwesomeIcon :icon="['fal', 'binoculars']" size="xl" class="mr-2" />
                    {{ $t('ViewObservation') }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <CloseModalButton @click="close" />
            </v-toolbar>

            <LoadingSymbol v-if="isLoading || localObservationId == null" />
            <v-form v-else-if="localObservation" ref="form" v-model="valid" class="pa-4" style="overflow-y: scroll">
                <v-row justify="space-between">
                    <v-col cols="auto">
                        <ObservationTypeIcons
                            iconHeight="40"
                            iconWidth="40"
                            iconSize="24"
                            :observationType="localObservation.observationTypeCode" />
                    </v-col>
                    <v-col v-if="recordedDate" class="mt-2">
                        <FontAwesomeIcon class="swatchG3" :icon="['fas', 'calendar-day']" size="xl" />
                        <span class="pl-2 swatchA1">{{ recordedDate }}</span>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="auto" class="mt-2">
                        <ObservationStatusChip
                            :observation-status-code="localObservation.observationStatusCode"
                            width="80px"
                            height="25px"
                            fontSize="15px" />
                    </v-col>
                    <v-col cols="auto" class="mt-1">
                        <v-sheet>
                            <v-menu open-on-hover>
                                <template v-slot:activator="{props}">
                                    <v-btn class="v-btn--custom" v-bind="props">
                                        <template v-slot:append>
                                            <FontAwesomeIcon :icon="['fas', 'caret-down']" size="xl" />
                                        </template>
                                        {{ $t('Operations') }}
                                    </v-btn>
                                </template>

                                <v-list>
                                    <v-list-item>
                                        <v-list-item-title>
                                            <v-btn
                                                @click="
                                                    manageObservationModalStore.open(localObservation.observationId)
                                                ">
                                                {{ $t('ModifyObservation') }}
                                            </v-btn>
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item v-if="userStore.hasRoleLevel(RoleCode.Observations, 3)">
                                        <v-list-item-title>
                                            <v-btn @click="scrollToElement">
                                                {{ $t('DeleteObservation') }}
                                            </v-btn>
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item v-if="localObservation.farmSiteId && localObservation.farmFieldId">
                                        <v-list-item-title>
                                            <v-btn
                                                @click.prevent.stop="
                                                    viewOnMap(localObservation.farmSiteId, localObservation.farmFieldId)
                                                ">
                                                {{ $t('SelectField') }}
                                            </v-btn>
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-sheet>
                    </v-col>
                </v-row>
                <v-row class="pa-3"
                    ><div class="bold swatchA1 slightlyLarger">"{{ localObservation.observationTitle }}"</div>
                    <IdIcon v-if="localObservation.observationId">{{ localObservation.observationId }}</IdIcon></v-row
                >
                <div class="title pt-3 pb-3">
                    <span class="space-between-div">
                        <div class="farm-field-title">
                            <FarmSiteChip
                                v-if="associatedSiteAbbreviation && associatedSiteAbbreviation != ''"
                                :farm-site-abbreviation="associatedSiteAbbreviation"
                                :farm-site-name="associatedSiteName" />
                            <span class="bold swatchA1 slightlyLarger pl-5" v-if="localObservation.farmFieldCode"
                                >{{
                                    localObservation.farmFieldCode +
                                    (localObservation.farmFieldCode && localObservation.farmFieldName ? ' - ' : '') +
                                    localObservation.farmFieldName
                                }}
                            </span>

                            <div class="farm-field-icons pl-5">
                                <CropIcon
                                    v-if="localObservation.farmFieldPrimaryCropcode"
                                    :cropCode="localObservation.farmFieldPrimaryCropcode"
                                    iconHeight="45"
                                    iconWidth="45"
                                    iconSize="25" />

                                <FarmFieldAgeIcon
                                    v-if="localObservation.farmFieldCropYear"
                                    iconHeight="45"
                                    iconWidth="45"
                                    :fieldAge="localObservation.farmFieldCropYear.toString()" />
                                <v-btn
                                    class="ml-2 rounded-button"
                                    v-if="localObservation.farmFieldId"
                                    @click="viewFarmFieldModalStore.open(localObservation.farmFieldId)">
                                    <template v-slot:prepend>
                                        <FontAwesomeIcon :icon="['fal', 'arrow-circle-right']" size="xl" />
                                    </template>
                                    {{ $t('View') }}
                                </v-btn>
                            </div>
                            <div>
                                <FieldSizeChip
                                    class="pt-1 mr-5"
                                    v-if="localObservation.farmFieldSize || localObservation.farmFieldSize == 0"
                                    :farmFieldSize="localObservation.farmFieldSize" />
                            </div>
                        </div>
                    </span>
                </div>

                <!-- Location -->
                <v-row align="center">
                    <v-col cols="2" align-self="center">{{ $t('Location') }}</v-col>

                    <v-col cols="10" class="swatchA1 bold pl-0 ml-0" v-if="localObservation.observationLocation">
                        {{
                            localObservation.observationLocation.coordinates[0] +
                            ', ' +
                            localObservation.observationLocation.coordinates[1]
                        }}
                        <v-btn
                            class="rounded-button location-button ml-5"
                            icon
                            @click.stop="selectObservation"
                            v-if="localObservation.farmSiteId && localObservation.observationId">
                            <FontAwesomeIcon :icon="['fal', 'location-dot']" size="xl"
                        /></v-btn>
                    </v-col>
                </v-row>

                <!-- Details -->
                <v-row align="center">
                    <v-col cols="2">{{ $t('Details') }}</v-col>
                    <v-col cols="10" class="swatchA1 bold pl-0 ml-0">{{ localObservation.observationComments }}</v-col>
                </v-row>

                <!-- Attachments -->
                <v-row align="center" class="pb-5">
                    <v-col cols="2">{{ $t('Attachments') }}</v-col>
                    <v-col cols="10" class="pl-0 ml-0">
                        <v-row v-if="imageArtifacts && imageArtifacts.length > 0">
                            <v-col cols="2" v-for="artifact in imageArtifacts" :key="artifact.artifactId">
                                <ImageCard
                                    :imageUrl="artifact.artifactUrl"
                                    :image-title="artifact.artifactDisplayName"
                                    :display-image-name="true" />
                            </v-col>
                        </v-row>
                        <v-row v-if="fileArtifacts && fileArtifacts.length > 0">
                            <v-col cols="2" v-for="artifact in fileArtifacts" :key="artifact.artifactId">
                                <FileTypeButton
                                    :key="artifact.artifactId"
                                    :url="artifact.artifactUrl"
                                    :display-name="artifact.artifactDisplayName"
                                    :file-type-code="artifact.artifactFileTypeCode" />
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <v-expansion-panels v-model="defaultPanel">
                    <v-expansion-panel>
                        <v-expansion-panel-title class="expansion-header">
                            <FontAwesomeIcon :icon="['fas', 'timeline-arrow']" size="xl" />
                            <span class="pl-5 slightlyLarger">{{ $t('Activity') }}</span></v-expansion-panel-title
                        >
                        <v-expansion-panel-text class="farm-block-expansion">
                            <div ref="statusSelection" v-if="isUserHasActionAccess">
                                <v-row class="swatchG4BG swatchWHT ma-1 pl-4 pt-2 expandable-header slightlyLarger"
                                    >{{ $t('NEWAction') }}
                                </v-row>

                                <div class="pa-3">
                                    <v-row align="center">
                                        <v-col cols="1">{{ $t('OnBehalfOf') }}</v-col>
                                        <v-col cols="1" class="d-flex justify-end"
                                            ><HelpIcon :help-text="$t('ManageObservation_User_HelpText')"
                                        /></v-col>
                                        <v-col cols="10" class="swatchA1 bold pl-0 ml-0">
                                            <v-autocomplete
                                                v-model="newAction.observationActionUserId"
                                                :items="users"
                                                :disabled="!isUserHasBehalfAccess"
                                                :rules="[requiredRule]"
                                                hide-details
                                                clearable
                                                small-chips
                                                item-title="userFullName"
                                                item-value="userId">
                                                <template #selection="{item}">
                                                    <UserChip
                                                        :user="
                                                            UserHelper.userChipType(
                                                                item.value,
                                                                userStore.user &&
                                                                    item.value &&
                                                                    item.value === userStore.user.userId
                                                                    ? $t('Myself')
                                                                    : item.title,
                                                            )
                                                        " />
                                                </template>
                                            </v-autocomplete>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="1">{{ $t('Details') }}</v-col>
                                        <v-col cols="1" class="d-flex justify-end"
                                            ><HelpIcon :help-text="$t('ActionObservation_Details_HelpText')"
                                        /></v-col>
                                        <v-col cols="10" class="swatchA1 bold pl-0 ml-0">
                                            <v-textarea
                                                v-model="newAction.observationActionComments"
                                                :rules="[requiredRule]"
                                                variant="outlined"
                                                clearable
                                                rows="3"
                                                auto-grow />
                                        </v-col>
                                    </v-row>
                                    <v-row align="center">
                                        <v-col cols="1">{{ $t('Attachments') }}</v-col>
                                        <v-col cols="1" class="d-flex justify-end"
                                            ><HelpIcon :help-text="$t('ActionObservation_Attachments_HelpText')"
                                        /></v-col>
                                        <v-col cols="10" class="swatchA1 bold pl-0 ml-0">
                                            <v-file-input
                                                v-model="newAction.artifacts"
                                                :rules="[uploadedFileRule]"
                                                :label="$t('FileUploads')"
                                                prepend-inner-icon="mdi-paperclip"
                                                :prepend-icon="null"
                                                chips
                                                multiple
                                                clearable
                                                variant="outlined"
                                                density="compact"
                                                hide-details="auto"
                                                class="swatchA1">
                                                <template v-slot:selection="{fileNames}">
                                                    <template
                                                        v-for="(artifact, index) in newAction.artifacts"
                                                        :key="index">
                                                        <v-chip
                                                            class="me-2 swatchA3BG swatchA3txt"
                                                            size="small"
                                                            label
                                                            rounded="0">
                                                            {{
                                                                (artifact as any).artifactDisplayName ||
                                                                fileNames[index]
                                                            }}
                                                        </v-chip>
                                                    </template>
                                                </template>
                                            </v-file-input>
                                        </v-col>
                                    </v-row>
                                    <v-row align="center">
                                        <v-col cols="1">{{ $t('ChangeStatus') }}</v-col>
                                        <v-col cols="1" class="d-flex justify-end"
                                            ><HelpIcon :help-text="$t('ActionObservation_ActionStatus_HelpText')"
                                        /></v-col>
                                        <v-col cols="4" class="pl-0 ml-0">
                                            <v-select
                                                v-model="newAction.observationStatusCode"
                                                :rules="[requiredRule]"
                                                required
                                                :items="observationStatuses"
                                                item-value="observationStatusCode"
                                                width="140px">
                                                <template #item="{props, item}">
                                                    <v-list-item v-bind="props" :title="null" width="450px">
                                                        <div style="display: inline-flex; align-items: center">
                                                            <ObservationStatusChip
                                                                :observation-status-code="
                                                                    item.raw.observationStatusCode
                                                                "
                                                                width="80px"
                                                                height="25px"
                                                                fontSize="15px" />
                                                            <span
                                                                class="pl-3"
                                                                style="font-size: small; vertical-align: middle">
                                                                {{ item.raw.observationStatusActionName }}</span
                                                            >
                                                        </div>
                                                    </v-list-item>
                                                </template>
                                                <template v-slot:selection="{item}">
                                                    <ObservationStatusChip
                                                        :observation-status-code="item.raw.observationStatusCode"
                                                        width="80px"
                                                        height="25px"
                                                        fontSize="15px" />
                                                </template>
                                            </v-select>
                                        </v-col>

                                        <v-col cols="6" class="d-flex justify-end"
                                            ><v-btn @click="close" class="rounded-button">
                                                <template v-slot:prepend>
                                                    <FontAwesomeIcon :icon="['fal', 'arrow-rotate-left']" size="xl" />
                                                </template>
                                                {{ $t('Cancel') }}
                                            </v-btn>
                                            <v-btn
                                                @click="save"
                                                :disabled="isLoading"
                                                class="ml-3 v-btn--custom success-button"
                                                color="success">
                                                <template v-slot:prepend>
                                                    <FontAwesomeIcon :icon="['fal', 'save']" size="xl" />
                                                </template>
                                                {{ $t('Save') }}
                                            </v-btn></v-col
                                        >
                                    </v-row>
                                </div>
                            </div>

                            <v-row class="swatchG4BG swatchWHT ma-1 pl-4 pt-2 expandable-header slightlyLarger">
                                {{ $t('PastActivity') }}</v-row
                            >
                            <div
                                class="pa-2"
                                v-for="(observationAction, index) in localObservation.observationActions"
                                :key="observationAction.observationActionId">
                                <v-row align="center">
                                    <v-col cols="2" v-if="observationAction.observationActionUserId">
                                        <UserChip
                                            :user="
                                                UserHelper.userChipType(
                                                    observationAction.observationActionUserId,
                                                    UserHelper.userFullName(
                                                        observationAction.observationActionUserGivenName,
                                                        observationAction.observationActionUserSurname,
                                                    ),
                                                )
                                            " />
                                    </v-col>
                                    <v-col cols="3" v-if="observationAction.observationActionDate"
                                        ><FontAwesomeIcon class="swatchG3" :icon="['fas', 'calendar-day']" size="xl" />
                                        <span class="pl-2 swatchA1">{{
                                            formatDate(observationAction.observationActionDate)
                                        }}</span></v-col
                                    >
                                    <v-col cols="7" class="d-flex justify-end">
                                        <ObservationStatusChip
                                            v-if="observationAction.observationStatusCode"
                                            :observation-status-code="observationAction.observationStatusCode"
                                            width="80px"
                                            height="25px"
                                            fontSize="15px" />
                                    </v-col>
                                </v-row>
                                <v-row align="center" class="ml-1 swatchA1">
                                    {{ observationAction.observationActionComments }}
                                </v-row>

                                <v-row
                                    align="center"
                                    v-if="
                                        observationAction.artifacts &&
                                        imageFilter(observationAction.artifacts).length > 0
                                    ">
                                    <v-col
                                        cols="2"
                                        v-for="artifact in imageFilter(observationAction.artifacts)"
                                        :key="artifact.artifactId">
                                        <ImageCard
                                            :imageUrl="artifact.artifactUrl"
                                            :image-title="artifact.artifactDisplayName"
                                            :display-image-name="true" />
                                    </v-col>
                                </v-row>
                                <v-row
                                    align="center"
                                    v-if="
                                        observationAction.artifacts &&
                                        fileFilter(observationAction.artifacts).length > 0
                                    ">
                                    <v-col
                                        cols="2"
                                        v-for="artifact in fileFilter(observationAction.artifacts)"
                                        :key="artifact.artifactId">
                                        <FileTypeButton
                                            :key="artifact.artifactId"
                                            :url="artifact.artifactUrl"
                                            :display-name="artifact.artifactDisplayName"
                                            :file-type-code="artifact.artifactFileTypeCode" />
                                    </v-col>
                                </v-row>

                                <div v-if="index !== localObservation.observationActions.length - 1">
                                    <div class="pb-5"></div>
                                    <v-divider thickness="3" />
                                </div>
                            </div>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-form>
            <v-card-actions class="justify-space-between swatchG9BG" v-if="!isLoading && localObservation != null">
                <v-btn @click="close">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'arrow-rotate-left']" size="xl" />
                    </template>
                    {{ $t('Cancel') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar.show" timeout="4000">
        {{ snackbar.text }}
    </v-snackbar>
</template>
<script setup lang="ts">
import {ref, inject, watch, computed, onUnmounted} from 'vue';
import FieldSizeChip from '@/components/other-chips/FieldSizeChip.vue';
import FarmSiteChip from '@/components/other-chips/FarmSiteChip.vue';
import FarmFieldAgeIcon from '@/components/FarmFieldAgeIcon.vue';
import {useViewFarmFieldModalStore} from '@/stores/modals/view-farm-field-modal-store';
const viewFarmFieldModalStore = useViewFarmFieldModalStore();
import {useHomeDashboardStore} from '@/stores/home-dashboard-store';
import {useFarmSites} from '@/composables/data-source/farm-sites';
const {getFarmSites, farmSites} = useFarmSites();
import {useViewObservationModalStore} from '@/stores/modals/view-observation-modal-store';
const viewObservationModalStore = useViewObservationModalStore();
import {useFarmStore} from '@/stores/farm-store';
const farmStore = useFarmStore();
import {useAdvancedSearchModalStore} from '@/stores/modals/advanced-search-modal-store';
const advancedSearchModalStore = useAdvancedSearchModalStore();
import router from '@/router';
import {useUserStore} from '@/stores/user-store';
const userStore = useUserStore();
import ApiService from '@/services/api-service';
import {SearchObservationsResponse} from '@/models/api/responses/search/SearchObservationsResponse';
import {ObservationSearchResultDto} from '@/models/data-transfer-objects/search/observation-search/ObservationSearchResultDto';
import {format} from 'date-fns';
import ObservationTypeIcons from '@/components/ObservationTypeIcons.vue';
import ObservationStatusChip from '@/components/ObservationStatusChip.vue';
import ImageCard from '@/components/ImageCard.vue';
import {ArtifactFileTypeCode} from '@/enums/artifact-file-type-code';
import FileTypeButton from '@/components/FileTypeButton.vue';
import UserChip from '@/components/UserChip.vue';
import {ArtifactDto} from '@/models/data-transfer-objects/system/ArtifactDto';
import {ActionObservationForm} from '@/models/observations/ActionObservationForm';
import {useUsers} from '@/composables/data-source/users';
const {getUsers, users} = useUsers();
import Validation from '@/helpers/ValidationHelper';
import {useObservationStatuses} from '@/composables/data-source/observation-statuses';
const {localObservationStatuses} = useObservationStatuses();
import {UploadedArtifactDto} from '@/models/data-transfer-objects/system/UploadedArtifactDto';
import {ArtifactFolder} from '@/enums/artifact-folder';
import {ArtifactTypeCode} from '@/enums/artifact-type-code';
import {ObservationActionTypeCode} from '@/enums/observation-action-type-code';
import {SaveObservationActionRequest} from '@/models/api/requests/observation/SaveObservationActionRequest';
import FileUploadHelper from '@/helpers/FileUploadHelper';
import i18n from '@/i18n';
import {RoleCode} from '@/enums/role-code';
import {useObservationTypes} from '@/composables/data-source/observation-types';
const {acknowledgedObservationTypeCodes} = useObservationTypes();
import {ObservationStatusCode} from '@/enums/observaton-status-code';
import {ObservationStatusOptionItem} from '@/models/observations/ObservationStatusOptionItem';
import UserHelper from '@/helpers/UserHelper';
import {useManageObservationModalStore} from '@/stores/modals/manage-observation-modal-store';

const manageObservationModalStore = useManageObservationModalStore();

// Stores
const homeDashboardStore = useHomeDashboardStore();

const apiService = inject('apiService') as ApiService;

// view field on map
async function viewOnMap(farmSiteId: number | null, farmFieldId: number | null) {
    if (farmSiteId && farmFieldId) {
        if (farmStore.selectedSiteId !== farmSiteId) {
            farmStore.selectedSiteId = farmSiteId;
            if (userStore.isLoggedIn && userStore.user) userStore.user.farmSiteId = farmSiteId;
            setTimeout(() => {
                homeDashboardStore.farmFieldIdToFocusOn = farmFieldId;
            }, 1000);
        } else homeDashboardStore.farmFieldIdToFocusOn = farmFieldId;

        viewObservationModalStore.close();
        advancedSearchModalStore.close();

        if (!homeDashboardStore.isHomeDashboardOpen) {
            router.push({name: 'HomeDashboard'});
        }
    }
}

const isLoading = ref<boolean>(false);
const associatedSiteAbbreviation = ref<string>('');
const associatedSiteName = ref<string>('');
const localObservationId = ref<number | null>(null);
const localObservation = ref<ObservationSearchResultDto | null>(null);
const newAction = ref<ActionObservationForm>(new ActionObservationForm());
const uploadedFileRule = Validation.createRule_UploadedFile();
const requiredRule = Validation.createRule_Required();
const defaultPanel = ref<number | null>(0);
const form = ref();
const valid = ref<boolean>(true);
const snackbar = ref({
    show: false,
    text: '',
});

const observationStatuses = ref();

const formatDate = (date: Date | null): string | null => {
    if (!date) return null;
    return format(date, 'd MMM yyyy HH:mm');
};

const recordedDate = computed(() => {
    if (!localObservation.value || !localObservation.value.recordedDate) return null;
    return formatDate(localObservation.value.recordedDate);
});

const imageArtifacts = computed(() =>
    localObservation.value?.artifacts.filter((e) => e.artifactFileTypeCode === ArtifactFileTypeCode.Image),
);
const fileArtifacts = computed(() =>
    localObservation.value?.artifacts.filter((e) => e.artifactFileTypeCode !== ArtifactFileTypeCode.Image),
);

const imageFilter = (items: ArtifactDto[]) =>
    items.filter((e) => e.artifactFileTypeCode === ArtifactFileTypeCode.Image);
const fileFilter = (items: ArtifactDto[]) => items.filter((e) => e.artifactFileTypeCode !== ArtifactFileTypeCode.Image);
const isUserHasBehalfAccess = computed<boolean>(() => userStore.hasRoleLevel(RoleCode.Observations, 2));
const isUserHasActionAccess = computed<boolean>(() => userStore.hasRoleLevel(RoleCode.Observations, 1));
const isUserHasDeleteAccess = computed<boolean>(() => userStore.hasRoleLevel(RoleCode.Observations, 3));

/**
 * Load data for the modal.
 */
const loadData = async (observationId: number) => {
    isLoading.value = true;

    // Reset form
    resetForm();

    // Set data
    localObservationId.value = observationId;
    await getFarmSites();
    await getUsers();

    // Get observation details
    const searchResults: SearchObservationsResponse = await apiService.post('search/observations', {
        observationId: observationId,
        includeArtifacts: true,
        includeObservationActions: true,
    });

    if (searchResults.observations.length === 1) {
        localObservation.value = searchResults.observations[0];

        if (viewObservationModalStore.activityComment != null) {
            newAction.value.observationActionComments = viewObservationModalStore.activityComment;
        }

        if (localObservation.value) {
            let localStatus = localObservationStatuses.value;

            if (!isUserHasBehalfAccess.value) {
                localStatus = localStatus.filter(
                    (e) =>
                        e.observationStatusCode !== ObservationStatusCode.Cancelled &&
                        e.observationStatusCode !== ObservationStatusCode.Archived,
                );
            } else if (isUserHasBehalfAccess.value && !isUserHasDeleteAccess.value) {
                localStatus = localStatus.filter((e) => e.observationStatusCode !== ObservationStatusCode.Cancelled);
            }

            newAction.value.observationStatusCode = localObservation.value.observationStatusCode;
            if (acknowledgedObservationTypeCodes.value.includes(localObservation.value.observationTypeCode)) {
                //Correct invalid status
                if (newAction.value.observationStatusCode === ObservationStatusCode.InProgress)
                    newAction.value.observationStatusCode = ObservationStatusCode.Acknowledged;
                if (newAction.value.observationStatusCode === ObservationStatusCode.Resolved)
                    newAction.value.observationStatusCode = ObservationStatusCode.Archived;

                if (localObservation.value.observationStatusCode === 'Open')
                    newAction.value.observationStatusCode = ObservationStatusCode.Acknowledged;
                observationStatuses.value = localStatus.filter(
                    (e) =>
                        e.observationStatusCode !== ObservationStatusCode.Open &&
                        e.observationStatusCode !== ObservationStatusCode.InProgress &&
                        e.observationStatusCode !== ObservationStatusCode.Resolved,
                );
            } else {
                //Correct invalid status
                if (newAction.value.observationStatusCode === ObservationStatusCode.Acknowledged)
                    newAction.value.observationStatusCode = ObservationStatusCode.InProgress;
                if (newAction.value.observationStatusCode === ObservationStatusCode.Archived)
                    newAction.value.observationStatusCode = ObservationStatusCode.Resolved;

                if (localObservation.value.observationStatusCode === 'Open')
                    newAction.value.observationStatusCode = ObservationStatusCode.InProgress;
                observationStatuses.value = localStatus.filter(
                    (e) =>
                        e.observationStatusCode !== ObservationStatusCode.Open &&
                        e.observationStatusCode !== ObservationStatusCode.Acknowledged &&
                        e.observationStatusCode !== ObservationStatusCode.Archived,
                );
            }

            if (
                (newAction.value.observationStatusCode === ObservationStatusCode.Cancelled &&
                    !isUserHasDeleteAccess.value) ||
                (newAction.value.observationStatusCode === ObservationStatusCode.Archived &&
                    !isUserHasBehalfAccess.value)
            ) {
                newAction.value.observationStatusCode = ObservationStatusCode.Acknowledged;
            }

            observationStatuses.value = observationStatuses.value.map(
                (e: ObservationStatusOptionItem): ObservationStatusOptionItem => ({
                    ...e,
                    observationStatusActionName:
                        newAction.value.observationStatusCode === e.observationStatusCode
                            ? e.observationStatusActionNameNoChange
                            : e.observationStatusActionNameWithChange,
                }),
            );

            const siteItem = farmSites.value.find((e) => e.farmSiteId === localObservation.value!.farmSiteId);
            if (siteItem) {
                const farmSiteAbbreviationAndNameTokens = siteItem.farmSiteAbbreviationAndName.split('-');
                associatedSiteAbbreviation.value = farmSiteAbbreviationAndNameTokens[0].trim();
                if (farmSiteAbbreviationAndNameTokens.length > 1)
                    associatedSiteName.value = farmSiteAbbreviationAndNameTokens[1].trim();
            }
        }

        // Set status to cancelled if opened through delete
        if (viewObservationModalStore.openDelete) {
            setTimeout(() => {
                scrollToElement();
            }, 500);
        }
    }

    isLoading.value = false;
};

/**
 * Save the modal.
 */
const save = async () => {
    // Perform final client side validation of form
    await form.value.validate();

    // If form is valid
    if (valid.value && localObservation.value) {
        let snackbarMessage = '';
        isLoading.value = true;

        try {
            const uploadedArtifacts: UploadedArtifactDto[] = [];
            if (newAction.value.haveArtifactsBeenUpdated) {
                // Build artifacts object
                newAction.value.artifacts.forEach((artifact) => {
                    const uploadedArtifact = new UploadedArtifactDto({
                        file: artifact,
                        artifactFolder: ArtifactFolder.Observations,
                        artifactTypeCode: ArtifactTypeCode.ObservationActionAttachment,
                    });

                    uploadedArtifacts.push(uploadedArtifact);
                });
            }

            // Build API request
            const saveActionRequest = {
                observationId: localObservation.value.observationId,
                observationActionTypeCode: ObservationActionTypeCode.Actioned,
                observationStatusCode:
                    newAction.value.observationStatusCode === localObservation.value?.observationStatusCode
                        ? null
                        : newAction.value.observationStatusCode,
                observationActionUserId: newAction.value.observationActionUserId,
                observationActionComments: newAction.value.observationActionComments,
                artifacts: uploadedArtifacts,
                haveArtifactsBeenUpdated: newAction.value.haveArtifactsBeenUpdated,
            } as SaveObservationActionRequest;

            // Call API to save observation
            await apiService.post('observations/save-action', FileUploadHelper.createFormDataObject(saveActionRequest));

            // Set snackbar message
            snackbarMessage = i18n.global.t('ActionObservation_Success');

            // Show success feedback to user
            snackbar.value.show = true;
            snackbar.value.text = snackbarMessage;
            viewObservationModalStore.savedCounter++;

            // Close modal
            viewObservationModalStore.close();
        } catch (ex: unknown) {
            // Show fail feedback to user
            snackbar.value.show = true;
            snackbar.value.text = i18n.global.t('ErrorGeneric');
            isLoading.value = false;
        }
    } else {
        // Show validation error feedback to user
        snackbar.value.show = true;
        snackbar.value.text = i18n.global.t('ErrorValidation');
    }
};

const resetForm = () => {
    localObservationId.value = null;
    localObservation.value = null;
    newAction.value = new ActionObservationForm();
    newAction.value.observationActionUserId = userStore.user && userStore.user.userId ? userStore.user.userId : null;
};

watch(
    [() => viewObservationModalStore.isVisible, () => manageObservationModalStore.savedCounter],
    async (isVisible) => {
        if (isVisible && viewObservationModalStore.observationId !== null) {
            await loadData(viewObservationModalStore.observationId);
        }
    },
);

/**
 * Close the modal.
 */
const close = () => {
    viewObservationModalStore.close();
};

onUnmounted(() => {
    resetForm();
    viewObservationModalStore.close();
});

// Select observation on home dashboard
const selectObservation = () => {
    // If observation details are available
    if (localObservation.value && localObservation.value.farmSiteId && localObservation.value.observationId) {
        // If the farm site of the observation is different to the farm site currently selected by the user
        if (farmStore.selectedSiteId !== localObservation.value.farmSiteId) {
            // Update user's farm site
            farmStore.selectedSiteId = localObservation.value.farmSiteId;
            if (userStore.isLoggedIn && userStore.user) userStore.user.farmSiteId = localObservation.value.farmSiteId;

            // Add delay to account for the changing of the user's farm site
            setTimeout(() => {
                // If observation details are still available after the delay
                if (localObservation.value && localObservation.value.observationId) {
                    // Update observation ID to focus on
                    homeDashboardStore.observationIdToFocusOn = localObservation.value.observationId;
                }
            }, 1000);
        } else {
            // Update observation ID to focus on
            homeDashboardStore.observationIdToFocusOn = localObservation.value.observationId;
        }

        // Close modals
        advancedSearchModalStore.close();
        viewObservationModalStore.close();

        // Redirect to Home Dashboard if not already there
        if (!homeDashboardStore.isHomeDashboardOpen) {
            router.push({name: 'HomeDashboard'});
        }
    }
};

// Update flag to indicate that artifacts have been updated.
watch(
    () => newAction.value.artifacts,
    () => {
        if (!isLoading.value) {
            newAction.value.haveArtifactsBeenUpdated = true;
        }
    },
);

const statusSelection = ref<HTMLElement | null>(null);

const scrollToElement = () => {
    if (statusSelection.value instanceof HTMLElement) {
        statusSelection.value.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
        setTimeout(() => {
            newAction.value.observationStatusCode = ObservationStatusCode.Cancelled;
        }, 500);
    }
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/swatches.scss';

.rounded-button {
    border: 1px solid $swatchG5 !important;
    background-color: $swatchGA !important;
    color: $swatchG1 !important;
    border-radius: 5px !important;
}

.success-button {
    color: $swatchWHT !important;
    border-radius: 5px !important;
}

.success-button:hover {
    opacity: 0.7 !important;
}

.expandable-header {
    height: 3rem;
}

.location-button {
    max-width: 2.5rem !important;
    max-height: 2.5rem !important;
}
.farm-field-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
}

.farm-field-icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    gap: 0.5rem;
}
</style>
