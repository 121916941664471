<template>
    <v-select
        class="farm-selector"
        theme="farm"
        :items="items"
        item-value="farmSiteId"
        item-title="farmSiteAbbreviationAndName"
        v-model="selectedSiteId">
    </v-select>
</template>

<script lang="ts">
import {defineComponent, inject, watch, ref} from 'vue';
import ApiService from '@/services/api-service';
import {useUserStore} from '@/stores/user-store';
import {useFarmStore} from '@/stores/farm-store';
import {GetFarmSitesResponse} from '@/models/api/responses/data-source/GetFarmSitesResponse';

export default defineComponent({
    props: {
        items: {type: Array<GetFarmSitesResponse>, required: true},
    },
    setup(props) {
        const apiService = inject('apiService') as ApiService;
        const userStore = useUserStore();
        const farmStore = useFarmStore();
        const defaultSiteId =
            farmStore.farmSites && farmStore.farmSites.length > 0 ? farmStore.farmSites[0].farmSiteId : undefined;

        // Get select site ID
        const selectedSiteId = ref<number | undefined>(defaultSiteId);

        // If the user's selected site ID (in the database) exists in the list
        if (
            userStore.user?.farmSiteId != null &&
            props.items.some((item) => item.farmSiteId == userStore.user?.farmSiteId)
        ) {
            // Select user's chosen site
            selectedSiteId.value = userStore.user?.farmSiteId;
            farmStore.selectedSiteId = selectedSiteId.value;
        } else {
            // Clear user's chosen site because it does not exist in the list
            if (userStore.user) userStore.user.farmSiteId = undefined;
        }

        // If the user does not have a selected site, update their preference to the default site
        if (defaultSiteId && userStore.isLoggedIn && userStore.user && userStore.user.farmSiteId === undefined) {
            farmStore.updateUserPreferredSite(apiService, defaultSiteId, userStore.user.userId).then((response) => {
                if (response && userStore.user) userStore.user.farmSiteId = defaultSiteId;
            });
        }

        watch(selectedSiteId, async (value) => {
            if (value && userStore.isLoggedIn && userStore.user && userStore.user.farmSiteId !== value) {
                const response = await farmStore.updateUserPreferredSite(apiService, value, userStore.user.userId);
                if (response) {
                    userStore.user.farmSiteId = value;
                } else {
                    selectedSiteId.value = userStore.user.farmSiteId;
                }
            } else if (userStore.isLoggedIn && userStore.user) {
                selectedSiteId.value = userStore.user.farmSiteId;
            }
            farmStore.selectedSiteId = selectedSiteId.value;
        });

        watch(
            () => farmStore.selectedSiteId,
            (val) => {
                if ((val || val === 0) && selectedSiteId.value != val) {
                    selectedSiteId.value = val;
                }
            },
        );

        return {
            selectedSiteId,
        };
    },
});
</script>

<style lang="scss">
@import '@/assets/scss/swatches.scss';
.divHeaderApp .v-input {
    height: 30px;
    width: 20rem;
    position: relative;
    bottom: 5px;
}
.farm-selector .v-select__selection--comma,
.farm-selector .v-select__selection {
    color: $swatchWHT;
    font-size: calc(1rem + 2px);
    font-family: 'Open Sans Condensed', sans-serif;
}

.v-theme--farm .v-list-item-title {
    color: $swatchBLK;
    font-size: calc(1rem + 1px);
    font-family: 'Open Sans Light', sans-serif;
}

.v-theme--farm .v-list-item--active {
    background-color: $swatchB4;
}

.farm-selector .v-input__control {
    height: 40px;
    color: $swatchA4;
}

.farm-selector .v-field__append-inner {
    color: $swatchWHT;
}
</style>
