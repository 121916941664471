<template>
    <div>
        <VueDraggableNext
            v-model="workTasks"
            :group="'tasks'"
            :disabled="isDragDisabled"
            :move="onMove"
            @start="onDragStart"
            @end="homeDashboardStore.isTaskDragMode = false">
            <v-list-item
                v-for="workTask in workTasks"
                :key="workTask.workTaskId"
                rounded="true"
                class="list-item-container"
                style="padding: 5px"
                :class="getTaskClass(workTask)"
                @mouseenter="homeDashboardStore.onFarmFieldMouseOver(workTask.farmFieldId)"
                @mouseleave="homeDashboardStore.onFarmFieldMouseOut"
                @click="() => selectItem(workTask)"
                @dblclick="() => handleRowDoubleClick(workTask.workTaskId)">
                <div class="work-task-details">
                    <div style="width: 45px; padding-top: 3px">
                        <WorkTaskTypeIcon
                            :workTaskTypeCode="getNextTask(workTask.workTaskTypeCode)"
                            iconHeight="35"
                            iconWidth="35" />
                    </div>
                    <div style="display: flex; flex-direction: column">
                        <div class="flex-row">
                            <div class="task-type-code" :style="{width: isTabOne ? '215px' : '165px'}">
                                <span
                                    v-if="
                                        props.isTabOne && workTask.farmFieldName && workTask.farmFieldName.length < 15
                                    "
                                    >{{ `"${workTask.farmFieldName}": ` }}
                                </span>
                                <span :class="getTaskTypeColour(workTask.workTaskTypeCode)">
                                    {{ workTask.workTaskTypeName }}
                                </span>
                            </div>
                            <div>
                                <WorkTaskStatus :work-task="workTask" />
                            </div>
                        </div>
                        <div v-if="!isCompressedMode" class="flex-row">
                            <div style="width: 25px; height: 25px">
                                <WorkTaskDateIcon :work-task="workTask" />
                            </div>
                            <div class="task-date swatchA1" :style="{width: isTabOne ? '215px' : '165px'}">
                                <WorkTaskDate :work-task="workTask" date-format="d MMM yyyy" />
                            </div>
                            <div v-if="!isTabOne && workTask.isIncludedInFutureTasks && !workTask.isOverdue">
                                <WorkTaskDaysUntilDue :work-task="workTask" />
                            </div>
                        </div>
                        <div
                            v-if="!isCompressedMode"
                            class="task-instructions"
                            :style="{width: isTabOne ? '315px' : '255px'}">
                            <WorkTaskSummary :work-task="workTask"></WorkTaskSummary>
                        </div>
                        <div
                            v-if="
                                !isCompressedMode &&
                                workTask.workTaskUsers.length > 0 &&
                                (workTask.isIncludedInRecentTasks || workTask.isStarted)
                            "
                            style="min-height: 30px"
                            :style="{width: isTabOne ? '315px' : '255px'}">
                            <v-list density="compact" class="user-chip-list pa-0">
                                <v-list-item
                                    v-for="workTaskUser in workTask.workTaskUsers"
                                    :key="workTaskUser.userId"
                                    class="pa-0">
                                    <UserChip
                                        :user="
                                            UserHelper.userChipType(
                                                workTaskUser.userId,
                                                UserHelper.userFullName(
                                                    workTaskUser.userGivenName,
                                                    workTaskUser.userSurname,
                                                ),
                                            )
                                        " />
                                </v-list-item>
                            </v-list>
                        </div>
                    </div>
                </div>
            </v-list-item>
        </VueDraggableNext>
    </div>
</template>

<script setup lang="ts">
import {computed, ref} from 'vue';
import {WorkTaskSearchResultDto} from '@/models/data-transfer-objects/search/work-task-search/WorkTaskSearchResultDto';
import {WorkTaskTypeCode} from '@/enums/work-task-type-code';
import {SwatchCode} from '@/enums/swatch-code';
import UserChip from '@/components/UserChip.vue';
import WorkTaskDate from '@/components/work-tasks/WorkTaskDate.vue';
import WorkTaskDateIcon from '@/components/work-tasks/WorkTaskDateIcon.vue';
import WorkTaskStatus from '@/components/work-tasks/WorkTaskStatus.vue';
import WorkTaskDaysUntilDue from '@/components/work-tasks/WorkTaskDaysUntilDue.vue';
import WorkTaskSummary from '@/views/work-tasks/WorkTaskSummary.vue';
import {useHomeDashboardStore} from '@/stores/home-dashboard-store';
import {useViewWorkTaskModalStore} from '@/stores/modals/view-work-task-modal-store';
import UserHelper from '@/helpers/UserHelper';
import {VueDraggableNext} from 'vue-draggable-next';
import {SortableEvent} from 'sortablejs';
import {NavigatorFutureTaskList} from '@/enums/home-dashboard/navigator-future-task-list';

// Props
const props = withDefaults(
    defineProps<{
        workTasks: WorkTaskSearchResultDto[];
        isTabOne: boolean;
        isCompressedMode?: boolean;
        modelValue?: WorkTaskSearchResultDto | null;
        listName?: string | null;
        isDragDisabled?: boolean;
    }>(),
    {
        isCompressedMode: false,
        modelValue: null,
        listName: null,
        isDragDisabled: true,
    },
);

// Stores
const homeDashboardStore = useHomeDashboardStore();
const viewWorkTaskModalStore = useViewWorkTaskModalStore();

const workTasks = computed(() => {
    return props.workTasks;
});

// Emit event when tasks change
const emit = defineEmits(['work-task-item-selected']);

// Timeout for row clicks
const clickTimeout = ref<number | null>(null);

// Select item
const selectItem = async (item: WorkTaskSearchResultDto) => {
    // Clear the timeout if it exists to avoid triggering the single-click event
    if (clickTimeout.value) {
        clearTimeout(clickTimeout.value);
        clickTimeout.value = null;
    }

    // Set a timeout to wait for a possible double-click
    clickTimeout.value = window.setTimeout(() => {
        emit('work-task-item-selected', item);
        clickTimeout.value = null;
    }, 200);
};

// Check if item is selected
const isItemSelected = (item: WorkTaskSearchResultDto) => {
    return props.modelValue?.workTaskId == item.workTaskId;
};

// Handle double click row
const handleRowDoubleClick = (workTaskId: number) => {
    // Clear the timeout to prevent the single-click event from firing
    if (clickTimeout.value) {
        clearTimeout(clickTimeout.value);
        clickTimeout.value = null;
    }

    viewWorkTaskModalStore.open(workTaskId);
};

// Get task code from string
const getNextTask = (nextTask: string | undefined) => {
    return nextTask as WorkTaskTypeCode;
};

// Get the colour for a task type code
const getTaskTypeColour = (taskCode: string | undefined) => {
    let colourClass = 'swatchBLK';
    const workTaskTypeCode = taskCode as WorkTaskTypeCode;

    switch (workTaskTypeCode) {
        case WorkTaskTypeCode.Cultivate: {
            colourClass = SwatchCode.SwatchB5;
            break;
        }
        case WorkTaskTypeCode.Fertilize: {
            colourClass = SwatchCode.SwatchB2;
            break;
        }
        case WorkTaskTypeCode.Harvest: {
            colourClass = SwatchCode.SwatchA1;
            break;
        }
        case WorkTaskTypeCode.Irrigate: {
            colourClass = SwatchCode.SwatchB6;
            break;
        }
        case WorkTaskTypeCode.Plant: {
            colourClass = SwatchCode.SwatchA4;
            break;
        }
        case WorkTaskTypeCode.Spray: {
            colourClass = SwatchCode.SwatchB1;
            break;
        }
    }

    return colourClass;
};

/**
 * Prevent lists from being modified.
 */
const onMove = () => {
    return false;
};

/**
 * Called when a task drag has started.
 */
const onDragStart = (event: SortableEvent) => {
    homeDashboardStore.isTaskDragMode = true;
    const oldIndex = event.oldIndex;

    if (oldIndex != undefined) {
        const draggedItem = props.workTasks[oldIndex];
        homeDashboardStore.draggedWorkTaskId = draggedItem.workTaskId;
    }

    homeDashboardStore.draggedSourceList = props.listName as NavigatorFutureTaskList;
};

/**
 * Get class for the task.
 */
const getTaskClass = (workTask: WorkTaskSearchResultDto) => {
    if (homeDashboardStore.isTaskDragMode && homeDashboardStore.draggedWorkTaskId == workTask.workTaskId) {
        return 'dragging-task';
    } else if (isItemSelected(workTask)) {
        return 'selected';
    }
    return '';
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/swatches.scss';

.list-item-container {
    width: 100%;
    border-radius: 5px;
    border: 1px solid $swatchG7;
    background-color: $swatchWHT;
    margin-bottom: 10px;

    &.selected,
    &:hover {
        background-color: rgba($swatchB7, 0.5);
        border: 1px solid $swatchB6;
    }

    &.dragging-task {
        background-color: rgba($swatchB6, 0.5);
        border: 1px solid $swatchB6;
    }

    .work-task-details {
        display: flex;
        flex-direction: row;
        height: 100%;

        .flex-row {
            display: flex;
            align-items: center;
            gap: 5px; /* Space between items in a row */

            .date-chip {
                width: 50px;
                height: 24px;
                background-color: $swatchERR;
                color: $swatchWHT;
                text-align: center;
                border-radius: 3px;
                font-family: 'Open Sans Condensed', sans-serif;
            }

            .task-type-code {
                height: 40px;
                align-content: center;
                font-size: large;
                font-weight: bold;
            }

            .task-date {
                height: 30px;
                align-content: center;
                padding-left: 5px;
            }
        }

        .task-instructions {
            min-height: 30px;
            max-height: 4.5em;
        }

        .user-chip-list {
            display: flex;
            flex-wrap: wrap;
            background-color: inherit;
        }
    }
}
</style>
