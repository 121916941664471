<template>
    <div class="navigator-panel-operations">
        <navigator-farm-field-filter v-if="homeDashboardStore.farmFieldFiltersVisible && hasFarmFieldAccess" />
        <navigator-observation-filter v-if="homeDashboardStore.observationFiltersVisible && hasObservationAccess" />
        <navigator-work-task-filter v-if="homeDashboardStore.workTaskFiltersVisible && hasWorkTaskAccess" />

        <span v-if="hasOperationsAccess">
            <span style="float: left; display: flex; gap: 5px">
                <v-btn
                    v-if="navigatorLevel != 1"
                    class="operation-button icon-only"
                    :class="expandedWidth ? '' : 'reduced-padding'"
                    elevation="2"
                    rounded="x-large"
                    @click="closeTab">
                    <FontAwesomeIcon :icon="['fal', 'arrow-left-to-line']" size="xl" />
                </v-btn>

                <!-- "Add" button -->
                <v-btn
                    v-if="displayAddButton"
                    class="operation-button"
                    :class="expandedWidth ? '' : 'reduced-padding'"
                    elevation="2"
                    rounded="x-large"
                    @click="addItem">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'plus']" size="xl" />
                    </template>
                    {{ $t('Add') }}
                </v-btn>
            </span>
            <span style="float: right; display: flex; gap: 5px">
                <v-btn
                    v-if="navigatorLevel == 1"
                    class="operation-button"
                    :class="expandedWidth ? '' : 'reduced-padding'"
                    elevation="2"
                    rounded="x-large">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'table-list']" size="xl" />
                    </template>
                    {{ $t('List') }}
                </v-btn>

                <v-btn
                    v-if="
                        homeDashboardStore.navigatorPanelActiveTab == NavigatorPanelTab.FarmFields &&
                        navigatorLevel == 1
                    "
                    class="operation-button icon-only"
                    :class="homeDashboardStore.isMultiSelectFarmFieldsMode ? 'active-button' : ''"
                    elevation="2"
                    rounded="x-large"
                    @click="
                        homeDashboardStore.isMultiSelectFarmFieldsMode = !homeDashboardStore.isMultiSelectFarmFieldsMode
                    ">
                    <FontAwesomeIcon :icon="['fal', 'square-dashed-circle-plus']" size="xl" />
                </v-btn>

                <v-btn
                    v-if="navigatorLevel != 1"
                    class="operation-button"
                    :class="expandedWidth ? '' : 'reduced-padding'"
                    elevation="2"
                    rounded="x-large"
                    @click="viewItem">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'circle-arrow-right']" size="xl" />
                    </template>
                    {{ $t('View') }}
                </v-btn>

                <v-btn
                    v-if="
                        (homeDashboardStore.navigatorPanelActiveTab == NavigatorPanelTab.WorkTasks &&
                            navigatorLevel == 1) ||
                        (homeDashboardStore.navigatorPanelActiveTab == NavigatorPanelTab.FarmFields &&
                            navigatorLevel == 2)
                    "
                    class="operation-button icon-only"
                    elevation="2"
                    rounded="x-large"
                    @click="updateCompressedMode()">
                    <FontAwesomeIcon :icon="['fal', expandMenuIcon]" size="xl" />
                </v-btn>

                <v-btn
                    v-if="navigatorLevel == 1"
                    class="operation-button icon-only"
                    :class="isFilterButtonActive ? 'active-button' : ''"
                    elevation="2"
                    rounded="x-large"
                    @click="openFilterModal()">
                    <FontAwesomeIcon
                        :icon="isFilterButtonActive ? ['fac', 'filter-check'] : ['fal', 'filter']"
                        size="xl" />
                </v-btn>

                <NavigatorFieldOperationsMenu
                    v-if="
                        navigatorLevel == 1 &&
                        homeDashboardStore.navigatorPanelActiveTab == NavigatorPanelTab.FarmFields
                    "
                    :farm-fields="homeDashboardStore.selectedFarmFieldsLevel1" />

                <NavigatorWorkTaskOperationsMenu
                    v-if="
                        (navigatorLevel == 2 &&
                            homeDashboardStore.navigatorPanelActiveTab == NavigatorPanelTab.FarmFields) ||
                        (navigatorLevel == 1 &&
                            homeDashboardStore.navigatorPanelActiveTab == NavigatorPanelTab.WorkTasks)
                    "
                    :work-task="navigatorLevel == 2
                        ? homeDashboardStore.selectedWorkTaskLevel2!
                        : homeDashboardStore.selectedWorkTaskLevel1!"
                    :farm-field="homeDashboardStore.selectedFarmFieldsLevel1[0]"
                    :navigator-level="navigatorLevel" />

                <NavigatorObservationOperationsMenu
                    v-if="
                        navigatorLevel == 1 &&
                        homeDashboardStore.navigatorPanelActiveTab == NavigatorPanelTab.Observations
                    "
                    :observation="homeDashboardStore.selectedObservationLevel1" />

                <!-- Task Actions -->
                <WorkTaskOperationsMenu
                    v-if="
                        (navigatorLevel == 2 &&
                            homeDashboardStore.navigatorPanelActiveTab == NavigatorPanelTab.WorkTasks) ||
                        (navigatorLevel == 3 &&
                            homeDashboardStore.navigatorPanelActiveTab == NavigatorPanelTab.FarmFields)
                    "
                    :work-task="
                    navigatorLevel == 3
                        ? homeDashboardStore.selectedWorkTaskLevel2!
                        : homeDashboardStore.selectedWorkTaskLevel1!
                "
                    :menu-text="$t('Actions')"
                    :elevation="2" />

                <!-- Observation Actions -->
                <ObservationOperationsMenu
                    v-if="
                        navigatorLevel == 2 &&
                        homeDashboardStore.navigatorPanelActiveTab == NavigatorPanelTab.Observations
                    "
                    :observation="homeDashboardStore.selectedObservationLevel1!"
                    :menu-text="$t('Actions')"
                    :elevation="2" />
            </span>
        </span>
    </div>
</template>

<script setup lang="ts">
import {defineProps, computed} from 'vue';
import {NavigatorPanelTab} from '@/enums/home-dashboard/navigator-panel-tab';
import {useHomeDashboardStore} from '@/stores/home-dashboard-store';
import {useManageFarmFieldModalStore} from '@/stores/modals/manage-farm-field-modal-store';
import {useViewWorkTaskModalStore} from '@/stores/modals/view-work-task-modal-store';
import {useManageWorkTaskModalStore} from '@/stores/modals/manage-work-task-modal-store';
import {useViewFarmFieldModalStore} from '@/stores/modals/view-farm-field-modal-store';
import {useManageObservationModalStore} from '@/stores/modals/manage-observation-modal-store';
import {useViewObservationModalStore} from '@/stores/modals/view-observation-modal-store';
import {ManageWorkTaskModalMode} from '@/enums/modal-modes/manage-work-task-modal-mode';
import WorkTaskOperationsMenu from '@/components/operations-menus/WorkTaskOperationsMenu.vue';
import NavigatorFarmFieldFilter from '@/views/home-dashboard/NavigatorFarmFieldFilter.vue';
import NavigatorWorkTaskFilter from '@/views/home-dashboard/NavigatorWorkTaskFilter.vue';
import NavigatorObservationFilter from '@/views/home-dashboard/NavigatorObservationFilter.vue';
import NavigatorFieldOperationsMenu from '@/components/operations-menus/NavigatorFieldOperationsMenu.vue';
import NavigatorWorkTaskOperationsMenu from '@/components/operations-menus/NavigatorWorkTaskOperationsMenu.vue';
import NavigatorObservationOperationsMenu from '@/components/operations-menus/NavigatorObservationOperationsMenu.vue';
import ObservationOperationsMenu from '@/components/operations-menus/ObservationOperationsMenu.vue';
import {RoleCode} from '@/enums/role-code';
import {useUserStore} from '@/stores/user-store';

const props = defineProps<{
    navigatorLevel: number;
    expandedWidth: boolean;
    isCompressedTaskMode?: boolean;
}>();

const emit = defineEmits(['update-compressed-mode']);

// Stores
const homeDashboardStore = useHomeDashboardStore();
const manageFarmFieldModalStore = useManageFarmFieldModalStore();
const viewWorkTaskModalStore = useViewWorkTaskModalStore();
const viewFarmFieldModalStore = useViewFarmFieldModalStore();
const manageWorkTaskModalStore = useManageWorkTaskModalStore();
const manageObservationModalStore = useManageObservationModalStore();
const viewObservationModalStore = useViewObservationModalStore();

const userStore = useUserStore();
const hasFarmFieldAccess = userStore.hasRoleLevel(RoleCode.Fields, 1);
const hasWorkTaskAccess = userStore.hasRoleLevel(RoleCode.Tasks, 1);
const hasObservationAccess = userStore.hasRoleLevel(RoleCode.Observations, 1);

// Open modal to view an item, depending on which tab the button is clicked in
const viewItem = () => {
    if (navigatorLevel.value == 2) {
        if (
            homeDashboardStore.navigatorPanelActiveTab == NavigatorPanelTab.WorkTasks &&
            homeDashboardStore.selectedWorkTaskLevel1 != null
        ) {
            // Open View Work Task modal
            viewWorkTaskModalStore.open(homeDashboardStore.selectedWorkTaskLevel1.workTaskId);
        } else if (homeDashboardStore.navigatorPanelActiveTab == NavigatorPanelTab.FarmFields) {
            // Open View Farm Field modal
            viewFarmFieldModalStore.open(homeDashboardStore.selectedFarmFieldsLevel1[0].farmFieldId);
        } else if (
            homeDashboardStore.navigatorPanelActiveTab == NavigatorPanelTab.Observations &&
            homeDashboardStore.selectedObservationLevel1 != null
        ) {
            // Open Observation modal
            viewObservationModalStore.open(homeDashboardStore.selectedObservationLevel1.observationId);
        }
    } else if (
        navigatorLevel.value == 3 &&
        homeDashboardStore.navigatorPanelActiveTab == NavigatorPanelTab.FarmFields &&
        homeDashboardStore.selectedWorkTaskLevel2 != null
    ) {
        // Open View Work Task modal
        viewWorkTaskModalStore.open(homeDashboardStore.selectedWorkTaskLevel2.workTaskId);
    }
};

// Open modal to add a new item, depending on which tab the button is clicked in
const addItem = () => {
    if (navigatorLevel.value == 1) {
        if (homeDashboardStore.navigatorPanelActiveTab == NavigatorPanelTab.FarmFields) {
            // Open Create Farm Field modal
            manageFarmFieldModalStore.open();
        } else if (homeDashboardStore.navigatorPanelActiveTab == NavigatorPanelTab.WorkTasks) {
            // Open Create Work Task modal
            manageWorkTaskModalStore.open(ManageWorkTaskModalMode.Create);
        } else if (homeDashboardStore.navigatorPanelActiveTab == NavigatorPanelTab.Observations) {
            // Open Create Observation modal
            manageObservationModalStore.open();
        }
    } else if (
        navigatorLevel.value == 2 &&
        homeDashboardStore.navigatorPanelActiveTab == NavigatorPanelTab.FarmFields
    ) {
        // Get ID of the selected farm field
        let farmFieldId =
            homeDashboardStore.selectedFarmFieldsLevel1.length == 1
                ? homeDashboardStore.selectedFarmFieldsLevel1[0].farmFieldId
                : null;

        // Open Create Work Task modal
        manageWorkTaskModalStore.open(ManageWorkTaskModalMode.Create, null, farmFieldId);
    }
};

// Close the selected tab
const closeTab = () => {
    if (navigatorLevel.value == 2) {
        if (homeDashboardStore.navigatorPanelActiveTab == NavigatorPanelTab.FarmFields) {
            homeDashboardStore.deselectWorkTaskLevel2();
            homeDashboardStore.deselectFarmFieldsLevel1();
        } else if (homeDashboardStore.navigatorPanelActiveTab == NavigatorPanelTab.WorkTasks) {
            homeDashboardStore.deselectWorkTaskLevel1();
        } else if (homeDashboardStore.navigatorPanelActiveTab == NavigatorPanelTab.Observations) {
            homeDashboardStore.deselectObservationLevel1();
        }
    } else if (navigatorLevel.value == 3) {
        if (homeDashboardStore.navigatorPanelActiveTab == NavigatorPanelTab.FarmFields) {
            homeDashboardStore.deselectWorkTaskLevel2();
        }
    }
};

const navigatorLevel = computed(() => {
    return props.navigatorLevel;
});

const expandedWidth = computed(() => {
    return props.expandedWidth;
});

const updateCompressedMode = () => {
    emit('update-compressed-mode', !props.isCompressedTaskMode);
};

const expandMenuIcon = computed(() => {
    return props.isCompressedTaskMode ? 'arrows-from-dotted-line' : 'arrows-to-dotted-line';
});

const panelActiveTab = computed(() => homeDashboardStore.navigatorPanelActiveTab);

// Open filter modal based on panel tab
const openFilterModal = () => {
    if (panelActiveTab.value == NavigatorPanelTab.FarmFields) {
        homeDashboardStore.farmFieldFiltersVisible = true;
    } else if (panelActiveTab.value == NavigatorPanelTab.WorkTasks) {
        homeDashboardStore.workTaskFiltersVisible = true;
    } else if (panelActiveTab.value == NavigatorPanelTab.Observations) {
        homeDashboardStore.observationFiltersVisible = true;
    }
};

// Determines if the filter button should be shown as active
const isFilterButtonActive = computed(() => {
    return (
        (panelActiveTab.value == NavigatorPanelTab.FarmFields && homeDashboardStore.farmFieldFilterApplied) ||
        (panelActiveTab.value == NavigatorPanelTab.WorkTasks &&
            homeDashboardStore.workTaskFutureFilterApplied &&
            homeDashboardStore.isTaskFutureMode) ||
        (panelActiveTab.value == NavigatorPanelTab.WorkTasks &&
            homeDashboardStore.workTaskRecentFilterApplied &&
            !homeDashboardStore.isTaskFutureMode) ||
        (panelActiveTab.value == NavigatorPanelTab.Observations &&
            homeDashboardStore.observationOpenFilterApplied &&
            homeDashboardStore.isObservationOpenMode) ||
        (panelActiveTab.value == NavigatorPanelTab.Observations &&
            homeDashboardStore.observationClosedFilterApplied &&
            !homeDashboardStore.isObservationOpenMode)
    );
});

// Determines if the user should be able to see operations
const hasOperationsAccess = computed(() => {
    return (
        (panelActiveTab.value == NavigatorPanelTab.FarmFields && hasFarmFieldAccess) ||
        (panelActiveTab.value == NavigatorPanelTab.WorkTasks && hasWorkTaskAccess) ||
        (panelActiveTab.value == NavigatorPanelTab.Observations && hasObservationAccess)
    );
});

// Determines if the user should be able to create
const hasCreateAccess = computed(() => {
    return (
        (panelActiveTab.value == NavigatorPanelTab.FarmFields && userStore.hasRoleLevel(RoleCode.Fields, 3)) ||
        (panelActiveTab.value == NavigatorPanelTab.WorkTasks && userStore.hasRoleLevel(RoleCode.Tasks, 2)) ||
        (panelActiveTab.value == NavigatorPanelTab.Observations && hasObservationAccess)
    );
});

// Determines if add button should be displayed
const displayAddButton = computed(() => {
    return (
        hasCreateAccess.value &&
        (navigatorLevel.value == 1 ||
            (homeDashboardStore.navigatorPanelActiveTab == NavigatorPanelTab.FarmFields && navigatorLevel.value == 2))
    );
});
</script>
