<template>
    <v-toolbar class="swatchA2BG">
        <FontAwesomeIcon :icon="['fa', 'flask-round-potion']" size="xl" class="swatchWHT ml-3" />
        <v-toolbar-title class="swatchWHT">{{ $t('ProductMixtures') + ` (${resultsList.length})` }} </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn :class="filterButtonClass" rounded="1" @click="switchBackToSearch">
            <template v-slot:prepend>
                <FontAwesomeIcon :icon="['fal', 'filter']" size="xl" v-if="isFilterApplied" />
                <FontAwesomeIcon :icon="['fac', 'filter-check']" size="xl" v-else />
            </template>
            {{ $t('Filters') }}
        </v-btn>
    </v-toolbar>
    <v-data-table
        class="pa-0 ma-0 product-variety-table"
        :headers="headers"
        :items="resultsList"
        :items-per-page="configStore.defaultListItemNumberPerPage"
        @click:row="viewProductMixture">
        <template v-slot:[`item.mixtureName`]="{item}">
            <div class="tableCell">
                <span class="swatchA1 slightlyLarger"> {{ item.mixtureName }} </span>
            </div>
        </template>
        <template v-slot:[`item.products`]="{item}">
            <v-row
                ><span
                    v-for="([subtypeCode, count], index) in Object.entries(productSubTypeCounts(item.products))"
                    :key="index"
                    class="ml-1">
                    <ProductSubtypeIcon
                        :product-count="count"
                        :productSubtypeCode="subtypeCode as ProductSubtypeCode"
                        :product-type-code="findProductType(subtypeCode as ProductSubtypeCode)" /> </span
            ></v-row>
        </template>
        <template v-slot:[`item.appliedFarmFieldTotal`]="{item}">
            <div class="tableCell">
                <b class="swatchA1"> {{ item.appliedFarmFieldTotal }} </b>
            </div>
        </template>
        <template v-slot:[`item.lastUsed`]="{item}">
            <div class="tableCell">
                <span class="swatchA1"> {{ formatDate(item.lastUsed) }} </span>
            </div>
        </template>
        <template v-slot:[`item.appliedAreaTotal`]="{item}">
            <v-row>
                <v-col cols="12" align="center">
                    <b class="swatchA1">
                        {{ parseFloat(item.appliedAreaTotal ? item.appliedAreaTotal.toFixed(2) : '0') + ' Ha' }}
                    </b>
                </v-col>
            </v-row>
        </template>

        <template v-slot:[`item.artifacts`]="{item}">
            <span v-for="(artifact, index) in item.artifacts" :key="artifact.artifactId">
                <span class="pr-1" v-if="index <= 0 + (item.links.length > 0 ? 0 : 1)">
                    <FileTypeButton
                        :url="artifact.artifactUrl"
                        :display-name="artifact.artifactDisplayName"
                        :file-type-code="artifact.artifactFileTypeCode" />
                </span>
            </span>

            <span v-for="(link, index) in item.links" :key="link.linkId">
                <span v-if="index <= 0 + (item.artifacts.length > 0 ? 0 : 1)" class="pr-1">
                    <FileTypeButton
                        :url="link.linkUrl"
                        :display-name="link.linkName"
                        :file-type-code="ArtifactFileTypeCode.Other" />
                </span>
            </span>

            <span class="pr-1"></span>
            <span v-if="item.artifacts && item.artifacts.length + item.links.length >= 3">
                <v-menu>
                    <template v-slot:activator="{props}">
                        <v-btn class="v-btn--custom" v-bind="props"
                            ><font-awesome-icon :icon="['fas', 'ellipsis']"
                        /></v-btn>
                    </template>

                    <v-list>
                        <v-list-item
                            v-for="artifact in item.artifacts.slice(1 + (item.links.length > 0 ? 0 : 1))"
                            :key="artifact.artifactId">
                            <v-list-item-title>
                                <FileTypeButton
                                    :url="artifact.artifactUrl"
                                    :display-name="artifact.artifactDisplayName"
                                    :file-type-code="artifact.artifactFileTypeCode" />
                            </v-list-item-title>
                        </v-list-item>

                        <v-list-item
                            v-for="link in item.links.slice(1 + (item.artifacts.length > 0 ? 0 : 1))"
                            :key="link.linkId">
                            <v-list-item-title>
                                <FileTypeButton
                                    :url="link.linkUrl"
                                    :display-name="link.linkName"
                                    :file-type-code="ArtifactFileTypeCode.Other" />
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </span>
        </template>

        <template v-slot:[`item.action`]="{item}">
            <div style="text-align: center">
                <v-menu>
                    <template v-slot:activator="{props}">
                        <v-btn class="v-btn--custom" v-bind="props"> {{ $t('Actions') }} </v-btn>
                    </template>

                    <v-list>
                        <v-list-item>
                            <v-list-item-title>
                                <v-btn @click="viewMixtureModalStore.open(item.mixtureId)">{{
                                    $t('ViewMixture')
                                }}</v-btn>
                            </v-list-item-title>
                            <v-list-item-title>
                                <v-btn
                                    :class="item.appliedFarmFieldTotal === 0 ? 'swatchG1' : ''"
                                    :disabled="item.appliedFarmFieldTotal === 0"
                                    @click="
                                        advancedSearchModalStore.close();
                                        router.push({path: `/work-task-list/${item.mixtureId}`});
                                    ">
                                    {{ $t('ViewApplicationsOfThisMixture') }}
                                </v-btn>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </template>
    </v-data-table>
</template>
<script setup lang="ts">
import {ref, onMounted, computed} from 'vue';
import {MixtureSearchResultDto} from '@/models/data-transfer-objects/search/mixture-search/MixtureSearchResultDto';
import i18n from '@/i18n';
import {format} from 'date-fns';
import {MixtureProductDto} from '@/models/data-transfer-objects/search/mixture-search/MixtureProductDto';
import ProductSubtypeIcon from '@/components/ProductSubtypeIcon.vue';
import {ProductSubtypeCode} from '@/enums/product-subtype-code';
import {ProductTypeCode} from '@/enums/product-type-code';
import {useViewMixtureModalStore} from '@/stores/modals/view-mixture-modal-store';
const viewMixtureModalStore = useViewMixtureModalStore();
import {useProductSubtypes} from '@/composables/data-source/product-subtypes';
const {getProductSubtypes, productSubtypes} = useProductSubtypes();
import FileTypeButton from '@/components/FileTypeButton.vue';
import {ArtifactFileTypeCode} from '@/enums/artifact-file-type-code';
import {useAdvancedSearchModalStore} from '@/stores/modals/advanced-search-modal-store';
const advancedSearchModalStore = useAdvancedSearchModalStore();
import {useConfigStore} from '@/stores/config-store';
const configStore = useConfigStore();
import router from '@/router';

const props = withDefaults(
    defineProps<{
        resultsList: MixtureSearchResultDto[];
        isFilterApplied: boolean;
    }>(),
    {
        resultsList: () => [] as MixtureSearchResultDto[],
        isFilterApplied: false,
    },
);

const emit = defineEmits<{
    (event: 'switch-to-search'): void;
}>();

const switchBackToSearch = () => {
    emit('switch-to-search');
};

// Format date to necessary structure
const formatDate = (date: Date | null): string | null => {
    if (!date) return null;

    return format(date, 'dd MMM, yyyy');
};

const viewProductMixture = (event: MouseEvent, row: {item: MixtureSearchResultDto}) => {
    if (row.item.mixtureId) viewMixtureModalStore.open(row.item.mixtureId);
};

const productSubTypeCounts = (products: MixtureProductDto[]) =>
    products.reduce<Record<string, number>>((acc, product) => {
        // Check if the productSubtypeCode exists in the accumulator object
        if (acc[product.productSubtypeCode]) {
            acc[product.productSubtypeCode]++;
        } else {
            acc[product.productSubtypeCode] = 1;
        }
        return acc;
    }, {});

const findProductType = (productSubType: ProductSubtypeCode) =>
    productSubtypes.value.find((e) => e.productSubtypeCode === productSubType)?.productTypeCode as
        | ProductTypeCode
        | undefined;
const headers = ref([
    {title: i18n.global.t('Name'), key: 'mixtureName', sortable: true},
    {title: i18n.global.t('ProductsUsed'), key: 'products', sortable: false},
    {title: i18n.global.t('FieldsUsed'), key: 'appliedFarmFieldTotal', sortable: true},
    {title: i18n.global.t('LastUsed'), key: 'lastUsed', sortable: true},
    {title: i18n.global.t('12MonthApplication'), key: 'appliedAreaTotal', sortable: true},
    {title: i18n.global.t('DataSheetsAndLinks'), key: 'artifacts', sortable: false},
    {title: i18n.global.t('Action'), key: 'action', sortable: false},
]);

const filterButtonClass = computed(() => (props.isFilterApplied ? ['v-btn--custom'] : ['swatchB6BG', 'swatchWHT']));

// Fetch data for dropdown
onMounted(async () => {
    // Get data
    await getProductSubtypes();
});
</script>
<style lang="scss">
@import '@/assets/scss/swatches.scss';
.product-variety-table .v-table__wrapper thead {
    background-color: $swatchG7;
}
</style>
