import {MobileDevicePlatform} from '@/enums/mobile-device-platform';
import {UserStatus} from '@/enums/user-status';
import { UserRoleLevelAssignmentDto } from './UserRoleLevelAssignmentDto';
import { UserFarmSitesDto } from '../data-transfer-objects/users/UserFarmSitesDto';

export class ManageUserForm {
    userId: number | null = null;
    username: string | null = null;
    userGivenName: string | null = null;
    userSurname: string | null = null;
    userFullName: string | null = null;
    userEmailAddress: string | null = null;
    userMobile: string | null = null;
    userStatus: UserStatus | null = null;
    dateLastLoggedIn: Date | null = null;
    dateLastLoggedInWeb: Date | null = null;
    dateLastLoggedInMobile: Date | null = null;
    dateSignupVerificationCompleted: Date | null = null;
    isCustomerUser = false;
    mobileDeviceId: number | null = null;
    mobileDeviceExternalId: string | null = null;
    mobileDeviceModel: string | null = null;
    mobileDeviceManufacturer: string | null = null;
    dateFirstActiveApp: Date | null = null;
    dateLastActiveApp: Date | null = null;
    appVersion: string | null = null;
    lastLoggedInMobileDevicePlatform: MobileDevicePlatform | null = null;
    lastLoggedInMobileDevicePlatformVersion: string | null = null;
    isPushNotificationEnabled = false;
    numAndroidDevices: number | null = null;
    numIosDevices: number | null = null;
    lastLoggedInWebBrowser: string | null = null;
    lastLoggedInWebBrowserVersion: string | null = null;
    dateFirstActiveWeb: Date | null = null;
    dateLastActiveWeb: Date | null = null;
    userRoleLevelAssignments: UserRoleLevelAssignmentDto[] = [];
    userFarmSiteRoleLevelAssignment: UserFarmSitesDto[] = [];
    userImage: File | null = null;
    hasPictureBeenUpdated: boolean = false;

    constructor(data?: Partial<ManageUserForm>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
