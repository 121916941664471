<template>
    <v-dialog v-model="manageWorkTaskPlanModalStore.isVisible" class="pnlUserManager" height="100%">
        <v-card height="100%">
            <v-toolbar color="secondary">
                <v-toolbar-title>
                    <FontAwesomeIcon :icon="['fal', 'list-timeline']" size="xl" class="mr-2" />
                    {{ modalTitle }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <CloseModalButton @click="close" />
            </v-toolbar>

            <div v-if="isLoading" style="height: 100%">
                <LoadingSymbol />
            </div>

            <v-form
                v-else
                ref="form"
                class="pa-4 divUserManager"
                v-model="valid"
                style="height: 100%; overflow-y: scroll">
                <!-- Work Task Plan Details Header (shown when modifying a work task) -->
                <WorkTaskPlanDetailsHeader
                    v-if="modalMode == ManageWorkTaskPlanModalMode.Modify && workTaskPlanSearchResult != null"
                    :work-task-plan="workTaskPlanSearchResult"
                    :show-operations-menu="false"
                    :show-description="false" />

                <!-- Instructions panel -->
                <v-card class="pnlInstructions mt-2">
                    <v-toolbar color="swatchA6">
                        <v-toolbar-title>
                            <FontAwesomeIcon :icon="['fas', 'question-circle']" size="xl" class="mr-2" />
                            {{ $t('Instructions') }}
                        </v-toolbar-title>
                    </v-toolbar>

                    <v-card-text class="instructionContentBG">
                        {{ instructionsText }}
                    </v-card-text>
                </v-card>

                <!-- Plan Name -->
                <div class="flex-layout fill flex-layout-space-05 align-items-center formRow flex-wrap-nowrap">
                    <div class="formHead">{{ $t('PlanName') }}</div>
                    <div class="formIcon">
                        <HelpIcon :help-text="$t('ManageWorkTaskPlan_PlanName_HelpText')" />
                    </div>
                    <div class="formModifyGroup">
                        <v-text-field
                            v-model="localWorkTaskPlan.workTaskPlanName"
                            :rules="[requiredRule, planNameUniqueRule]"
                            :placeholder="$t('EnterPlanName')"
                            max-width="400px"
                            class="swatchA1 font-weight-bold">
                            <template v-slot:append-inner>
                                <FontAwesomeIcon
                                    :icon="['fas', 'spinner']"
                                    pulse
                                    size="xl"
                                    class="swatchA2"
                                    v-if="localWorkTaskPlan.workTaskPlanName && isCheckingPlanName" />

                                <CircleIcon
                                    class="marginLeft marginRightSmall swatchREDBG"
                                    color="swatchWHT"
                                    :title="$t('PlanNameIsNotAvailable')"
                                    v-if="
                                        localWorkTaskPlan.workTaskPlanName &&
                                        !isCheckingPlanName &&
                                        !isPlanNameAvailable
                                    ">
                                    <FontAwesomeIcon :icon="['fas', 'xmark']" />
                                </CircleIcon>

                                <CircleIcon
                                    class="marginLeft marginRightSmall swatchGRNBG"
                                    color="swatchWHT"
                                    :title="$t('PlanNameIsAvailable')"
                                    v-if="
                                        localWorkTaskPlan.workTaskPlanName && !isCheckingPlanName && isPlanNameAvailable
                                    ">
                                    <FontAwesomeIcon :icon="['fas', 'check']" />
                                </CircleIcon>
                            </template>
                        </v-text-field>
                    </div>
                </div>

                <!-- Description -->
                <div class="flex-layout fill flex-layout-space-05 align-items-center">
                    <div class="formHead">{{ $t('Description') }}</div>
                    <div class="formIcon">
                        <HelpIcon :help-text="$t('ManageWorkTaskPlan_Description_HelpText')" />
                    </div>
                    <div class="formModifyGroup">
                        <v-textarea
                            v-model="localWorkTaskPlan.workTaskPlanDescription"
                            :rules="[requiredRule]"
                            :placeholder="$t('DescribeThisTaskPlan')"
                            variant="outlined"
                            density="compact"
                            rows="1"
                            auto-grow
                            hide-details="auto"
                            class="swatchA1 font-weight-bold">
                        </v-textarea>
                    </div>
                </div>

                <!-- Plan Sequence -->
                <div class="mt-6">
                    <v-expansion-panels class="marginTop" v-model="expansionPanelsPlanSequence">
                        <v-expansion-panel>
                            <v-expansion-panel-title class="expansion-header">
                                {{ $t('PlanSequence') }}
                            </v-expansion-panel-title>
                            <v-expansion-panel-text>
                                <!-- Plan items saved against this task -->
                                <ManageWorkTaskPlanItem
                                    v-for="(workTaskPlanItem, index) in localWorkTaskPlan.workTaskPlanItems"
                                    :ref="
                                        (el) => {
                                            if (el) (workTaskPlanItemRefs[index] as any) = el;
                                        }
                                    "
                                    :key="
                                        'WorkSubTask' +
                                        (workTaskPlanItem.workTaskPlanItemId ?? workTaskPlanItem.newItemId)
                                    "
                                    :work-task-plan-item="workTaskPlanItem"
                                    :work-task-plan-item-list="localWorkTaskPlan.workTaskPlanItems"
                                    :work-task-types="workTaskTypesWithNull"
                                    :work-task-templates="workTaskTemplates"
                                    :index="index"
                                    @update="updateWorkTaskPlanItem"
                                    @delete="deleteWorkTaskPlanItem"
                                    @move-item-ahead="moveWorkTaskPlanItemAhead"
                                    @move-item-back="moveWorkSubTaskBack" />

                                <!-- Add sub-task -->
                                <ManageWorkTaskPlanItem
                                    :work-task-plan-item-list="localWorkTaskPlan.workTaskPlanItems"
                                    :work-task-types="workTaskTypesWithNull"
                                    :work-task-templates="workTaskTemplates"
                                    :index="-1"
                                    @add="addWorkTaskPlanItem" />
                            </v-expansion-panel-text>
                        </v-expansion-panel>
                    </v-expansion-panels>

                    <!-- "At Least One Plan Item Added" validator -->
                    <v-validation :validation-value="numberOfTaskPlanItems" :rules="[atLeastTwoTaskPlanItemsAddedRule]">
                        <template v-slot="{errorMessages, isValid}">
                            <div v-if="isValid.value != null">
                                <span v-for="(error, index) in errorMessages.value" :key="index" class="error-message">
                                    {{ error }}
                                </span>
                            </div>
                        </template>
                    </v-validation>
                </div>
            </v-form>

            <v-card-actions class="justify-space-between swatchG9BG">
                <!-- Close Modal -->
                <v-btn @click="close">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'arrow-rotate-left']" size="xl" />
                    </template>
                    {{ $t('Cancel') }}
                </v-btn>
                <!-- Save Task Plan -->
                <v-btn @click="save" :disabled="isLoading">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'save']" size="xl" />
                    </template>
                    {{ $t('Save') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" timeout="4000">
        {{ snackbar.text }}
    </v-snackbar>
</template>

<script setup lang="ts">
import '@/assets/scss/user/user-manager.scss';
import {ManageWorkTaskPlanModalMode} from '@/enums/modal-modes/manage-work-task-plan-modal-mode';
import {computed, watch, inject, ref, nextTick} from 'vue';
import ApiService from '@/services/api-service.js';
import CloseModalButton from '@/components/CloseModalButton.vue';
import WorkTaskPlanDetailsHeader from '@/components/WorkTaskPlanDetailsHeader.vue';
import i18n from '@/i18n';
import {SearchWorkTaskPlansRequest} from '@/models/api/requests/search/SearchWorkTaskPlansRequest';
import {ManageWorkTaskPlanForm} from '@/models/work-tasks/ManageWorkTaskPlanForm';
import {useManageWorkTaskPlanModalStore} from '@/stores/modals/manage-work-task-plan-modal-store';
import Validation from '@/helpers/ValidationHelper';
import ManageWorkTaskPlanItem from '@/views/work-tasks/ManageWorkTaskPlanItem.vue';
import FileUploadHelper from '@/helpers/FileUploadHelper';
import {ValidatableComponent} from '@/models/generic/ValidatableComponent';
import {debounce} from 'lodash';
import {CheckPlanNameAvailableRequest} from '@/models/api/requests/work-tasks/CheckPlanNameAvailableRequest';
import {WorkTaskPlanItemForm} from '@/models/work-tasks/WorkTaskPlanItemForm';
import {SaveWorkTaskPlanItemDto} from '@/models/data-transfer-objects/work-tasks/SaveWorkTaskPlanItemDto';
import {SaveWorkTaskPlanRequest} from '@/models/api/requests/work-tasks/SaveWorkTaskPlanRequest';
import {WorkTaskPlanSearchParametersDto} from '@/models/data-transfer-objects/search/work-task-plan-search/WorkTaskPlanSearchParametersDto';
import {WorkTaskPlanSearchResultDto} from '@/models/data-transfer-objects/search/work-task-plan-search/WorkTaskPlanSearchResultDto';
import {SearchWorkTaskPlansResponse} from '@/models/api/responses/search/SearchWorkTaskPlansResponse';
import {WorkTaskTemplateSearchResultDto} from '@/models/data-transfer-objects/search/work-task-template-search/WorkTaskTemplateSearchResultDto';
import {SearchWorkTaskTemplatesRequest} from '@/models/api/requests/search/SearchWorkTaskTemplatesRequest';
import {WorkTaskTemplateSearchParametersDto} from '@/models/data-transfer-objects/search/work-task-template-search/WorkTaskTemplateSearchParametersDto';
import {SearchWorkTaskTemplatesResponse} from '@/models/api/responses/search/SearchWorkTaskTemplatesResponse';
import {useWorkTaskTypes} from '@/composables/data-source/work-task-types';
const {getWorkTaskTypes, workTaskTypesWithNull} = useWorkTaskTypes();

// Form
const form = ref();
const valid = ref<boolean>(true);
let isLoading = ref<boolean>(false);
const snackbar = ref({
    show: false,
    text: '',
});
const expansionPanelsPlanSequence = ref([0]);
const workTaskPlanItemRefs = ref<ValidatableComponent[]>([]);
const isCheckingPlanName = ref(false);
const isPlanNameAvailable = ref(true);

// Rules
const requiredRule = Validation.createRule_Required();
const atLeastTwoTaskPlanItemsAddedRule = (v: number) => {
    return v >= 2 || i18n.global.t('Validation_AtLeastTwoTaskPlanItemsAdded');
};
const planNameUniqueRule = async (v: string) => {
    if (!v) return true;
    if (isCheckingPlanName.value == true) return false;
    return await checkPlanNameAvailable(v);
};

// Services
const apiService = inject('apiService') as ApiService;

// Modal
const manageWorkTaskPlanModalStore = useManageWorkTaskPlanModalStore();
const modalMode = computed(() => manageWorkTaskPlanModalStore.modalMode);
const workTask = ref<ManageWorkTaskPlanForm>(new ManageWorkTaskPlanForm());
const localWorkTaskPlan = ref<ManageWorkTaskPlanForm>({...workTask.value});
const workTaskPlanSearchResult = ref<WorkTaskPlanSearchResultDto | null>(null);

// Data
const workTaskTemplates = ref<WorkTaskTemplateSearchResultDto[]>([]);

/**
 * Load work task data for the modal.
 */
const loadModal = async () => {
    isLoading.value = true;

    // Reset form
    resetForm();

    // Load dropdown data
    await loadWorkTaskTemplates();
    await getWorkTaskTypes();

    // If a work task plan ID was specified
    if (manageWorkTaskPlanModalStore.workTaskPlanId !== null) {
        // Load data for the task plan that is being modified
        if (manageWorkTaskPlanModalStore.workTaskPlanId !== null) {
            await loadWorkTaskPlanData();
        }
    }

    // Update value of local work task
    localWorkTaskPlan.value = {...workTask.value};

    // If the task is being duplicated, perform additional setup on the work task object
    if (modalMode.value == ManageWorkTaskPlanModalMode.Duplicate) {
        setupNewDuplicatedTask();
    }

    // Get task template for all plan items
    getTaskTemplateForAllPlanItems();

    // End loading (nextTick is used to ensure that the watch events on localWorkTaskPlan are not triggered during the load)
    nextTick(() => {
        isLoading.value = false;
    });
};

/**
 * Loads data for a specific work task plan.
 */
const loadWorkTaskPlanData = async () => {
    // Build search request using the work task plan ID
    const searchRequest: SearchWorkTaskPlansRequest = {
        searchParameters: new WorkTaskPlanSearchParametersDto({
            workTaskPlanId: manageWorkTaskPlanModalStore.workTaskPlanId,
            includeWorkTaskPlanItems: true,
        }),
    };

    // Get work task details
    const searchResults = (await apiService.post(
        'search/work-task-plans',
        searchRequest,
    )) as SearchWorkTaskPlansResponse;

    if (searchResults.workTaskPlans.length == 1) {
        // There is a single result, so set the work task object to that value
        workTask.value = Object.assign(new ManageWorkTaskPlanForm(), searchResults.workTaskPlans[0]);

        // Store search result
        workTaskPlanSearchResult.value = searchResults.workTaskPlans[0];
    }
};

/**
 * Loads the list of work task templates.
 */
const loadWorkTaskTemplates = async () => {
    // Build search request
    const workTaskTemplatesSearchRequest: SearchWorkTaskTemplatesRequest = {
        searchParameters: new WorkTaskTemplateSearchParametersDto(),
    };

    // Get work task template details
    const workTaskTemplatesSearchResults = (await apiService.post(
        'search/work-task-templates',
        workTaskTemplatesSearchRequest,
    )) as SearchWorkTaskTemplatesResponse;
    workTaskTemplates.value = workTaskTemplatesSearchResults.workTaskTemplates;
};

/**
 * Clear IDs and set "duplicated from" IDs when this task plan is being duplicated from another plan.
 */
const setupNewDuplicatedTask = () => {
    // Store the ID of the task plan that this was duplicated from (this will need to be passed to the Save API later)
    localWorkTaskPlan.value.duplicatedFromWorkTaskPlanId = localWorkTaskPlan.value.workTaskPlanId;

    // Set the work task plan ID to null to ensure that a new task plan ends up being created when saving
    localWorkTaskPlan.value.workTaskPlanId = null;

    // For each plan item
    localWorkTaskPlan.value.workTaskPlanItems.forEach((workTaskPlanItem) => {
        // Store the ID of the sub-task that this was duplicated from (this will need to be passed to the Save API later)
        workTaskPlanItem.duplicatedFromWorkTaskPlanItemId = workTaskPlanItem.workTaskPlanItemId;
    });

    // Clear IDs
    clearWorkTaskPlanItems();

    // Plan name should be set to null
    localWorkTaskPlan.value.workTaskPlanName = null;
};

/**
 * Set the IDs of the plan items to null. Also update the newItemId to ensure they can be uniquely identified.
 */
const clearWorkTaskPlanItems = async () => {
    localWorkTaskPlan.value.workTaskPlanItems.forEach((workTaskPlanItem, index) => {
        workTaskPlanItem.newItemId = `Duplicate-${index}`;
        workTaskPlanItem.workTaskPlanItemId = null;
    });
};

/**
 * Close the modal.
 */
const close = () => {
    manageWorkTaskPlanModalStore.close();
};

/**
 * Validate all plan items before saving.
 */
const validateAllPlanItems = async (): Promise<boolean> => {
    const results = await Promise.all(workTaskPlanItemRefs.value.map((child) => child.validate()));
    return results.every((result) => result === true);
};

/**
 * Save the modal.
 */
const save = async () => {
    // Perform final client side validation of form
    await form.value.validate();
    let isValid = valid.value && (await validateAllPlanItems());

    // If form is valid
    if (isValid) {
        let snackbarMessage = '';
        isLoading.value = true;

        try {
            // For each plan item
            const workTaskPlanItems: SaveWorkTaskPlanItemDto[] = [];
            localWorkTaskPlan.value.workTaskPlanItems.forEach((workTaskPlanItem, index) => {
                // Build plan item object
                const uploadedWorkTaskPlanItem = new SaveWorkTaskPlanItemDto({
                    workTaskPlanItemId: workTaskPlanItem.workTaskPlanItemId,
                    sequenceNumber: index + 1,
                    workTaskTemplateId: workTaskPlanItem.workTaskTemplate!.workTaskTemplateId,
                    // A null "Due After" value will be used for the first item
                    numberOfDaysDueAfterPreviousTask:
                        index > 0 ? workTaskPlanItem.numberOfDaysDueAfterPreviousTask : null,
                    duplicatedFromWorkTaskPlanItemId: workTaskPlanItem.duplicatedFromWorkTaskPlanItemId,
                });

                // Add plan item to array
                workTaskPlanItems.push(uploadedWorkTaskPlanItem);
            });

            // Build API request
            const saveWorkTaskPlanRequest: SaveWorkTaskPlanRequest = {
                workTaskPlan: {
                    workTaskPlanId: localWorkTaskPlan.value.workTaskPlanId,
                    workTaskPlanName: localWorkTaskPlan.value.workTaskPlanName!,
                    workTaskPlanDescription: localWorkTaskPlan.value.workTaskPlanDescription!,
                    workTaskPlanItems: workTaskPlanItems,
                    duplicatedFromWorkTaskPlanId: localWorkTaskPlan.value.duplicatedFromWorkTaskPlanId,
                },
            };

            // Call API to save work task
            await apiService.post(
                'work-task-plans/work-task-plan',
                FileUploadHelper.createFormDataObject(saveWorkTaskPlanRequest),
            );

            // Set snackbar message
            if (
                modalMode.value == ManageWorkTaskPlanModalMode.Create ||
                modalMode.value == ManageWorkTaskPlanModalMode.Duplicate
            ) {
                snackbarMessage = i18n.global.t('CreateWorkTaskPlan_Success');
            } else if (modalMode.value == ManageWorkTaskPlanModalMode.Modify) {
                snackbarMessage = i18n.global.t('ModifyWorkTaskPlan_Success');
            }

            // Show success feedback to user
            snackbar.value.show = true;
            snackbar.value.text = snackbarMessage;
            manageWorkTaskPlanModalStore.savedCounter++;

            // Close modal
            manageWorkTaskPlanModalStore.close();
        } catch (ex: unknown) {
            // Show fail feedback to user
            snackbar.value.show = true;
            snackbar.value.text = i18n.global.t('ErrorGeneric');
            isLoading.value = false;
            console.error(ex);
        }
    } else {
        // Show validation error feedback to user
        snackbar.value.show = true;
        snackbar.value.text = i18n.global.t('ErrorValidation');
    }
};

/**
 * Adds a new task plan item.
 */
const addWorkTaskPlanItem = (workTaskPlanItem: WorkTaskPlanItemForm) => {
    localWorkTaskPlan.value.workTaskPlanItems.push(workTaskPlanItem);
};

/**
 * Updates a task plan item.
 */
const updateWorkTaskPlanItem = (workTaskPlanItem: WorkTaskPlanItemForm) => {
    const index = localWorkTaskPlan.value.workTaskPlanItems.findIndex(
        (wtpi) =>
            (workTaskPlanItem.workTaskPlanItemId != null &&
                wtpi.workTaskPlanItemId === workTaskPlanItem.workTaskPlanItemId) ||
            (workTaskPlanItem.newItemId != null && wtpi.newItemId === workTaskPlanItem.newItemId),
    );

    if (index !== -1) {
        // Update the found object with the new object's properties
        localWorkTaskPlan.value.workTaskPlanItems[index] = {
            ...localWorkTaskPlan.value.workTaskPlanItems[index],
            ...workTaskPlanItem,
        };
    }
};

/**
 * Deletes the specified plan item. This deletion is not saved until the form is saved.
 */
const deleteWorkTaskPlanItem = (workTaskPlanItemIndex: number) => {
    localWorkTaskPlan.value.workTaskPlanItems.splice(workTaskPlanItemIndex, 1);

    // Delete the ref object for this deleted plan item so that it's no longer validated
    workTaskPlanItemRefs.value.splice(workTaskPlanItemIndex, 1);
};

/**
 * Moves the task plan item up.
 */
const moveWorkTaskPlanItemAhead = (workTaskPlanItemIndex: number) => {
    setTimeout(() => {
        // Check if the index is within bounds and not the last element
        if (workTaskPlanItemIndex < localWorkTaskPlan.value.workTaskPlanItems.length - 1) {
            // Swap the item with the next item
            [
                localWorkTaskPlan.value.workTaskPlanItems[workTaskPlanItemIndex],
                localWorkTaskPlan.value.workTaskPlanItems[workTaskPlanItemIndex + 1],
            ] = [
                localWorkTaskPlan.value.workTaskPlanItems[workTaskPlanItemIndex + 1],
                localWorkTaskPlan.value.workTaskPlanItems[workTaskPlanItemIndex],
            ];
        }
    }, 250);
};

/**
 * Moves the task plan item down.
 */
const moveWorkSubTaskBack = (workTaskPlanItemIndex: number) => {
    setTimeout(() => {
        // Check if the index is within bounds and not the first element
        if (workTaskPlanItemIndex > 0) {
            // Swap the item with the previous item
            [
                localWorkTaskPlan.value.workTaskPlanItems[workTaskPlanItemIndex],
                localWorkTaskPlan.value.workTaskPlanItems[workTaskPlanItemIndex - 1],
            ] = [
                localWorkTaskPlan.value.workTaskPlanItems[workTaskPlanItemIndex - 1],
                localWorkTaskPlan.value.workTaskPlanItems[workTaskPlanItemIndex],
            ];
        }
    }, 250);
};

/**
 * For each plan item, get its task template from the full list of data source templates.
 */
const getTaskTemplateForAllPlanItems = () => {
    localWorkTaskPlan.value.workTaskPlanItems.forEach((workTaskPlan) => {
        workTaskPlan.workTaskTemplate =
            workTaskTemplates.value.find((wtt) => wtt.workTaskTemplateId == workTaskPlan.workTaskTemplateId) ?? null;
    });
};

/**
 * Reset the form to its default state.
 */
const resetForm = () => {
    workTask.value = new ManageWorkTaskPlanForm();

    localWorkTaskPlan.value = {...workTask.value};
    workTaskPlanSearchResult.value = null;
    workTaskPlanItemRefs.value = [];
    expansionPanelsPlanSequence.value = [0];
    isCheckingPlanName.value = false;
    isPlanNameAvailable.value = true;
};

/**
 * Checks if the specified plan name is available. Debounce is used to prevent the API from being called too often.
 * TODO: Currently this only prevents the API from being called more than once every 500ms. We should change it so
 * that it prevents the API from being called until 500ms after the value is last updated. We might also want to
 * move this into a reusable component at some point.
 */
const debouncedCheckPlanNameAvailable = debounce(
    async (workTaskPlanName: string, resolve: (value: string | boolean) => void) => {
        const request: CheckPlanNameAvailableRequest = {
            workTaskPlanName,
            workTaskPlanId: localWorkTaskPlan.value.workTaskPlanId,
        };

        const isAvailable = await apiService.post('work-task-plans/check-plan-name-available', request);
        isCheckingPlanName.value = false;
        isPlanNameAvailable.value = isAvailable;

        resolve(isAvailable || i18n.global.t('Validation_PlanNameUnique'));
    },
    500,
);

/**
 * Checks if the specified template name is available.
 */
const checkPlanNameAvailable = async (workTaskTemplateName: string): Promise<string | boolean> => {
    isCheckingPlanName.value = true;

    return new Promise((resolve) => {
        debouncedCheckPlanNameAvailable(workTaskTemplateName, resolve);
    });
};

/**
 * The title of the modal.
 */
const modalTitle = computed(() => {
    let title = '';

    switch (modalMode.value) {
        case ManageWorkTaskPlanModalMode.Modify:
            title = i18n.global.t('ModifyTaskPlan');
            break;
        case ManageWorkTaskPlanModalMode.Create:
            title = i18n.global.t('CreateATaskPlan');
            break;
        case ManageWorkTaskPlanModalMode.Duplicate:
            title = i18n.global.t('DuplicateTaskPlan');
            break;
    }
    return title;
});

/**
 * The text to show in the Instructions section.
 */
const instructionsText = computed(() => {
    let instructions = '';

    switch (modalMode.value) {
        case ManageWorkTaskPlanModalMode.Modify:
            instructions = i18n.global.t('ModifyWorkTaskPlanInstructions');
            break;
        case ManageWorkTaskPlanModalMode.Create:
            instructions = i18n.global.t('CreateWorkTaskPlanInstructions');
            break;
        case ManageWorkTaskPlanModalMode.Duplicate:
            instructions = i18n.global.t('DuplicateWorkTaskPlanInstructions');
            break;
    }
    return instructions;
});

/**
 * The number of plan items that have been added.
 */
const numberOfTaskPlanItems = computed(() => {
    return localWorkTaskPlan.value.workTaskPlanItems.length;
});

// Watch for changes to modal state
watch(
    () => manageWorkTaskPlanModalStore.isVisible,
    async (isVisible) => {
        if (isVisible) {
            await loadModal();
        }
    },
);
</script>
