import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b834bdd2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "initials"
}
const _hoisted_2 = {
  key: 1,
  class: "pr-2 pl-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_chip = _resolveComponent("v-chip")!

  return (_openBlock(), _createBlock(_component_v_chip, {
    closable: _ctx.closable,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showLink && _ctx.handleResultClick(_ctx.selectedUser.userId))),
    "onClick:close": _cache[1] || (_cache[1] = ($event: any) => (_ctx.showLink && _ctx.emitClose)),
    class: _normalizeClass(["pl-0", _ctx.closable ? '' : 'pr-0']),
    style: _normalizeStyle({height: _ctx.iconSize + 'px'})
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_avatar, {
        left: "",
        class: "custom-avatar",
        size: _ctx.iconSize
      }, {
        default: _withCtx(() => [
          (_ctx.selectedUser.profilePicture)
            ? (_openBlock(), _createBlock(_component_v_img, {
                key: 0,
                src: _ctx.selectedUser.profilePicture
              }, null, 8, ["src"]))
            : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.getInitials(_ctx.selectedUser.username)), 1))
        ]),
        _: 1
      }, 8, ["size"]),
      (!_ctx.selectedUser.username && !_ctx.iconOnly)
        ? _renderSlot(_ctx.$slots, "username", { key: 0 }, undefined, true)
        : (!_ctx.iconOnly)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.selectedUser.username), 1))
          : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["closable", "class", "style"]))
}