import {ApplicationRateUnitCode} from '@/enums/application-rate-unit-code';

export class WorkTaskProductForm {
    workTaskProductId: number | null = null;
    workTaskTemplateProductId: number | null = null;
    productId: number | null = null;
    plannedApplicationRate: number | null = null;
    plannedApplicationRateUnitCode: ApplicationRateUnitCode | null = null;
    actualApplicationRate: number | null = null;
    actualApplicationRateUnitCode: ApplicationRateUnitCode | null = null;
    mixtureId: number | null = null;
    newItemId: string | null = null;

    constructor(data?: Partial<WorkTaskProductForm>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
