<template>
    <v-dialog v-model="manageUserModalStore.isVisible" class="pnlUserManager">
        <v-card>
            <v-toolbar color="secondary">
                <v-toolbar-title>
                    <FontAwesomeIcon :icon="['fal', 'user']" size="xl" class="mr-2" />
                    {{ modalTitle }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <CloseModalButton @click="close" />
            </v-toolbar>

            <LoadingSymbol v-if="isLoading" />

            <v-form v-else-if="modalMode == ManageUserModalMode.Modify && !hasAccess('modifyUser')">
                <!-- No permission to modify this user -->
                <div class="pa-2">{{ $t('YouDoNotHavePermissionToModifyThisUser') }}</div>
            </v-form>

            <v-form v-else ref="form" class="pa-4 divUserManager" v-model="valid" style="overflow-y: scroll">
                <!-- Profile -->
                <div
                    v-if="
                        modalMode === ManageUserModalMode.Modify ||
                        modalMode === ManageUserModalMode.View ||
                        modalMode === ManageUserModalMode.ChangeProfile
                    "
                    class="flex-layout justify-content-space-between profile-main flex-wrap-nowrap">
                    <!-- Details -->
                    <div class="profile-detail">
                        <div v-if="user.userImage" class="large theme" style="margin-bottom: 2.5rem">
                            <UserChip
                                :icon-only="true"
                                icon-size="48"
                                :show-link="false"
                                :user="UserHelper.userChipType(user.userId, user.userFullName, userProfilePicture)" />
                        </div>
                        <div v-else class="large theme staffUserIconClass">
                            <FontAwesomeIcon :icon="['fas', 'user-shield']" />
                        </div>
                        <div class="space-between-div paddingLeft">
                            <div>
                                <span class="bold swatchA2 slightlyLarger">
                                    {{ user.userGivenName }}, {{ user.userSurname }}
                                </span>
                                <IdIcon>{{ user.userId }}</IdIcon>
                            </div>
                            <div>
                                <span>{{ user.userEmailAddress }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="space-between-div marginRightSmall marginTop">
                        <!--Operations Button-->
                        <div
                            v-if="modalMode === ManageUserModalMode.View && hasAccess('modifyUser')"
                            style="margin-bottom: 0.5rem; margin-left: 50%">
                            <v-menu open-on-hover>
                                <template v-slot:activator="{props}">
                                    <v-btn color="primary" v-bind="props"> {{ $t('Operations') }} </v-btn>
                                </template>

                                <v-list>
                                    <v-list-item>
                                        <v-list-item-title
                                            v-if="
                                                userFromSearchResults != null &&
                                                hasFullStaffAccess &&
                                                userFromSearchResults.userStatus == UserStatus.Active
                                            ">
                                            <v-btn @click="resetUserPasswordModalStore.open(userFromSearchResults)">{{
                                                $t('ResetUsersPassword')
                                            }}</v-btn>
                                        </v-list-item-title>
                                        <v-list-item-title v-if="hasModifyStaffAccess">
                                            <v-btn
                                                @click="
                                                    manageUserModalStore.open(ManageUserModalMode.Modify, user.userId)
                                                ">
                                                {{ $t('ManageUserSlashRoles') }}
                                            </v-btn>
                                        </v-list-item-title>
                                        <v-list-item-title v-if="hasFullStaffAccess">
                                            <v-btn @click="deleteUserModalStore.open(userFromSearchResults!)">
                                                {{ $t('DeleteUserAccount') }}
                                            </v-btn>
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                        <!-- Signup Status -->
                        <div class="flex-layout justify-content-flex-end">
                            <span style="margin-right: 3rem">{{ $t('SignupVerified') }}</span>
                            <span v-if="user.dateSignupVerificationCompleted">
                                {{ formatDate(user.dateSignupVerificationCompleted) }}
                            </span>
                            <span v-else class="swatchORG"> {{ $t('NotYetVerified') }} </span>
                        </div>
                        <div
                            v-if="
                                !user.dateSignupVerificationCompleted &&
                                hasAccess('modifyUser') &&
                                modalMode !== ManageUserModalMode.ChangeProfile
                            "
                            class="flex-layout justify-content-flex-end"
                            style="margin-top: 0.5rem; margin-bottom: 0.5rem">
                            <SignupVerificationManager :user-id="user.userId!"></SignupVerificationManager>
                        </div>
                    </div>
                </div>

                <!-- Instructions -->
                <v-card class="pnlInstructions marginTop" v-if="modalMode == ManageUserModalMode.Create">
                    <v-toolbar color="swatchA6">
                        <v-toolbar-title>
                            <FontAwesomeIcon :icon="['fas', 'question-circle']" size="xl" class="mr-2" />
                            {{ $t('Instructions') }}
                        </v-toolbar-title>
                    </v-toolbar>

                    <v-card-text class="instructionContentBG">
                        {{ $t('CreateUserInstructions', {systemName: configStore.systemName}) }}
                    </v-card-text>
                </v-card>

                <v-expansion-panels multiple class="marginTop" v-model="openedExpansionPanels">
                    <!-- User Details -->
                    <v-expansion-panel>
                        <v-expansion-panel-title class="expansion-header">{{
                            $t('UserDetails')
                        }}</v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <!-- First Name -->
                            <div class="flex-layout fill flex-layout-space-05 align-items-center flex-wrap-nowrap">
                                <div class="formHead">{{ $t('FirstName') }}</div>
                                <div class="formIcon">
                                    <HelpIcon :help-text="$t('ManageUser_FirstName_HelpText')" />
                                </div>
                                <span v-if="modalMode === ManageUserModalMode.View">{{ user.userGivenName }}</span>
                                <div
                                    v-if="
                                        modalMode === ManageUserModalMode.Modify ||
                                        modalMode === ManageUserModalMode.Create ||
                                        modalMode === ManageUserModalMode.ChangeProfile
                                    "
                                    class="formModifyGroup">
                                    <v-text-field
                                        v-model="localUser.userGivenName"
                                        class="percent20"
                                        :rules="userGivenNameRules"
                                        required
                                        @blur="generateUsername">
                                    </v-text-field>
                                </div>
                            </div>

                            <!-- Surname -->
                            <div class="flex-layout fill flex-layout-space-05 align-items-center flex-wrap-nowrap">
                                <div class="formHead">{{ $t('Surname') }}</div>
                                <div class="formIcon">
                                    <HelpIcon :help-text="$t('ManageUser_Surname_HelpText')" />
                                </div>
                                <span v-if="modalMode === ManageUserModalMode.View">{{ user.userSurname }}</span>
                                <div
                                    v-if="
                                        modalMode === ManageUserModalMode.Modify ||
                                        modalMode === ManageUserModalMode.Create ||
                                        modalMode === ManageUserModalMode.ChangeProfile
                                    "
                                    class="formModifyGroup">
                                    <v-text-field
                                        v-model="localUser.userSurname"
                                        class="percent20"
                                        :rules="userSurnameRules"
                                        required
                                        @blur="generateUsername">
                                    </v-text-field>
                                </div>
                            </div>

                            <!-- Username -->
                            <div
                                v-if="configStore.enableLoginViaUsername"
                                class="flex-layout fill flex-layout-space-05 align-items-center flex-wrap-nowrap">
                                <div class="formHead">{{ $t('Username') }}</div>
                                <div class="formIcon">
                                    <HelpIcon :help-text="$t('ManageUser_Username_HelpText')" />
                                </div>
                                <span v-if="modalMode === ManageUserModalMode.View">{{ user.username }}</span>
                                <div
                                    v-if="
                                        modalMode === ManageUserModalMode.Modify ||
                                        modalMode === ManageUserModalMode.Create ||
                                        modalMode === ManageUserModalMode.ChangeProfile
                                    "
                                    class="formModifyGroup">
                                    <v-text-field
                                        v-model="localUser.username"
                                        class="percent20"
                                        :rules="usernameRules"
                                        required>
                                        <template v-slot:append-inner>
                                            <FontAwesomeIcon
                                                :icon="['fas', 'spinner']"
                                                pulse
                                                size="xl"
                                                class="swatchA2"
                                                v-if="localUser.username && isCheckingUsername" />

                                            <CircleIcon
                                                class="marginLeft marginRightSmall swatchREDBG"
                                                color="swatchWHT"
                                                :title="$t('UsernameIsNotAvailable')"
                                                v-if="
                                                    localUser.username && !isCheckingUsername && !isUsernameAvailable
                                                ">
                                                <FontAwesomeIcon :icon="['fas', 'xmark']" />
                                            </CircleIcon>

                                            <CircleIcon
                                                class="marginLeft marginRightSmall swatchGRNBG"
                                                color="swatchWHT"
                                                :title="$t('UsernameIsAvailable')"
                                                v-if="localUser.username && !isCheckingUsername && isUsernameAvailable">
                                                <FontAwesomeIcon :icon="['fas', 'check']" />
                                            </CircleIcon>
                                        </template>
                                    </v-text-field>
                                </div>
                            </div>

                            <!-- Email Address -->
                            <div class="flex-layout fill flex-layout-space-05 align-items-center flex-wrap-nowrap">
                                <div class="formHead">{{ $t('EmailAddress') }}</div>
                                <div class="formIcon">
                                    <HelpIcon :help-text="$t('ManageUser_EmailAddress_HelpText')" />
                                </div>
                                <span v-if="modalMode === ManageUserModalMode.View">{{ user.userEmailAddress }}</span>
                                <div
                                    v-if="
                                        modalMode === ManageUserModalMode.Modify ||
                                        modalMode === ManageUserModalMode.Create ||
                                        modalMode === ManageUserModalMode.ChangeProfile
                                    "
                                    class="formModifyGroup">
                                    <v-text-field
                                        v-model="localUser.userEmailAddress"
                                        class="percent20"
                                        :rules="userEmailAddressRules"
                                        required>
                                        <template v-slot:append-inner>
                                            <FontAwesomeIcon
                                                :icon="['fas', 'spinner']"
                                                pulse
                                                size="xl"
                                                class="swatchA2"
                                                v-if="localUser.userEmailAddress && isCheckingEmailAddress" />

                                            <CircleIcon
                                                class="marginLeft marginRightSmall swatchREDBG"
                                                color="swatchWHT"
                                                :title="$t('EmailAddressIsNotAvailable')"
                                                v-if="
                                                    localUser.userEmailAddress &&
                                                    !isCheckingEmailAddress &&
                                                    !isEmailAddressAvailable
                                                ">
                                                <FontAwesomeIcon :icon="['fas', 'xmark']" />
                                            </CircleIcon>

                                            <CircleIcon
                                                class="marginLeft marginRightSmall swatchGRNBG"
                                                color="swatchWHT"
                                                :title="$t('EmailAddressIsAvailable')"
                                                v-if="
                                                    localUser.userEmailAddress &&
                                                    !isCheckingEmailAddress &&
                                                    isEmailAddressAvailable
                                                ">
                                                <FontAwesomeIcon :icon="['fas', 'check']" />
                                            </CircleIcon>
                                        </template>
                                    </v-text-field>
                                </div>
                            </div>

                            <!-- Mobile Number -->
                            <div class="flex-layout fill flex-layout-space-05 align-items-center flex-wrap-nowrap">
                                <div class="formHead">{{ $t('MobileNumber') }}</div>
                                <div class="formIcon">
                                    <HelpIcon :help-text="$t('ManageUser_MobileNumber_HelpText')" />
                                </div>
                                <span v-if="modalMode === ManageUserModalMode.View">{{ user.userMobile }}</span>
                                <div
                                    v-if="
                                        modalMode === ManageUserModalMode.Modify ||
                                        modalMode === ManageUserModalMode.Create ||
                                        modalMode === ManageUserModalMode.ChangeProfile
                                    "
                                    class="formModifyGroup">
                                    <v-text-field
                                        v-model="localUser.userMobile"
                                        class="percent20"
                                        :rules="userMobileRules">
                                    </v-text-field>
                                </div>
                            </div>

                            <!-- Upload Picture -->
                            <div
                                class="flex-layout fill flex-layout-space-05 align-items-center flex-wrap-nowrap"
                                v-if="
                                    modalMode === ManageUserModalMode.Modify ||
                                    modalMode === ManageUserModalMode.Create ||
                                    modalMode === ManageUserModalMode.ChangeProfile
                                ">
                                <div class="formHead">{{ $t('Picture') }}</div>
                                <div class="formIcon">
                                    <HelpIcon :help-text="$t('ManageUser_Picture_HelpText')" />
                                </div>
                                <v-file-input
                                    v-model="localUser.userImage"
                                    :rules="[uploadedFileRule, sizeFileRule]"
                                    :label="$t('FileUploads')"
                                    :prepend-icon="null"
                                    prepend-inner-icon="mdi-paperclip"
                                    chips
                                    variant="outlined"
                                    density="compact"
                                    hide-details="auto"
                                    class="swatchA1 percent20">
                                    <template v-slot:selection="{fileNames}">
                                        <v-chip
                                            v-if="localUser.userImage"
                                            class="me-2 swatchA3BG swatchA3txt"
                                            size="small"
                                            label
                                            rounded="0">
                                            {{ (localUser.userImage as any).artifactDisplayName || fileNames[0] }}
                                        </v-chip>
                                    </template>
                                </v-file-input>
                            </div>

                            <!-- Reset Password -->
                            <div
                                class="flex-layout fill flex-layout-space-05 align-items-center flex-wrap-nowrap"
                                v-if="
                                    modalMode === ManageUserModalMode.Modify &&
                                    hasAccess('resetPassword') &&
                                    userFromSearchResults != null
                                ">
                                <div class="formHead">{{ $t('Password') }}</div>
                                <div class="formIcon">
                                    <HelpIcon :help-text="$t('ManageUser_Password_HelpText')" />
                                </div>
                                <v-btn
                                    @click="resetUserPasswordModalStore.open(userFromSearchResults)"
                                    class="v-btn--custom">
                                    <template v-slot:prepend>
                                        <FontAwesomeIcon :icon="['fal', 'envelope']" size="xl" />
                                    </template>
                                    {{ $t('ResetPassword') }}
                                </v-btn>
                            </div>
                        </v-expansion-panel-text>
                    </v-expansion-panel>

                    <!-- User Role Levels -->
                    <v-expansion-panel
                        v-if="
                            modalMode == ManageUserModalMode.View ||
                            modalMode == ManageUserModalMode.Create ||
                            modalMode == ManageUserModalMode.Modify
                        ">
                        <v-expansion-panel-title class="expansion-header">{{
                            $t('UserRoleLevels')
                        }}</v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <div v-if="localUser.userRoleLevelAssignments.length > 0" style="margin-top: 0.5rem">
                                <div
                                    class="flex-layout fill flex-layout-space-05 align-items-center flex-wrap-nowrap"
                                    v-for="(role, index) in localUser.userRoleLevelAssignments.filter(
                                        (role) => !role.isSiteSpecific,
                                    )"
                                    :key="index">
                                    <div class="formHead">{{ role.roleName }}:</div>
                                    <div class="formIcon">
                                        <HelpIcon :help-text="role.roleDescription" />
                                    </div>
                                    <span v-if="modalMode === ManageUserModalMode.View" class="percent35">{{
                                        role.roleLevelDescription
                                    }}</span>
                                    <v-select
                                        v-if="
                                            localUser.userRoleLevelAssignments &&
                                            (modalMode === ManageUserModalMode.Modify ||
                                                modalMode === ManageUserModalMode.Create)
                                        "
                                        v-model="role.roleLevel"
                                        hide-details
                                        :items="getUserRoleLevels(role.roleCode)"
                                        item-title="roleLevelDescription"
                                        item-value="roleLevel"
                                        class="percent35"></v-select>
                                </div>
                            </div>

                            <div v-else>{{ $t('UnableToRetrieveRoleDetails') }}</div>
                        </v-expansion-panel-text>
                    </v-expansion-panel>

                    <!-- Site Specific User Role Levels -->
                    <v-expansion-panel
                        v-if="
                            modalMode == ManageUserModalMode.View ||
                            modalMode == ManageUserModalMode.Create ||
                            modalMode == ManageUserModalMode.Modify
                        ">
                        <v-expansion-panel-title class="expansion-header">{{
                            $t('SiteSpecific')
                        }}</v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <div v-if="localUser.userFarmSiteRoleLevelAssignment.length > 0">
                                <v-data-table
                                    :headers="siteSpecificHeaders"
                                    :items="localUser.userFarmSiteRoleLevelAssignment"
                                    class="site-role-list"
                                    :items-per-page="-1"
                                    hide-default-footer>
                                    <template v-slot:[`item.farmSiteName`]="{item}">
                                        <div class="tableCell" style="font-size: medium">
                                            {{ item.farmSiteName }}
                                        </div>
                                    </template>

                                    <template v-slot:[`item.fieldLevel`]="{item}">
                                        <div class="tableCell">
                                            <v-select
                                                v-if="
                                                    modalMode === ManageUserModalMode.Modify ||
                                                    modalMode === ManageUserModalMode.Create
                                                "
                                                v-model="item.fieldLevel"
                                                hide-details
                                                :items="simpleRoles"
                                                item-title="title"
                                                item-value="value"
                                                width="13rem" />
                                            <span v-else style="font-size: medium">{{
                                                getUserRoleSimpleText(item.fieldLevel)
                                            }}</span>
                                        </div>
                                    </template>

                                    <template v-slot:[`item.taskLevel`]="{item}">
                                        <div class="tableCell">
                                            <v-select
                                                v-if="
                                                    modalMode === ManageUserModalMode.Modify ||
                                                    modalMode === ManageUserModalMode.Create
                                                "
                                                v-model="item.taskLevel"
                                                hide-details
                                                :items="simpleRoles"
                                                item-title="title"
                                                item-value="value"
                                                width="13rem" />
                                            <span v-else style="font-size: medium">{{
                                                getUserRoleSimpleText(item.taskLevel)
                                            }}</span>
                                        </div>
                                    </template>

                                    <template v-slot:[`item.observationLevel`]="{item}">
                                        <div class="tableCell">
                                            <v-select
                                                v-if="
                                                    modalMode === ManageUserModalMode.Modify ||
                                                    modalMode === ManageUserModalMode.Create
                                                "
                                                v-model="item.observationLevel"
                                                hide-details
                                                :items="simpleRoles"
                                                item-title="title"
                                                item-value="value"
                                                width="13rem" />
                                            <span v-else style="font-size: medium">{{
                                                getUserRoleSimpleText(item.observationLevel)
                                            }}</span>
                                        </div>
                                    </template>

                                    <template v-slot:[`item.userFarmSitePosition`]="{item}">
                                        <div class="tableCell">
                                            <v-select
                                                v-if="
                                                    modalMode === ManageUserModalMode.Modify ||
                                                    modalMode === ManageUserModalMode.Create
                                                "
                                                v-model="item.userFarmSitePosition"
                                                :items="userFarmSitePositionsWithNull"
                                                :item-title="(item: UserFarmSitePosition) => item.getUserFarmPositionName()"
                                                item-value="userFarmSitePositionCode"
                                                width="13rem" />
                                            <span v-else style="font-size: medium">{{
                                                getUserFarmPositionName(item.userFarmSitePosition)
                                            }}</span>
                                        </div>
                                    </template>
                                </v-data-table>
                            </div>

                            <div v-else class="pa-4">{{ $t('UserHasNoFarmSitesAssigned') }}</div>
                        </v-expansion-panel-text>
                    </v-expansion-panel>

                    <!-- Platform and Usage -->
                    <v-expansion-panel v-if="modalMode == ManageUserModalMode.View">
                        <v-expansion-panel-title class="expansion-header pnlPlatformUsage">
                            {{ $t('PlatformAndUsage') }}
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <div style="display: flex; justify-content: space-between; flex-wrap: wrap">
                                <!-- Mobile -->
                                <div style="flex-basis: 49.675%">
                                    <!-- Mobile Header -->
                                    <div
                                        class="formHeader swatchBLK swatchA5BG"
                                        style="margin-top: 0.5rem; margin-bottom: 1rem">
                                        <CircleIcon
                                            class="marginLeft marginRightSmall swatchWHTBG swatchA2Border"
                                            color="swatchA2">
                                            <FontAwesomeIcon :icon="['fal', 'mobile']" />
                                        </CircleIcon>
                                        <div style="margin-left: 1rem">{{ $t('MobilePlatform') }}</div>
                                    </div>

                                    <div class="marginLeft">
                                        <!-- Primary Mobile -->
                                        <div class="flexRow marginBottom">
                                            <div style="min-width: 10rem">{{ $t('PrimaryMobile') }}</div>
                                            <HelpIcon
                                                :help-text="$t('ManageUser_PrimaryMobile_HelpText')"
                                                style="margin-right: 2rem" />
                                            <!-- Device Platform -->
                                            <div class="flexRow" style="width: 100%">
                                                <CircleIcon
                                                    v-if="
                                                        user.lastLoggedInMobileDevicePlatform ==
                                                        MobileDevicePlatform.iOS
                                                    "
                                                    class="marginRightSmall">
                                                    <FontAwesomeIcon :icon="['fab', 'apple']" />
                                                </CircleIcon>
                                                <CircleIcon
                                                    v-if="
                                                        user.lastLoggedInMobileDevicePlatform ==
                                                        MobileDevicePlatform.Android
                                                    "
                                                    class="marginRightSmall">
                                                    <FontAwesomeIcon :icon="['fab', 'android']" />
                                                </CircleIcon>
                                                <div
                                                    v-if="user.lastLoggedInMobileDevicePlatform != null"
                                                    class="flexRow"
                                                    style="inline-size: inherit">
                                                    <span
                                                        v-if="
                                                            user.lastLoggedInMobileDevicePlatform ==
                                                            MobileDevicePlatform.iOS
                                                        "
                                                        >{{ $t('Ios') }}</span
                                                    >
                                                    <span
                                                        v-else-if="
                                                            user.lastLoggedInMobileDevicePlatform ==
                                                            MobileDevicePlatform.Android
                                                        "
                                                        >{{ $t('Android') }}</span
                                                    >
                                                    <span v-else>{{ $t('Other') }}</span>
                                                    <span class="swatchA2 marginLeftSmall">{{
                                                        user.lastLoggedInMobileDevicePlatformVersion
                                                    }}</span>
                                                </div>
                                                <div v-if="user.lastLoggedInMobileDevicePlatform != null">
                                                    <!-- TODO: Add purge device button -->
                                                    <!-- <input
                                                        type="button"
                                                        class="btn center trash-can-xmark marginRight"
                                                        @click="openConfirmPurgeDevice()"
                                                        style="padding: 1rem" /> -->
                                                </div>
                                                <span v-else>{{ $t('NA') }}</span>
                                            </div>
                                        </div>
                                        <!-- Application Version -->
                                        <div class="flexRow marginBottom">
                                            <div style="min-width: 10rem">{{ $t('ApplicationVersion') }}</div>
                                            <HelpIcon
                                                :help-text="$t('ManageUser_ApplicationVersion_HelpText')"
                                                style="margin-right: 2rem" />
                                            <span v-if="user.appVersion != null" class="swatchA2">
                                                {{ user.appVersion }}
                                            </span>
                                            <span v-else>{{ $t('NA') }}</span>
                                        </div>
                                        <!-- First Active -->
                                        <div class="flexRow marginBottom">
                                            <div style="min-width: 10rem">{{ $t('FirstActive') }}</div>
                                            <HelpIcon
                                                :help-text="$t('ManageUser_FirstActiveMobile_HelpText')"
                                                style="margin-right: 2rem" />
                                            <span v-if="user.dateFirstActiveApp != null" class="swatchA2">
                                                {{ formatDate(user.dateFirstActiveApp) }}
                                            </span>
                                            <span v-else>{{ $t('NA') }}</span>
                                        </div>
                                        <!-- Last Active -->
                                        <div class="flexRow marginBottom">
                                            <div style="min-width: 10rem">{{ $t('LastActive') }}</div>
                                            <HelpIcon
                                                :help-text="$t('ManageUser_LastActiveMobile_HelpText')"
                                                style="margin-right: 2rem" />
                                            <span v-if="user.dateLastActiveApp" class="swatchA2">
                                                {{ formatDate(user.dateLastActiveApp) }}
                                            </span>
                                            <span v-else>{{ $t('NA') }}</span>
                                        </div>
                                        <!-- Notifications -->
                                        <div class="flexRow marginBottom">
                                            <div style="min-width: 10rem">{{ $t('Notifications') }}</div>
                                            <HelpIcon
                                                :help-text="$t('ManageUser_Notifications_HelpText')"
                                                style="margin-right: 2rem" />
                                            <div
                                                v-if="
                                                    user.isPushNotificationEnabled &&
                                                    user.isPushNotificationEnabled != null
                                                "
                                                class="flexRow">
                                                <CircleIcon class="marginRightSmall swatchGRNBG">
                                                    <FontAwesomeIcon :icon="['fal', 'check']" />
                                                </CircleIcon>
                                                <span>{{ $t('Enabled') }}</span>
                                            </div>
                                            <div
                                                v-else-if="
                                                    !user.isPushNotificationEnabled &&
                                                    user.isPushNotificationEnabled != null
                                                "
                                                class="flexRow">
                                                <CircleIcon class="marginRightSmall swatchG4BG">
                                                    <FontAwesomeIcon :icon="['fal', 'xmark']" />
                                                </CircleIcon>
                                                <span>{{ $t('Disabled') }}</span>
                                            </div>
                                            <span v-else>{{ $t('NA') }}</span>
                                        </div>
                                        <!-- Devices -->
                                        <div class="flexRow marginBottom">
                                            <div style="min-width: 10rem">{{ $t('Devices') }}</div>
                                            <HelpIcon
                                                :help-text="$t('ManageUser_Devices_HelpText')"
                                                style="margin-right: 2rem" />
                                            <div
                                                v-if="user.numIosDevices != null || user.numAndroidDevices != null"
                                                class="flexRow">
                                                <div class="flexRow">
                                                    <CircleIcon class="marginRightSmall">
                                                        <FontAwesomeIcon :icon="['fab', 'apple']" />
                                                    </CircleIcon>
                                                    <span class="swatchA2" style="margin-right: 2rem">
                                                        {{ user.numIosDevices }}
                                                    </span>
                                                </div>
                                                <div class="flexRow marginRight">
                                                    <CircleIcon class="marginRightSmall">
                                                        <FontAwesomeIcon :icon="['fab', 'android']" />
                                                    </CircleIcon>
                                                    <span class="swatchA2">{{ user.numAndroidDevices }}</span>
                                                </div>

                                                <!-- TODO: Add View Devices button -->
                                                <!-- <input
                                                    type="button"
                                                    :title="$t('ViewDevices')"
                                                    class="btn icn eye marginLeft"
                                                    @click.stop="openViewDevices(user.userId)" /> -->
                                            </div>
                                            <span v-else>{{ $t('NA') }}</span>
                                        </div>
                                    </div>
                                </div>

                                <!-- Browser -->
                                <div style="flex-basis: 49.675%">
                                    <!-- Browser Header -->
                                    <div
                                        class="formHeader swatchBLK swatchA5BG"
                                        style="margin-top: 0.5rem; margin-bottom: 1rem">
                                        <CircleIcon
                                            class="marginLeft marginRightSmall swatchWHTBG swatchA2Border"
                                            color="swatchA2">
                                            <FontAwesomeIcon :icon="['fal', 'browser']" />
                                        </CircleIcon>
                                        <div style="margin-left: 1rem">{{ $t('BrowserPlatform') }}</div>
                                    </div>

                                    <div class="marginLeft">
                                        <!-- Primary Browser -->
                                        <div class="flexRow marginBottom">
                                            <div style="min-width: 10rem">{{ $t('PrimaryBrowser') }}</div>
                                            <HelpIcon
                                                :help-text="$t('ManageUser_PrimaryBrowser_HelpText')"
                                                style="margin-right: 2rem" />
                                            <!-- Browser Platform -->
                                            <div class="flexRow">
                                                <div v-if="user.lastLoggedInWebBrowser" class="flexRow">
                                                    <CircleIcon class="marginRightSmall">
                                                        <FontAwesomeIcon :icon="browserIcon" />
                                                    </CircleIcon>
                                                    <span>
                                                        {{ user.lastLoggedInWebBrowser }}
                                                        <span
                                                            v-if="user.lastLoggedInWebBrowserVersion != null"
                                                            class="swatchA2 marginLeftSmall">
                                                            {{ user.lastLoggedInWebBrowserVersion }}
                                                        </span>
                                                    </span>
                                                </div>
                                                <span v-else>{{ $t('NA') }}</span>
                                            </div>
                                        </div>
                                        <!-- First Active -->
                                        <div class="flexRow marginBottom">
                                            <div style="min-width: 10rem">{{ $t('FirstActive') }}</div>
                                            <HelpIcon
                                                :help-text="$t('ManageUser_FirstActiveWeb_HelpText')"
                                                style="margin-right: 2rem" />
                                            <span v-if="user.dateFirstActiveWeb" class="swatchA2">
                                                {{ formatDate(user.dateFirstActiveWeb) }}
                                            </span>
                                            <span v-else>{{ $t('NA') }}</span>
                                        </div>
                                        <!-- Last Active -->
                                        <div class="flexRow marginBottom">
                                            <div style="min-width: 10rem">{{ $t('LastActive') }}</div>
                                            <HelpIcon
                                                :help-text="$t('ManageUser_LastActiveWeb_HelpText')"
                                                style="margin-right: 2rem" />
                                            <span v-if="user.dateLastActiveWeb" class="swatchA2">
                                                {{ formatDate(user.dateLastActiveWeb) }}
                                            </span>
                                            <span v-else>{{ $t('NA') }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- TODO: Add Usage Summary chart -->
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-form>

            <v-card-actions class="justify-space-between swatchG9BG">
                <v-btn @click="close">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'arrow-rotate-left']" size="xl" />
                    </template>
                    {{ modalMode == ManageUserModalMode.View ? $t('Close') : $t('Cancel') }}
                </v-btn>
                <v-btn @click="save" v-if="modalMode != ManageUserModalMode.View" :disabled="isLoading">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'save']" size="xl" />
                    </template>
                    {{ $t('Save') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" timeout="4000">
        {{ snackbar.text }}
    </v-snackbar>
</template>

<script setup lang="ts">
import '@/assets/scss/user/user-manager.scss';

import {ManageUserModalMode} from '@/enums/modal-modes/manage-user-modal-mode';
import {RoleCode} from '@/enums/role-code';
import {useConfigStore} from '@/stores/config-store';
import {useUserStore} from '@/stores/user-store';
import {useDeleteUserModalStore} from '@/stores/modals/delete-user-modal-store';
import {useManageUserModalStore} from '@/stores/modals/manage-user-modal-store';
import {ManageUserForm} from '@/models/users/ManageUserForm';
import {computed, watch, inject, ref, onMounted} from 'vue';
import {debounce} from 'lodash';
import {UserStatus} from '@/enums/user-status';
import {SearchUsersResponse} from '@/models/api/responses/search/SearchUsersResponse';
import {SearchUsersRequest} from '@/models/api/requests/search/SearchUsersRequest';
import ApiService from '@/services/api-service.js';
import {format} from 'date-fns';
import CloseModalButton from '@/components/CloseModalButton.vue';
import {MobileDevicePlatform} from '@/enums/mobile-device-platform';
import {GetUserRoleLevelsResponse} from '@/models/api/responses/data-source/GetUserRoleLevelsResponse';
import {CheckUsernameAvailableRequest} from '@/models/api/requests/users/CheckUsernameAvailableRequest';
import {CheckEmailAddressAvailableRequest} from '@/models/api/requests/users/CheckEmailAddressAvailableRequest';
import SignupVerificationManager from './SignupVerificationManager.vue';
import {SaveUserRequest} from '@/models/api/requests/users/SaveUserRequest';
import {ChangeProfileRequest} from '@/models/api/requests/users/ChangeProfileRequest';
import i18n from '@/i18n';
import {useResetUserPasswordModalStore} from '@/stores/modals/reset-user-password-modal-store';
import UserChip from '@/components/UserChip.vue';
import Validation from '@/helpers/ValidationHelper';
import {UploadedArtifactDto} from '@/models/data-transfer-objects/system/UploadedArtifactDto';
import {ArtifactFolder} from '@/enums/artifact-folder';
import {ArtifactTypeCode} from '@/enums/artifact-type-code';
import FileUploadHelper from '@/helpers/FileUploadHelper';
import {ArtifactFileTypeCode} from '@/enums/artifact-file-type-code';
import UserHelper from '@/helpers/UserHelper';
import {ArtifactDto} from '@/models/data-transfer-objects/system/ArtifactDto';
import {GetUserRoleLevelAssignmentsResponse} from '@/models/api/responses/users/GetUserRoleLevelAssignmentsResponse';
import {UserFarmSitePosition, userFarmSitePositions} from '@/services/user-farm-site-position-service';

// Config settings
const configStore = useConfigStore();
const regexEmail = configStore.regexEmail ? new RegExp(configStore.regexEmail) : null;
const regexMobile = configStore.regexMobile ? new RegExp(configStore.regexMobile) : null;
const regexUsername = configStore.regexUsername ? new RegExp(configStore.regexUsername) : null;

// Form
const form = ref();
const valid = ref<boolean>(true);
let isLoading = ref<boolean>(false);
const openedExpansionPanels = ref<number[]>([0, 1, 2]);
const snackbar = ref({
    show: false,
    text: '',
});

// Rules
const userGivenNameRules = ref([(v: string | null) => !!v || i18n.global.t('Validation_Required')]);
const userSurnameRules = ref([(v: string | null) => !!v || i18n.global.t('Validation_Required')]);
const usernameRules = ref([
    (v: string | null) => !!v || i18n.global.t('Validation_Required'),
    (v: string | null) => {
        if (!v || !regexUsername) return true;
        return regexUsername.test(v) || i18n.global.t('Validation_InvalidFormat');
    },
    async (v: string | null) => {
        if (!v) return true;
        if (isCheckingUsername.value == true) return false;
        return await checkUsernameAvailable(v);
    },
]);
const userEmailAddressRules = ref([
    (v: string | null) => !!v || i18n.global.t('Validation_Required'),
    (v: string | null) => {
        if (!v || !regexEmail) return true;
        return regexEmail.test(v) || i18n.global.t('Validation_InvalidFormat');
    },
    async (v: string | null) => {
        if (!v) return true;
        if (isCheckingEmailAddress.value == true) return false;
        return await checkEmailAddressAvailable(v);
    },
]);
const userMobileRules = ref([
    (v: string | null) => {
        if (!v || !regexMobile) return true;
        return regexMobile.test(v) || i18n.global.t('Validation_InvalidFormat');
    },
]);
const uploadedFileRule = Validation.createRule_UploadedFile([ArtifactFileTypeCode.Image]);
const sizeFileRule = Validation.createRule_Image(128, 128);

// Services
const apiService = inject('apiService') as ApiService;

// User modal
const deleteUserModalStore = useDeleteUserModalStore();
const manageUserModalStore = useManageUserModalStore();
const resetUserPasswordModalStore = useResetUserPasswordModalStore();
const modalMode = computed(() => manageUserModalStore.modalMode);
const userFromSearchResults = ref<SearchUsersResponse | null>(null);
const user = ref<ManageUserForm>(new ManageUserForm());
const localUser = ref<ManageUserForm>({...user.value});
const userRoleLevels = ref<GetUserRoleLevelsResponse[]>([]);
const isCheckingUsername = ref(false);
const isUsernameAvailable = ref(true);
const isCheckingEmailAddress = ref(false);
const isEmailAddressAvailable = ref(true);
const userProfilePicture = ref<ArtifactDto | null>(null);

// User access
const userStore = useUserStore();
const hasModifyStaffAccess = computed(() => userStore.hasRoleLevel(RoleCode.System, 2));
const hasFullStaffAccess = computed(() => userStore.hasRoleLevel(RoleCode.System, 3));
const systemLevel = computed(() => userStore.getRoleLevel(RoleCode.System));

const userFarmSitePositionsWithNull = ref<UserFarmSitePosition[]>(userFarmSitePositions);

/**
 * Load data for the modal.
 */
const loadData = async (userId: number | null) => {
    isLoading.value = true;

    // Reset form
    resetForm();

    // Get user role levels if they have not already been loaded
    if (
        userRoleLevels.value.length == 0 &&
        (modalMode.value == ManageUserModalMode.Create || modalMode.value == ManageUserModalMode.Modify)
    ) {
        userRoleLevels.value = await apiService.get('data-source/user-role-levels');
    }

    // If a user ID was specified
    if (userId !== null) {
        // Build search request using the user ID
        const searchRequest: SearchUsersRequest = new SearchUsersRequest({
            isQuickSearch: false,
            userId: userId,
        });

        // Get user details
        const searchResults = (await apiService.post('search/users', searchRequest)) as SearchUsersResponse[];

        if (searchResults.length == 1) {
            // There is a single result, so set the user object to that value
            userFromSearchResults.value = searchResults[0];
            user.value = Object.assign(new ManageUserForm(), userFromSearchResults.value);
            userProfilePicture.value = searchResults[0].userImage ?? null;
        }
    }

    // Get user role level assignments
    const results = (await apiService.post(
        'users/role-level-assignments',
        user.value.userId,
    )) as GetUserRoleLevelAssignmentsResponse;
    user.value.userRoleLevelAssignments = results.userRoleLevelAssignments;
    user.value.userFarmSiteRoleLevelAssignment = results.userFarmSiteRoleLevelAssignments;

    // Update value of local user
    localUser.value = {...user.value};

    setTimeout(() => {
        isLoading.value = false;
    }, 500);
};

/**
 * Checks if the specified username is available. Debounce is used to prevent the API from being called too often.
 * TODO: Currently this only prevents the API from being called more than once every 500ms. We should change it so
 * that it prevents the API from being called until 500ms after the value is last updated. We might also want to
 * move this into a reusable component at some point.
 */
const debouncedCheckUsernameAvailable = debounce(
    async (username: string, resolve: (value: string | boolean) => void) => {
        const request: CheckUsernameAvailableRequest = {
            username,
            userId: user.value.userId,
        };

        const isAvailable = await apiService.post('users/check-username-available', request);
        isCheckingUsername.value = false;
        isUsernameAvailable.value = isAvailable;

        resolve(isAvailable || i18n.global.t('Validation_UsernameAlreadyTaken'));
    },
    500,
);

/**
 * Checks if the specified username is available.
 */
const checkUsernameAvailable = async (username: string): Promise<string | boolean> => {
    isCheckingUsername.value = true;

    return new Promise((resolve) => {
        debouncedCheckUsernameAvailable(username, resolve);
    });
};

/**
 * Checks if the specified email address is available. Debounce is used to prevent the API from being called too often.
 * TODO: See debouncedCheckUsernameAvailable TODO.
 */
const debouncedCheckEmailAddressAvailable = debounce(
    async (userEmailAddress: string, resolve: (value: string | boolean) => void) => {
        const request: CheckEmailAddressAvailableRequest = {
            userEmailAddress,
            userId: user.value.userId,
        };

        const isAvailable = await apiService.post('users/check-email-address-available', request);
        isCheckingEmailAddress.value = false;
        isEmailAddressAvailable.value = isAvailable;

        resolve(isAvailable || i18n.global.t('Validation_EmailAddressAlreadyTaken'));
    },
    500,
);

/**
 * Checks if the specified email address is available.
 */
const checkEmailAddressAvailable = async (userEmailAddress: string): Promise<string | boolean> => {
    isCheckingEmailAddress.value = true;

    return new Promise((resolve) => {
        debouncedCheckEmailAddressAvailable(userEmailAddress, resolve);
    });
};

/**
 * Generates a username based on the first/last name.
 */
const generateUsername = () => {
    if (
        configStore.enableLoginViaUsername &&
        localUser.value.userGivenName != null &&
        localUser.value.userGivenName !== '' &&
        localUser.value.userSurname != null &&
        localUser.value.userSurname !== '' &&
        (localUser.value.username == null || localUser.value.username === '')
    ) {
        localUser.value.username =
            localUser.value.userSurname.toLowerCase() + localUser.value.userGivenName.substr(0, 1).toLowerCase();
    }
};

/**
 * Close the modal.
 */
const close = () => {
    manageUserModalStore.close();
};

/**
 * Save the modal.
 */
const save = async () => {
    // Perform final client side validation of form
    // TODO: Improve the way this validation is done. Currently it does the availability checks before showing the loading image.
    await form.value.validate();

    // If form is valid
    if (valid.value) {
        let snackbarMessage = '';
        isLoading.value = true;

        try {
            if (modalMode.value == ManageUserModalMode.ChangeProfile) {
                // Build API request
                const changeProfileRequest: ChangeProfileRequest = {
                    userId: localUser.value.userId,
                    username: localUser.value.username!.trim(),
                    userGivenName: localUser.value.userGivenName!.trim(),
                    userSurname: localUser.value.userSurname!.trim(),
                    userEmailAddress: localUser.value.userEmailAddress!.trim(),
                    userMobile: localUser.value.userMobile?.trim() === '' ? null : localUser.value.userMobile,
                    userImage:
                        localUser.value.userImage && localUser.value.hasPictureBeenUpdated
                            ? new UploadedArtifactDto({
                                  file: localUser.value.userImage,
                                  artifactFolder: ArtifactFolder.UserPhotos,
                                  artifactTypeCode: ArtifactTypeCode.UserImage,
                              })
                            : null,
                    hasPictureBeenUpdated: localUser.value.hasPictureBeenUpdated,
                };

                // Call API to change profile
                await apiService.post(
                    'users/change-profile',
                    FileUploadHelper.createFormDataObject(changeProfileRequest),
                );

                // Set snackbar message
                snackbarMessage = i18n.global.t('ChangeProfile_Success');
            } else {
                // Build API request
                const saveUserRequest: SaveUserRequest = {
                    userId: localUser.value.userId,
                    username: localUser.value.username?.trim() || null,
                    userGivenName: localUser.value.userGivenName!.trim(),
                    userSurname: localUser.value.userSurname!.trim(),
                    userEmailAddress: localUser.value.userEmailAddress!.trim(),
                    userMobile: localUser.value.userMobile?.trim() === '' ? null : localUser.value.userMobile,
                    userRoleLevelAssignments: localUser.value.userRoleLevelAssignments.map((assignment) => ({
                        roleCode: assignment.roleCode,
                        roleLevel: assignment.roleLevel,
                    })),
                    userFarmSiteRoleLevelAssignments: localUser.value.userFarmSiteRoleLevelAssignment,
                    userImage:
                        localUser.value.userImage && localUser.value.hasPictureBeenUpdated
                            ? new UploadedArtifactDto({
                                  file: localUser.value.userImage,
                                  artifactFolder: ArtifactFolder.UserPhotos,
                                  artifactTypeCode: ArtifactTypeCode.UserImage,
                              })
                            : null,
                    hasPictureBeenUpdated: localUser.value.hasPictureBeenUpdated,
                };

                // Call API to save user
                await apiService.post('users/user', FileUploadHelper.createFormDataObject(saveUserRequest));

                // Set snackbar message
                if (modalMode.value == ManageUserModalMode.Create) {
                    snackbarMessage = i18n.global.t('CreateUser_Success');
                } else if (modalMode.value == ManageUserModalMode.Modify) {
                    snackbarMessage = i18n.global.t('ModifyUser_Success');
                }
            }

            // Show success feedback to user
            snackbar.value.show = true;
            snackbar.value.text = snackbarMessage;
            manageUserModalStore.savedCounter++;

            // Update user store
            userStore.updateAfterSavingUser(localUser.value, modalMode.value);

            // Close modal
            manageUserModalStore.close();
        } catch (ex: unknown) {
            // Show fail feedback to user
            console.error(ex);
            snackbar.value.show = true;
            snackbar.value.text = i18n.global.t('ErrorGeneric');
            isLoading.value = false;
        }
    }
};

/**
 * Reset the form to its default state.
 */
const resetForm = () => {
    //TODO: Figure out a better way to do this than specifying the indexes.
    openedExpansionPanels.value = [0, 1, 2];
    user.value = new ManageUserForm();
    localUser.value = {...user.value};
    isCheckingUsername.value = false;
    isUsernameAvailable.value = true;
    userFarmSitePositionsWithNull.value = userFarmSitePositions;
};

/**
 * Check if the session user can have access.
 * NOTE: It would make more sense to perform this logic in the API/service and returning a boolean in the search results. Then
 * we could show/hide operations menu items in the search results form depending on whether the user has access. For now we will
 * leave it like this though, because this is how it's been done previously.
 *
 * @param type Type of access.
 */
const hasAccess = (type: string) => {
    // Get system level of the selected user.
    let hasAccess = false,
        selectedUserSystemLevel =
            (user.value && user.value.userRoleLevelAssignments
                ? user.value.userRoleLevelAssignments.find((role) => role.roleCode === RoleCode.System)?.roleLevel
                : 0) ?? 0;

    switch (type) {
        // Modifying a staff user requires system 2 or 3 and the level to be higher than selected user.
        case 'modifyUser':
            if (hasModifyStaffAccess.value && systemLevel.value >= selectedUserSystemLevel) {
                hasAccess = true;
            }
            break;

        // resetPassword:
        // 1. userStatusCode must be 'ACTIVE' and also meets the following requirements
        //      a. Reseting a staff user password requires system level 3.
        case 'resetPassword':
            if (user.value.userStatus === UserStatus.Active && hasFullStaffAccess) {
                hasAccess = true;
            }
            break;
    }

    return hasAccess;
};

/**
 * Format date for display.
 */
const formatDate = (date: Date | null): string | null => {
    if (!date) return null;

    return format(date, 'dd/MM/yyyy HH:mm');
};

/**
 * Get the role levels for a specific role.
 */
const getUserRoleLevels = (roleCode: string): GetUserRoleLevelsResponse[] => {
    return userRoleLevels.value
        .filter((roleLevel) => roleLevel.roleCode === roleCode)
        .sort((a, b) => a.roleLevel - b.roleLevel);
};

/**
 * Get simplified text for user level.
 */
const simpleRoles = ref([
    {value: 0, title: i18n.global.t('NoAccess')},
    {value: 1, title: i18n.global.t('ReadOnlyAccess')},
    {value: 2, title: i18n.global.t('PartialAccess')},
    {value: 3, title: i18n.global.t('FullAccess')},
]);

/**
 * Headers for the site specific roles table.
 */
const siteSpecificHeaders = ref([
    {title: i18n.global.t('SiteName'), key: 'farmSiteName', sortable: false, width: '20%'},
    {title: i18n.global.t('FarmFields'), key: 'fieldLevel', sortable: false, width: '20%'},
    {title: i18n.global.t('Tasks'), key: 'taskLevel', sortable: false, width: '20%'},
    {title: i18n.global.t('Observations'), key: 'observationLevel', sortable: false, width: '20%'},
    {title: i18n.global.t('Position'), key: 'userFarmSitePosition', sortable: false, width: '20%'},
]);

/**
 * Get simplified access text based on role level.
 */
const getUserRoleSimpleText = (roleLevel: number): string => {
    const simpleRole = simpleRoles.value.find((role) => role.value === roleLevel);

    return simpleRole ? simpleRole.title : i18n.global.t('NoAccess');
};

/**
 * Get farm position name based on position code.
 */
const getUserFarmPositionName = (positionCode: string) => {
    return userFarmSitePositions.find((sp) => sp.userFarmSitePositionCode == positionCode)?.getUserFarmPositionName();
};

/**
 * The title of the modal.
 */
const modalTitle = computed(() => {
    let title = '';

    switch (modalMode.value) {
        case ManageUserModalMode.View:
            title = i18n.global.t('ViewUser');
            break;
        case ManageUserModalMode.Modify:
            title = i18n.global.t('ModifyUser');
            break;
        case ManageUserModalMode.Create:
            title = i18n.global.t('CreateUser');
            break;
        case ManageUserModalMode.ChangeProfile:
            title = i18n.global.t('ChangeProfile');
            break;
    }
    return title;
});

/**
 * The icon to show next to the user's web browser.
 */
const browserIcon = computed(() => {
    let browserIcon = ['fas', 'question'];

    switch (user.value.lastLoggedInWebBrowser) {
        case 'Chrome':
            browserIcon = ['fab', 'chrome'];
            break;
        case 'Edge':
            browserIcon = ['fab', 'edge'];
            break;
        case 'Firefox':
            browserIcon = ['fab', 'firefox'];
            break;
        case 'IE':
            browserIcon = ['fab', 'internet-explorer'];
            break;
        case 'Opera':
            browserIcon = ['fab', 'opera'];
            break;
        case 'Safari':
            browserIcon = ['fab', 'safari'];
            break;
    }

    return browserIcon;
});

// Watch for changes to manageUserModalStore.userId
watch(
    () => manageUserModalStore.isVisible,
    async (isVisible) => {
        if (isVisible) {
            await loadData(manageUserModalStore.userId);
        }
    },
);

watch(
    () => deleteUserModalStore.deletedCounter,
    (newVal, oldVal) => {
        // If a user has been deleted, close this modal
        if (newVal > oldVal) {
            close();
        }
    },
);

// Update flag to indicate that the picture has been updated
watch(
    () => localUser.value.userImage,
    () => {
        if (!isLoading.value) {
            localUser.value.hasPictureBeenUpdated = true;
        }
    },
);

// Set values when mounted
onMounted(() => {
    // Add null farm site position
    userFarmSitePositionsWithNull.value.unshift({
        userFarmSitePositionCode: null,
        getUserFarmPositionName() {
            return '';
        },
    });
});
</script>
