<template>
    <v-chip variant="flat" pill :size="size" :color="lighterColor" class="pr-5 work-task-type-chip">
        <v-avatar start>
            <CircleIcon :style="{height: '40px', width: '40px'}" :class="iconClass">
                <FontAwesomeIcon :icon="[iconFamily, iconCode]" :size="iconSize" />
            </CircleIcon>
        </v-avatar>

        <div class="chip-text" :class="{'large-text': size == 'xlarge'}">
            <span>{{ name }}</span>
        </div>
    </v-chip>
</template>

<script lang="ts">
import {PropType, defineComponent, computed} from 'vue';
import {getWorkTaskTypeByCode, WorkTaskType} from '@/services/work-task-types-service';
import {WorkTaskTypeCode} from '@/enums/work-task-type-code';
import {getSwatchColor, lightenColor} from '@/helpers/SwatchHelper';
import {SwatchCode} from '@/enums/swatch-code';

export default defineComponent({
    props: {
        workTaskTypeCode: String as PropType<WorkTaskTypeCode>,
        size: {
            type: String,
            default: 'xlarge',
        },
        iconSize: {
            type: String,
            default: 'xl',
        },
    },
    setup(props) {
        const workTaskType = computed<WorkTaskType | undefined>(() => {
            return getWorkTaskTypeByCode(props.workTaskTypeCode as WorkTaskTypeCode);
        });

        const iconFamily = computed<string>(() => {
            return workTaskType.value?.iconFamily || 'fas';
        });

        const iconCode = computed<string>(() => {
            return workTaskType.value?.iconCode || 'question';
        });

        const color = computed<string>(() => {
            return workTaskType.value?.color || SwatchCode.SwatchBLK;
        });

        const lighterColor = computed<string>(() => {
            const swatch = workTaskType.value?.color || SwatchCode.SwatchBLK;
            const hexColor = getSwatchColor(swatch);
            return lightenColor(hexColor, 15);
        });

        const name = computed<string | null>(() => {
            return workTaskType.value?.getWorkTaskTypeName() || null;
        });

        const iconClass = computed<string>(() => {
            return `work-task-type-icon bg-${color.value}`;
        });

        return {
            iconFamily,
            iconCode,
            name,
            iconClass,
            lighterColor,
        };
    },
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/swatches.scss';

.chip-text {
    font-weight: bold;
    font-size: 12pt;
    color: $swatchWHT;

    &.large-text {
        font-size: 14pt;
    }
}
</style>
