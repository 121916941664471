<template>
    <v-menu v-model="isOperationsActive" open-on-click>
        <template v-slot:activator="{props}">
            <v-btn
                class="operation-button icon-only"
                :class="isOperationsActive || homeDashboardStore.selectedWorkTaskLevel2 ? 'active-button' : ''"
                elevation="2"
                rounded="x-large"
                v-bind="props">
                <FontAwesomeIcon :icon="['fal', 'bars']" size="xl" />
            </v-btn>
        </template>

        <v-list>
            <v-list-item v-if="workTask">
                <v-list-item-title>
                    <v-btn @click="viewWorkTaskModalStore.open(workTask.workTaskId)">
                        {{ $t('ViewTaskDetails') }}
                    </v-btn>
                </v-list-item-title>
                <v-list-item-title>
                    <v-btn @click="viewWorkTaskModalStore.open(workTask.workTaskId, true)">
                        {{ $t('ExportSlashPrintTask') }}
                    </v-btn>
                </v-list-item-title>
                <v-list-item-title v-if="userStore.hasRoleLevel(RoleCode.Tasks, 2)">
                    <v-btn
                        @click="
                            manageWorkTaskModalStore.open(
                                ManageWorkTaskModalMode.Modify,
                                workTask.workTaskId,
                                workTask.farmFieldId,
                            )
                        ">
                        {{ $t('ModifyTask') }}
                    </v-btn>
                </v-list-item-title>
                <v-list-item-title v-if="userStore.hasRoleLevel(RoleCode.Tasks, 2)">
                    <v-btn @click="actionWorkTaskModalStore.open(workTask.workTaskId)">
                        {{ $t('ActionTask') }}
                    </v-btn>
                </v-list-item-title>
                <v-list-item-title v-if="userStore.hasRoleLevel(RoleCode.Tasks, 3)">
                    <v-btn @click="deleteOrCancelWorkTaskModalStore.open(workTask.workTaskId)">
                        {{ $t('CancelTask') }}
                    </v-btn>
                </v-list-item-title>
                <v-list-item-title v-if="userStore.hasRoleLevel(RoleCode.Tasks, 2)">
                    <v-btn @click="rescheduleWorkTaskModalStore.open(workTask.workTaskId)">
                        {{ $t('RescheduleTask') }}
                    </v-btn>
                </v-list-item-title>
                <v-list-item-title v-if="userStore.hasRoleLevel(RoleCode.Tasks, 2)">
                    <v-btn
                        @click="manageWorkTaskModalStore.open(ManageWorkTaskModalMode.Duplicate, workTask.workTaskId)">
                        {{ $t('DuplicateTask') }}
                    </v-btn>
                </v-list-item-title>
                <v-list-item-title v-if="userStore.hasRoleLevel(RoleCode.PlansAndTemplates, 2)">
                    <v-btn
                        @click="
                            manageWorkTaskModalStore.open(ManageWorkTaskModalMode.CreateTemplate, workTask.workTaskId)
                        ">
                        {{ $t('CreateTemplateFromTask') }}
                    </v-btn>
                </v-list-item-title>
                <v-list-item-title v-if="userStore.hasRoleLevel(RoleCode.Fields, 1)">
                    <v-btn @click="viewOnMap(workTask.farmFieldId)">
                        {{ $t('SelectField') }}
                    </v-btn>
                </v-list-item-title>
            </v-list-item>

            <v-list-item v-else-if="farmField && navigatorLevel == 2">
                <v-list-item-title v-if="userStore.hasRoleLevel(RoleCode.Fields, 1)">
                    <v-btn @click="viewFarmFieldModalStore.open(farmField.farmFieldId)">
                        {{ $t('ViewField') }}
                    </v-btn>
                </v-list-item-title>
                <v-list-item-title v-if="userStore.hasRoleLevel(RoleCode.Fields, 3)">
                    <v-btn @click="manageFarmFieldModalStore.open(farmField.farmFieldId)">
                        {{ $t('ModifyField') }}
                    </v-btn>
                </v-list-item-title>
                <v-list-item-title v-if="userStore.hasRoleLevel(RoleCode.Tasks, 2)">
                    <v-btn
                        @click="
                            manageWorkTaskModalStore.open(ManageWorkTaskModalMode.Create, null, farmField.farmFieldId)
                        ">
                        {{ $t('CreateNewTaskInField') }}
                    </v-btn>
                </v-list-item-title>
                <v-list-item-title v-if="userStore.hasRoleLevel(RoleCode.Tasks, 2)">
                    <v-btn @click="selectWorkTaskTemplateModalStore.open(farmField.farmFieldId)">
                        {{ $t('CreateNewTaskFromTemplate') }}
                    </v-btn>
                </v-list-item-title>
                <v-list-item-title v-if="userStore.hasRoleLevel(RoleCode.Fields, 2)">
                    <v-btn @click="manageFarmFieldSeasonModalStore.open(farmField)">
                        {{ $t('ManageSeasons') }}
                    </v-btn>
                </v-list-item-title>
                <v-list-item-title v-if="userStore.hasRoleLevel(RoleCode.Tasks, 2) && farmField.plantedWorkTaskId">
                    <v-btn
                        @click="
                            manageWorkTaskModalStore.open(
                                ManageWorkTaskModalMode.Modify,
                                farmField.plantedWorkTaskId,
                                farmField.farmFieldId,
                            )
                        "
                        >{{ $t('ModifyLatestPlantTask') }}</v-btn
                    >
                </v-list-item-title>
                <v-list-item-title v-if="farmField.farmFieldId && userStore.hasRoleLevel(RoleCode.Fields, 3)">
                    <v-btn
                        @click="
                            viewFarmFieldModalStore.close();
                            deleteFarmFieldModalStore.open(farmField.farmFieldId);
                        ">
                        {{ $t('DeleteField') }}
                    </v-btn>
                </v-list-item-title>
            </v-list-item>

            <v-list-item v-else-if="navigatorLevel == 1">
                <v-list-item-title v-if="userStore.hasRoleLevel(RoleCode.Tasks, 2)">
                    <v-btn @click="manageWorkTaskModalStore.open(ManageWorkTaskModalMode.Create, null, null)">
                        {{ $t('CreateANewTask') }}
                    </v-btn>
                </v-list-item-title>
                <v-list-item-title>
                    <v-btn @click="router.push({name: 'WorkTaskList'})">{{ $t('SiteTaskList') }}</v-btn>
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script setup lang="ts">
import {defineProps, ref} from 'vue';
import {useViewFarmFieldModalStore} from '@/stores/modals/view-farm-field-modal-store';
import {useViewWorkTaskModalStore} from '@/stores/modals/view-work-task-modal-store';
import {useManageFarmFieldModalStore} from '@/stores/modals/manage-farm-field-modal-store';
import {useManageWorkTaskModalStore} from '@/stores/modals/manage-work-task-modal-store';
import {useManageFarmFieldSeasonModalStore} from '@/stores/modals/manage-farm-field-season-modal-store';
import {useDeleteOrCancelWorkTaskModalStore} from '@/stores/modals/delete-or-cancel-work-task-modal-store';
import {useActionWorkTaskModalStore} from '@/stores/modals/action-work-task-modal-store';
import {ManageWorkTaskModalMode} from '@/enums/modal-modes/manage-work-task-modal-mode';
import {useHomeDashboardStore} from '@/stores/home-dashboard-store';
import {WorkTaskSearchResultDto} from '@/models/data-transfer-objects/search/work-task-search/WorkTaskSearchResultDto';
import {FarmFieldSearchResultDto} from '@/models/data-transfer-objects/search/farm-field-search/FarmFieldSearchResultDto';
import {useSelectWorkTaskTemplateModalStore} from '@/stores/modals/select-work-task-template-modal-store';
import router from '@/router';
import {RoleCode} from '@/enums/role-code';
import {useUserStore} from '@/stores/user-store';
import {useRescheduleWorkTaskModalStore} from '@/stores/modals/reschedule-work-task-modal-store';
import {useDeleteFarmFieldModalStore} from '@/stores/modals/delete-farm-field-modal-store';

defineProps<{
    workTask: WorkTaskSearchResultDto | null;
    farmField: FarmFieldSearchResultDto | undefined;
    navigatorLevel: number;
}>();

// Stores
const viewFarmFieldModalStore = useViewFarmFieldModalStore();
const viewWorkTaskModalStore = useViewWorkTaskModalStore();
const manageFarmFieldModalStore = useManageFarmFieldModalStore();
const manageWorkTaskModalStore = useManageWorkTaskModalStore();
const manageFarmFieldSeasonModalStore = useManageFarmFieldSeasonModalStore();
const deleteOrCancelWorkTaskModalStore = useDeleteOrCancelWorkTaskModalStore();
const actionWorkTaskModalStore = useActionWorkTaskModalStore();
const homeDashboardStore = useHomeDashboardStore();
const selectWorkTaskTemplateModalStore = useSelectWorkTaskTemplateModalStore();
const userStore = useUserStore();
const rescheduleWorkTaskModalStore = useRescheduleWorkTaskModalStore();
const deleteFarmFieldModalStore = useDeleteFarmFieldModalStore();

const isOperationsActive = ref<boolean>(false);

// view field on map
async function viewOnMap(farmFieldId: number) {
    homeDashboardStore.farmFieldIdToFocusOn = farmFieldId;
}
</script>
