<template>
    <v-card rounded="true" class="list-item-container ma-0 pa-0" style="padding: 5px" elevation="1">
        <v-row class="task-type-code swatchG7BG">
            <div class="pl-1">
                <WorkTaskTypeIcon
                    :workTaskTypeCode="getNextTask(workTask.workTaskTypeCode)"
                    iconHeight="35"
                    iconWidth="35" />
            </div>

            <span class="swatchBLK task-type-title pl-2">
                {{ workTask.workTaskTypeName }}
            </span>
            <div class="circle" v-if="workTask.isOverdue || workTask.isCompleted || workTask.isCancelled">
                <FontAwesomeIcon
                    class="swatchRED"
                    :icon="['fas', 'calendar-circle-exclamation']"
                    v-if="workTask.isOverdue" />
                <FontAwesomeIcon class="swatchGRN" :icon="['fas', 'circle-check']" v-else />
            </div>

            <div v-else-if="workTask.isStarted" class="circle">
                <FontAwesomeIcon class="swatchB3 start-circle" :icon="['fas', 'circle-play']" size="lg" />
            </div>

            <div
                class="future-dates mt-2"
                v-else-if="workTask.isIncludedInFutureTasks && getDaysUntilTaskIsDue(workTask.dueDate) !== ''">
                {{ getDaysUntilTaskIsDue(workTask.dueDate) }}
            </div>
        </v-row>

        <v-row class="pl-2 pt-1" justify="space-between">
            <div>
                <FontAwesomeIcon
                    :class="getDateWorkIconColour(workTask)"
                    :icon="['fas', getDateWorkIcon(workTask)]"
                    size="xl" />

                <span :class="getDateWorkTextColour(workTask)">
                    {{ getWorkTaskDate(workTask) }}
                </span>
            </div>

            <v-menu class="mr-2 d-flex justify-end" @click.stop>
                <template v-slot:activator="{props}">
                    <div v-bind="props" class="task-menu-button mr-2">
                        <div class="menu-hover">
                            <FontAwesomeIcon :icon="['fas', 'caret-down']" size="xl" style="padding-left: 6px" />
                        </div>
                    </div>
                </template>

                <v-list class="swatchG2BG" density="compact">
                    <v-list-item v-if="workTask && workTask.workTaskId">
                        <v-list-item-title v-if="userStore.hasRoleLevel(RoleCode.Tasks, 1)">
                            <v-btn
                                class="swatchWHT swatchG2BG"
                                @click="viewWorkTaskModalStore.open(workTask.workTaskId)">
                                {{ $t('ViewTaskDetails') }}
                            </v-btn>
                        </v-list-item-title>
                        <v-list-item-title v-if="userStore.hasRoleLevel(RoleCode.Tasks, 1)">
                            <v-btn
                                class="swatchWHT swatchG2BG"
                                @click="viewWorkTaskModalStore.open(workTask.workTaskId, true)">
                                {{ $t('ExportSlashPrintTask') }}
                            </v-btn>
                        </v-list-item-title>
                        <v-list-item-title v-if="userStore.hasRoleLevel(RoleCode.Tasks, 2)">
                            <v-btn
                                class="swatchWHT swatchG2BG"
                                @click="
                                    manageWorkTaskModalStore.open(ManageWorkTaskModalMode.Modify, workTask.workTaskId)
                                ">
                                {{ $t('ModifyTask') }}
                            </v-btn>
                        </v-list-item-title>
                        <v-list-item-title
                            v-if="
                                (workTask.isCompleted || workTask.isCancelled) &&
                                userStore.hasRoleLevel(RoleCode.Tasks, 2)
                            ">
                            <v-btn
                                class="swatchWHT swatchG2BG"
                                @click="actionWorkTaskModalStore.open(workTask.workTaskId)">
                                {{ $t('ModifyCompletedTask') }}
                            </v-btn>
                        </v-list-item-title>
                        <v-list-item-title
                            v-if="
                                !workTask.isStarted &&
                                !workTask.isCompleted &&
                                !workTask.isCancelled &&
                                userStore.hasRoleLevel(RoleCode.Tasks, 3)
                            ">
                            <v-btn
                                class="swatchWHT swatchG2BG"
                                @click="deleteOrCancelWorkTaskModalStore.open(workTask.workTaskId)">
                                {{ $t('DeleteSlashCancelTask') }}
                            </v-btn>
                        </v-list-item-title>
                        <v-list-item-title v-if="userStore.hasRoleLevel(RoleCode.Tasks, 2)">
                            <v-btn
                                class="swatchWHT swatchG2BG"
                                @click="rescheduleWorkTaskModalStore.open(workTask.workTaskId)">
                                {{ $t('RescheduleTask') }}
                            </v-btn>
                        </v-list-item-title>
                        <v-list-item-title v-if="userStore.hasRoleLevel(RoleCode.Tasks, 2)">
                            <v-btn
                                class="swatchWHT swatchG2BG"
                                @click="
                                    manageWorkTaskModalStore.open(
                                        ManageWorkTaskModalMode.Duplicate,
                                        workTask.workTaskId,
                                    )
                                ">
                                {{ $t('DuplicateTask') }}
                            </v-btn>
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-row>
        <v-row class="task-instructions pl-2">
            <WorkTaskSummary v-if="workTask" :work-task="workTask" />
        </v-row>
        <div
            v-if="workTask.workTaskUsers.length > 0 && (workTask.isIncludedInRecentTasks || workTask.isStarted)"
            style="min-height: 30px">
            <v-row
                v-for="(workTaskUser, index) in workTask.workTaskUsers.slice(0, 2)"
                :key="workTaskUser.userId"
                class="pb-1 pl-2">
                <UserChip
                    :user="
                        UserHelper.userChipType(
                            workTaskUser.userId,
                            UserHelper.userFullName(workTaskUser.userGivenName, workTaskUser.userSurname),
                        )
                    " />
                <span class="user-circle" v-if="isShowingUserNumber(workTask.workTaskUsers, index)">
                    {{ '+' + notShowUserNumber(workTask.workTaskUsers) }}
                </span>
            </v-row>
        </div>
    </v-card>
</template>

<script setup lang="ts">
import {WorkTaskSearchResultDto} from '@/models/data-transfer-objects/search/work-task-search/WorkTaskSearchResultDto';
import {WorkTaskTypeCode} from '@/enums/work-task-type-code';
import {format} from 'date-fns';
import UserChip from '@/components/UserChip.vue';
import {WorkTaskUserDto} from '@/models/data-transfer-objects/search/work-task-search/WorkTaskUserDto';
import UserHelper from '@/helpers/UserHelper';
import {RoleCode} from '@/enums/role-code';
import {useUserStore} from '@/stores/user-store';
const userStore = useUserStore();
import {useViewWorkTaskModalStore} from '@/stores/modals/view-work-task-modal-store';
const viewWorkTaskModalStore = useViewWorkTaskModalStore();
import {useManageWorkTaskModalStore} from '@/stores/modals/manage-work-task-modal-store';
const manageWorkTaskModalStore = useManageWorkTaskModalStore();
import {ManageWorkTaskModalMode} from '@/enums/modal-modes/manage-work-task-modal-mode';
import {useActionWorkTaskModalStore} from '@/stores/modals/action-work-task-modal-store';
const actionWorkTaskModalStore = useActionWorkTaskModalStore();
import {useDeleteOrCancelWorkTaskModalStore} from '@/stores/modals/delete-or-cancel-work-task-modal-store';
const deleteOrCancelWorkTaskModalStore = useDeleteOrCancelWorkTaskModalStore();
import {useRescheduleWorkTaskModalStore} from '@/stores/modals/reschedule-work-task-modal-store';
const rescheduleWorkTaskModalStore = useRescheduleWorkTaskModalStore();
import WorkTaskSummary from '@/views/work-tasks/WorkTaskSummary.vue';

// Props
withDefaults(
    defineProps<{
        workTask: WorkTaskSearchResultDto;
    }>(),
    {},
);

const notShowUserNumber = (workTaskUsers: WorkTaskUserDto[]): number => {
    if (!workTaskUsers || workTaskUsers.length < 2) return 0;
    else return workTaskUsers.length - 2;
};

const isShowingUserNumber = (workTaskUsers: WorkTaskUserDto[], index: number): boolean => {
    if (notShowUserNumber(workTaskUsers) === 0) return false;
    return index === 1;
};

// Get the icon to display for a work task date
const getDateWorkIcon = (workTask: WorkTaskSearchResultDto): string | null => {
    if (workTask.isCompleted) {
        return 'calendar-check';
    }

    return 'calendar-day';
};

const getDaysUntilTaskIsDue = (dueDate: Date): string => {
    const today = new Date();
    const nextDate = new Date(dueDate);
    let timeDifference = nextDate.getTime() - today.getTime();
    let dayDifference = Math.round(timeDifference / (1000 * 3600 * 24));
    if (dayDifference > 99) return '99+';
    if (dayDifference <= 0) return '';
    return dayDifference.toString();
};

// Get the colour for a work task icon
const getDateWorkIconColour = (workTask: WorkTaskSearchResultDto): string[] | null => {
    if (workTask.isCompleted) {
        return ['swatchGRN', 'date-chip'];
    }
    return ['swatchG3', 'date-chip'];
};

const getDateWorkTextColour = (workTask: WorkTaskSearchResultDto): string[] | null => {
    if (workTask.isOverdue) {
        return ['swatchRED', 'task-date'];
    }
    return ['swatchA1', 'task-date'];
};

// Get the colour for a work task icon
const getWorkTaskDate = (workTask: WorkTaskSearchResultDto): string | null => {
    if (workTask.isCompleted) {
        return formatWorkDate(workTask.completedDate);
    }

    return formatWorkDate(workTask.dueDate);
};

const formatWorkDate = (date: Date | null): string | null => {
    if (!date) return null;
    return format(date, 'd MMM, yyyy');
};

// Get task code from string
const getNextTask = (nextTask: string | undefined) => {
    return nextTask as WorkTaskTypeCode;
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/swatches.scss';

.list-item-container {
    width: 200px;
    height: 200px;
    border-radius: 3px;
    border: 1px solid $swatchG7;
    background-color: $swatchWHT;
    margin-bottom: 10px;

    &.selected,
    &:hover {
        background-color: rgba($swatchB7, 0.5);
        border: 1px solid $swatchB6;
        cursor: pointer;
    }

    .circle {
        width: 35px; /* Width of the circle */
        height: 35px; /* Height of the circle */
        background-color: $swatchWHT;
        border-radius: 50%; /* Makes the element a circle */
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .future-dates {
        width: 24px;
        height: 21px !important; /* Ensure height is in pixels */
        background-color: $swatchG3;
        color: $swatchWHT;
        font-family: 'Open Sans Condensed', sans-serif;
        border: 1px solid $swatchWHT;
        display: flex; /* Use Flexbox to center the content */
        align-items: center; /* Vertically center */
        justify-content: center; /* Horizontally center */
    }

    .v-row {
        margin: 0;
    }

    .date-chip {
        height: 25px;
        width: 25px;
    }

    .task-type-code {
        height: 45px;
        align-content: center;
        font-size: large;
        font-weight: bold;
    }

    .task-type-title {
        width: 118px;
        padding: 0px;
        margin: 0px;
        align-content: center;
    }

    .task-date {
        height: 30px;
        align-content: center;
        padding-left: 5px;
    }

    .task-instructions {
        min-height: 30px;
        max-height: 4.5em;
        font-weight: bold;
    }

    .task-menu-button {
        padding: 0;
        width: 28px;
        height: 32px;
        min-width: 28px;
        min-height: 32px;
        max-width: 28px;
        max-height: 32px;
    }

    .menu-hover:hover {
        background-color: $swatchB6;
        color: $swatchWHT;
        cursor: pointer;
    }

    .user-circle {
        width: 28px; /* Width of the circle */
        height: 28px; /* Height of the circle */
        background-color: $swatchG4;
        border-radius: 50%; /* Makes the element a circle */
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Open Sans Condensed', sans-serif;
    }
}
</style>
