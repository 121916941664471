<template>
    <v-dialog v-model="viewWorkTaskModalStore.isVisible" style="min-width: 90vw; max-width: 90vw">
        <v-card ref="workTaskModalContentRef">
            <v-toolbar color="secondary">
                <v-toolbar-title>
                    <FontAwesomeIcon :icon="['fal', 'clipboard-list-check']" size="xl" class="mr-2" />
                    {{ $t('ViewTask') }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <CloseModalButton @click="close" />
            </v-toolbar>

            <LoadingSymbol v-if="isLoading || workTask == null" />

            <v-form v-else class="pa-4" style="overflow-y: scroll">
                <!-- Work Task Details Header -->
                <WorkTaskDetailsHeader :work-task="workTask" :farm-field="farmField!" />

                <!-- Work Task Action Details Subheader -->
                <v-expansion-panels class="mt-2" v-model="expansionPanelActionDetails" rounded="0">
                    <v-expansion-panel>
                        <v-expansion-panel-title class="expansion-header">{{
                            $t('ActionDetails')
                        }}</v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <WorkTaskActionDetails
                                :work-task-actions="workTask.workTaskActions"
                                :started-user="
                                    UserHelper.userChipType(
                                        workTask.startedUserId,
                                        UserHelper.userFullName(
                                            workTask.startedUserGivenName,
                                            workTask.startedUserSurname,
                                        ),
                                    )
                                " />
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>

                <!-- Work Task Details Subheader -->
                <div class="subHeader mt-2">
                    {{ $t('TaskDetails') }}
                </div>

                <!-- Due Date Timing -->
                <table class="tblForm" style="width: 100%">
                    <tr>
                        <td style="width: 12rem">
                            {{ $t('DueDateTiming') }}
                        </td>
                        <td>
                            <WorkTaskDueDate :work-task="workTask" :predecessor-work-task="predecessorWorkTask" />
                        </td>
                    </tr>
                    <tr v-if="workTask.workTaskTemplateName">
                        <td>
                            {{ $t('FromTemplate') }}
                        </td>
                        <td>
                            <WorkTaskTemplateChip
                                :work-task-type-code="workTask.workTaskTypeCode"
                                :work-task-template-name="workTask.workTaskTemplateName"
                                size="default"
                                icon-size="lg" />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {{ $t('Instructions') }}
                        </td>
                        <td class="swatchA1 bold">
                            {{ workTask.workTaskInstructions }}
                        </td>
                    </tr>
                    <tr v-if="workTask.workTaskTypeCode == WorkTaskTypeCode.Plant">
                        <td>
                            {{ $t('CropToPlant') }}
                        </td>
                        <td class="swatchA1 bold">
                            <CropIcon :cropCode="workTask.plantCropCode" :is-show-crop-name="true" />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {{ $t('Attachments') }}
                        </td>
                        <td class="swatchA1 bold">
                            <div
                                v-for="(artifact, index) in workTask.artifacts"
                                :key="artifact.artifactId"
                                :class="{'mt-2': index > 0}">
                                <FileTypeButton
                                    :url="artifact.artifactUrl"
                                    :display-name="artifact.artifactDisplayName"
                                    :file-type-code="artifact.artifactFileTypeCode" />
                            </div>
                        </td>
                    </tr>
                    <tr v-if="workTask.workTaskTypeCode == WorkTaskTypeCode.Irrigate">
                        <td>
                            {{ $t('IrrigationRate') }}
                        </td>
                        <td style="display: flex; align-items: center">
                            <span class="swatchA1 bold" :class="{strikethrough: isActualIrrigationRateDifferent}">
                                {{ workTask.irrigationRatePlanned }}
                            </span>
                            <span class="swatchA1 ml-2">ML per Ha</span>

                            <div v-if="isActualIrrigationRateDifferent" class="ml-10">
                                <span class="swatchRED bold">{{ workTask.irrigationRateActual }}</span>
                                <span class="swatchA1 ml-2">ML per Ha</span>
                            </div>
                        </td>
                    </tr>
                </table>

                <!-- Cultivate: Sub-Tasks -->
                <div v-if="workTask.workTaskTypeCode == WorkTaskTypeCode.Cultivate" class="mt-2">
                    <div class="subHeader">{{ $t('CultivateSubTasks') }} ({{ workTask.workSubTasks.length }})</div>
                    <ViewWorkSubTaskItem
                        v-for="(workSubTask, index) in workTask.workSubTasks"
                        :key="workSubTask.workSubTaskId"
                        :work-sub-task="workSubTask"
                        :index="index" />
                </div>

                <!-- Fertilize/Spray: Products -->
                <div
                    v-if="
                        workTask.workTaskTypeCode == WorkTaskTypeCode.Fertilize ||
                        workTask.workTaskTypeCode == WorkTaskTypeCode.Spray
                    "
                    class="mt-2">
                    <div class="subHeader">{{ $t('Products') }} ({{ workTask.workTaskProducts.length }})</div>
                    <ViewWorkTaskProductItem
                        v-for="(workTaskProduct, index) in workTask.workTaskProducts"
                        :key="workTaskProduct.workTaskProductId"
                        :work-task-product="workTaskProduct"
                        :work-task-product-list="workTask.workTaskProducts"
                        :index="index"
                        :work-task-type-code="workTask.workTaskTypeCode" />
                </div>

                <!-- Plant: Varieties, Fertilizer Products, Spray Products -->
                <div v-if="workTask.workTaskTypeCode == WorkTaskTypeCode.Plant" class="mt-2">
                    <!-- Varieties Planted -->
                    <v-expansion-panels
                        v-if="workTask.isCompleted"
                        class="marginTop"
                        v-model="expansionPanelsVarieties">
                        <v-expansion-panel>
                            <v-expansion-panel-title class="expansion-header">
                                {{ $t('VarietiesPlanted') }} ({{ workTask.workTaskFarmBlocks.length }})
                            </v-expansion-panel-title>
                            <v-expansion-panel-text>
                                <ViewWorkTaskFarmBlockItem
                                    v-for="(workTaskFarmBlock, index) in workTask.workTaskFarmBlocks"
                                    :key="workTaskFarmBlock.workTaskFarmBlockId"
                                    :work-task-farm-block="workTaskFarmBlock"
                                    :index="index" />
                            </v-expansion-panel-text>
                        </v-expansion-panel>
                    </v-expansion-panels>

                    <!-- Variety Preferences -->
                    <v-expansion-panels v-else class="marginTop" v-model="expansionPanelsVarieties">
                        <v-expansion-panel>
                            <v-expansion-panel-title class="expansion-header">
                                {{ $t('VarietyPreferences') }} ({{ workTask.workTaskCropVarietyPreferences.length }})
                            </v-expansion-panel-title>
                            <v-expansion-panel-text>
                                <ViewWorkTaskCropVarietyPreferenceItem
                                    v-for="(
                                        workTaskCropVarietyPreference, index
                                    ) in workTask.workTaskCropVarietyPreferences"
                                    :key="workTaskCropVarietyPreference.workTaskCropVarietyPreferenceId"
                                    :work-task-crop-variety-preference="workTaskCropVarietyPreference"
                                    :index="index" />
                            </v-expansion-panel-text>
                        </v-expansion-panel>
                    </v-expansion-panels>

                    <!-- Fertilizer Products -->
                    <div class="subHeader mt-2">
                        {{ $t('FertiliserProductsToApplyAtPlanting') }} ({{ fertilizerWorkTaskProducts.length }})
                    </div>
                    <ViewWorkTaskProductItem
                        v-for="(workTaskProduct, index) in fertilizerWorkTaskProducts"
                        :key="workTaskProduct.workTaskProductId"
                        :work-task-product="workTaskProduct"
                        :work-task-product-list="fertilizerWorkTaskProducts"
                        :index="index"
                        :work-task-type-code="workTask.workTaskTypeCode" />

                    <!-- Spray Products -->
                    <div class="subHeader mt-2">
                        {{ $t('SprayProductsToApplyAtPlanting') }} ({{ chemicalWorkTaskProducts.length }})
                    </div>
                    <ViewWorkTaskProductItem
                        v-for="(workTaskProduct, index) in chemicalWorkTaskProducts"
                        :key="workTaskProduct.workTaskProductId"
                        :work-task-product="workTaskProduct"
                        :work-task-product-list="chemicalWorkTaskProducts"
                        :index="index"
                        :work-task-type-code="workTask.workTaskTypeCode" />
                </div>
            </v-form>

            <v-card-actions class="justify-space-between swatchG9BG">
                <v-btn @click="close">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'arrow-rotate-left']" size="xl" />
                    </template>
                    {{ $t('Cancel') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" timeout="4000">
        {{ snackbar.text }}
    </v-snackbar>
</template>

<script setup lang="ts">
import '@/assets/scss/user/user-manager.scss';
import {computed, watch, inject, ref, ComponentPublicInstance, nextTick} from 'vue';
import ApiService from '@/services/api-service.js';
import CloseModalButton from '@/components/CloseModalButton.vue';
import WorkTaskDetailsHeader from '@/components/WorkTaskDetailsHeader.vue';
import {SearchWorkTasksRequest} from '@/models/api/requests/search/SearchWorkTasksRequest';
import {SearchWorkTasksResponse} from '@/models/api/responses/search/SearchWorkTasksResponse';
import {WorkTaskSearchResultDto} from '@/models/data-transfer-objects/search/work-task-search/WorkTaskSearchResultDto';
import {SearchFarmFieldsRequest} from '@/models/api/requests/search/SearchFarmFieldsRequest';
import {SearchFarmFieldsResponse} from '@/models/api/responses/search/SearchFarmFieldsResponse';
import {FarmFieldSearchResultDto} from '@/models/data-transfer-objects/search/farm-field-search/FarmFieldSearchResultDto';
import {useViewWorkTaskModalStore} from '@/stores/modals/view-work-task-modal-store';
import {WorkTaskDueDateTiming} from '@/enums/work-task-due-date-timing';
import {GetPredecessorWorkTasksResponse} from '@/models/api/responses/work-tasks/GetPredecessorWorkTasksResponse';
import FileTypeButton from '@/components/FileTypeButton.vue';
import WorkTaskTemplateChip from '@/components/WorkTaskTemplateChip.vue';
import {WorkTaskTypeCode} from '@/enums/work-task-type-code';
import ViewWorkSubTaskItem from './ViewWorkSubTaskItem.vue';
import ViewWorkTaskProductItem from './ViewWorkTaskProductItem.vue';
import ViewWorkTaskCropVarietyPreferenceItem from './ViewWorkTaskCropVarietyPreferenceItem.vue';
import ViewWorkTaskFarmBlockItem from './ViewWorkTaskFarmBlockItem.vue';
import {ProductTypeCode} from '@/enums/product-type-code';
import PrintHelper from '@/helpers/PrintHelper';
import i18n from '@/i18n';
import WorkTaskActionDetails from '@/components/work-tasks/WorkTaskActionDetails.vue';
import WorkTaskDueDate from '@/components/work-tasks/WorkTaskDueDate.vue';
import UserHelper from '@/helpers/UserHelper';
import ManageWorkTaskHelper from '@/helpers/ManageWorkTaskHelper';

// Form
let isLoading = ref<boolean>(false);
const predecessorWorkTask = ref<GetPredecessorWorkTasksResponse | null>(null);
const expansionPanelsVarieties = ref([0]);
const workTaskModalContentRef = ref<ComponentPublicInstance | null>(null);
const expansionPanelActionDetails = ref<number | null>(0);
const snackbar = ref({
    show: false,
    text: '',
});

// Services
const apiService = inject('apiService') as ApiService;

// Modal
const viewWorkTaskModalStore = useViewWorkTaskModalStore();
const workTask = ref<WorkTaskSearchResultDto | null>(null);
const farmField = ref<FarmFieldSearchResultDto | null>(null);

/**
 * Load work task data for the modal.
 */
const loadWorkTaskData = async () => {
    isLoading.value = true;

    // Reset form
    resetForm();

    // If a work task ID was specified
    if (viewWorkTaskModalStore.workTaskId !== null) {
        // Build search request using the work task ID
        const searchRequest: SearchWorkTasksRequest = new SearchWorkTasksRequest({
            isQuickSearch: false,
            workTaskId: viewWorkTaskModalStore.workTaskId,
            includeArtifacts: true,
            includeWorkSubTasks: true,
            includeWorkTaskMixtures: true,
            includeWorkTaskProducts: true,
            includeWorkTaskCropVarietyPreferences: true,
            includeWorkTaskFarmBlocks: true,
            includeWorkTaskActions: true,
        });

        // Get work task details
        const searchResults = (await apiService.post('search/work-tasks', searchRequest)) as SearchWorkTasksResponse;

        if (searchResults.workTasks.length == 1) {
            // Store search result
            workTask.value = searchResults.workTasks[0];

            // Get farm field details
            await getFarmFieldDetails();

            // If this task is due after another task
            if (workTask.value.dueDateTiming == WorkTaskDueDateTiming.DueAfterTask) {
                // Get the details of the other task
                predecessorWorkTask.value = await ManageWorkTaskHelper.getPredecessorWorkTask(
                    workTask.value,
                    apiService,
                );
            }

            // Sort work task products
            sortWorkTaskProducts();
        }
    }

    // End loading
    isLoading.value = false;

    if (viewWorkTaskModalStore.isPrinting) {
        printContent();
    }
};

/**
 * Get farm field details of an existing work task.
 */
const getFarmFieldDetails = async () => {
    if (workTask.value == null) return;

    // Build search request using the farm field ID
    const searchRequest: SearchFarmFieldsRequest = new SearchFarmFieldsRequest({
        isQuickSearch: false,
        farmFieldId: workTask.value.farmFieldId,
    });

    // Get farm field details
    const searchResults = (await apiService.post('search/farm-fields', searchRequest)) as SearchFarmFieldsResponse;

    if (searchResults.farmFields.length == 1) {
        // Store search result
        farmField.value = searchResults.farmFields[0];
    }
};

/**
 * Close the modal.
 */
const close = () => {
    viewWorkTaskModalStore.close();
};

/**
 * Reset the form to its default state.
 */
const resetForm = () => {
    workTask.value = null;
    farmField.value = null;
    expansionPanelsVarieties.value = [0];
};

/**
 * Sort the list of products to ensure that they can be grouped by mixture.
 */
const sortWorkTaskProducts = () => {
    if (!workTask.value) return;

    workTask.value.workTaskProducts.sort((a, b) => {
        // Prioritize items with non-null mixtureName
        if (a.mixtureName === null && b.mixtureName !== null) return 1; // 'a' comes after 'b'
        if (a.mixtureName !== null && b.mixtureName === null) return -1; // 'a' comes before 'b'

        // If both mixtureNames are non-null, or both are null, compare by mixtureName
        if (a.mixtureName !== null && b.mixtureName !== null) {
            if (a.mixtureName < b.mixtureName) return -1;
            if (a.mixtureName > b.mixtureName) return 1;
        }

        // If mixtureName is the same or both are null, prioritize non-null mixtureId
        if (a.mixtureId === null && b.mixtureId !== null) return 1; // 'a' comes after 'b'
        if (a.mixtureId !== null && b.mixtureId === null) return -1; // 'a' comes before 'b'

        // If both mixtureIds are non-null, or both are null, compare by mixtureId
        if (a.mixtureId !== null && b.mixtureId !== null) {
            if (a.mixtureId < b.mixtureId) return -1;
            if (a.mixtureId > b.mixtureId) return 1;
        }

        // If both mixtureName and mixtureId are the same or both null, compare by workTaskProductId
        if (a.workTaskProductId < b.workTaskProductId) return -1;
        if (a.workTaskProductId > b.workTaskProductId) return 1;

        // If all fields are equal, return 0 (no change in order)
        return 0;
    });
};

/**
 * Get the current date in YYYYMMDD format.
 */
const getCurrentDateFormatted = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
    const day = String(today.getDate()).padStart(2, '0');

    return `${year}${month}${day}`;
};

/**
 * Obtains modal content for printing.
 */
const printContent = async () => {
    await nextTick(); // Ensure DOM is updated
    if (workTaskModalContentRef.value && workTaskModalContentRef.value.$el) {
        const content = workTaskModalContentRef.value.$el.innerHTML; // Access DOM element
        if (content) {
            const currentDate = getCurrentDateFormatted();
            const taskName = workTask.value?.workTaskName ? workTask.value?.workTaskName : i18n.global.t('PrintTask');
            const taskId = workTask.value?.workTaskId;
            const printTitle = `${currentDate}_${taskName.replace(/\s+/g, '_')}_${taskId}`;
            PrintHelper.printDialogContent(content, printTitle);
        } else {
            snackbar.value.show = true;
            snackbar.value.text = i18n.global.t('ErrorGeneric');
        }
    }

    viewWorkTaskModalStore.isPrinting = false;
};

/**
 * Flag to indicate if the actual irrigation rate is different to the planned irrigation rate.
 */
const isActualIrrigationRateDifferent = computed(() => {
    return (
        workTask.value &&
        workTask.value.irrigationRateActual != null &&
        workTask.value.irrigationRateActual != workTask.value.irrigationRatePlanned
    );
});

/**
 * Fertilizer products.
 */
const fertilizerWorkTaskProducts = computed(() => {
    if (!workTask.value) return [];

    return workTask.value.workTaskProducts.filter((wtp) => wtp.productTypeCode == ProductTypeCode.Fertilizer);
});

/**
 * Chemical products.
 */
const chemicalWorkTaskProducts = computed(() => {
    if (!workTask.value) return [];

    return workTask.value.workTaskProducts.filter((wtp) => wtp.productTypeCode == ProductTypeCode.Chemical);
});

// Watch for changes to modal state
watch(
    () => viewWorkTaskModalStore.isVisible,
    async (isVisible) => {
        if (isVisible) {
            await loadWorkTaskData();
        }
    },
);

watch(
    () => viewWorkTaskModalStore.isPrinting,
    async (isPrinting) => {
        if (isPrinting && !isLoading.value) {
            printContent();
        }
    },
);
</script>
