import {ManageUserModalMode} from '@/enums/modal-modes/manage-user-modal-mode';
import {RoleCode} from '@/enums/role-code';
import {LoginResponse} from '@/models/api/responses/authentication/LoginResponse';
import {ManageUserForm} from '@/models/users/ManageUserForm';
import { UserFarmSiteRoleLevelAssignmentsDto } from '@/models/users/UserFarmSiteRoleLevelAssignmentsDto';
import {defineStore} from 'pinia';
import {computed, ref, watch} from 'vue';

export const useUserStore = defineStore('user', () => {
    const localStorageUserKey = 'AGRIAPP_USER';

    // Reactive state holding the user object
    const user = ref<LoginResponse | null>(null);

    // Initialize the user object from local storage if available
    const savedUser = localStorage.getItem(localStorageUserKey);
    if (savedUser) {
        user.value = JSON.parse(savedUser);
    }

    /**
     * Set user data.
     */
    function login(userDetails: LoginResponse) {
        user.value = userDetails;
    }

    /**
     * Clear user data.
     */
    function logout() {
        user.value = null;
    }

    /**
     * Checks to see if the user's level for a role is greater than or equal to the specified role level.
     * @param roleCode Role to check access for.
     * @param minimumRequiredRoleLevel Minimum role level for check for.
     * @returns True if the user's access level for the specified roleCode is greater than or equal to the
     * level specified in minimumRequiredRoleLevel.
     */
    function hasRoleLevel(roleCode: RoleCode, minimumRequiredRoleLevel: number, farmSiteId: number | null = null): boolean {
        const roleLevel = getRoleLevel(roleCode, farmSiteId);

        // Check if user has the minimum role level
        return roleLevel >= minimumRequiredRoleLevel;
    }

    /**
     * Gets the user's access level for the specified role.
     * @param roleCode Role to get access for.
     * @returns User's role level.
     */
    function getRoleLevel(roleCode: RoleCode, farmSiteId: number | null = null): number {
        const userRoles = user.value?.roleLevels;

        // Return null if no user roles found
        if (!userRoles) return 0;

        // Get the role assignment for the specified role
        const role = userRoles.find((ra) => ra.roleCode === roleCode);

        if (!role) return 0;

        // If the role isn't site specific, return its level
        if (!role.isSiteSpecific) return role.roleLevel;

        // Check for system-level role and return max access if level 3
        const systemLevel = userRoles.find((ra) => ra.roleCode === RoleCode.System);
        if (systemLevel?.roleLevel === 3) return 3;

        // Get site-specific roles
        const siteRoles = user.value?.farmSiteRoleLevels;
        if (!siteRoles) return 0;

        // Find the site-specific role based on current farm site
        const siteRole = siteRoles.find(
            (sr) => sr.roleCode === roleCode && sr.farmSiteId === (farmSiteId ?? user.value?.farmSiteId)
        );

        return siteRole?.roleLevel || 0;
    }

    /**
     * Update the user store based on the details of the saved Manage User form.
     * @param userForm User details that were saved.
     */
    function updateAfterSavingUser(userForm: ManageUserForm, modalMode: ManageUserModalMode) {
        if (user.value != null && userForm.userId == user.value.userId) {
            user.value.firstName = userForm.userGivenName !== null ? userForm.userGivenName : undefined;
            user.value.lastName = userForm.userSurname !== null ? userForm.userSurname : undefined;
            user.value.username = userForm.username !== null ? userForm.username : undefined;
            user.value.emailAddress = userForm.userEmailAddress !== null ? userForm.userEmailAddress : undefined;

            if (modalMode == ManageUserModalMode.Modify) {
                user.value.roleLevels = userForm.userRoleLevelAssignments;

                if (user.value.farmSiteRoleLevels) {
                    userForm.userFarmSiteRoleLevelAssignment.forEach((roleAssignment) => {
                        const siteRoles = user.value?.farmSiteRoleLevels.filter((f) => f.farmSiteId == roleAssignment.farmSiteId);

                        if (siteRoles) {
                            updateRoleLevel(siteRoles, 'Fields', roleAssignment.fieldLevel);
                            updateRoleLevel(siteRoles, 'Tasks', roleAssignment.taskLevel);
                            updateRoleLevel(siteRoles, 'Observations', roleAssignment.observationLevel);
                        }
                    });
                }
            }
        }
    }

    /**
     * Update specific site level.
     * @param roleAssignments User's roles filtered by farm site.
     * @param roleCode The code being updated.
     * @param roleLevel The new role level to assign.
     */
    function updateRoleLevel(roleAssignments: UserFarmSiteRoleLevelAssignmentsDto[], roleCode: string, roleLevel: number) {
        const role = roleAssignments.find((f) => f.roleCode === roleCode);
        if (role) {
            role.roleLevel = roleLevel;
        }
    }

    /**
     * Check if the user is logged in.
     */
    const isLoggedIn = computed(() => !!user.value);

    /**
     * Warning when accessing log out only pages.
     */
    const showLogoutWarning = ref<boolean>(false);

    function setShowLogoutWarning(value: boolean) {
        showLogoutWarning.value = value;
    }

    // Watch the user state and update local storage
    watch(
        user,
        (newUser) => {
            if (newUser) {
                localStorage.setItem(localStorageUserKey, JSON.stringify(newUser));
            } else {
                localStorage.removeItem(localStorageUserKey);
            }
        },
        {deep: true},
    );

    return {user, login, logout, hasRoleLevel, getRoleLevel, updateAfterSavingUser, isLoggedIn, showLogoutWarning, setShowLogoutWarning};
});
