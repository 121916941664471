<template>
    <div>
        <v-dialog v-model="isVisible">
            <v-card>
                <v-form ref="form" class="pa-4" style="overflow-y: scroll; height: 100%" @keyup.enter="search">
                    <crop-variety-search-parameters v-model:searchParameters="localSearchParametersCrop" />
                </v-form>
                <v-card-actions class="swatchG9BG">
                    <v-btn @click="isVisible = false">
                        <template v-slot:prepend>
                            <FontAwesomeIcon :icon="['fal', 'arrow-rotate-left']" size="xl" />
                        </template>
                        {{ $t('Cancel') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="search" :disabled="isLoading">
                        <template v-slot:prepend>
                            <FontAwesomeIcon :icon="['fal', 'search']" size="xl" />
                        </template>
                        {{ $t('Search') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <LoadingSymbol v-if="isLoading" />
        <crop-variety-search-results
            v-else
            @switch-to-search="openSearchModal"
            :is-filter-applied="isFilterApplied"
            :results-list="searchResultsCrop" />
    </div>
</template>

<script setup lang="ts">
import {computed, ref, inject, onMounted} from 'vue';
import ApiService from '@/services/api-service.js';
import {SearchCropVarietiesRequest} from '@/models/api/requests/search/SearchCropVarietiesRequest';
import {CropVarietyDto} from '@/models/data-transfer-objects/search/crop-variety-search/CropVarietySearchDto';
import {SearchCropVarietiesResponse} from '@/models/api/responses/search/SearchCropVarietiesResponse';
import CropVarietySearchParameters from '@/views/search/CropVarietySearchParameters.vue';
import CropVarietySearchResults from '@/views/search/CropVarietySearchResults.vue';

// Services
const apiService = inject('apiService') as ApiService;

//Local variables
const isVisible = ref<boolean>(false);
const isLoading = ref<boolean>(false);
const searchParametersCrop = ref(new SearchCropVarietiesRequest());
const localSearchParametersCrop = ref(new SearchCropVarietiesRequest());
const searchResultsCrop = ref<CropVarietyDto[]>([]);

const isFilterApplied = computed(
    () =>
        (searchParametersCrop.value.isQuickSearch &&
            (searchParametersCrop.value.quickSearchText === null ||
                searchParametersCrop.value.quickSearchText === '')) ||
        (!searchParametersCrop.value.isQuickSearch &&
            searchParametersCrop.value.cropId === null &&
            (searchParametersCrop.value.cropVarietyName === null ||
                searchParametersCrop.value.cropVarietyName === '') &&
            (searchParametersCrop.value.cropTypeIdList === null ||
                searchParametersCrop.value.cropTypeIdList.length === 0) &&
            (searchParametersCrop.value.farmFieldIdList === null ||
                searchParametersCrop.value.farmFieldIdList.length === 0) &&
            (searchParametersCrop.value.farmSiteIdList === null ||
                searchParametersCrop.value.farmSiteIdList.length === 0)),
);

/**
 * Open search modal
 */
async function openSearchModal() {
    isVisible.value = true;
}

/**
 * Save the modal.
 */
const search = async () => {
    isLoading.value = true;

    searchParametersCrop.value = Object.assign(new SearchCropVarietiesRequest(), localSearchParametersCrop.value);
    const response: SearchCropVarietiesResponse = await apiService.post(
        'search/crop-varieties',
        searchParametersCrop.value,
    );
    searchResultsCrop.value = response.crops;

    isLoading.value = false;
    isVisible.value = false;
};

onMounted(async () => {
    await search();
    localSearchParametersCrop.value = Object.assign(new SearchCropVarietiesRequest(), searchParametersCrop.value);
});
</script>
