<template>
    <v-dialog
        v-model="rescheduleWorkTaskModalStore.isVisible"
        class="pnlUserManager"
        style="min-width: 90vw; max-width: 90vw">
        <v-card>
            <v-toolbar color="secondary">
                <v-toolbar-title>
                    <FontAwesomeIcon :icon="['fal', 'calendar-pen']" size="xl" class="mr-2" />
                    {{ $t('RescheduleTask') }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <CloseModalButton @click="close" />
            </v-toolbar>

            <LoadingSymbol v-if="isLoading || workTask == null" />

            <v-form ref="form" v-else class="pa-4 divUserManager" v-model="valid" style="overflow-y: scroll">
                <!-- A minimum height is set as a workaround for an issue with the date picker not showing if the modal is too small -->
                <div style="min-height: 38rem">
                    <!-- Work Task Details Header -->
                    <WorkTaskDetailsHeader
                        :work-task="workTask"
                        :farm-field="farmField!"
                        :show-operations-menu="false"
                        :show-created-and-modified-details="false" />

                    <!-- Subheader -->
                    <div class="subHeader mt-2 mb-4">
                        {{ $t('Timing') }}
                    </div>

                    <div>
                        <!-- Current Timing -->
                        <div class="flex-layout fill flex-layout-space-05 align-items-center">
                            <div class="formHead">{{ $t('CurrentTiming') }}</div>
                            <div class="formIcon">
                                <HelpIcon :help-text="$t('RescheduleWorkTask_CurrentTiming_HelpText')" />
                            </div>
                            <div class="formModifyGroup">
                                <WorkTaskDueDate :work-task="workTask" :predecessor-work-task="predecessorWorkTask" />
                            </div>
                        </div>

                        <!-- New Timing -->
                        <div class="flex-layout fill flex-layout-space-05 align-items-center">
                            <div class="formHead">{{ $t('NewTiming') }}</div>
                            <div class="formIcon">
                                <HelpIcon :help-text="$t('RescheduleWorkTask_NewTiming_HelpText')" />
                            </div>
                            <div class="formModifyGroup">
                                <WorkTaskDueDateTimingControl
                                    ref="dueDateTimingControl"
                                    v-model:work-task="localRescheduleWorkTaskModel"
                                    :work-task-type-code="workTask.workTaskTypeCode"
                                    :is-loading="isLoading" />
                            </div>
                        </div>

                        <!-- Reason -->
                        <div class="flex-layout fill flex-layout-space-05 align-items-center">
                            <div class="formHead">{{ $t('Reason') }}</div>
                            <div class="formIcon">
                                <HelpIcon :help-text="$t('RescheduleWorkTask_Reason_HelpText')" />
                            </div>
                            <div style="flex-grow: 1">
                                <v-textarea
                                    v-model="localRescheduleWorkTaskModel.rescheduleTaskReason"
                                    :rules="workTask.isOverdue ? [requiredRule] : []"
                                    required
                                    variant="outlined"
                                    density="compact"
                                    rows="1"
                                    auto-grow
                                    hide-details="auto"
                                    class="swatchA1 font-weight-bold">
                                </v-textarea>
                            </div>
                        </div>

                        <!-- Cascade Changes -->
                        <div
                            v-if="cascadeChangesOptionsFiltered.length > 0"
                            class="flex-layout fill flex-layout-space-05 align-items-center">
                            <div class="formHead">{{ $t('CascadeChanges') }}</div>
                            <div class="formIcon">
                                <HelpIcon :help-text="$t('RescheduleWorkTask_CascadeChanges_HelpText')" />
                            </div>
                            <div>
                                <v-select
                                    v-model="localRescheduleWorkTaskModel.rescheduleTaskCascadeChangesOptionCode"
                                    :items="cascadeChangesOptionsFiltered"
                                    hide-details
                                    :item-title="(item: CascadeChangesOption) => item.getCascadeChangesOptionName()"
                                    item-value="cascadeChangesOptionCode"
                                    width="600px"
                                    class="swatchA1 font-weight-bold">
                                </v-select>
                            </div>
                        </div>
                    </div>

                    <!-- Subheader -->
                    <div class="subHeader mt-2 mb-4">
                        {{ $t('ImpactOfChange') }}
                    </div>

                    <!-- Impact of Change -->
                    <LoadingSymbol v-if="isLoadingImpactOfChanges" />
                    <div v-else class="pl-6">
                        <div v-if="tasksThatWillHaveDueDateChanged.length > 0" class="mb-4">
                            <RescheduleWorkTaskChanges
                                :tasks-that-will-be-changed="tasksThatWillHaveDueDateChanged"
                                :description="$t('RescheduleTaskImpact_EstimatedDueDateChanged')" />
                        </div>
                        <div v-if="tasksThatWillHaveDueDaysAfterChanged.length > 0" class="mb-4">
                            <RescheduleWorkTaskChanges
                                :tasks-that-will-be-changed="tasksThatWillHaveDueDaysAfterChanged"
                                :description="$t('RescheduleTaskImpact_DueAfterDaysChanged')" />
                        </div>
                        <div v-if="tasksThatWillHaveDependencyRemoved.length > 0" class="mb-4">
                            <RescheduleWorkTaskChanges
                                :tasks-that-will-be-changed="tasksThatWillHaveDependencyRemoved"
                                :description="$t('RescheduleTaskImpact_DependencyRemoved')" />
                        </div>
                        <div v-if="newPreviousTask.length > 0 && newNextTask.length > 0" class="mb-4">
                            <RescheduleWorkTaskChanges
                                :tasks-that-will-be-changed="newPreviousAndNextTasks"
                                :description="$t('RescheduleTaskImpact_TaskSequenceChanged_Between')" />
                        </div>
                        <div v-if="newPreviousTask.length > 0 && newNextTask.length == 0" class="mb-4">
                            <RescheduleWorkTaskChanges
                                :tasks-that-will-be-changed="newPreviousTask"
                                :description="$t('RescheduleTaskImpact_TaskSequenceChanged_Next')" />
                        </div>
                        <div v-if="newPreviousTask.length == 0 && newNextTask.length > 0" class="mb-4">
                            <RescheduleWorkTaskChanges
                                :tasks-that-will-be-changed="newNextTask"
                                :description="$t('RescheduleTaskImpact_TaskSequenceChanged_Previous')" />
                        </div>
                    </div>
                </div>
            </v-form>

            <v-card-actions class="justify-space-between swatchG9BG">
                <v-btn @click="close">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'arrow-rotate-left']" size="xl" />
                    </template>
                    {{ $t('Cancel') }}
                </v-btn>

                <v-btn @click="save" :disabled="isLoading">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'save']" size="xl" />
                    </template>
                    {{ $t('Save') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" timeout="4000">
        {{ snackbar.text }}
    </v-snackbar>
</template>

<script setup lang="ts">
import '@/assets/scss/user/user-manager.scss';
import {watch, inject, ref, nextTick, computed} from 'vue';
import ApiService from '@/services/api-service.js';
import CloseModalButton from '@/components/CloseModalButton.vue';
import WorkTaskDetailsHeader from '@/components/WorkTaskDetailsHeader.vue';
import WorkTaskDueDateTimingControl from '@/components/work-tasks/WorkTaskDueDateTimingControl.vue';
import WorkTaskDueDate from '@/components/work-tasks/WorkTaskDueDate.vue';
import {SearchWorkTasksRequest} from '@/models/api/requests/search/SearchWorkTasksRequest';
import {SearchWorkTasksResponse} from '@/models/api/responses/search/SearchWorkTasksResponse';
import {WorkTaskSearchResultDto} from '@/models/data-transfer-objects/search/work-task-search/WorkTaskSearchResultDto';
import {SearchFarmFieldsRequest} from '@/models/api/requests/search/SearchFarmFieldsRequest';
import {SearchFarmFieldsResponse} from '@/models/api/responses/search/SearchFarmFieldsResponse';
import {FarmFieldSearchResultDto} from '@/models/data-transfer-objects/search/farm-field-search/FarmFieldSearchResultDto';
import {useRescheduleWorkTaskModalStore} from '@/stores/modals/reschedule-work-task-modal-store';
import i18n from '@/i18n';
import {RescheduleWorkTaskForm} from '@/models/work-tasks/RescheduleWorkTaskForm';
import Validation from '@/helpers/ValidationHelper';
import {RescheduleWorkTaskRequest} from '@/models/api/requests/work-tasks/RescheduleWorkTaskRequest';
import {format, isAfter, isBefore} from 'date-fns';
import {GetPredecessorWorkTasksResponse} from '@/models/api/responses/work-tasks/GetPredecessorWorkTasksResponse';
import {WorkTaskDueDateTiming} from '@/enums/work-task-due-date-timing';
import ManageWorkTaskHelper from '@/helpers/ManageWorkTaskHelper';
import {CascadeChangesOption, cascadeChangesOptions} from '@/services/cascade-changes-options-service';
import {RescheduleWorkTaskResponse} from '@/models/api/responses/work-tasks/RescheduleWorkTaskResponse';
import {CascadeChangesOptionCode} from '@/enums/cascade-changes-option-code';
import {WorkTaskChangeDetailsDto} from '@/models/data-transfer-objects/work-tasks/WorkTaskChangeDetailsDto';
import RescheduleWorkTaskChanges from '@/components/work-tasks/RescheduleWorkTaskChanges.vue';

// Form
const form = ref();
const dueDateTimingControl = ref();
const valid = ref<boolean>(true);
let isLoading = ref<boolean>(false);
let isLoadingImpactOfChanges = ref<boolean>(false);
const snackbar = ref({
    show: false,
    text: '',
});
const predecessorWorkTask = ref<GetPredecessorWorkTasksResponse | null>(null);

// Rules
const requiredRule = Validation.createRule_Required();

// Services
const apiService = inject('apiService') as ApiService;

// Modal
const rescheduleWorkTaskModalStore = useRescheduleWorkTaskModalStore();
const workTask = ref<WorkTaskSearchResultDto | null>(null);
const farmField = ref<FarmFieldSearchResultDto | null>(null);
const localRescheduleWorkTaskModel = ref<RescheduleWorkTaskForm>(new RescheduleWorkTaskForm());
const isRescheduledBefore = ref(false);
const isRescheduledAfter = ref(false);
const tasksThatWillBeChanged = ref<WorkTaskChangeDetailsDto[]>([]);

/**
 * Load work task data for the modal.
 */
const loadWorkTaskData = async () => {
    isLoading.value = true;

    // Reset form
    resetForm();

    // If a work task ID was specified
    if (rescheduleWorkTaskModalStore.workTaskId !== null) {
        // Build search request using the work task ID
        const searchRequest: SearchWorkTasksRequest = new SearchWorkTasksRequest({
            isQuickSearch: false,
            workTaskId: rescheduleWorkTaskModalStore.workTaskId,
        });

        // Get work task details
        const searchResults = (await apiService.post('search/work-tasks', searchRequest)) as SearchWorkTasksResponse;

        if (searchResults.workTasks.length == 1) {
            // Store search result
            workTask.value = searchResults.workTasks[0];

            // Set work task details in form model
            localRescheduleWorkTaskModel.value.workTaskId = workTask.value.workTaskId;
            localRescheduleWorkTaskModel.value.farmFieldId = workTask.value.farmFieldId;

            if (rescheduleWorkTaskModalStore.dueDateTiming && rescheduleWorkTaskModalStore.dueDateLatest) {
                localRescheduleWorkTaskModel.value.dueDateTiming = rescheduleWorkTaskModalStore.dueDateTiming;
                localRescheduleWorkTaskModel.value.dueDateLatest = rescheduleWorkTaskModalStore.dueDateLatest;
            }

            // Get farm field details
            await getFarmFieldDetails();

            // If this task is due after another task
            if (workTask.value.dueDateTiming == WorkTaskDueDateTiming.DueAfterTask) {
                // Get the details of the other task
                predecessorWorkTask.value = await ManageWorkTaskHelper.getPredecessorWorkTask(
                    workTask.value,
                    apiService,
                );
            }
        }
    }

    // End loading (nextTick is used to ensure that the watch events are not triggered during the load)
    nextTick(() => {
        if (rescheduleWorkTaskModalStore.dueDateTiming && rescheduleWorkTaskModalStore.dueDateLatest) {
            getImpactOfChanges();
        }
        isLoading.value = false;
    });
};

/**
 * Get farm field details of an existing work task.
 */
const getFarmFieldDetails = async () => {
    if (workTask.value == null) return;

    // Build search request using the farm field ID
    const searchRequest: SearchFarmFieldsRequest = new SearchFarmFieldsRequest({
        isQuickSearch: false,
        farmFieldId: workTask.value.farmFieldId,
    });

    // Get farm field details
    const searchResults = (await apiService.post('search/farm-fields', searchRequest)) as SearchFarmFieldsResponse;

    if (searchResults.farmFields.length == 1) {
        // Store search result
        farmField.value = searchResults.farmFields[0];
    }
};

/**
 * Save the modal.
 */
const save = async () => {
    // Perform final client side validation of form
    await form.value.validate();
    const isDueDateValid = await dueDateTimingControl.value.validate();

    // If form is valid
    if (valid.value && isDueDateValid) {
        let snackbarMessage = '';
        isLoading.value = true;

        try {
            // Build API request
            const rescheduleWorkTaskRequest = buildRescheduleWorkTaskApiRequest(true);

            // Call API to reschedule work task
            await apiService.post('work-tasks/reschedule-work-task', rescheduleWorkTaskRequest);

            // Set snackbar message
            snackbarMessage = i18n.global.t('RescheduleWorkTask_Success');

            // Show success feedback to user
            snackbar.value.show = true;
            snackbar.value.text = snackbarMessage;
            rescheduleWorkTaskModalStore.savedCounter++;

            // Close modal
            rescheduleWorkTaskModalStore.close();
        } catch (ex: unknown) {
            // Show fail feedback to user
            snackbar.value.show = true;
            snackbar.value.text = i18n.global.t('ErrorGeneric');
            isLoading.value = false;
        }
    } else {
        // Show validation error feedback to user
        snackbar.value.show = true;
        snackbar.value.text = i18n.global.t('ErrorValidation');
    }
};

/**
 * Builds the request object for the Action Task APIs.
 */
const buildRescheduleWorkTaskApiRequest = (commitChanges: boolean) => {
    // Build API request
    const actionWorkTaskRequest: RescheduleWorkTaskRequest = {
        parameters: {
            workTaskId: localRescheduleWorkTaskModel.value.workTaskId!,
            dueDateTiming: localRescheduleWorkTaskModel.value.dueDateTiming,
            dueDateOn: localRescheduleWorkTaskModel.value.dueDateOn
                ? formatDateForApi(localRescheduleWorkTaskModel.value.dueDateOn)
                : null,
            dueDateLatest: localRescheduleWorkTaskModel.value.dueDateLatest
                ? formatDateForApi(localRescheduleWorkTaskModel.value.dueDateLatest)
                : null,
            dueDateMonth: localRescheduleWorkTaskModel.value.dueDateMonth
                ? formatDateForApi(
                      new Date(
                          localRescheduleWorkTaskModel.value.dueDateMonth.year,
                          localRescheduleWorkTaskModel.value.dueDateMonth.month,
                          1,
                      ),
                  )
                : null,
            dueDateRange:
                localRescheduleWorkTaskModel.value.dueDateRange &&
                localRescheduleWorkTaskModel.value.dueDateRange.length == 2
                    ? [
                          formatDateForApi(localRescheduleWorkTaskModel.value.dueDateRange[0]),
                          formatDateForApi(localRescheduleWorkTaskModel.value.dueDateRange[1]),
                      ]
                    : null,
            dueAfterWorkTaskId: localRescheduleWorkTaskModel.value.dueAfterWorkTaskId,
            dueAfterWorkTaskWithinDays: localRescheduleWorkTaskModel.value.dueAfterWorkTaskWithinDays,
            rescheduleTaskReason: localRescheduleWorkTaskModel.value.rescheduleTaskReason,
            rescheduleTaskCascadeChangesOptionCode:
                localRescheduleWorkTaskModel.value.rescheduleTaskCascadeChangesOptionCode,
            commitChanges: commitChanges,
            getBeforeAndAfterTasks: !commitChanges,
        },
    };

    return actionWorkTaskRequest;
};

/**
 * Call API to get the impact of the changes.
 */
const getImpactOfChanges = async () => {
    isLoadingImpactOfChanges.value = true;

    // Reset flags
    isRescheduledBefore.value = false;
    isRescheduledAfter.value = false;
    tasksThatWillBeChanged.value = [];

    // Build API request
    const request = buildRescheduleWorkTaskApiRequest(false);

    // Call API to get impact of changes
    const response = (await apiService.post('work-tasks/reschedule-work-task', request)) as RescheduleWorkTaskResponse;

    // Store results
    tasksThatWillBeChanged.value = response.workTasksThatWillBeChanged;

    // Find changed details for the rescheduled task
    const rescheduledTask = response.workTasksThatWillBeChanged.find(
        (wt) => wt.workTaskId == localRescheduleWorkTaskModel.value.workTaskId,
    );

    // If the details of the rescheduled task were found
    if (rescheduledTask != null) {
        // Get original and new dates
        const newDueDate = rescheduledTask.dueDateLatest;
        const originalDueDate = workTask.value!.dueDate;

        // If both due dates were found
        if (newDueDate != null && originalDueDate != null) {
            // If the task was rescheduled to an earlier date
            if (isBefore(newDueDate, originalDueDate)) {
                isRescheduledBefore.value = true;

                // If the Leave Push Later Tasks Back option is selected OR if no option is selected
                if (
                    localRescheduleWorkTaskModel.value.rescheduleTaskCascadeChangesOptionCode ==
                        CascadeChangesOptionCode.PushLaterTasksBack ||
                    localRescheduleWorkTaskModel.value.rescheduleTaskCascadeChangesOptionCode == null
                ) {
                    // Set to Maintain Current Dependency Days (default)
                    localRescheduleWorkTaskModel.value.rescheduleTaskCascadeChangesOptionCode =
                        CascadeChangesOptionCode.MaintainCurrentDependencyDays;
                }
                // If the Leave Later Tasks Unchanged option is selected
                else if (
                    localRescheduleWorkTaskModel.value.rescheduleTaskCascadeChangesOptionCode ==
                    CascadeChangesOptionCode.LeaveLaterTasksUnchanged
                ) {
                    // Set to Recalculate Dependency Days
                    localRescheduleWorkTaskModel.value.rescheduleTaskCascadeChangesOptionCode =
                        CascadeChangesOptionCode.RecalculateDependencyDays;
                }
            }
            // If the task was rescheduled to a later date
            else if (isAfter(newDueDate, originalDueDate)) {
                isRescheduledAfter.value = true;

                // If the Leave Maintain Current Dependency Days option is selected OR if no option is selected
                if (
                    localRescheduleWorkTaskModel.value.rescheduleTaskCascadeChangesOptionCode ==
                        CascadeChangesOptionCode.MaintainCurrentDependencyDays ||
                    localRescheduleWorkTaskModel.value.rescheduleTaskCascadeChangesOptionCode == null
                ) {
                    // Set to Push Later Tasks Back (default)
                    localRescheduleWorkTaskModel.value.rescheduleTaskCascadeChangesOptionCode =
                        CascadeChangesOptionCode.PushLaterTasksBack;
                }
                // If the Recalculate Dependency Days option is selected
                else if (
                    localRescheduleWorkTaskModel.value.rescheduleTaskCascadeChangesOptionCode ==
                    CascadeChangesOptionCode.RecalculateDependencyDays
                ) {
                    // Set to Leave Later Tasks Unchanged
                    localRescheduleWorkTaskModel.value.rescheduleTaskCascadeChangesOptionCode =
                        CascadeChangesOptionCode.LeaveLaterTasksUnchanged;
                }
            } else {
                // Rescheduled to same date to clear the selected Cascade Changes option
                localRescheduleWorkTaskModel.value.rescheduleTaskCascadeChangesOptionCode = null;
            }
        }
    }

    // End loading (nextTick is used to ensure that the watch events are not triggered during the load)
    nextTick(() => {
        isLoadingImpactOfChanges.value = false;
    });
};

/**
 * The filtered options for Cascade Changes. This will show only those options that are relevant, depending on whether
 * the task has been rescheduled to an earlier or later date.
 */
const cascadeChangesOptionsFiltered = computed(() => {
    return cascadeChangesOptions.filter(
        (o) =>
            o.isAnOptionWhenTaskMovedToEarlierDueDate == isRescheduledBefore.value &&
            o.isAnOptionWhenTaskMovedToLaterDueDate == isRescheduledAfter.value,
    );
});

/**
 * Returns a list of tasks that will have their due date changed as a result of the rescheduling.
 */
const tasksThatWillHaveDueDateChanged = computed(() => {
    return tasksThatWillBeChanged.value.filter((t) => t.isDueDateChanged);
});

/**
 * Returns a list of tasks that will have their "due days after" changed as a result of the rescheduling.
 */
const tasksThatWillHaveDueDaysAfterChanged = computed(() => {
    return tasksThatWillBeChanged.value.filter((t) => t.isDueAfterDaysChanged);
});

/**
 * Returns a list of tasks that will have their dependency removed as a result of the rescheduling.
 */
const tasksThatWillHaveDependencyRemoved = computed(() => {
    return tasksThatWillBeChanged.value.filter((t) => t.isDependencyRemoved);
});

/**
 * Returns the new previous task as a result of the rescheduling.
 */
const newPreviousTask = computed(() => {
    return tasksThatWillBeChanged.value.filter((t) => t.isNewPreviousTask);
});

/**
 * Returns the new previous task as a result of the rescheduling.
 */
const newNextTask = computed(() => {
    return tasksThatWillBeChanged.value.filter((t) => t.isNewNextTask);
});

/**
 * Returns the new previous and next tasks as a result of the rescheduling.
 */
const newPreviousAndNextTasks = computed(() => {
    return tasksThatWillBeChanged.value.filter((t) => t.isNewPreviousTask || t.isNewNextTask);
});

/**
 * Format date for the API.
 */
const formatDateForApi = (date: Date) => {
    return format(date, 'yyyy-MM-dd');
};

/**
 * Close the modal.
 */
const close = () => {
    rescheduleWorkTaskModalStore.close();
};

/**
 * Reset the form to its default state.
 */
const resetForm = () => {
    workTask.value = null;
    farmField.value = null;
    localRescheduleWorkTaskModel.value = new RescheduleWorkTaskForm();
    isRescheduledBefore.value = false;
    isRescheduledAfter.value = false;
    tasksThatWillBeChanged.value = [];
    isLoadingImpactOfChanges.value = false;
};

// Watch for changes to modal state
watch(
    () => rescheduleWorkTaskModalStore.isVisible,
    async (isVisible) => {
        if (isVisible) {
            await loadWorkTaskData();
        }
    },
);

// When the task's due date is changed, call API to get the impact of the changes.
watch(
    [
        () => localRescheduleWorkTaskModel.value.dueDateTiming,
        () => localRescheduleWorkTaskModel.value.dueDateOn,
        () => localRescheduleWorkTaskModel.value.dueDateLatest,
        () => localRescheduleWorkTaskModel.value.dueDateMonth,
        () => localRescheduleWorkTaskModel.value.dueDateRange,
        () => localRescheduleWorkTaskModel.value.dueAfterWorkTaskId,
        () => localRescheduleWorkTaskModel.value.dueAfterWorkTaskWithinDays,
        () => localRescheduleWorkTaskModel.value.rescheduleTaskCascadeChangesOptionCode,
    ],

    async () => {
        if (!isLoading.value && !isLoadingImpactOfChanges.value) {
            const isValid = await dueDateTimingControl.value.validate();

            if (isValid) {
                getImpactOfChanges();
            }
        }
    },
);
</script>

<style lang="scss" scoped>
@import '@/assets/scss/swatches.scss';

.formHead {
    width: 9rem;
}

.completeConfirmation {
    background-color: rgba($swatchGRN, 0.2) !important;

    .confirm-header {
        background-color: rgba($swatchA5, 0.5);
        font-size: large;
    }

    .confirm-checkbox {
        font-size: 18pt;
    }
}
</style>
