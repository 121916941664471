import {HeaderSearchMode} from '@/enums/header-search-mode';
import {RoleCode} from '@/enums/role-code';
import i18n from '@/i18n';
import {SelectListItem} from '@/models/generic/SelectListItem';
import {useUserStore} from '@/stores/user-store';
import {computed, ref} from 'vue';

export function useHeaderSearchModes() {
    const userStore = useUserStore();
    const searchModes = ref<SelectListItem[]>([]);

    /**
     * Adds the search modes that the user has access to.
     */
    const setSearchModes = async () => {
        // Add Farm Field search mode
        if (userStore.hasRoleLevel(RoleCode.Fields, 1)) {
            searchModes.value.push({value: HeaderSearchMode.FarmField, title: i18n.global.t('Field')});
        }

        // Add Cropsearch mode
        searchModes.value.push({
            value: HeaderSearchMode.Crop,
            title: i18n.global.t('CropVariety'),
        });

        // Add Product search mode
        searchModes.value.push({
            value: HeaderSearchMode.Product,
            title: i18n.global.t('Product'),
        });

        // Add Product search mode
        searchModes.value.push({
            value: HeaderSearchMode.ProductMixture,
            title: i18n.global.t('ProductMixture'),
        });

        // Add Observation search mode
        if (userStore.hasRoleLevel(RoleCode.Observations, 1)) {
            searchModes.value.push({value: HeaderSearchMode.Observation, title: i18n.global.t('Observation')});
        }

        // Add Work Task search mode
        if (userStore.hasRoleLevel(RoleCode.Tasks, 1)) {
            searchModes.value.push({value: HeaderSearchMode.WorkTask, title: i18n.global.t('Task')});
        }

        // Add User search mode
        if (userStore.hasRoleLevel(RoleCode.System, 1)) {
            searchModes.value.push({value: HeaderSearchMode.User, title: i18n.global.t('User')});
        }
    };

    /**
     * Gets the default search mode out of the search modes that the user has access to.
     */
    const defaultSearchMode = computed<HeaderSearchMode | null>(() => {
        if (searchModes.value.length > 0) {
            return searchModes.value[0].value as HeaderSearchMode;
        }

        return null;
    });

    return {
        searchModes,
        defaultSearchMode,
        setSearchModes,
    };
}
