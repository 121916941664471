<template>
    <div style="display: flex; align-items: center">
        <WorkTaskPlanChip :work-task-plan-name="workTaskPlan.workTaskPlanName" />

        <div class="ml-2">
            <IdIcon>{{ workTaskPlan.workTaskPlanId }}</IdIcon>
        </div>

        <div v-if="showOperationsMenu" style="margin-left: auto">
            <WorkTaskPlanOperationsMenu :work-task-plan="workTaskPlan" :show-delete-task-plan="showDeletePlanLink" />
        </div>
    </div>

    <div style="display: flex; align-items: center" class="mt-4">
        <div v-if="showCreatedAndModifiedDetails">
            <!-- Created -->
            <div v-if="workTaskPlan.createdUserId" class="flex-layout fill flex-layout-space-05">
                <div class="formHead" style="width: 12rem">{{ $t('Created') }}</div>
                <div style="flex-grow: 1">
                    <UserChip
                        :user="
                            UserHelper.userChipType(
                                workTaskPlan.createdUserId,
                                UserHelper.userFullName(
                                    workTaskPlan.createdUserGivenName,
                                    workTaskPlan.createdUserSurname,
                                ),
                            )
                        " />

                    <span class="swatchA1 ml-4">{{ formatDate(workTaskPlan.createdDate) }}</span>
                </div>
            </div>

            <!-- Last Modified -->
            <div v-if="workTaskPlan.modifiedUserId" class="flex-layout fill flex-layout-space-05">
                <div class="formHead" style="width: 12rem">{{ $t('LastModified') }}</div>
                <div>
                    <UserChip
                        :user="
                            UserHelper.userChipType(
                                workTaskPlan.modifiedUserId,
                                UserHelper.userFullName(
                                    workTaskPlan.modifiedUserGivenName,
                                    workTaskPlan.modifiedUserSurname,
                                ),
                            )
                        " />

                    <span class="swatchA1 ml-4">{{ formatDate(workTaskPlan.modifiedDate) }}</span>
                </div>
            </div>

            <!-- Instructions -->
            <div v-if="showDescription" class="flex-layout fill flex-layout-space-05">
                <div class="formHead" style="width: 12rem">{{ $t('Description') }}</div>
                <div class="swatchA1 bold">
                    {{ workTaskPlan.workTaskPlanDescription }}
                </div>
            </div>
        </div>

        <div style="align-self: baseline; gap: 0.5rem; margin-left: auto" class="mt-2">
            <div style="display: flex; align-items: center">
                <div
                    v-for="workTaskPlanItem in workTaskPlanItemsSorted"
                    :key="workTaskPlanItem.workTaskPlanItemId"
                    class="ml-1">
                    <WorkTaskTypeIcon :workTaskTypeCode="workTaskPlanItem.workTaskTypeCode" />
                </div>
            </div>

            <div style="display: flex; justify-content: flex-end; width: 100%" class="mt-2">
                <WorkTaskPlanNumberOfTimesAppliedChip :number-of-times-applied="workTaskPlan.numberOfTimesApplied" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import {computed, defineProps} from 'vue';
import {WorkTaskPlanSearchResultDto} from '@/models/data-transfer-objects/search/work-task-plan-search/WorkTaskPlanSearchResultDto';
import {format} from 'date-fns';
import UserChip from '@/components/UserChip.vue';
import WorkTaskPlanNumberOfTimesAppliedChip from '@/components/other-chips/WorkTaskPlanNumberOfTimesAppliedChip.vue';
import WorkTaskPlanChip from '@/components/WorkTaskPlanChip.vue';
import WorkTaskPlanOperationsMenu from '@/components/operations-menus/WorkTaskPlanOperationsMenu.vue';
import UserHelper from '@/helpers/UserHelper';

const props = withDefaults(
    defineProps<{
        workTaskPlan: WorkTaskPlanSearchResultDto;
        showOperationsMenu?: boolean;
        showCreatedAndModifiedDetails?: boolean;
        showDescription?: boolean;
        showDeletePlanLink?: boolean;
    }>(),
    {
        showOperationsMenu: true,
        showCreatedAndModifiedDetails: true,
        showDescription: true,
        showDeletePlanLink: true,
    },
);

const formatDate = (date: Date | null): string | null => {
    if (!date) return null;
    return format(date, 'dd MMMM yyyy');
};

/**
 * List of work task plan items sorted by their sequence number.
 */
const workTaskPlanItemsSorted = computed(() => {
    const sortedArray = [...props.workTaskPlan.workTaskPlanItems];
    return sortedArray.sort((a, b) => a.sequenceNumber - b.sequenceNumber);
});
</script>
