<template>
    <v-tooltip bottom>
        <template v-slot:activator="{props}">
            <div :class="backgroundClass">
                <CircleIcon
                    v-bind="props"
                    :style="{height: iconHeight + 'px', width: iconWidth + 'px', fontSize: iconSize + 'pt'}"
                    :class="iconClass">
                    <FontAwesomeIcon :icon="[iconFamily, iconCode]" :class="backgroundClass" />
                </CircleIcon>
                <span class="pr-2" style="padding-left: 5px" v-if="displayText && displayText != ''">{{
                    displayText
                }}</span>
            </div>
        </template>
        <slot name="helpText"></slot>
        {{ name }}
    </v-tooltip>
</template>

<script lang="ts">
import {PropType, defineComponent, computed} from 'vue';
import {getCropByCode, Crop} from '@/services/crops-service';
import {CropCode} from '@/enums/crop-code';

export default defineComponent({
    name: 'CropIcon',
    props: {
        cropCode: String as PropType<CropCode>,
        primary: {
            type: Boolean,
            default: false,
        },
        isCollapsed: {
            type: Boolean,
            default: false,
        },
        isShowCropName: {
            type: Boolean,
            default: false,
        },
        varieties: {
            type: Array<string>,
            default: [],
        },
        collapseCharacterLimit: {
            type: Number,
            default: null,
        },
        iconHeight: {
            type: String,
            default: '30',
        },
        iconWidth: {
            type: String,
            default: '30',
        },
        iconSize: {
            type: String,
            default: '18',
        },
    },
    setup(props) {
        const crop = computed<Crop | undefined>(() => {
            return getCropByCode(props.cropCode as CropCode);
        });

        const iconFamily = computed<string>(() => {
            return crop.value?.iconFamily || 'fas';
        });

        const iconCode = computed<string>(() => {
            return crop.value?.iconCode || 'question';
        });

        const name = computed<string | null>(() => {
            return crop.value?.getCropName() || null;
        });

        const iconClass = computed<string>(() => (props.primary ? 'primary-icon' : 'secondary-icon'));

        const backgroundClass = computed<string>(() =>
            props.primary ? 'rounded-text primary-background' : 'rounded-text secondary-background',
        );
        const displayText = computed<string>(() => {
            if (props.isShowCropName) {
                if (crop.value && crop.value.getCropName()) return crop.value.getCropName();
                else return '';
            } else if (props.varieties && props.varieties.length > 0) {
                if (props.varieties.length == 1) {
                    return props.varieties[0];
                } else if (props.isCollapsed) {
                    let varietyText = props.varieties.join(' / ');
                    if (props.collapseCharacterLimit && varietyText.length < props.collapseCharacterLimit) {
                        return varietyText;
                    } else {
                        return props.varieties[0] + ' +';
                    }
                } else return props.varieties.join(' / ');
            } else return '';
        });
        return {
            iconFamily,
            iconCode,
            name,
            iconClass,
            backgroundClass,
            displayText,
        };
    },
});
</script>

<style lang="scss" scoped>
@import '../assets/scss/swatches.scss';
.rounded-text {
    display: inline-flex;
    align-items: center;
    border-radius: 30px; /* Adjust the border radius to make it round */
    font-family: 'Open Sans Condensed', sans-serif;
}
.primary-background {
    background-color: rgba($swatchA2, 0.8);
    color: $swatchWHT;
}
.secondary-background {
    background-color: rgba($swatchA5, 0.8);
    color: $swatchBLK;
}
.primary-icon {
    background-color: $swatchA2 !important;
}
.secondary-icon {
    background-color: $swatchA5 !important;
}
</style>
