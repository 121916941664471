import {ApplicationRateUnitCode} from '@/enums/application-rate-unit-code';

export class SaveWorkTaskProductDto {
    workTaskProductId: number | null = null;
    productId!: number;
    plannedApplicationRate!: number;
    plannedApplicationRateUnitCode!: ApplicationRateUnitCode;

    constructor(data?: Partial<SaveWorkTaskProductDto>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
