<template>
    <v-dialog v-model="manageObservationModalStore.isVisible" class="pnlUserManager">
        <v-card style="overflow-y: hidden">
            <v-toolbar color="secondary">
                <v-toolbar-title>
                    <FontAwesomeIcon :icon="['fal', 'binoculars']" size="xl" class="mr-2" />
                    {{ modalTitle }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <CloseModalButton @click="close" />
            </v-toolbar>

            <LoadingSymbol v-if="isLoading" />
            <v-form v-else ref="form" class="pa-4 divUserManager" v-model="valid" style="overflow-y: scroll">
                <!-- Select Type -->
                <div class="flex-layout fill flex-layout-space-05 align-items-center">
                    <div class="formHead">{{ $t('SelectType') }}</div>
                    <div class="formIcon">
                        <HelpIcon :help-text="$t('ManageObservation_SelectType_HelpText')" />
                    </div>
                    <div>
                        <ObservationTypeSelect
                            v-model="localObservation.observationTypeCode"
                            :rules="[requiredRule]"
                            :is-enabled="true" />
                    </div>
                </div>

                <div class="flex-layout fill flex-layout-space-05 align-items-center formRow flex-wrap-nowrap mt-3">
                    <div class="formHead">{{ $t('Location') }}</div>
                    <div class="formIcon">
                        <HelpIcon :help-text="$t('ManageObservation_Location_HelpText')" />
                    </div>
                    <div class="formModifyGroup">
                        <LocationPicker
                            class="pl-0 ml-0"
                            :latLng="locationCentroid"
                            :input-required="true"
                            @update:latLng="locationCentroid = $event" />
                    </div>
                </div>

                <!-- Field -->
                <div class="flex-layout fill flex-layout-space-05 align-items-center" v-if="displayFarmField">
                    <div class="formHead">{{ $t('Field') }}</div>
                    <div class="formIcon">
                        <HelpIcon :help-text="$t('ManageObservation_Field_HelpText')" />
                    </div>
                    <div class="formModifyGroup">
                        <v-autocomplete
                            v-model="localObservation.farmFieldId"
                            :rules="requireFarmFieldRule ? [requiredRule] : [() => true]"
                            required
                            :placeholder="$t('SelectField')"
                            :items="farmFields"
                            item-title="farmFieldCodeAndName"
                            item-value="farmFieldId"
                            max-width="400px"
                            menu-icon=""
                            class="swatchA1 font-weight-bold">
                            <template v-slot:append-inner>
                                <CircleIcon
                                    class="marginLeft marginRightSmall swatchGRNBG"
                                    color="swatchWHT"
                                    v-if="localObservation.farmFieldId != null">
                                    <FontAwesomeIcon :icon="['fas', 'check']" />
                                </CircleIcon>
                            </template>
                        </v-autocomplete>
                    </div>
                </div>

                <!-- Observation Summary -->
                <div class="flex-layout fill flex-layout-space-05 align-items-center formRow flex-wrap-nowrap">
                    <div class="formHead">{{ $t('Summary') }}</div>
                    <div class="formIcon">
                        <HelpIcon :help-text="$t('ManageObservation_Summary_HelpText')" />
                    </div>
                    <div class="formModifyGroup">
                        <v-text-field
                            v-model="localObservation.summary"
                            :placeholder="$t('EnterObservationSummary')"
                            :rules="[requiredRule, maxCharacterRule]"
                            max-width="400px"
                            class="swatchA1 font-weight-bold">
                        </v-text-field>
                    </div>
                </div>

                <!-- Observation Users -->
                <div
                    class="flex-layout fill flex-layout-space-05 align-items-center formRow flex-wrap-nowrap"
                    v-if="isUserHasAccess">
                    <div class="formHead">{{ $t('OnBehalfOf') }}</div>
                    <div class="formIcon">
                        <HelpIcon :help-text="$t('ManageObservation_User_HelpText')" />
                    </div>
                    <div class="formModifyGroup">
                        <v-autocomplete
                            v-model="localObservation.behalfUserId"
                            :items="users"
                            :rules="[requiredRule]"
                            hide-details
                            clearable
                            small-chips
                            item-title="userFullName"
                            item-value="userId">
                            <template #selection="{item}">
                                <UserChip
                                    :user="
                                        UserHelper.userChipType(
                                            item.value,
                                            userStore.user && item.value && item.value === userStore.user.userId
                                                ? $t('Myself')
                                                : item.title,
                                        )
                                    " />
                            </template>
                        </v-autocomplete>
                    </div>
                </div>

                <div class="flex-layout fill flex-layout-space-05 align-items-center">
                    <div class="formHead">{{ $t('DateCreated') }}</div>
                    <div class="formIcon">
                        <HelpIcon :help-text="$t('ManageObservation_DateCreated_HelpText')" />
                    </div>
                    <div class="formModifyGroup">
                        <div style="display: flex; align-items: center">
                            <VueDatePicker
                                v-model="localObservation.dateCreated"
                                :disabled-dates="disableFutureDate"
                                format="dd/MM/yyyy HH:mm"
                                :teleport="true"
                                text-input
                                style="max-width: 300px"
                                class="swatchA1 font-weight-medium"
                                :locale="i18n.global.locale" />
                        </div>

                        <!-- DatePicker Validation -->
                        <v-validation :rules="[dateRequiredRule]">
                            <template v-slot="{errorMessages, isValid}">
                                <div v-if="isValid.value != null">
                                    <span
                                        v-for="(error, index) in errorMessages.value"
                                        :key="index"
                                        class="error-message">
                                        {{ error }}
                                    </span>
                                </div>
                            </template>
                        </v-validation>
                    </div>
                </div>

                <!-- Details-->
                <div class="flex-layout fill flex-layout-space-05 align-items-center">
                    <div class="formHead">{{ $t('Details') }}</div>
                    <div class="formIcon">
                        <HelpIcon :help-text="$t('ManageObservation_Details_HelpText')" />
                    </div>
                    <div class="formModifyGroup">
                        <v-textarea
                            v-model="localObservation.details"
                            :placeholder="$t('EnterDetailsForThisObservation')"
                            variant="outlined"
                            density="compact"
                            rows="2"
                            auto-grow
                            hide-details="auto"
                            class="swatchA1 font-weight-bold">
                        </v-textarea>
                    </div>
                </div>

                <!-- Attachments -->
                <div class="flex-layout fill flex-layout-space-05 align-items-center formRow">
                    <div class="formHead">{{ $t('Attachments') }}</div>
                    <div class="formIcon">
                        <HelpIcon :help-text="$t('ManageObservation_Attachments_HelpText')" />
                    </div>
                    <!-- :prepend-icon="null" -->
                    <div class="formModifyGroup">
                        <v-file-input
                            v-model="localObservation.artifacts"
                            :rules="[uploadedFileRule]"
                            :label="$t('FileUploads')"
                            prepend-inner-icon="mdi-paperclip"
                            :prepend-icon="null"
                            chips
                            multiple
                            clearable
                            variant="outlined"
                            density="compact"
                            hide-details="auto"
                            class="swatchA1">
                            <template v-slot:selection="{fileNames}">
                                <template v-for="(artifact, index) in localObservation.artifacts" :key="index">
                                    <v-chip class="me-2 swatchA3BG swatchA3txt" size="small" label rounded="0">
                                        {{ (artifact as any).artifactDisplayName || fileNames[index] }}
                                    </v-chip>
                                </template>
                            </template>
                        </v-file-input>
                    </div>
                </div>
            </v-form>

            <v-card-actions class="justify-space-between swatchG9BG">
                <v-btn @click="close">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'arrow-rotate-left']" size="xl" />
                    </template>
                    {{ $t('Cancel') }}
                </v-btn>
                <v-btn @click="save" :disabled="isLoading">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'save']" size="xl" />
                    </template>
                    {{ $t('Save') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" timeout="4000">
        {{ snackbar.text }}
    </v-snackbar>
</template>

<script setup lang="ts">
import '@/assets/scss/user/user-manager.scss';
import {computed, watch, inject, ref, defineAsyncComponent, nextTick, onMounted} from 'vue';
import ApiService from '@/services/api-service.js';
import CloseModalButton from '@/components/CloseModalButton.vue';
import ObservationTypeSelect from '@/components/ObservationTypeSelect.vue';
import i18n from '@/i18n';
import {SearchObservationsRequest} from '@/models/api/requests/search/SearchObservationsRequest';
import {SearchObservationsResponse} from '@/models/api/responses/search/SearchObservationsResponse';
import {ManageObservationForm} from '@/models/observations/ManageObservationForm';
import {SaveObservationRequest} from '@/models/api/requests/observation/SaveObservationRequest';
import {useManageObservationModalStore} from '@/stores/modals/manage-observation-modal-store';
import {useFarmFields} from '@/composables/data-source/farm-fields';
const {getFarmFields, farmFields} = useFarmFields();
const VueDatePicker = defineAsyncComponent(() => import('@vuepic/vue-datepicker'));
import {useUserStore} from '@/stores/user-store';
import Validation from '@/helpers/ValidationHelper';
import {UploadedArtifactDto} from '@/models/data-transfer-objects/system/UploadedArtifactDto';
import {ArtifactFolder} from '@/enums/artifact-folder';
import {ArtifactTypeCode} from '@/enums/artifact-type-code';
import FileUploadHelper from '@/helpers/FileUploadHelper';
import {FarmFieldSearchResultDto} from '@/models/data-transfer-objects/search/farm-field-search/FarmFieldSearchResultDto';
const userStore = useUserStore();
import {ObservationSearchResultDto} from '@/models/data-transfer-objects/search/observation-search/ObservationSearchResultDto';
import UserChip from '@/components/UserChip.vue';
import {useUsers} from '@/composables/data-source/users';
const {getUsers, users} = useUsers();
import LocationPicker from '@/components/LocationPicker.vue';
import {useObservationTypes} from '@/composables/data-source/observation-types';
const {getObservationTypes, observationTypes} = useObservationTypes();
import {RoleCode} from '@/enums/role-code';
import UserHelper from '@/helpers/UserHelper';
import {GetFarmFieldContainingLatLngRequest} from '@/models/api/requests/farm/GetFarmFieldContainingLatLngRequest';
import {useConfigStore} from '@/stores/config-store';
const configStore = useConfigStore();

// Form
const form = ref();
const valid = ref<boolean>(true);
let isLoading = ref<boolean>(false);
const snackbar = ref({
    show: false,
    text: '',
});
const locationCentroid = ref<number[]>([configStore.defaultMapLatitude, configStore.defaultMapLongitude]);
const displayFarmField = computed(() => {
    if (!localObservation.value.observationTypeCode) return false;
    const observationType = observationTypes.value.find(
        (e) => e.observationTypeCode === localObservation.value.observationTypeCode,
    );
    if (!observationType) return false;
    return observationType.canBeAssociatedWithFarmField;
});

const requireFarmFieldRule = computed(() => {
    if (!localObservation.value.observationTypeCode) return false;
    const observationType = observationTypes.value.find(
        (e) => e.observationTypeCode === localObservation.value.observationTypeCode,
    );
    if (!observationType) return false;
    return observationType.mustBeAssociatedWithFarmField;
});

// Rules
const requiredRule = Validation.createRule_Required();
const maxCharacterRule = (v: string | null) => !v || v.length <= 100 || i18n.global.t('Validation_InvalidFormat');
const uploadedFileRule = Validation.createRule_UploadedFile();

// Services
const apiService = inject('apiService') as ApiService;

// Modal
const manageObservationModalStore = useManageObservationModalStore();
const observation = ref<ManageObservationForm>(new ManageObservationForm());
const localObservation = ref<ManageObservationForm>({...observation.value});
const observationSearchResult = ref<ObservationSearchResultDto | null>(null);
const farmFieldSearchResult = ref<FarmFieldSearchResultDto | null>(null);

const isUserHasAccess = computed<boolean>(() => userStore.hasRoleLevel(RoleCode.Observations, 2));

const dateRequiredRule = () => {
    if (localObservation.value.dateCreated) return true;

    return i18n.global.t('Validation_Required');
};

/**
 * Load observation data for the modal.
 */
const loadObservationData = async () => {
    isLoading.value = true;

    // Reset form
    resetForm();

    // Load dropdown data
    await getFarmFields(userStore.user?.farmSiteId ? [userStore.user.farmSiteId] : []);
    await getObservationTypes();
    await getUsers();

    // If observation ID was specified
    if (manageObservationModalStore.observationId !== null) {
        // Build search request using the observation ID
        const searchRequest: SearchObservationsRequest = new SearchObservationsRequest({
            isQuickSearch: false,
            observationId: manageObservationModalStore.observationId,
            includeArtifacts: true,
        });

        // Get observation details
        const searchResults = (await apiService.post(
            'search/observations',
            searchRequest,
        )) as SearchObservationsResponse;

        if (searchResults.observations.length == 1) {
            // There is a single result, so set the observation object to that value
            observation.value = Object.assign(new ManageObservationForm(), searchResults.observations[0]);
            observation.value.summary = searchResults.observations[0].observationTitle;
            observation.value.behalfUserId =
                searchResults.observations[0].lastModifiedUserId ?? searchResults.observations[0].recordedUserId;
            observation.value.dateCreated = searchResults.observations[0].recordedDate;
            observation.value.details = searchResults.observations[0].observationComments;

            locationCentroid.value = searchResults.observations[0].observationLocation
                ? searchResults.observations[0].observationLocation.coordinates.reverse()
                : [configStore.defaultMapLatitude, configStore.defaultMapLongitude];

            // Store search result
            observationSearchResult.value = searchResults.observations[0];
        }
    } else {
        observation.value.behalfUserId = userStore.user && userStore.user.userId ? userStore.user.userId : null;
        observation.value.dateCreated = new Date();
    }

    // Update value of local observation
    localObservation.value = {...observation.value};

    // End loading (nextTick is used to ensure that the watch events on localObservation are not triggered during the load)
    nextTick(() => {
        isLoading.value = false;
    });
};

/**
 * Save the modal.
 */
const save = async () => {
    // Perform final client side validation of form
    await form.value.validate();

    // If form is valid
    if (valid.value) {
        let snackbarMessage = '';
        isLoading.value = true;

        try {
            const uploadedArtifacts: UploadedArtifactDto[] = [];
            if (localObservation.value.haveArtifactsBeenUpdated) {
                // Build artifacts object
                localObservation.value.artifacts.forEach((artifact) => {
                    const uploadedArtifact = new UploadedArtifactDto({
                        file: artifact,
                        artifactFolder: ArtifactFolder.Observations,
                        artifactTypeCode: ArtifactTypeCode.ObservationAttachment,
                    });

                    uploadedArtifacts.push(uploadedArtifact);
                });
            }

            // Build API request
            const saveObservationRequest = {
                observationId: localObservation.value.observationId,
                observationTypeCode: localObservation.value.observationTypeCode,
                farmFieldId: localObservation.value.farmFieldId,
                farmSiteId: userStore.user?.farmSiteId ?? null,
                location:
                    locationCentroid.value && locationCentroid.value.length === 2 ? [...locationCentroid.value] : null,
                summary: localObservation.value.summary,
                dateCreated:
                    localObservation.value.dateCreated && typeof localObservation.value.dateCreated === 'string'
                        ? new Date(localObservation.value.dateCreated).toISOString()
                        : localObservation.value.dateCreated
                        ? localObservation.value.dateCreated.toISOString()
                        : new Date().toISOString(),
                behalfUserId: localObservation.value.behalfUserId,
                details: localObservation.value.details,
                artifacts: uploadedArtifacts,
                haveArtifactsBeenUpdated: localObservation.value.haveArtifactsBeenUpdated,
            } as SaveObservationRequest;

            // Call API to save observation
            await apiService.post(
                'observations/observation',
                FileUploadHelper.createFormDataObject(saveObservationRequest),
            );

            // Set snackbar message
            if (manageObservationModalStore.observationId === null) {
                snackbarMessage = i18n.global.t('CreateObservation_Success');
            } else {
                snackbarMessage = i18n.global.t('ModifyObservation_Success');
            }

            // Show success feedback to user
            snackbar.value.show = true;
            snackbar.value.text = snackbarMessage;
            manageObservationModalStore.savedCounter++;

            // Close modal
            manageObservationModalStore.close();
        } catch (ex: unknown) {
            // Show fail feedback to user
            snackbar.value.show = true;
            snackbar.value.text = i18n.global.t('ErrorGeneric');
            isLoading.value = false;
        }
    } else {
        // Show validation error feedback to user
        snackbar.value.show = true;
        snackbar.value.text = i18n.global.t('ErrorValidation');
    }
};

// Function to check if a date falls within any of the disabled ranges
const disableFutureDate = (date: Date) => {
    const now = new Date();

    // Create a new date object for the next day
    const nextDay = new Date(now);

    nextDay.setDate(now.getDate() + 1);

    nextDay.setHours(0, 0, 0, 0);

    return date >= nextDay;
};

/**
 * Reset the form to its default state.
 */
const resetForm = () => {
    observation.value = new ManageObservationForm();
    localObservation.value = {...observation.value};
    observationSearchResult.value = null;
    farmFieldSearchResult.value = null;
    locationCentroid.value = [configStore.defaultMapLatitude, configStore.defaultMapLongitude];
};

/**
 * The title of the modal.
 */
const modalTitle = computed(() =>
    manageObservationModalStore.observationId === null
        ? i18n.global.t('CreateAnObservation')
        : i18n.global.t('ModifyObservation'),
);

// Watch for changes to modal state
watch(
    () => manageObservationModalStore.isVisible,
    async (isVisible) => {
        if (isVisible) {
            await loadObservationData();
        }
    },
);

// Update flag to indicate that artifacts have been updated.
watch(
    () => localObservation.value.artifacts,
    () => {
        if (!isLoading.value) {
            localObservation.value.haveArtifactsBeenUpdated = true;
        }
    },
);

//reset farmfield whenver observation type is changed
watch(
    () => localObservation.value.observationTypeCode,
    (val) => {
        const observationType = observationTypes.value.find((e) => e.observationTypeCode === val);
        if (observationType && !observationType.canBeAssociatedWithFarmField) {
            localObservation.value.farmFieldId = null;
        }
    },
);

// Update farm field when the location is changed if it hasn't already been set.
watch(
    () => locationCentroid,
    async () => {
        // If a lat/lng has been selected
        if (locationCentroid.value != null && locationCentroid.value.length == 2) {
            // Call API to get the farm field
            const request: GetFarmFieldContainingLatLngRequest = {
                latitude: locationCentroid.value[0],
                longitude: locationCentroid.value[1],
            };

            const farmFieldId = (await apiService.get('farmFields/farm-field-containing-lat-lng', request)) as number;

            // Only update the field if this lat/lng is within the boundaries of a field
            if (farmFieldId != null) {
                localObservation.value.farmFieldId = farmFieldId;
            }
        }
    },
    {deep: true},
);

onMounted(async () => {
    if (userStore.isLoggedIn) await loadObservationData();
});

/**
 * Close the modal.
 */
const close = () => {
    manageObservationModalStore.close();
};
</script>
