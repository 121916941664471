<template>
    <v-toolbar v-if="displayToolbar" class="swatchA2BG">
        <FontAwesomeIcon :icon="['fal', 'binoculars']" size="xl" class="swatchWHT ml-3" />
        <v-toolbar-title class="swatchWHT observation-data-table-title"
            >{{ $t('ObservationList') + ` (${resultsList.length})` }}
        </v-toolbar-title>

        <v-tabs v-model="tab" bg-color="swatchA2" selected-class="swatchWHTBG swatchBLK">
            <v-tab :value="false">
                <FontAwesomeIcon :icon="['fal', 'location-check']" size="xl" class="mr-2" />
                {{ $t('ClosedObservations') + ` (${closedObs.length})` }}
            </v-tab>
            <v-tab :value="true">
                <FontAwesomeIcon :icon="['fal', 'location-dot']" size="xl" class="mr-2" />
                {{ $t('OpenObservations') + ` (${openObs.length})` }}
            </v-tab>
        </v-tabs>

        <v-spacer></v-spacer>

        <v-btn class="v-btn--custom mr-2" rounded="1" @click="manageObservationModalStore.open()">
            <template v-slot:prepend>
                <FontAwesomeIcon :icon="['fal', 'plus']" size="xl" />
            </template>
            {{ $t('Add') }}
        </v-btn>
        <v-btn class="v-btn--custom mr-2" rounded="1" @click="viewObservationListOnHome(tab)">
            <template v-slot:prepend>
                <FontAwesomeIcon :icon="['fal', 'map']" size="xl" />
            </template>
            {{ $t('MapView') }}
        </v-btn>

        <v-btn :class="filterButtonClass" rounded="1" @click="switchBackToSearch">
            <template v-slot:prepend>
                <FontAwesomeIcon :icon="['fal', 'filter']" size="xl" v-if="isFilterApplied" />
                <FontAwesomeIcon :icon="['fac', 'filter-check']" size="xl" v-else />
            </template>
            {{ $t('Filters') }}
        </v-btn>

        <v-menu open-on-hover>
            <template v-slot:activator="{props}">
                <v-btn v-bind="props" class="ml-2 v-btn--custom" rounded="1">
                    <template v-slot:append>
                        <FontAwesomeIcon :icon="['fal', 'bars']" size="xl" />
                    </template>
                    {{ $t('Operations') }}
                </v-btn>
            </template>
            <v-list>
                <v-list-item>
                    <v-list-item-title>
                        <v-btn @click="manageObservationModalStore.open()">
                            {{ $t('CreateObservation') }}
                        </v-btn>
                    </v-list-item-title>
                </v-list-item>
                <v-list-item>
                    <v-list-item-title>
                        <v-btn @click="exportObservationList">
                            {{ $t('ExportResults') }}
                        </v-btn>
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </v-toolbar>
    <v-data-table
        :headers="headers"
        :items="tab ? openObs : closedObs"
        :items-per-page="configStore.defaultListItemNumberPerPage"
        @click:row="handleResultClick"
        class="observation-list">
        <template v-slot:[`item.observationTypeCode`]="{item}">
            <v-row>
                <v-col cols="12" align="center">
                    <ObservationTypeIcons :observationType="item.observationTypeCode" />
                </v-col>
            </v-row>
        </template>
        <template v-slot:[`item.observationTitle`]="{item}">
            <div class="swatchA1 slightlyLarger">{{ item.observationTitle }}</div>
        </template>

        <template v-slot:[`item.farmFieldCode`]="{item}">
            <v-row align="center" v-if="item.farmFieldId">
                <span style="width: 25px; display: flex; align-items: center; justify-content: center">
                    <SvgLoader
                        v-if="item.farmFieldPolygonImageUrl"
                        :url="item.farmFieldPolygonImageUrl"
                        :color="item.farmFieldColorHexCode ?? '#B8E531'" />

                    <SvgLoader v-else url="Field_UnknownShape.svg" :color="item.farmFieldColorHexCode ?? '#B8E531'" />
                </span>
                <span class="swatchA1 slightlyLarger pl-2">
                    {{ `${item.farmFieldCode} - ${item.farmFieldName}` }}
                </span>
            </v-row>
        </template>

        <template v-slot:[`item.recordedDate`]="{item}">
            <v-row>
                <span class="pl-1 pr-3 pt-1 swatchA1"> {{ formatDate(item.recordedDate) }}</span>
                <UserChip
                    v-if="item.recordedUserId"
                    :user="{
                        userId: item.recordedUserId,
                        username: `${item.recordedUserGivenName} ${item.recordedUserSurname}`,
                        profilePicture: '',
                    }" />
            </v-row>
        </template>

        <template v-slot:[`item.observationStatusCode`]="{item}">
            <v-row>
                <v-col cols="12" align="center"
                    ><ObservationStatusChip
                        :observation-status-code="item.observationStatusCode"
                        width="80px"
                        height="25px"
                        fontSize="15px" />
                </v-col>
            </v-row>
        </template>

        <template v-slot:[`item.farmFieldPrimaryCropcode`]="{item}">
            <v-row v-if="item.farmFieldPrimaryCropcode">
                <v-col cols="12" align="center">
                    <CropIcon :cropCode="item.farmFieldPrimaryCropcode" :is-show-crop-name="true" />
                </v-col>
            </v-row>
        </template>

        <template v-slot:[`item.lastModifiedDate`]="{item}">
            <v-row>
                <span class="pl-1 pr-3 pt-1 swatchA1"> {{ formatLastActionDate(item) }}</span>
                <UserChip v-if="getLastActionUser(item)" :user="getLastActionUser(item)" />
            </v-row>
        </template>

        <template v-slot:[`item.action`]="{item}">
            <div style="text-align: center" v-if="item.observationId">
                <v-menu>
                    <template v-slot:activator="{props}">
                        <v-btn class="v-btn--custom" v-bind="props"> {{ $t('Actions') }} </v-btn>
                    </template>

                    <v-list>
                        <v-list-item>
                            <v-list-item-title>
                                <v-btn @click="viewObservationModalStore.open(item.observationId)">{{
                                    $t('ViewSlashActionObservation')
                                }}</v-btn>
                            </v-list-item-title>
                            <v-list-item-title>
                                <v-btn @click="manageObservationModalStore.open(item.observationId)">
                                    {{ $t('ModifyObservation') }}
                                </v-btn>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </template>
    </v-data-table>
</template>

<script setup lang="ts">
import {ref, computed, onMounted} from 'vue';
import {ObservationSearchResultDto} from '@/models/data-transfer-objects/search/observation-search/ObservationSearchResultDto';
import i18n from '@/i18n';
import ObservationTypeIcons from '@/components/ObservationTypeIcons.vue';
import ObservationStatusChip from '@/components/ObservationStatusChip.vue';
import {useViewObservationModalStore} from '@/stores/modals/view-observation-modal-store';
const viewObservationModalStore = useViewObservationModalStore();
import UserChip from '@/components/UserChip.vue';
import {format} from 'date-fns';
import SvgLoader from '@/components/SvgLoader.vue';
import {useManageObservationModalStore} from '@/stores/modals/manage-observation-modal-store';
import {useConfigStore} from '@/stores/config-store';
const configStore = useConfigStore();

const manageObservationModalStore = useManageObservationModalStore();

const props = withDefaults(
    defineProps<{
        resultsList: ObservationSearchResultDto[];
        isFilterApplied?: boolean;
        displayToolbar?: boolean;
        openObservationDefault?: boolean;
    }>(),
    {
        resultsList: () => [] as ObservationSearchResultDto[],
        isFilterApplied: false,
        displayToolbar: true,
        openObservationDefault: true,
    },
);
const tab = ref<boolean>(true);

// TODO: This header name might need to change
const headers = computed(() => [
    {title: i18n.global.t('Type'), key: 'observationTypeCode', sortable: true},
    {title: i18n.global.t('Summary'), key: 'observationTitle', sortable: true},
    {title: i18n.global.t('Field'), key: 'farmFieldCode', sortable: true},
    {title: i18n.global.t('Crop'), key: 'farmFieldPrimaryCropcode', sortable: false},
    {title: i18n.global.t('Status'), key: 'observationStatusCode', sortable: true},
    {title: i18n.global.t('Raised'), key: 'recordedDate', sortable: true},
    {
        title: tab.value ? i18n.global.t('LastAction') : i18n.global.t('ResolvedSlashClosed'),
        key: 'lastModifiedDate',
        sortable: true,
    },
    {title: i18n.global.t('Action'), key: 'action', sortable: false},
]);

const closedObs = computed(() => props.resultsList.filter((e) => e.isClosed));
const openObs = computed(() => props.resultsList.filter((e) => !e.isClosed));
/**
 * Handle click event on a result.
 */
const handleResultClick = (event: MouseEvent, row: {item: ObservationSearchResultDto}) => {
    viewObservationModalStore.open(row.item.observationId);
};

const filterButtonClass = computed(() => (props.isFilterApplied ? ['v-btn--custom'] : ['swatchB6BG', 'swatchWHT']));

const emit = defineEmits<{
    (event: 'switch-to-search'): void;
    (event: 'export-observation-list'): void;
    (event: 'view-observation-list-on-home', isOpen: boolean): void;
}>();

const switchBackToSearch = () => {
    emit('switch-to-search');
};
const exportObservationList = () => {
    emit('export-observation-list');
};
const viewObservationListOnHome = (isOpen: boolean) => {
    emit('view-observation-list-on-home', isOpen);
};
const formatDate = (date: Date | null): string => (!date ? '' : format(date, 'dd MMM yyyy HH:mm'));

const formatLastActionDate = (item: ObservationSearchResultDto): string => {
    const date = tab.value ? item.lastModifiedDate : item.resolvedDate ?? item.archivedDate ?? item.cancelledDate;
    return !date ? '' : format(date, 'dd MMM yyyy HH:mm');
};

const getLastActionUser = (item: ObservationSearchResultDto) => {
    const [userId, fullName] = tab.value
        ? [item.lastModifiedUserId, `${item.lastModifiedUserGivenName} ${item.lastModifiedUserSurname}`]
        : item.resolvedUserId
        ? [item.resolvedUserId, `${item.resolvedUserGivenName} ${item.resolvedUserSurname}`]
        : item.archivedUserId
        ? [item.archivedUserId, `${item.archivedUserGivenName} ${item.archivedUserSurname}`]
        : item.cancelledUserId
        ? [item.cancelledUserId, `${item.cancelledUserGivenName} ${item.cancelledUserSurname}`]
        : [null, ''];
    return userId
        ? {
              userId: userId,
              username: fullName,
              profilePicture: '',
          }
        : null;
};

onMounted(async () => {
    tab.value = props.openObservationDefault;
});
</script>
<style lang="scss">
@import '@/assets/scss/swatches.scss';

#observation-operations-activator {
    border: 1px solid $swatchG5;
    background-color: $swatchGA;
    color: $swatchG1;

    &:hover {
        color: $swatchWHT;
        background-color: $swatchB6;
    }
}

.observation-list .v-table__wrapper {
    overflow-y: hidden;
}

.observation-list .v-table__wrapper thead {
    background-color: $swatchG9;
}

.observation-data-table-title {
    font-size: 0.5rem;
    max-width: 250px;
}
</style>
