import {SwatchCode} from '@/enums/swatch-code';
import {WorkTaskTypeCode} from '@/enums/work-task-type-code';
import i18n from '@/i18n';

export interface WorkTaskType {
    workTaskTypeCode: WorkTaskTypeCode;
    getWorkTaskTypeName(): string;
    getWorkTaskTypePast(): string;
    iconFamily: string;
    iconCode: string;
    color: SwatchCode;
}

export const workTaskTypes: WorkTaskType[] = [
    {
        workTaskTypeCode: WorkTaskTypeCode.Cultivate,
        getWorkTaskTypeName() {
            return i18n.global.t('WorkTaskType_WorkTaskTypeName_Cultivate');
        },
        getWorkTaskTypePast() {
            return i18n.global.t('WorkTaskType_WorkTaskTypePast_Cultivate');
        },
        iconFamily: 'fal',
        iconCode: 'shovel',
        color: SwatchCode.SwatchB5,
    },
    {
        workTaskTypeCode: WorkTaskTypeCode.Fertilize,
        getWorkTaskTypeName() {
            return i18n.global.t('WorkTaskType_WorkTaskTypeName_Fertilize');
        },
        getWorkTaskTypePast() {
            return i18n.global.t('WorkTaskType_WorkTaskTypePast_Fertilize');
        },
        iconFamily: 'fal',
        iconCode: 'bag-seedling',
        color: SwatchCode.SwatchB2,
    },
    {
        workTaskTypeCode: WorkTaskTypeCode.Harvest,
        getWorkTaskTypeName() {
            return i18n.global.t('WorkTaskType_WorkTaskTypeName_Harvest');
        },
        getWorkTaskTypePast() {
            return i18n.global.t('WorkTaskType_WorkTaskTypePast_Harvest');
        },
        iconFamily: 'fak',
        iconCode: 'harvester',
        color: SwatchCode.SwatchA1,
    },
    {
        workTaskTypeCode: WorkTaskTypeCode.Irrigate,
        getWorkTaskTypeName() {
            return i18n.global.t('WorkTaskType_WorkTaskTypeName_Irrigate');
        },
        getWorkTaskTypePast() {
            return i18n.global.t('WorkTaskType_WorkTaskTypePast_Irrigate');
        },
        iconFamily: 'fal',
        iconCode: 'droplet',
        color: SwatchCode.SwatchB6,
    },
    {
        workTaskTypeCode: WorkTaskTypeCode.Plant,
        getWorkTaskTypeName() {
            return i18n.global.t('WorkTaskType_WorkTaskTypeName_Plant');
        },
        getWorkTaskTypePast() {
            return i18n.global.t('WorkTaskType_WorkTaskTypePast_Plant');
        },
        iconFamily: 'fal',
        iconCode: 'seedling',
        color: SwatchCode.SwatchA4,
    },
    {
        workTaskTypeCode: WorkTaskTypeCode.Spray,
        getWorkTaskTypeName() {
            return i18n.global.t('WorkTaskType_WorkTaskTypeName_Spray');
        },
        getWorkTaskTypePast() {
            return i18n.global.t('WorkTaskType_WorkTaskTypePast_Spray');
        },
        iconFamily: 'fal',
        iconCode: 'sprinkler-ceiling',
        color: SwatchCode.SwatchB1,
    },
];

export function getWorkTaskTypeByCode(workTaskTypeCode: WorkTaskTypeCode): WorkTaskType | undefined {
    return workTaskTypes.find((type) => type.workTaskTypeCode === workTaskTypeCode);
}
