<template>
    <div v-if="workTask.workTaskId" style="display: flex; align-items: center">
        <WorkTaskTypeIcon
            :work-task-type-code="workTask!.workTaskTypeCode"
            icon-height="24"
            icon-width="24"
            icon-size="sm"
            :is-tooltip-disabled="true" />
        <span class="ml-4 swatchA1 bold">
            {{ workTask!.workTaskTypeName.toUpperCase() }}
        </span>
        <span v-if="workTask.workTaskName" class="swatchA1 bold"> : "{{ workTask.workTaskName }}" </span>
        <div class="ml-2 swatchA1">ID: {{ workTask.workTaskId }}</div>
        <!-- Completed/Due Date. The "9999-01-01" date comparison is done because the due date is currently set to 
         DateOnly.MaxValue when the task is dependent upon an inocmplete task. Eventually we might just set the date
         to null instead, and if that happens we can remove this date comparison. -->
        <div
            v-if="workTask.dueDate && new Date(workTask.dueDate) < new Date('9999-01-01')"
            class="ml-2 swatchA1"
            style="font-style: italic">
            ({{ formatDateForDisplay }})
        </div>
    </div>
</template>

<script setup lang="ts">
import {computed, defineProps} from 'vue';
import WorkTaskTypeIcon from '@/components/WorkTaskTypeIcon.vue';
import {format} from 'date-fns';
import {GetPredecessorWorkTasksResponse} from '@/models/api/responses/work-tasks/GetPredecessorWorkTasksResponse';

const props = defineProps<{
    workTask: GetPredecessorWorkTasksResponse;
}>();

/**
 * Format date for displaying to the user.
 */
const formatDateForDisplay = computed(() => {
    let date = null;
    if (props.workTask.isCompleted) date = props.workTask.completedDate;
    else if (props.workTask.isCancelled) date = props.workTask.cancelledDate;
    else date = props.workTask.dueDate;

    if (date != null) return format(date, 'dd MMM yyyy');
    else return null;
});
</script>
