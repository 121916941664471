<template>
    <v-tooltip bottom :disabled="isTooltipDisabled">
        <template v-slot:activator="{props}">
            <CircleIcon
                v-bind="props"
                :style="{height: iconHeight + 'px', width: iconWidth + 'px', border: border}"
                :class="iconClass">
                <FontAwesomeIcon :icon="[iconFamily, iconCode]" :size="iconSize" />
            </CircleIcon>
        </template>
        <slot name="helpText"></slot>
        {{ name }}
    </v-tooltip>
</template>

<script lang="ts">
import {PropType, defineComponent, computed} from 'vue';
import {getWorkTaskTypeByCode, WorkTaskType} from '@/services/work-task-types-service';
import {WorkTaskTypeCode} from '@/enums/work-task-type-code';

export default defineComponent({
    name: 'WorkTaskTypeIcon',
    props: {
        workTaskTypeCode: String as PropType<WorkTaskTypeCode>,
        border: {
            type: String,
            default: '1px solid white',
        },
        iconHeight: {
            type: String,
            default: '40',
        },
        iconWidth: {
            type: String,
            default: '40',
        },
        iconSize: {
            type: String,
            default: 'xl',
        },
        isTooltipDisabled: {
            default: false,
        },
    },
    setup(props) {
        const workTaskType = computed<WorkTaskType | undefined>(() => {
            return getWorkTaskTypeByCode(props.workTaskTypeCode as WorkTaskTypeCode);
        });

        const iconFamily = computed<string>(() => {
            return workTaskType.value?.iconFamily || 'fas';
        });

        const iconCode = computed<string>(() => {
            return workTaskType.value?.iconCode || 'question';
        });

        const color = computed<string>(() => {
            return workTaskType.value?.color || '#000000';
        });

        const name = computed<string | null>(() => {
            return workTaskType.value?.getWorkTaskTypeName() || null;
        });

        const iconClass = computed<string>(() => {
            return `work-task-type-icon bg-${color.value}`;
        });

        return {
            iconFamily,
            iconCode,
            name,
            iconClass,
        };
    },
});
</script>

<style lang="scss" scoped>
.work-task-type-icon {
    font-size: 18pt;
}
</style>
