<template>
    <v-dialog v-model="viewCropVarietyModalStore.isVisible" class="viewFarmSiteModal">
        <v-card style="overflow-y: hidden">
            <v-toolbar color="swatchA2">
                <v-toolbar-title class="ma-0 pa-0 pl-3">
                    <font-awesome-icon :icon="['fac', 'crop-variety']" size="xl" class="mr-3" />
                    {{ $t('ViewCropVariety') }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <CloseModalButton @click="close" />
            </v-toolbar>
            <LoadingSymbol v-if="isLoading || localCropVarietyId == null || !cropVariety" />
            <v-form v-else ref="form" class="pa-4" style="overflow-y: scroll">
                <v-row align="center" class="pl-6">
                    <v-col cols="10">
                        <v-row class="pb-4">
                            <CropIcon
                                :cropCode="cropVariety.cropCode"
                                :primary="cropVariety.isPrimary"
                                :is-show-crop-name="true"
                                iconHeight="45"
                                iconWidth="45"
                                iconSize="25" />

                            <div class="pl-6 swatchA1 slightlyLarger bold">
                                "{{ cropVariety.cropVarietyName }}" <IdIcon>{{ cropVariety.cropVarietyId }}</IdIcon>
                            </div></v-row
                        >

                        <v-row> {{ cropVariety.cropVarietyDescription }}</v-row>
                    </v-col>

                    <v-col cols="2">
                        <v-row class="d-flex justify-end mr-6">
                            <CropDistributionChart
                                v-if="cropDistributionData"
                                :chart-width="150"
                                :chart-height="150"
                                :cropSizeData="cropDistributionData"
                        /></v-row>

                        <v-row class="d-flex justify-end mr-6">
                            <FieldSizeChip
                                v-if="cropVariety.areaPlantedTotal !== null"
                                :farmFieldSize="parseFloat(cropVariety.areaPlantedTotal.toFixed(2))" />
                        </v-row>
                    </v-col>
                </v-row>
                <v-row align="center">
                    <v-col cols="2" class="pl-5">
                        {{ $t('Origin') }}
                    </v-col>
                    <v-col cols="10" class="swatchA1">{{ cropVariety.cropVarietyOrigin ?? '' }} </v-col>
                </v-row>
                <v-row align="center">
                    <v-col cols="2" class="pl-5">
                        {{ $t('DefaultSowRate') }}
                    </v-col>
                    <v-col cols="10" class="swatchA1">{{ cropVariety.defaultSowRate ?? '' }} </v-col>
                </v-row>

                <div class="pa-2"></div>
                <v-expansion-panels multiple rounded="0" v-model="openedExpansionPanels">
                    <v-expansion-panel>
                        <v-expansion-panel-title class="expansion-header pl-3">
                            <FontAwesomeIcon :icon="['fal', 'vector-square']" size="xl" class="mr-3" />
                            {{ $t('BlocksPlanted') + ' (' + farmBlocks.length + ')' }}</v-expansion-panel-title
                        >
                        <v-expansion-panel-text>
                            <v-data-table
                                class="farm-block-table pa-0 ma-0 pt-1"
                                :headers="farmBlockheaders"
                                :items="farmBlocks"
                                :items-per-page="-1"
                                hide-default-footer>
                                <template v-slot:[`item.farmSiteName`]="{item}">
                                    <v-col align="center" v-if="item.farmSiteId">
                                        <FarmSiteChip
                                            v-if="item.farmSiteAbbreviation && item.farmSiteName"
                                            :farm-site-abbreviation="item.farmSiteAbbreviation"
                                            :farm-site-name="item.farmSiteName" />
                                    </v-col>
                                </template>
                                <template v-slot:[`item.farmBlockCode`]="{item}">
                                    <div class="tableCell">
                                        <b class="swatchA1">{{ item.farmBlockCode }}</b>
                                    </div>
                                </template>
                                <template v-slot:[`item.farmFieldCropYear`]="{item}">
                                    <v-col align="center" v-if="item.farmFieldCropYear">
                                        <FarmFieldAgeIcon
                                            iconHeight="40"
                                            iconWidth="40"
                                            :fieldAge="item.farmFieldCropYear.toString()" />
                                    </v-col>
                                </template>

                                <template v-slot:[`item.seedOrigin`]="{item}">
                                    <v-col align="center" v-if="item.seedOriginFarmBlock">
                                        <SeedOriginChip
                                            :farmSiteAbbreviation="item.farmSiteAbbreviation"
                                            :farmFieldCode="item.farmFieldCode"
                                            :farmBlockCode="item.farmBlockCode" />
                                    </v-col>
                                    <v-col align="center" v-else> N/A </v-col>
                                </template>

                                <template v-slot:[`item.farmBlockNumRows`]="{item}">
                                    <div class="tableCell">
                                        <span class="swatchA1"
                                            >{{ item.farmBlockStartRow + '-' + item.farmBlockEndRow + ' ' }}
                                        </span>
                                        (<span class="swatchA1">
                                            {{ item.farmBlockNumRows }}
                                        </span>
                                        <span class="swatchBLK">{{
                                            ' ' + (item.farmBlockNumRows > 1 ? $t('Rows') : $t('Row'))
                                        }}</span
                                        >)
                                    </div>
                                </template>

                                <template v-slot:[`item.farmBlockSize`]="{item}">
                                    <div class="tableCell">
                                        {{ item.farmBlockSize + ' ha' }}
                                    </div>
                                </template>

                                <template v-slot:[`item.action`]="{item}">
                                    <div style="text-align: center">
                                        <v-menu>
                                            <template v-slot:activator="{props}">
                                                <v-btn rounded="1" elevation="2" v-bind="props">
                                                    <template v-slot:append>
                                                        <FontAwesomeIcon :icon="['fas', 'caret-down']" size="xl" />
                                                    </template>
                                                    {{ $t('Actions') }}
                                                </v-btn>
                                            </template>

                                            <v-list>
                                                <v-list-item v-if="item.farmFieldId">
                                                    <v-list-item-title>
                                                        <v-btn
                                                            @click="
                                                                viewFarmFieldModalStore.open(item.farmFieldId);
                                                                viewCropVarietyModalStore.close();
                                                            ">
                                                            {{ $t('ViewField') }}
                                                        </v-btn>
                                                    </v-list-item-title>
                                                </v-list-item>
                                                <v-list-item
                                                    v-if="
                                                        item.seedOriginFarmBlock && item.seedOriginFarmBlock.farmFieldId
                                                    ">
                                                    <v-list-item-title>
                                                        <v-btn
                                                            @click="
                                                                viewFarmFieldModalStore.open(
                                                                    item.seedOriginFarmBlock.farmFieldId,
                                                                );
                                                                viewCropVarietyModalStore.close();
                                                            ">
                                                            {{ $t('ViewSeedBlock') }}
                                                        </v-btn>
                                                    </v-list-item-title>
                                                </v-list-item>
                                                <v-list-item v-if="item.farmSiteId && item.farmFieldId">
                                                    <v-list-item-title>
                                                        <v-btn
                                                            @click="identifyBlock(item.farmSiteId, item.farmFieldId)">
                                                            {{ $t('ShowBlockOnMap') }}
                                                        </v-btn>
                                                    </v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </div>
                                </template>
                            </v-data-table>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                    <div class="pa-2"></div>
                    <v-expansion-panel>
                        <v-expansion-panel-title class="expansion-header pl-3">
                            <FontAwesomeIcon :icon="['fal', 'files']" size="xl" class="mr-3" />
                            {{ $t('DataSheetsAndFiles') + ' (' + artifacts.length + ')' }}</v-expansion-panel-title
                        >
                        <v-expansion-panel-text>
                            <v-data-table
                                class="pa-0 ma-0 pt-1 blocks-planted-table"
                                :headers="artifactHeader"
                                :items="artifacts"
                                :items-per-page="-1"
                                hide-default-footer>
                                <template v-slot:[`item.artifactFileTypeCode`]="{item}">
                                    <v-col align="center">
                                        <FileTypeButton
                                            :url="item.artifactUrl"
                                            :display-name="undefined"
                                            :file-type-code="item.artifactFileTypeCode" />
                                    </v-col>
                                </template>
                                <template v-slot:[`item.artifactDisplayName`]="{item}">
                                    <div class="swatchA1 slightlyLarger bold pa-0 ma-0" style="max-width: 200px">
                                        {{ item.artifactDisplayName }}
                                    </div>
                                </template>
                                <template v-slot:[`item.artifactDescription`]="{item}">
                                    <div
                                        class="swatchA1 pa-0 ma-0"
                                        v-if="item.artifactDescription"
                                        style="min-width: 250px">
                                        {{ item.artifactDescription }}
                                    </div>
                                </template>

                                <template v-slot:[`item.artifactFileSize`]="{item}">
                                    <v-col
                                        cols="12"
                                        align="center"
                                        class="swatchA1 pa-0 ma-0"
                                        v-if="item.artifactFileSize !== null"
                                        style="min-width: 100px">
                                        {{ parseFloat((item.artifactFileSize / 1024).toFixed(4)) + ' MB' }}
                                    </v-col>
                                </template>

                                <template v-slot:[`item.artifactCreatedDate`]="{item}">
                                    <v-col
                                        cols="12"
                                        align="center"
                                        class="swatchA1 pa-0 ma-0"
                                        v-if="item.artifactCreatedDate"
                                        style="min-width: 180px">
                                        {{ formatDateTime(item.artifactCreatedDate) }}
                                    </v-col>
                                </template>
                            </v-data-table>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                    <div class="pa-2"></div>
                    <v-expansion-panel>
                        <v-expansion-panel-title class="expansion-header pl-3">
                            <FontAwesomeIcon :icon="['fal', 'arrow-up-right-from-square']" size="xl" class="mr-3" />
                            {{ $t('Links') + ' (' + links.length + ')' }}</v-expansion-panel-title
                        >
                        <v-expansion-panel-text>
                            <v-data-table
                                class="pa-0 ma-0 pt-1 blocks-planted-table"
                                :headers="linkHeader"
                                :items="links"
                                :items-per-page="-1"
                                hide-default-footer>
                                <template v-slot:[`item.linkId`]="{item}">
                                    <v-col align="center">
                                        <FileTypeButton
                                            :url="item.linkUrl"
                                            :display-name="undefined"
                                            :file-type-code="ArtifactFileTypeCode.Other" />
                                    </v-col>
                                </template>
                                <template v-slot:[`item.linkName`]="{item}">
                                    <div class="swatchA1 slightlyLarger bold pa-0 ma-0" style="max-width: 200px">
                                        {{ item.linkName }}
                                    </div>
                                </template>
                                <template v-slot:[`item.linkUrl`]="{item}">
                                    <div
                                        class="swatchA1 slightlySmaller pa-0 ma-0"
                                        v-if="item.linkUrl"
                                        style="min-width: 250px">
                                        {{ item.linkUrl }}
                                    </div>
                                </template>

                                <template v-slot:[`item.linkCreatedDate`]="{item}">
                                    <v-col
                                        cols="12"
                                        align="center"
                                        class="swatchA1 pa-0 ma-0"
                                        v-if="item.linkCreatedDate"
                                        style="min-width: 180px">
                                        {{ formatDateTime(item.linkCreatedDate) }}
                                    </v-col>
                                </template>
                            </v-data-table>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-form>

            <v-card-actions class="justify-space-between swatchG9BG" v-if="!isLoading && localCropVarietyId != null">
                <v-btn @click="close">
                    <template v-slot:prepend>
                        <FontAwesomeIcon :icon="['fal', 'arrow-rotate-left']" size="xl" />
                    </template>
                    {{ $t('Back') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
import '@/assets/scss/farm/view-farm-site-modal.scss';
import 'leaflet/dist/leaflet.css';
import '@/assets/scss/home-dashboard/home-dashboard-map.scss';

import {useViewCropVarietyModalStore} from '@/stores/modals/view-crop-variety-modal-store';
import {watch, inject, ref, onUnmounted} from 'vue';
import ApiService from '@/services/api-service.js';
import CloseModalButton from '@/components/CloseModalButton.vue';
import {CropVarietyDto} from '@/models/data-transfer-objects/search/crop-variety-search/CropVarietySearchDto';
import {SearchCropVarietiesResponse} from '@/models/api/responses/search/SearchCropVarietiesResponse';
import FieldSizeChip from '@/components/other-chips/FieldSizeChip.vue';
import i18n from '@/i18n';
import FarmSiteChip from '@/components/other-chips/FarmSiteChip.vue';
import {format} from 'date-fns';
import {SearchCropVarietiesRequest} from '@/models/api/requests/search/SearchCropVarietiesRequest';
import {DetailedArtifactDto} from '@/models/data-transfer-objects/system/DetailedArtifactDto';
import FileTypeButton from '@/components/FileTypeButton.vue';
import {LinkDto} from '@/models/data-transfer-objects/system/LinkDto';
import {ArtifactFileTypeCode} from '@/enums/artifact-file-type-code';
import {FarmBlockDto} from '@/models/data-transfer-objects/search/farm-field-search/FarmBlockDto';
import {useFarmStore} from '@/stores/farm-store';
const farmStore = useFarmStore();
import {useUserStore} from '@/stores/user-store';
const userStore = useUserStore();
import {useAdvancedSearchModalStore} from '@/stores/modals/advanced-search-modal-store';
const advancedSearchModalStore = useAdvancedSearchModalStore();
import {useHomeDashboardStore} from '@/stores/home-dashboard-store';
const homeDashboardStore = useHomeDashboardStore();
import router from '@/router';
import FarmFieldAgeIcon from '@/components/FarmFieldAgeIcon.vue';
import SeedOriginChip from '@/components/other-chips/SeedOriginChip.vue';
import {useViewFarmFieldModalStore} from '@/stores/modals/view-farm-field-modal-store';
import {GetCropDistributionResponse} from '@/models/api/responses/crops/GetCropDistributionResponse';
import CropDistributionChart from '@/components/CropDistributionChart.vue';

// Form
let isLoading = ref<boolean>(false);

const viewCropVarietyModalStore = useViewCropVarietyModalStore();
const isConfirmed = ref<boolean>(false);
const localCropVarietyId = ref<number | null>(null);
const cropVariety = ref<CropVarietyDto | null>(null);
const farmBlocks = ref<FarmBlockDto[]>([]);
const artifacts = ref<DetailedArtifactDto[]>([]);
const links = ref<LinkDto[]>([]);
const viewFarmFieldModalStore = useViewFarmFieldModalStore();
const cropDistributionData = ref<GetCropDistributionResponse | null>(null);

const farmBlockheaders = ref([
    {title: i18n.global.t('FarmSite'), key: 'farmSiteName', sortable: false},
    {title: i18n.global.t('BlockCode'), key: 'farmBlockCode', sortable: true},
    {title: i18n.global.t('CropYear'), key: 'farmFieldCropYear', sortable: true},
    {title: i18n.global.t('SeedOrigin'), key: 'seedOrigin', sortable: true},
    {title: i18n.global.t('Rows'), key: 'farmBlockNumRows', sortable: true},
    {title: i18n.global.t('Area'), key: 'farmBlockSize', sortable: true},
    {title: '', key: 'action', sortable: false},
]);

const artifactHeader = ref([
    {title: i18n.global.t('Icon'), key: 'artifactFileTypeCode', sortable: false},
    {title: i18n.global.t('Name'), key: 'artifactDisplayName', sortable: true},
    {title: i18n.global.t('Description'), key: 'artifactDescription', sortable: false},
    {title: i18n.global.t('Size'), key: 'artifactFileSize', sortable: false},
    {title: i18n.global.t('Date'), key: 'artifactCreatedDate', sortable: true},
]);

const linkHeader = ref([
    {title: i18n.global.t('Icon'), key: 'linkId', sortable: false},
    {title: i18n.global.t('Name'), key: 'linkName', sortable: true},
    {title: i18n.global.t('Description'), key: 'linkUrl', sortable: false},
    {title: i18n.global.t('Date'), key: 'linkCreatedDate', sortable: true},
]);

const openedExpansionPanels = ref<number[]>([0, 1, 2]);
// Services
const apiService = inject('apiService') as ApiService;

/**
 * Load data for the modal.
 */
const loadData = async (cropVarietyId: number) => {
    isLoading.value = true;

    // Reset form
    resetForm();

    // Set data
    localCropVarietyId.value = cropVarietyId;

    // Get crop variety details
    const searchResults = (await apiService.post('search/crop-varieties', {
        isQuickSearch: false,
        cropVarietyIdList: [cropVarietyId],
        includeFarmBlocks: true,
    } as SearchCropVarietiesRequest)) as SearchCropVarietiesResponse;

    if (searchResults.crops.length === 1) {
        cropVariety.value = searchResults.crops[0];
        artifacts.value = searchResults.crops[0].artifacts;
        links.value = searchResults.crops[0].links;
        farmBlocks.value = searchResults.crops[0].farmBlocks;
        if (searchResults.crops[0].isPrimary && searchResults.crops[0].cropId) {
            const cropSizeResponse = await apiService.get(`crops/crop-distribution/${searchResults.crops[0].cropId}`);
            if (cropSizeResponse && cropSizeResponse.cropSizes && cropSizeResponse.cropSizes.length === 1) {
                cropDistributionData.value = cropSizeResponse.cropSizes[0];
            }
        }
    }

    //close no data panels
    const expandPanel = [];
    if (farmBlocks.value.length > 0) expandPanel.push(0);
    if (artifacts.value.length > 0) expandPanel.push(1);
    if (links.value.length > 0) expandPanel.push(2);
    openedExpansionPanels.value = expandPanel;

    isLoading.value = false;
};

/**
 * Close the modal.
 */
const close = () => {
    viewCropVarietyModalStore.close();
};

onUnmounted(() => {
    viewCropVarietyModalStore.close();
});

/**
 * Reset the form to its default state.
 */
const resetForm = () => {
    cropVariety.value = null;
    isConfirmed.value = false;
    farmBlocks.value = [];
    artifacts.value = [];
    links.value = [];
    cropDistributionData.value = null;
};

// Watch for changes to viewUserModalStore.userId
watch(
    () => viewCropVarietyModalStore.isVisible,
    async (val) => {
        if (val && viewCropVarietyModalStore.cropVarietyId !== null) {
            await loadData(viewCropVarietyModalStore.cropVarietyId);
        }
    },
);

const formatDateTime = (date: Date | null): string | null => {
    if (!date) return null;

    return format(date, 'dd/MM/yyyy h:mm a');
};

// view field on map
async function identifyBlock(farmSiteId: null | number, farmFieldId: null | number) {
    if (farmSiteId && farmFieldId) {
        if (farmStore.selectedSiteId !== farmSiteId) {
            farmStore.selectedSiteId = farmSiteId;
            if (userStore.isLoggedIn && userStore.user) userStore.user.farmSiteId = farmSiteId;
            setTimeout(() => {
                homeDashboardStore.farmFieldIdToFocusOn = farmFieldId;
            }, 1000);
        } else homeDashboardStore.farmFieldIdToFocusOn = farmFieldId;

        advancedSearchModalStore.close();
        viewCropVarietyModalStore.close();

        if (!homeDashboardStore.isHomeDashboardOpen) {
            router.push({name: 'HomeDashboard'});
        }
    }
}
</script>
<style lang="scss">
@import '@/assets/scss/swatches.scss';
.blocks-planted-table .v-table__wrapper thead {
    background-color: $swatchG9;
}
</style>
