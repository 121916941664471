<template>
    <v-form
        ref="form"
        v-model="valid"
        validate-on="submit"
        class="work-task-product-item"
        :class="{'new-item': isAddNewItem}">
        <table style="width: 100%; border-spacing: 1px; margin-bottom: -1px" class="tblForm swatchG9BG">
            <tr v-if="index == 0 || workTaskCropVarietyPreferenceList.length == 0">
                <td class="swatchG7BG">{{ $t('NumberShort') }}</td>
                <td class="swatchG7BG">{{ $t('Variety') }}</td>
                <td class="swatchG7BG">{{ $t('SeedOrigin') }}</td>
                <td class="swatchG7BG">{{ $t('SeedRate') }}</td>
                <td class="swatchG7BG"></td>
            </tr>
            <tr>
                <td style="width: 7%; text-align: center" class="swatchWHTBG">
                    <span v-if="isAddNewItem">NEW</span>
                    <span v-if="!isAddNewItem">{{ index + 1 }}</span>
                </td>
                <td style="width: 32%" class="swatchWHTBG">
                    <div style="display: flex; align-items: center">
                        <v-autocomplete
                            v-model="localWorkTaskCropVarietyPreference.cropVarietyId"
                            :rules="[requiredRule, uniqueRule]"
                            required
                            :readonly="!isAddNewItem"
                            :placeholder="$t('SelectAVariety')"
                            :items="cropVarieties"
                            item-title="cropVarietyName"
                            item-value="cropVarietyId"
                            max-width="250px"
                            menu-icon=""
                            class="swatchA1 font-weight-bold"
                            :class="{noSelection: !isAddNewItem}">
                            <template v-slot:append-inner>
                                <CircleIcon
                                    class="marginLeft marginRightSmall swatchG2BG"
                                    color="swatchWHT"
                                    v-if="!isAddNewItem">
                                    <FontAwesomeIcon :icon="['fas', 'lock']" />
                                </CircleIcon>
                                <CircleIcon
                                    class="marginLeft marginRightSmall swatchGRNBG"
                                    color="swatchWHT"
                                    v-else-if="localWorkTaskCropVarietyPreference.cropVarietyId != null">
                                    <FontAwesomeIcon :icon="['fas', 'check']" />
                                </CircleIcon>
                            </template>
                        </v-autocomplete>

                        <!-- View Crop Variety button -->
                        <v-btn
                            v-if="localWorkTaskCropVarietyPreference.cropVarietyId != null"
                            @click="viewCropVarietyModalStore.open(localWorkTaskCropVarietyPreference.cropVarietyId)"
                            class="v-btn--custom ml-4">
                            <template v-slot:prepend>
                                <FontAwesomeIcon :icon="['fal', 'arrow-circle-right']" size="xl" />
                            </template>
                            {{ $t('View') }}
                        </v-btn>
                    </div>
                </td>
                <td style="width: 23%; text-align: center" class="swatchWHTBG">
                    <v-autocomplete
                        v-model="localWorkTaskCropVarietyPreference.seedOriginBlockId"
                        :placeholder="$t('SelectSeedOrigin')"
                        :items="
                            farmBlocks.filter(
                                (fb) =>
                                    localWorkTaskCropVarietyPreference.cropVarietyId != null &&
                                    fb.cropVarietyId == localWorkTaskCropVarietyPreference.cropVarietyId,
                            )
                        "
                        item-title="farmBlockFullName"
                        item-value="farmBlockId"
                        max-width="250px"
                        menu-icon=""
                        class="swatchA1 font-weight-bold">
                        <template v-slot:append-inner>
                            <CircleIcon
                                class="marginLeft marginRightSmall swatchGRNBG"
                                color="swatchWHT"
                                v-if="localWorkTaskCropVarietyPreference.seedOriginBlockId != null">
                                <FontAwesomeIcon :icon="['fas', 'check']" />
                            </CircleIcon>
                        </template>
                    </v-autocomplete>
                </td>
                <td style="width: 26%" class="swatchWHTBG">
                    <div style="display: flex; align-items: center">
                        <v-text-field
                            v-model="localWorkTaskCropVarietyPreference.seedRate"
                            :rules="[requiredRule, numericGreaterThanZeroRule]"
                            required
                            max-width="100px"
                            class="swatchA1 font-weight-bold" />

                        <v-select
                            v-model="localWorkTaskCropVarietyPreference.seedRateUnitCode"
                            :rules="[requiredRule]"
                            required
                            :items="seedRateUnits"
                            :item-title="(item: SeedRateUnit) => item.getSeedRateUnitName()"
                            item-value="seedRateUnitCode"
                            max-width="180px"
                            class="ml-2" />
                    </div>
                </td>
                <td style="width: 12%; text-align: center" class="swatchWHTBG">
                    <v-btn
                        v-if="isAddNewItem"
                        @click="addWorkTaskCropVarietyPreference"
                        class="v-btn--custom"
                        style="align-self: flex-end; max-width: 100%">
                        <template v-slot:prepend>
                            <FontAwesomeIcon :icon="['fal', 'plus']" size="xl" />
                        </template>
                        {{ $t('Add') }}
                    </v-btn>

                    <v-btn
                        v-if="!isAddNewItem"
                        @click="deleteWorkTaskCropVarietyPreference(index)"
                        class="v-btn--custom"
                        style="align-self: flex-end; max-width: 100%">
                        <template v-slot:prepend>
                            <FontAwesomeIcon :icon="['fal', 'trash']" size="xl" />
                        </template>
                        {{ $t('Delete') }}
                    </v-btn>
                </td>
            </tr>
        </table>
    </v-form>
</template>

<script setup lang="ts">
import Validation from '@/helpers/ValidationHelper';
import i18n from '@/i18n';
import {computed, nextTick, onMounted, ref, watch} from 'vue';
import {WorkTaskCropVarietyPreferenceForm} from '@/models/work-tasks/WorkTaskCropVarietyPreferenceForm';
import {GetCropVarietiesResponse} from '@/models/api/responses/data-source/GetCropVarietiesResponse';
import {GetFarmBlocksResponse} from '@/models/api/responses/data-source/GetFarmBlocksResponse';
import {SeedRateUnit, seedRateUnits} from '@/services/seed-rate-units-service';
import {useViewCropVarietyModalStore} from '@/stores/modals/view-crop-variety-modal-store';
const viewCropVarietyModalStore = useViewCropVarietyModalStore();

const props = withDefaults(
    defineProps<{
        workTaskCropVarietyPreference?: WorkTaskCropVarietyPreferenceForm | null;
        workTaskCropVarietyPreferenceList: WorkTaskCropVarietyPreferenceForm[];
        cropVarieties: GetCropVarietiesResponse[];
        farmBlocks: GetFarmBlocksResponse[];
        index: number;
    }>(),
    {
        workTaskCropVarietyPreference: null,
    },
);
const emit = defineEmits(['add', 'update', 'delete']);

// Form
const form = ref();
const valid = ref<boolean>(true);
const isLoading = ref<boolean>(true);
const localWorkTaskCropVarietyPreference = ref<WorkTaskCropVarietyPreferenceForm>(
    new WorkTaskCropVarietyPreferenceForm(),
);
let newItemCount = 0;

// Rules
const requiredRule = Validation.createRule_Required();
const numericGreaterThanZeroRule = Validation.createRule_Numeric(
    i18n.global.t('Validation_MustBeGreaterThanZero'),
    0.000000000001,
);
const uniqueRule = ref((v: number | null) => {
    if (!v) return true;
    if (!isAddNewItem.value) return true;
    return (
        props.workTaskCropVarietyPreferenceList.filter((cv) => cv.cropVarietyId == v).length == 0 ||
        i18n.global.t('Validation_VarietyPreferenceUnique')
    );
});

/**
 * Adds the variety preference.
 */
const addWorkTaskCropVarietyPreference = async () => {
    // Perform final client side validation of form
    await validate();

    // If form is valid
    if (valid.value) {
        // Increment new item count so that new items have a unique ID
        newItemCount += 1;

        // Set new item ID
        localWorkTaskCropVarietyPreference.value.newItemId = 'NewItem' + newItemCount;

        // Call emit
        emit('add', localWorkTaskCropVarietyPreference.value);

        // Reset data so that another item can be added if needed
        localWorkTaskCropVarietyPreference.value = new WorkTaskCropVarietyPreferenceForm();

        // Set default values
        setDefaultValues();
    }
};

/**
 * Deletes the product.
 */
const deleteWorkTaskCropVarietyPreference = (workTaskCropVarietyPreferenceIndex: number) => {
    emit('delete', workTaskCropVarietyPreferenceIndex);
};

/**
 * Set default values.
 */
const setDefaultValues = () => {
    // Determine which seed rate unit to use by default
    let defaultSeedRateUnit = seedRateUnits.find((sru) => sru.isDefault) || null;

    // Set default seed rate unit
    localWorkTaskCropVarietyPreference.value.seedRateUnitCode = defaultSeedRateUnit?.seedRateUnitCode || null;

    // Clear seed rate
    localWorkTaskCropVarietyPreference.value.seedRate = 0;
};

/**
 * Validate the form.
 */
const validate = async (): Promise<boolean> => {
    if (form.value) {
        const {valid} = await form.value.validate();
        return valid;
    }
    return false;
};

/**
 * Flag to indicate if this is in "Add" mode or if it's displaying an existing work task product.
 */
const isAddNewItem = computed(() => props.index == -1);

// Update parent when the data is changed.
watch(
    () => localWorkTaskCropVarietyPreference,
    () => {
        if (!isLoading.value) {
            emit('update', localWorkTaskCropVarietyPreference.value);
        }
    },
    {deep: true},
);

// When the Variety is changed, clear the Seed Origin selection.
watch(
    () => localWorkTaskCropVarietyPreference.value.cropVarietyId,
    () => {
        if (!isLoading.value) {
            localWorkTaskCropVarietyPreference.value.seedOriginBlockId = null;
        }
    },
    {deep: true},
);

onMounted(async () => {
    if (props.workTaskCropVarietyPreference) {
        localWorkTaskCropVarietyPreference.value = Object.assign(
            new WorkTaskCropVarietyPreferenceForm(),
            props.workTaskCropVarietyPreference,
        );
    } else {
        setDefaultValues();
    }

    // End loading (nextTick is used to ensure that the watch events on localWorkTaskCropVarietyPreference are not triggered during the load)
    nextTick(() => {
        isLoading.value = false;
    });
});

defineExpose({validate});
</script>
