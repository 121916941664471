<template>
    <div>
        <v-dialog v-model="isVisible">
            <v-card>
                <v-form ref="form" class="pa-4" style="overflow-y: scroll; height: 100%" @keyup.enter="search">
                    <farm-field-search-parameters v-model:searchParameters="localSearchParametersFarmField" />
                </v-form>
                <v-card-actions class="swatchG9BG">
                    <v-btn @click="isVisible = false">
                        <template v-slot:prepend>
                            <FontAwesomeIcon :icon="['fal', 'arrow-rotate-left']" size="xl" />
                        </template>
                        {{ $t('Cancel') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="search" :disabled="isLoading">
                        <template v-slot:prepend>
                            <FontAwesomeIcon :icon="['fal', 'search']" size="xl" />
                        </template>
                        {{ $t('Search') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <LoadingSymbol v-if="isLoading" />

        <farm-field-search-results
            v-else
            @switch-to-search="openSearchModal"
            @view-fields-on-home="viewFieldListOnHome"
            :is-filter-applied="isFilterApplied"
            :results-list="searchResultsFarmField" />
    </div>
</template>

<script setup lang="ts">
import {computed, ref, inject, onMounted, watch} from 'vue';
import ApiService from '@/services/api-service.js';
import {SearchFarmFieldsRequest} from '@/models/api/requests/search/SearchFarmFieldsRequest';
import {FarmFieldSearchResultDto} from '@/models/data-transfer-objects/search/farm-field-search/FarmFieldSearchResultDto';
import {SearchFarmFieldsResponse} from '@/models/api/responses/search/SearchFarmFieldsResponse';
import FarmFieldSearchParameters from '@/views/search/FarmFieldSearchParameters.vue';
import FarmFieldSearchResults from '@/views/search/FarmFieldSearchResults.vue';
import {useFarmStore} from '@/stores/farm-store';
const farmStore = useFarmStore();
import {useHomeDashboardStore} from '@/stores/home-dashboard-store';
const homeDashboardStore = useHomeDashboardStore();
import {useAdvancedSearchModalStore} from '@/stores/modals/advanced-search-modal-store';
const advancedSearchModalStore = useAdvancedSearchModalStore();
import {NavigatorPanelTab} from '@/enums/home-dashboard/navigator-panel-tab';
import router from '@/router';
import {useRoute} from 'vue-router';
const route = useRoute();

// Services
const apiService = inject('apiService') as ApiService;

//Local variables
const isVisible = ref<boolean>(false);
const isLoading = ref<boolean>(false);
const searchParametersFarmField = ref(new SearchFarmFieldsRequest());
const localSearchParametersFarmField = ref(new SearchFarmFieldsRequest());
const searchResultsFarmField = ref<FarmFieldSearchResultDto[]>([]);

const isFilterApplied = computed(
    () =>
        !!(
            (searchParametersFarmField.value.isQuickSearch &&
                (searchParametersFarmField.value.quickSearchText === null ||
                    searchParametersFarmField.value.quickSearchText === '')) ||
            (!searchParametersFarmField.value.isQuickSearch &&
                searchParametersFarmField.value.cropId === null &&
                (searchParametersFarmField.value.cropVarietyName === null ||
                    searchParametersFarmField.value.cropVarietyName === '') &&
                (searchParametersFarmField.value.farmFieldName === null ||
                    searchParametersFarmField.value.farmFieldName === '') &&
                (searchParametersFarmField.value.cropTypeIdList === null ||
                    searchParametersFarmField.value.cropTypeIdList.length === 0) &&
                (searchParametersFarmField.value.workTaskTypeCodeList === null ||
                    searchParametersFarmField.value.workTaskTypeCodeList.length === 0) &&
                (searchParametersFarmField.value.workTaskStatus === null ||
                    searchParametersFarmField.value.workTaskStatus === '') &&
                (searchParametersFarmField.value.productName === null ||
                    searchParametersFarmField.value.productName === '') &&
                searchParametersFarmField.value.cropYear === null &&
                searchParametersFarmField.value.farmSiteIdList &&
                searchParametersFarmField.value.farmSiteIdList.length === 1 &&
                searchParametersFarmField.value.farmSiteIdList[0] === farmStore.selectedSiteId)
        ),
);

/**
 * Open search modal
 */
async function openSearchModal() {
    isVisible.value = true;
}

/**
 * Save the modal.
 */
const search = async () => {
    isLoading.value = true;

    searchParametersFarmField.value = Object.assign(
        new SearchFarmFieldsRequest(),
        localSearchParametersFarmField.value,
    );

    //Include farm blocks and open observations
    searchParametersFarmField.value.includeFarmBlocks = true;
    searchParametersFarmField.value.includeOpenObservations = true;
    searchParametersFarmField.value.includeFarmSeasons = true;

    const response: SearchFarmFieldsResponse = await apiService.post(
        'search/farm-fields',
        searchParametersFarmField.value,
    );
    searchResultsFarmField.value = response.farmFields;

    isLoading.value = false;
    isVisible.value = false;
};

onMounted(async () => {
    await loadFarmFieldList(route.params.cropVarietyId);
});

const loadFarmFieldList = async (val: string | string[] | null) => {
    if (farmStore.selectedSiteId) localSearchParametersFarmField.value.farmSiteIdList = [farmStore.selectedSiteId];
    if (homeDashboardStore.parameterFarmFields.length > 0) {
        localSearchParametersFarmField.value.farmFieldIdList = [...homeDashboardStore.parameterFarmFields];
        homeDashboardStore.parameterFarmFields = [];
    }
    if (val && Number.isInteger(Number(val))) {
        localSearchParametersFarmField.value.cropVarietyIdList = [Number(val)];
    }
    await search();
    localSearchParametersFarmField.value = Object.assign(
        new SearchFarmFieldsRequest(),
        searchParametersFarmField.value,
    );
};

const viewFieldListOnHome = () => {
    homeDashboardStore.resetFarmFieldSearchParameters();
    homeDashboardStore.navigatorPanelActiveTab = NavigatorPanelTab.FarmFields;
    homeDashboardStore.farmFields = searchResultsFarmField.value;
    homeDashboardStore.fieldPanelVersion += 1;

    advancedSearchModalStore.close();

    if (!homeDashboardStore.isHomeDashboardOpen) {
        homeDashboardStore.farmFieldPreventInitialLoading = true;
        router.push({name: 'HomeDashboard'});
    }
};

watch(
    () => route.params.cropVarietyId,
    async (val) => {
        await loadFarmFieldList(val);
    },
);
</script>
